<div class="card-body" style="display: flex; flex-direction: column;">
    <ng-container *ngIf="card">
        <h3>{{ card.libelle }}</h3>
        <div *ngIf="!card.charts || card.charts?.length  === 1" class="buttons-container">
            <div *ngFor="let line of card.charts; let index = index;" style="">
                <button type="button"
                        class="btn specific-color-bckg-2 btn-sm"
                        (click)="lineButtonClick(index)">{{ line.names }}</button>
            </div>
        </div>
        <div class="canvas-container" #container>
            <canvas
                    baseChart
                    [datasets]="lineChartDatasets"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [chartType]="'line'"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
        </div>
    </ng-container>
</div>
