export class ColorsUtils {

    public static colors: string[] = [
        '#84BA3F',
        '#45BBE0',
        '#db2d2e',
        '#cfcfcf',
        '#ebb000',
        '#343a40',
        '#0284ed',
        '#f4f4f4',
        '#07cb79',
        '#dc3545',
        '#28a745',
        '#E5E5E5',
        '#ffc107',
        '#555555',
        '#00a4bd',
        '#84ba3f',
    ];
    public static lastColor = '#E5E5E5';


    public static colorsEnseigne = [
        {
            enseigne: 388,
            colors: [
                '#0b5aa1',
                '#e0001b',
                '#000'
            ]
        },
        {
            enseigne: 64,
            colors: [
                '#0b5aa1',
                '#e0001b',
                '#000'
            ]
        }
    ];

    static hexaToRgb(hexa: string): { r: number, g: number, b: number } {
        const regex = /[0-9A-Fa-f]{6}/g;
        if ((hexa.length !== 4 && hexa.length !== 7) || !regex.test(hexa)) {
            return null;
        }
        hexa = hexa.substr(1, hexa.length);
        return {
            r: parseInt(hexa.substr(0, 2), 16),
            g: parseInt(hexa.substr(2, 2), 16),
            b: parseInt(hexa.substr(4, 2), 16)
        };
    }

    static rgbToHexa(rgb: { r: number, g: number, b: number }): string {
        if (!(rgb.r >= 0 && rgb.r <= 255 && rgb.g >= 0 && rgb.g <= 255 && rgb.b >= 0 && rgb.b <= 255)) {
            return null;
        }
        // tslint:disable-next-line:no-bitwise
        return '#' + ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1);
    }

    static getEnseigneColor(enseigne: number): string[] {
        return this.colorsEnseigne.find(item => item.enseigne === enseigne)?.colors;
    }

    static getColors(length: number): string[] {
        if (length <= this.colors.length) {
            return this.colors;
        } else {
            do {
                this.colors.push(this.getRandomColor());
            } while (this.colors.length < length);
            this.colors[this.colors.length - 1] = this.lastColor;
            return this.colors;
        }
    }

    static getColor(index: number): string {
        if (index < this.colors.length) {
            return this.colors[index];
        } else {
            return this.getRandomColor();
        }
    }

    static getRandomColor(): string {
        // let tmp: { r: number, g: number, b: number };
        // do {
        //     tmp = {r: Math.floor(Math.random() * 256), g: Math.floor(Math.random() * 256), b: Math.floor(Math.random() * 256)};
        // } while (tmp.r + tmp.g + tmp.b > 450);
        // return this.rgbToHexa(tmp);
        return this.rgbToHexa({
            r: Math.floor(Math.random() * 256),
            g: Math.floor(Math.random() * 256),
            b: Math.floor(Math.random() * 256)
        });
    }
}
