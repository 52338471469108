<div class="wrapper" [class.slide-menu]="isSlideMenu">
    <!--=================================
     preloader -->
    <div id="pre-loader" *ngIf="commondataService.loader.value">
        <img src="assets/images/pre-loader/loader-01.svg" alt="">
    </div>
    <!--=================================
     preloader -->
    <!--=================================
     header start-->
    <nav class="admin-header navbar navbar-default col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <!-- logo -->
        <div class="text-left navbar-brand-wrapper">
            <a style="cursor: pointer;" (click)="goToExistingProduct()" class="navbar-brand brand-logo">
                <img src="assets/images/logo-dark.webp" alt=""/>
            </a>
            <a class="navbar-brand brand-logo-mini">
                <img src="assets/images/logo-icon-dark.png" alt="">
            </a>
        </div>

        <!-- Top bar left -->
        <ul class="nav navbar-nav mr-auto">
            <!-- Collapse left menu button -->
            <li class="nav-item">
                <a (click)="toggleMenu()" id="button-toggle" class="button-toggle-nav inline-block ml-20 pull-left"
                   style="cursor: pointer">
                    <i *ngIf="!isSlideMenu" class="fa fa-chevron-left fa-lg mr-10"></i>
                    <i *ngIf="isSlideMenu" class="fa fa-chevron-right fa-lg mr-10"></i>
                </a>
            </li>

            <li  class="nav-item nav-item-layout">
                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                    <button type="button"
                            class="btn btn-outline-primary me-2"
                            id="dropdownManualdashboard" ngbDropdownAnchor
                            (focus)="myDrop.open()">
                        <i class="fa fa-signal fa-lg mr-10"></i>
                        <span>Analytique</span></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManualdashboard">
                        <button class="menu-list"
                                style="cursor: pointer"
                                (auxclick)="openExternal('products')"
                                [routerLink]="['/dashboard']"
                                ngbDropdownItem>
                            <i class="fa fa-tachometer fa-lg mr-10"></i>
                            {{"components.layout.dashboard" | translate}}</button>
                        <button class="menu-list"
                                style="cursor: pointer"
                                (auxclick)="openExternal('pricehistory')"
                                [routerLink]="['/exports']" ngbDropdownItem>
                            <i class="fa fa-download fa-lg mr-10"></i>
                            {{"components.layout.exports" | translate}}</button>
                    </div>
                </div>
            </li>

            <li  class="nav-item nav-item-layout">
                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                    <button type="button"
                            class="btn btn-outline-primary me-2"
                            id="dropdownManual" ngbDropdownAnchor
                            (focus)="myDrop.open()">
                        <i class="fa fa-photo fa-lg mr-10"></i>
                        <span>Catalogue</span></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManual">
                      <button class="menu-list"
                              style="cursor: pointer"
                              (auxclick)="openExternal('products')"
                              (click)="goToExistingProduct()"
                              ngbDropdownItem>
                        <i class=" fa fa-shopping-bag fa-lg mr-10"></i>
                        {{"components.layout.products" | translate}}</button>
                      <button class="menu-list"
                              style="cursor: pointer"
                              (auxclick)="openExternal('pricehistory')"
                              [routerLink]="['/pricehistory']" ngbDropdownItem>
                        <i class="fa fa-line-chart fa-lg mr-10"></i>
                        {{"components.layout.prices" | translate}}</button>

                        <button class="menu-list"
                                style="cursor: pointer"
                                (auxclick)="openExternal('rulescatalogue')"
                                [routerLink]="['/rulescatalogue']" ngbDropdownItem>
                            <i class="fa fa-cogs fa-lg mr-10"></i>
                            Règles catalogue</button>
                    </div>
                  </div>
            </li>

            <li class="nav-item nav-item-layout">
                <button class="btn btn-outline-primary me-2"
                        (auxclick)="openExternal('suppliers')"
                        [routerLink]="['/suppliers']">
                    <i class="fa fa-male fa-lg mr-10"></i>
                    <span>{{"components.layout.suppliers" | translate}}</span>
                </button>
            </li>

            <li class="nav-item nav-item-layout">
                <button class="btn btn-outline-primary me-2"
                        (auxclick)="openExternal('categories')"
                        [routerLink]="['/categories']">
                    <i class="fa fa-window-restore fa-lg mr-10"></i>

                    <span>{{"components.layout.categories" | translate}}</span>
                </button>
            </li>

            <li class="nav-item nav-item-layout">
                <button class="btn btn-outline-danger me-2"
                        (auxclick)="openExternal('sale-prices')"
                        [routerLink]="['/sale-prices']">
                    <i class="fa fa-money fa-lg mr-10"></i>
                    <span>Tarification</span>
                </button>
            </li>
        </ul>

        <a *ngIf="hasAccess" [routerLink]="['/product']" class="button xx-small pull-right">
            <i class="fa fa-plus" aria-hidden="true" style="font-size: 18px;"></i>
            {{'components.product.buttons.create' | translate}}
        </a>

        <!-- top bar right -->
        <ul class="nav navbar-nav ml-auto">
<!--            <li class="nav-item dropdown" id="layoutTranslationWrapper">-->
<!--                <select #langSelect (change)="languageChange(langSelect.value)" style="margin-top: 13px;">-->
<!--                    <option *ngFor="let lang of translateService.getLangs()" [value]="lang"-->
<!--                            [selected]="lang === translateService.currentLang">{{ lang | uppercase }}</option>-->
<!--                </select>-->
<!--            </li>-->
            <li class="nav-item dropdown ">
                <a class="nav-link top-nav" data-toggle="dropdown" href="javascript:void(0);" role="button"
                   aria-haspopup="true" aria-expanded="false">
                    <i class="ti-bell"></i>
                    <span class="badge badge-danger notification-status"> </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-big dropdown-notifications">
                    <div class="dropdown-header notifications">
                        <strong>{{"components.layout.notifications" | translate}}</strong>
                        <span class="badge badge-pill badge-warning">0</span>
                    </div>
                    <div class="dropdown-divider"></div>

                </div>
            </li>

            <li class="nav-item dropdown mr-30">
                <a class="nav-link nav-pill user-avatar" data-toggle="dropdown" href="javascript:void(0);" role="button"
                   aria-haspopup="true" aria-expanded="false">
                    <img src="assets/images/profile-avatar.jpg" alt="avatar">
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <div class="dropdown-header">
                        <div class="media">
                            <div class="media-body">
                                <h5 class="mt-0 mb-0">{{name}}</h5>
                                <span>{{email}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript:void(0);"><i
                            class="text-warning ti-user"></i>{{"components.layout.profile" | translate}}</a>
                    <a (click)="logout()" class="dropdown-item" href="javascript:void(0);"><i
                            class="text-danger ti-unlock"></i>{{"components.layout.logout" | translate}}</a>
                </div>
            </li>
        </ul>
    </nav>

    <!--=================================
     header End-->
    <!--=================================
     Main content -->

    <div class="container-fluid">
        <div class="row">
            <!-- Left Sidebar start-->
            <div class="side-menu-fixed">
                <ng-scrollbar>
                    <div class="nav navbar-nav side-menu">
                        <!--                        <router-outlet name="sidebar"></router-outlet>-->
                        <sidebar-catalog></sidebar-catalog>
                    </div>
                </ng-scrollbar>
            </div>

            <!-- Left Sidebar End-->
            <!--=================================
            wrapper -->
            <div class="content-wrapper">
                <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
            </div>


            <!-- main content wrapper end-->
        </div>
    </div>

    <!--=================================
     footer -->
</div>