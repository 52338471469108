import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({name: 'bigNumber'})
export class BignumberPipe implements PipeTransform {

    constructor(private decimalPipe: DecimalPipe) {
    }

    transform(value: number, args?: any): any {
        if (value <= 1000) {
            return this.decimalPipe.transform(value);
        }

        let v: string;
        let afterV: number;
        let beforeV: number;
        if (value > 1000000) {
            v = "M";
            afterV = Math.round((value % 1000000) / 100000);
            beforeV = Math.floor(value / 1000000);
        } else if (value > 1000) {
            v = "K";
            afterV = Math.round((value % 1000) / 100);
            beforeV = Math.floor(value / 1000);
        }
        if (afterV === 0) {
            return beforeV + v;
        } else if (afterV === 10) {
            return ++beforeV + v;
        } else {
            return beforeV + "," + afterV + v;
        }
    }
}
