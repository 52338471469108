/// <reference types="@types/googlemaps" />
declare var google: any;

import {EventEmitter, Injectable, Injector, Output, ViewContainerRef} from "@angular/core";
import {MarkertypeGooglemapsEnum} from "src/app/shared/enums/googlemaps/markertype-googlemaps.enum";


@Injectable()
export class GooglemapsService {
    private maps: Map<number, { map: google.maps.Map, wrapper: ViewContainerRef, injector: Injector }> = new Map();

    // markers
    @Output() onMarkerClick: EventEmitter<{ mapId: number, markerName: string, type: MarkertypeGooglemapsEnum }> = new EventEmitter();
    @Output() onPolygonClick: EventEmitter<{ mapId: number, polygonName: string }> = new EventEmitter();
    private markers: Map<number, Map<string, { marker: google.maps.Marker, data: any }>> = new Map();
    private polygons: Map<number, Map<string, { polygon: google.maps.Polygon, data: any }>> = new Map();
    private controls: Map<number, Map<number, HTMLButtonElement>> = new Map();
    private infoWindow: google.maps.InfoWindow;

    // map initialisation by component
    init(map: google.maps.Map, wrapper: ViewContainerRef, injector: Injector): number {
        const mapId = this.maps.size;
        this.maps.set(mapId, {map: map, wrapper: wrapper, injector: injector});
        this.markers.set(mapId, new Map());
        this.polygons.set(mapId, new Map());
        this.controls.set(mapId, new Map());
        this.infoWindow = new google.maps.InfoWindow();
        return mapId;
    }

    // check if map initalised (used by component to know if script has to be loaded - not wonderfull, should be improved once)
    hasMaps(): boolean {
        return this.maps.size > 0;
    }

    // check if a map exist
    hasMap(mapId: number): boolean {
        return this.maps.has(mapId);
    }

    // map initialisation by component
    getMap(mapId: number): { map: google.maps.Map, wrapper: ViewContainerRef, injector: Injector } {
        if (this.hasMap(mapId)) {
            return this.maps.get(mapId);
        }
        return null;
    }

    // return div (used for displacements)
    getMapDiv(mapId: number): Element {
        if (this.hasMap(mapId)) {
            return this.maps.get(mapId).map.getDiv();
        }
        return null;
    }


    // ================================================= Markers =================================================

    drawMarker(mapId: number,
               marker: {
                   latitude: number,
                   longitude: number,
                   name: string,
                   type: MarkertypeGooglemapsEnum
               },
               description?: string,
               image?: any): void {
        let coordinates: any;
        if (marker.latitude && marker.longitude) {
            coordinates = (
                {
                    lat: marker.latitude,
                    lng: marker.longitude
                });
        } else {
            return;
        }
        if (!this.markers.get(mapId).has(marker.name)) {
            // new
            const options: any = {
                map: this.getMap(mapId).map,
                position: coordinates,
                title: description ? description : null,
                zIndex: 500 + this.markers.get(mapId).size
            };
            if (image) {
                options.icon = image;
            }

            const item = new google.maps.Marker(options);
            item.addListener("click", () => {
                this.onMarkerClick.emit({mapId, markerName: marker.name, type: marker.type});
                // this.showPolygonInfowindow(mapId, marker.id, handler);
            });
            this.markers.get(mapId).set(marker.name, {marker: item, data: marker});
        } else {
            // existing
            this.markers.get(mapId).get(marker.name).marker.setPosition(new google.maps.LatLng(coordinates.lat, coordinates.lng));
        }
    }

    centerOnMarkers(mapId: number): void {
        if (this.markers.get(mapId).size > 0) {
            const latlngbounds = new google.maps.LatLngBounds();
            this.markers.get(mapId).forEach((item) => {
                latlngbounds.extend(item.marker.getPosition());
            });
            this.getMap(mapId).map.setCenter(latlngbounds.getCenter());
            this.getMap(mapId).map.fitBounds(latlngbounds);
        }
    }

    centerOnMarker(mapId: number, markerName: string): void {
        this.getMap(mapId).map.setZoom(50);
        this.getMap(mapId).map.panTo(this.markers.get(mapId).get(markerName).marker.getPosition());
    }

    clearMarkers(mapId: number): void {
        this.markers.get(mapId).forEach((marker, key) => {
            marker.marker.setMap(null);
            this.markers.get(mapId).delete(key);
        })
    }

    clearMarker(mapId: number, markerName: string): void {
        this.markers.get(mapId)?.get(markerName)?.marker?.setMap(null);
        this.markers.get(mapId)?.delete(markerName);
    }


    // ================================================= Polygons =================================================

    drawPolygon(mapId: number, polygonName: string, coordinates: { lat: number, lng: number }[], options?: google.maps.PolygonOptions): void {
        if (!polygonName || !coordinates?.length) {
            return;
        }
        if (!options) {
            options = {};
            options.strokeColor = "#FF0000";
            options.strokeOpacity = 0.8;
            options.strokeWeight = 2;
            options.fillColor = "#FF0000";
            options.fillOpacity = 0.35;
            options.draggable = false;
            options.geodesic = true;
            options.editable = true;
        }

        if (!this.polygons.get(mapId).has(polygonName)) {
            // new
            options.paths = coordinates;
            options.map = this.getMap(mapId).map;

            const item = new google.maps.Polygon(options);
            item.addListener("click", () => {
                this.onPolygonClick.emit({mapId, polygonName: polygonName});
                // this.showPolygonInfowindow(mapId, polygonId, handler);
            });

            this.polygons.get(mapId).set(polygonName, {polygon: item, data: null});
        } else {
            // existing
            this.polygons.get(mapId).get(polygonName).polygon.setPath(coordinates);
            this.polygons.get(mapId).get(polygonName).polygon.set("strokeColor", options.strokeColor);
            this.polygons.get(mapId).get(polygonName).polygon.set("strokeOpacity", options.strokeOpacity);
            this.polygons.get(mapId).get(polygonName).polygon.set("strokeWeight", options.strokeWeight);
            this.polygons.get(mapId).get(polygonName).polygon.set("fillColor", options.fillColor);
            this.polygons.get(mapId).get(polygonName).polygon.set("fillOpacity", options.fillOpacity);
            this.polygons.get(mapId).get(polygonName).polygon.setDraggable(options.draggable);
            this.polygons.get(mapId).get(polygonName).polygon.setEditable(options.editable);
        }
    }

    getPolygon(mapId: number, polygonName: string): { polygon: google.maps.Polygon, data: any } {
        return this.polygons.get(mapId).get(polygonName);
    }

    clearPolygon(mapId: number, polygonName: string): void {
        this.polygons.get(mapId)?.get(polygonName)?.polygon?.setMap(null);
        this.polygons.get(mapId)?.delete(polygonName);
    }


    // ================================================= InfoWindow =================================================

    showPolygonInfowindow(mapId: number, polygonName: string, handler: any): void {
        console.log(mapId, polygonName, handler);
        console.log(this.getPolygon(mapId, polygonName));
        const contentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h1 id="firstHeading" class="firstHeading">Uluru</h1>' +
            '<div id="bodyContent">' +
            "<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large " +
            "sandstone rock formation in the southern part of the " +
            "Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) " +
            "south west of the nearest large town, Alice Springs; 450&#160;km " +
            "(280&#160;mi) by road. Kata Tjuta and Uluru are the two major " +
            "features of the Uluru - Kata Tjuta National Park. Uluru is " +
            "sacred to the Pitjantjatjara and Yankunytjatjara, the " +
            "Aboriginal people of the area. It has many springs, waterholes, " +
            "rock caves and ancient paintings. Uluru is listed as a World " +
            "Heritage Site.</p>" +
            '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">' +
            "https://en.wikipedia.org/w/index.php?title=Uluru</a> " +
            "(last visited June 22, 2009).</p>" +
            "</div>" +
            "</div>";
        this.infoWindow.setContent(contentString);
        this.infoWindow.open(this.getMap(mapId).map);
        this.infoWindow.setPosition(handler.latLng);
    }


    // ================================================= Custom overlay =================================================

    // not working : using markers instead !
    // drawOverlayImage(mapId: number) {
    //     const bounds = new google.maps.LatLngBounds(
    //         new google.maps.LatLng(46.281819, 2.287132),
    //         new google.maps.LatLng(46.400471, 2.005608)
    //     );
    //     // The photograph is courtesy of the U.S. Geological Survey.
    //     const image = "https://developers.google.com/maps/documentation/javascript/examples/full/images/talkeetna.png";
    //     const overlay = new OverlayGooglemapsComponent(this.getMap(mapId).map);
    //     overlay.clickEmitter.subscribe(handler => {
    //         console.log("service subscribe " + handler);
    //         // this.showInfowindow(mapId, -1, handler);
    //     })
    // }


    // ================================================= Custom controls =================================================

    addControls(mapId: number,
                controls: {
                    id: number,
                    icon?: string,
                    title: string,
                    position: number,
                    index: number,
                    callback: Function,
                    activation?: boolean
                }[]): void {

        const container = document.createElement("div");
        container.className = "customcontrol gmnoprint";

        controls.forEach(control => {
            const item = document.createElement("button") as HTMLButtonElement;
            item.innerHTML = control.icon ? " <i class=\"fa " + control.icon + "\"> </i>" + control.title : control.title;
            item.title = control.title;
            item.addEventListener("click", () => {
                controls.forEach(control2 => {
                    this.activateControl(mapId, control2.id, control.id === control2.id);
                })
                control.callback();
            });

            const container2 = document.createElement("div");
            container2.className = "gm-style-mtc";
            container.appendChild(container2);
            container2.appendChild(item);

            const controlsArray = this.getMap(mapId).map.controls[control.position].getArray();
            controlsArray.splice(Math.min(controlsArray.length, control.position), 0, container);

            this.controls.get(mapId).set(control.id, item);

            if (control.activation != undefined) {
                this.activateControl(mapId, control.id, control.activation);
            }
        })
    }

    activateControl(mapId: number, controlId: number, activation: boolean = true): void {
        if (!this.controls.get(mapId)?.get(controlId)) {
            return;
        }
        this.controls.get(mapId).get(controlId).style.fontWeight = activation ? "bold" : "";
    }
}
