<div style="padding-top: 10px;" class="row">
    <div class="col-lg-12 col-md-12">
        <ngb-tabset #tabs class="tab nav-center">
            <ngb-tab id="enseignes"
                     title="Enseignes">
                <ng-template ngbTabContent>
                    <app-category-catalogue>
                    </app-category-catalogue>
                </ng-template>
            </ngb-tab>
            <ngb-tab id="categories"
                     title="Catégories">
                <ng-template ngbTabContent>
                    <category>
                    </category>
                </ng-template>
            </ngb-tab>

            <ngb-tab id="exluRules"
                     title="Règle d'exclusion">
                <ng-template ngbTabContent>
                    <rules>
                    </rules>
                </ng-template>
            </ngb-tab>

        </ngb-tabset>
    </div>
</div>