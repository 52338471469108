import {Injectable, Injector} from '@angular/core';
import {HttpServiceClass} from "../classes/services/http-services.class";
import {BrandDtoInterface, CatalogueDtoInterface, DeliveryCountryDtoInterface, SupplierDtoInterface} from "src/app/shared/interfaces/models/rest";
import {HttpParams} from "@angular/common/http";
import {ProductSearchModelsInterface} from "src/app/shared/interfaces/models/search/product-search-models.interface";
import {ProductFormsModelsInterface} from "src/app/shared/interfaces/models/forms/product-forms-models.interface";
import {DateUtils} from "src/app/shared/utils/date.utils";
import {PaginationInterface} from "src/app/shared/interfaces/pagination.interface";
import {Observable} from "rxjs/internal/Observable";
import {PageableModelsInterface} from "src/app/shared/interfaces/models/pageable-models.interface";

@Injectable({
    providedIn: 'root'
})
export class CatalogService extends HttpServiceClass<CatalogueDtoInterface> {
    protected endpoint = "products";

    constructor(protected injector: Injector) {
        super(injector);
    }


    search(params: HttpParams, options?: { pagination?: PaginationInterface }): Observable<PageableModelsInterface<CatalogueDtoInterface>> {
        if (!options) {
            options = {pagination: {page: 0, size: 12}};
        } else if (options && !options.pagination) {
            options.pagination = {page: 0, size: 12};
        } 
        return super.search(params, options);
    }


    getId(item: CatalogueDtoInterface): number {
        return item.idCatalogue;
    }

    formToSearch(form: ProductFormsModelsInterface, pagination?: { page?: number, size?: number }): ProductSearchModelsInterface {
        let search = {} as ProductSearchModelsInterface;
        if (!form) {
            return search;
        }

        if (form.keywords)
            search.keywords = form.keywords.split(";");
        if (form.ean)
            search.ean = form.ean;
        if (form.code)
            search.code = form.code;
        if (form.categories && form.categories.length)
            search.categories = form.categories;
        if (form.deliveryCountries && form.deliveryCountries.length)
            search.deliveryCountries = form.deliveryCountries.map(item => item.code);
        if (form.suppliers && form.suppliers.length)
            search.suppliers = form.suppliers.map(item => item.idSupplier);
        if (form.status && form.status.length)
            search.status = form.status.map(item => item.code);
        if (form.brands && form.brands.length)
            search.brands = form.brands.map(item => item.label);
        if (form.stock)
            search.stock = form.stock;
        if (form.costPriceMin)
            search.costPriceMin = form.costPriceMin;
        if (form.costPriceMax)
            search.costPriceMax = form.costPriceMax;
        if (form.retailPriceMin)
            search.retailPriceMin = form.retailPriceMin;
        if (form.retailPriceMax)
            search.retailPriceMax = form.retailPriceMax;
        if (form.publicPriceMin)
            search.publicPriceMin = form.publicPriceMin;
        if (form.publicPriceMax)
            search.publicPriceMax = form.publicPriceMax;
        if (form.integrationDateFrom)
            search.integrationDateFrom = form.integrationDateFrom;
        if (form.integrationDateTo)
            search.integrationDateTo = form.integrationDateTo;
        if (form.selectionDateFrom)
            search.selectionDateFrom = form.selectionDateFrom;
        if (form.selectionDateTo)
            search.selectionDateTo = form.selectionDateTo;

        if (pagination) {
            if (pagination.page)
                search.page = pagination.page;
            if (pagination.size)
                search.size = pagination.size;
        }

        return search;
    }

    searchToParams(search: ProductSearchModelsInterface): HttpParams {
        let httpParams = new HttpParams();
        if (!search) {
            return httpParams;
        }

        if (search.keywords)
            httpParams = httpParams.append("keywords", search.keywords.join(","));
        if (search.ean)
            httpParams = httpParams.append("ean", search.ean);
        if (search.code)
            httpParams = httpParams.append("code", search.code);
        if (search.categories && search.categories.length)
            httpParams = httpParams.append("categories", search.categories.join(","));
        if (search.deliveryCountries && search.deliveryCountries.length)
            httpParams = httpParams.append("deliveryCountries", search.deliveryCountries.join(","));
        if (search.suppliers && search.suppliers.length)
            httpParams = httpParams.append("suppliers", search.suppliers.join(","));
        if (search.status && search.status.length)
            httpParams = httpParams.append("status", search.status.join(","));
        if (search.brands && search.brands.length)
            httpParams = httpParams.append("brands", search.brands.join(","));
        if (search.stock) {
            httpParams = httpParams.append("stockMin", search.stock);
            // httpParams = httpParams.append("stockMax", stock[1]);
        }
        if (search.costPriceMin)
            httpParams = httpParams.append("costPriceMin", search.costPriceMin);
        if (search.costPriceMax)
            httpParams = httpParams.append("costPriceMax", search.costPriceMax);
        if (search.retailPriceMin)
            httpParams = httpParams.append("retailPriceMin", search.retailPriceMin);
        if (search.retailPriceMax)
            httpParams = httpParams.append("retailPriceMax", search.retailPriceMax);
        if (search.publicPriceMin)
            httpParams = httpParams.append("publicPriceMin", search.publicPriceMin);
        if (search.publicPriceMax)
            httpParams = httpParams.append("publicPriceMax", search.publicPriceMax);
        if (search.integrationDateFrom)
            // @ts-ignore
            httpParams = httpParams.append("integrationDateFrom", DateUtils.ngbDateToDate(search.integrationDateFrom).toISOString());
        if (search.integrationDateTo)
            // @ts-ignore
            httpParams = httpParams.append("integrationDateTo", DateUtils.ngbDateToDate(earch.integrationDateTo).toISOString());
        if (search.selectionDateFrom)
            // @ts-ignore
            httpParams = httpParams.append("selectionDateFrom", DateUtils.ngbDateToDate(search.selectionDateFrom).toISOString());
        if (search.selectionDateTo)
            // @ts-ignore
            httpParams = httpParams.append("selectionDateTo", DateUtils.ngbDateToDate(search.selectionDateTo).toISOString());

        if (search.page)
            httpParams = httpParams.append("page", search.page);
        if (search.size)
            httpParams = httpParams.append("size", search.size);


        return httpParams;
    }

    searchToForm(
        search: ProductSearchModelsInterface,
        countries: DeliveryCountryDtoInterface[],
        suppliers: SupplierDtoInterface[],
        status: { id: number, code: string }[],
        brands: BrandDtoInterface[]
    ): ProductFormsModelsInterface {
        let form = {} as ProductFormsModelsInterface;
        if (!search) {
            return form;
        }

        if (search.keywords)
            form.keywords = search.keywords.join(";");
        if (search.ean)
            form.ean = search.ean;
        if (search.code)
            form.code = search.code;
        if (search.categories && search.categories.length)
            form.categories = search.categories;
        if (search.deliveryCountries && search.deliveryCountries.length && countries) {
            const items: DeliveryCountryDtoInterface[] = [];
            for (let item of search.deliveryCountries) {
                const foundItem = countries.find(sub => sub.code === item);
                if (foundItem) {
                    items.push(foundItem);
                }
            }
            form.deliveryCountries = items;
        }
        if (search.suppliers && search.suppliers.length && suppliers) {
            const items: any[] = [];
            for (let item of search.suppliers) {
                const foundItem = suppliers.find(sub => sub.idSupplier === item);
                if (foundItem) {
                    items.push(foundItem);
                }
            }
            form.suppliers = items;
        }
        if (search.status && search.status.length && status) {
            const items: any[] = [];
            for (let item of search.status) {
                const foundItem = status.find(sub => sub.code === item);
                if (foundItem) {
                    items.push(foundItem);
                }
            }
            form.status = items;
        }
        if (search.brands && search.brands.length && brands) {
            const items: any[] = [];
            for (let item of search.brands) {
                const foundItem = brands.find(sub => sub.label === item);
                if (foundItem) {
                    items.push(foundItem);
                }
            }
            form.brands = items;
        }
        if (search.stock) {
            form.stock = search.stock;
        }
        if (search.costPriceMin)
            form.costPriceMin = search.costPriceMin;
        if (search.costPriceMax)
            form.costPriceMax = search.costPriceMax;
        if (search.retailPriceMin)
            form.retailPriceMin = search.retailPriceMin;
        if (search.retailPriceMax)
            form.retailPriceMax = search.retailPriceMax;
        if (search.publicPriceMin)
            form.publicPriceMin = search.publicPriceMin;
        if (search.publicPriceMax)
            form.publicPriceMax = search.publicPriceMax;
        if (search.integrationDateFrom)
            form.integrationDateFrom = search.integrationDateFrom;
        if (search.integrationDateTo)
            form.integrationDateTo = search.integrationDateTo;
        if (search.selectionDateFrom)
            form.selectionDateFrom = search.selectionDateFrom;
        if (search.selectionDateTo)
            form.selectionDateTo = search.selectionDateTo;

        return form;
    }
}
