import {Injectable, Injector} from '@angular/core';
import {ProductYoukadoDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { JsonUtils } from "src/app/shared/utils/json.utils";

@Injectable({
    providedIn: 'root'
})
export class ProductyoukadoService extends HttpServiceClass<ProductYoukadoDtoInterface> {
    protected endpoint = "products";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: ProductYoukadoDtoInterface): number {
        return item.idProductYoukado;
    }

    getNextValSequence(params?: HttpParams) {
        return this.httpClient.get<number>(
            this.getPath({ path: "nextVal" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getEnseignesInclusion(params?: { idProduct: number }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getEnseignesInclusion" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getProperties(params?: { idEnseigne: number; idProduct: number }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getProperties" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getProductByCode(params?: { codeProduct: string; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getProductByCode" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getProductById(params?: { idProduct: number; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getProductById" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
