import { Injectable, Injector } from '@angular/core';
import {EnseigneDtoInterface} from '../interfaces/models/rest';
import { HttpServiceClass } from "src/app/shared/classes/services/http-services.class";
import { JsonUtils } from "src/app/shared/utils/json.utils";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class EnseignesService extends HttpServiceClass<EnseigneDtoInterface> {
    protected endpoint = "enseignes";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: EnseigneDtoInterface): number {
        return item.id_ext_enseigne;
    }

    getEnseignes(params?: HttpParams) {
        return this.httpClient.get<EnseigneDtoInterface[]>(
            this.getPath({ path: "" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
