import {Injectable, Injector} from '@angular/core';
import {HistoryProductSupplierStockDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";

@Injectable({
    providedIn: 'root'
})
export class StockhistoryService extends HttpServiceClass<HistoryProductSupplierStockDtoInterface> {
    protected endpoint = "historyproductsupplierstocks";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: HistoryProductSupplierStockDtoInterface): number {
        return item.idHistoryProductSupplierStock;
    }
}
