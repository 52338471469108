import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[autofocussearchmultiselect]'
})
export class AutofocussearchmultiselectDirective {

    @HostListener('click', ['$event'])
    public click(event: KeyboardEvent): void {
        setTimeout(() => {
            try {
                const item = event.target as HTMLDivElement;
                if (item.classList.contains("dropdown-btn")) {
                    (item.parentElement.nextElementSibling.getElementsByTagName("input")[0] as HTMLInputElement).focus();
                }
            } catch (exception) {
            }
        }, 0);
    }
}
