import {Injectable, Injector} from '@angular/core';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {map} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class SalePriceService extends HttpServiceClass<any> {
    protected endpoint = "salePrice";

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected getId(item: any): number {
        return 0;
    }

    getSalePrice(params?: { idEnseigne: number; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getSalePrice" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
