import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponents } from "src/app/shared/classes/components/base-components.class";
import { Router } from "@angular/router";
import { KeycloakService } from 'keycloak-angular';
import {LanguageService} from "src/app/shared/services/language.service";

declare var document: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends BaseComponents implements OnInit {
    name: string;
    email: string;
    isSearchActive: boolean = false;
    isSlideMenu: boolean = false;
    currentComponent: any;
    hasAccess: boolean;

    constructor(
        protected router: Router,
        protected injector: Injector,
        private keycloakService: KeycloakService,
        protected languageService: LanguageService,) {
        super(injector);
    }

    ngOnInit() {

        this.hasAccess = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_DIRECTION_GENERALE')
        || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_SUPER_ADMIN')
        || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_ACHATS');

        this.name = this.keycloakService.getKeycloakInstance().tokenParsed.name;
        this.email = this.keycloakService.getKeycloakInstance().tokenParsed.email;
        this.commondataService.showLoader(true);
    }

    openExternal(link: string) {
        let url = null;
        if(link == 'products'){
            url = this.router.serializeUrl(
                this.router.createUrlTree(
                    ["/"+link+"/"],
                    { queryParams: { search: '{"deliveryCountries":["FR"],"status":["new","selected"],"stock":1}' } }
                )
            );
        }
        else{
            url = this.router.serializeUrl(
                this.router.createUrlTree(
                    ["/"+link+"/"]
                )
            );
        }

        window.open('/marketplace/#' + url, '_blank');
    }

    toggleSearch() {
        this.isSearchActive = !this.isSearchActive;
    }

    toggleMenu() {
        this.isSlideMenu = !this.isSlideMenu;
        if(this.currentComponent.isSlideMenu != null){
            this.currentComponent.isSlideMenu = this.isSlideMenu;
        }
        this.commondataService.changeStateMenu(this.isSlideMenu);

    }

    onOutletLoaded(component: any) {
        this.currentComponent = component;
    }

    languageChange( languageToUse : any){
        this.translateService.use(languageToUse);
    }

    expandCollapse(sectionName: any) {
        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "collapse" || CurrentCls == "collapse hide") {
            document.getElementById(sectionName).setAttribute("class", "collapse show");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "true");
        } else {
            document.getElementById(sectionName).setAttribute("class", "collapse hide");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "false");
        }
    }

    toggleFullscreen(elem: any) {
        elem = elem || document.documentElement;
        if (!document.fullscreenElement && !document['mozFullScreenElement'] &&
            !document.webkitFullscreenElement && !document['msFullscreenElement']) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document['msExitFullscreen']) {
                document['msExitFullscreen']();
            } else if (document['mozCancelFullScreen']) {
                document['mozCancelFullScreen']();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    // On click "products" button, display all the catalogue
    goToExistingProduct() {
        this.router.navigate(['/products'], { queryParams: { search: '{"status":["published"]}' } }).then();
    }

    logout() {
        this.keycloakService.logout();
    }
}
