
export class CalculationUtils {

    static getClosestInTenPercent(value: number): number {
        return this.getClosestInRange(value, [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]);
    }

    static getClosestInRange(value: number, range: number[]): number {
        if (value <= range[0]) {
            return range[0];
        } else if (value >= range[range.length - 1]) {
            return range[range.length - 1];
        }

        for (let i = 0; i < range.length; i++) {
            if (value > range[i] && value <= range[i + 1]) {
                if (value - range[i] <= range[i + 1] - value) {
                    return range[i];
                } else {
                    return range[i + 1];
                }
            }
        }
        return null;
    }
}
