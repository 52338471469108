import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {DeliverycountryService} from "src/app/shared/services/deliverycountry.service";
import {CommonDataService} from "src/app/common-data.service";
import {ExportsService} from "src/app/shared/services/exports.service";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {ExportClass} from "src/app/shared/classes/exports/export.class";
import {DeliveryCountryDtoInterface, EnseigneDtoInterface, SupplierDtoInterface, LanguageDtoInterface, CategoryDtoInterface
} from "src/app/shared/interfaces/models/rest";
import {RemplacementValueClass} from "src/app/shared/classes/exports/ReplacementValue.class";
import {SupplierService} from "src/app/shared/services/supplier.service";
import {ModalService} from "src/app/shared/services";
import {DateUtils} from "src/app/shared/utils/date.utils";
import {EnseignesService} from "src/app/shared/services/enseignes-service";
import {LanguageService} from "src/app/shared/services/language.service";
import {CategoryService} from "src/app/shared/services/category.service";
import {DropdownElementClass} from "src/app/shared/classes/dropdown/dropdownElement.class";
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";

@Component({
    selector: 'app-exports',
    templateUrl: './exports.component.html',
    styleUrls: ['./exports.component.css']
})
export class ExportsComponent extends BaseComponents implements OnInit {
    exports: ExportClass[] = [];
    exportsTabs: string[] = [];
    availableDeliveryCountries: DeliveryCountryDtoInterface[] = [];
    deliveryCountriesSelectedItems: DeliveryCountryDtoInterface[] = [];
    availableSuppliers: SupplierDtoInterface[] = [];
    suppliersSelectedItems: SupplierDtoInterface[] = [];
    availablePublicationState: any[];
    publicationStateSelectedItems: any[];
    publicationStartDate: any;
    publicationEndDate: any;
    orderStartDate: any;
    orderEndDate: any;
    enseignesList: EnseigneDtoInterface[];
    selectedEnseigne: EnseigneDtoInterface[];
    generic: Boolean = true;
    specific: Boolean = false;
    languagesList: string[] = [];
    languages: LanguageDtoInterface[] = [];
    selectedLanguagesProduit: LanguageDtoInterface[] = [];
    selectedLanguagesCategorie: LanguageDtoInterface[] = [];
    categories: CategoryDtoInterface[] = [];
    dropdownListCategory: DropdownElementClass[] = [];
    currentCategory: any[]  = [];

    dropDownCategorySettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'text',
        allowSearchFilter: true,
        enableCheckAll: false
    };
    constructor(
        protected exportsService: ExportsService,
        protected deliverycountryService: DeliverycountryService,
        private modalService: ModalService,
        private enseignesService: EnseignesService,
        protected supplierService: SupplierService,
        protected languageService: LanguageService,
        protected categoryService: CategoryService,
        protected injector: Injector) {
        super(injector);
    }


    ngOnInit(): void {
        this.orderStartDate = DateUtils.dateToNgbdate(DateUtils.previousDay(new Date()));
        this.orderEndDate = DateUtils.dateToNgbdate(new Date());
        this.availablePublicationState = [{
            code: 'published',
            label: 'Publié'
        },
            {
                code: 'unpublished',
                label: 'Dépublié'
            },
            {
                code: 'standby',
                label: 'Stand-by'
            }]
        this.publicationStateSelectedItems = [{
            code: 'published',
            label: 'Publié'
        }]

        this.selectedLanguagesProduit = [{
            code: 'FR',
            idLanguage: 1,
            label: 'FRANCAIS',
            order: 1

        }]
        this.selectedLanguagesCategorie = [{
            code: 'FR',
            idLanguage: 1,
            label: 'FRANCAIS',
            order: 1

        }]

        this.commondataService.showLoader(true);
        this.subscriptions.push(combineLatest([
            this.exportsService.getListExports(),
            this.deliverycountryService.listLite(),
            this.supplierService.list(),
            this.enseignesService.getEnseignes(),
            this.languageService.listLite(),
            this.categoryService.list()
        ]).subscribe((results: any) => {
            this.exports = results[0];
            this.exportsTabs = this.exports.map((exp: ExportClass) => exp.tab);
            this.exportsTabs = this.exportsTabs.filter(
                (element, i) => i === this.exportsTabs.indexOf(element)
            );
            this.availableDeliveryCountries = results[1];
            this.availableSuppliers = results[2];
            this.enseignesList = results[3];

            this.languages = results[4].sort((a:any, b:any) => a.label < b.label ? -1 : 1);
            this.languages.sort((a, b) => a.order < b.order ? -1 : 1);
            for (let language of this.languages) {
                this.languagesList.push(language.code);
            }

            this.commondataService.showLoader(false);
            this.categories = results[5];
            this.setDropDownCategoryList(this.categories);

        }, error => {
            this.dealWithStandardError(error);
            this.commondataService.showLoader(false);
        }));
    }

    setDropDownCategoryList(categories: CategoryDtoInterface[]): void {
        let list = [];
        for (let cat of categories) {
            let libele = cat.translationCategoriesCategory.find(translationCat => translationCat.languageTranslationCategory.code === "FR")?.labelYoukado;
            if (!libele) {
                libele = cat.code;
            }
            list.push({ id: cat.idCategory, text: libele });
        }
        this.dropdownListCategory = list;
    }
    changeCountry(): void {
        const isFrSelected = this.deliveryCountriesSelectedItems.some(lang => lang.code === 'FR');
        if (isFrSelected ) {
                this.deliveryCountriesSelectedItems = this.deliveryCountriesSelectedItems.filter(lang => lang.code === 'FR');

        }
    }

    getExport(idExport: number, fileName: string, tab: string): boolean {
        if(this.deliveryCountriesSelectedItems != null && this.deliveryCountriesSelectedItems.length > 0){
            let fileCountryLastVal = '_';
            this.deliveryCountriesSelectedItems.forEach(
                (element : DeliveryCountryDtoInterface) => {
                    fileName+=fileCountryLastVal+element.code;
                }
            );
        }

        if ((this.selectedLanguagesProduit == null || this.selectedLanguagesProduit.length == 0) && ((this.selectedLanguagesCategorie == null || this.selectedLanguagesCategorie.length == 0))) {
            this.modalService.open("showLanguages");
            return false;
        } else if ((this.deliveryCountriesSelectedItems == null || this.deliveryCountriesSelectedItems.length == 0)) {
            this.modalService.open("showPopupDeliveryCountry");
            return false;
        } else if ((this.publicationStateSelectedItems == null || this.publicationStateSelectedItems.length == 0) && tab == 'Offres') {
            this.modalService.open("showPopupStatePublication");
            return false;
        } else if ((this.orderStartDate == null || this.orderStartDate == undefined || this.orderEndDate == null || this.orderEndDate == undefined) && tab == 'Ventes') {
            this.modalService.open("orderInterval");
            return false;
        }
        let remplacementValueSqls = this.remplacementValue();
        this.commondataService.showLoader(true);
        this.exportsService.getExport(idExport, fileName, remplacementValueSqls).subscribe((results: any) => {
            this.commondataService.showLoader(false);
        }, error => {
            this.dealWithStandardError(error);
        });

        return true;
    }

    private remplacementValue() {
        let remplacementValueSqls: any = [];
       
        if (this.selectedLanguagesProduit != null && this.selectedLanguagesProduit.length > 0) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'langueProduit';
            remplacementValueSql.type = 'long';
            remplacementValueSql.value = this.selectedLanguagesProduit[0].idLanguage.toString();
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.selectedLanguagesCategorie != null && this.selectedLanguagesCategorie.length > 0) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'langueCategorie';
            remplacementValueSql.type = 'long';
            remplacementValueSql.value = this.selectedLanguagesCategorie[0].idLanguage.toString();
            remplacementValueSqls.push(remplacementValueSql);
        }

        if(this.currentCategory !=null && this.currentCategory.length>0){
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'idCategory';
            remplacementValueSql.type = 'array[long]';
            remplacementValueSql.value = this.currentCategory.map(item => item.id).join(";")
            remplacementValueSqls.push(remplacementValueSql);
        }

        let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'idDeliveryCountry';
            remplacementValueSql.type = 'array[long]';
            remplacementValueSql.value = this.deliveryCountriesSelectedItems.map(item => item.idDeliveryCountry).join(";")
            remplacementValueSqls.push(remplacementValueSql);

            let remplacementValueSqlOrder = {} as RemplacementValueClass;
            remplacementValueSqlOrder.elementToReplace = 'deliveryCountry';
            remplacementValueSqlOrder.type = 'array[string]';
            remplacementValueSqlOrder.value = this.deliveryCountriesSelectedItems.map(item => item.code).join(";")
            remplacementValueSqls.push(remplacementValueSqlOrder);

        if (this.suppliersSelectedItems != null && this.suppliersSelectedItems.length > 0) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'idSupplier';
            remplacementValueSql.type = 'array[long]';
            remplacementValueSql.value = this.suppliersSelectedItems.map(item => item.idSupplier).join(";")
            remplacementValueSqls.push(remplacementValueSql);
        } else {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'isBestChoice';
            remplacementValueSql.type = 'string';
            remplacementValueSql.value = ''
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.publicationStateSelectedItems != null && this.publicationStateSelectedItems.length > 0) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'publicationState';
            remplacementValueSql.type = 'array[string]';
            remplacementValueSql.value = this.publicationStateSelectedItems.map(item => item.code).join(";")
            remplacementValueSqls.push(remplacementValueSql);

            let publicationState = this.publicationStateSelectedItems.find(ps => ps.code == 'published');
            if (publicationState != null && publicationState.code != null) {
                console.log("publicationState : "+JSON.stringify(publicationState));
                let remplacementValueSqlState = {} as RemplacementValueClass;
                remplacementValueSqlState.elementToReplace = 'publicationcategorie';
                remplacementValueSqlState.type = 'string';
                remplacementValueSqlState.value = 'true'
                remplacementValueSqls.push(remplacementValueSqlState);
            }
        }


        if (this.publicationStartDate != null && this.publicationStartDate != undefined) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'publicationStartDate';
            remplacementValueSql.type = 'date';
            remplacementValueSql.value = DateUtils.format(this.publicationStartDate) + ' 00:00:00';
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.publicationEndDate != null && this.publicationEndDate != undefined) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'publicationEndDate';
            remplacementValueSql.type = 'date';
            remplacementValueSql.value = DateUtils.format(this.publicationEndDate) + ' 00:00:00';
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.orderStartDate != null && this.orderStartDate != undefined) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'orderStartDate';
            remplacementValueSql.type = 'date';
            remplacementValueSql.value = DateUtils.format(this.orderStartDate) + ' 00:00:00';
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.orderEndDate != null && this.orderEndDate != undefined) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'orderEndDate';
            remplacementValueSql.type = 'date';
            remplacementValueSql.value = DateUtils.format(this.orderEndDate) + ' 00:00:00';
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.suppliersSelectedItems != null && this.suppliersSelectedItems.length > 0) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'supplier';
            remplacementValueSql.type = 'array[string]';
            remplacementValueSql.value = this.suppliersSelectedItems.map(item => item.label).join(";")
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.selectedEnseigne != null && this.selectedEnseigne.length > 0) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'nomEnseigne';
            remplacementValueSql.type = 'array[string]';
            remplacementValueSql.value = this.selectedEnseigne.map(item => item.nomEnseigne).join(";")
            remplacementValueSqls.push(remplacementValueSql);

            let remplacementValueSqlEnseigne = {} as RemplacementValueClass;
            remplacementValueSqlEnseigne.elementToReplace = 'flterEnseigne';
            remplacementValueSqlEnseigne.type = 'string';
            remplacementValueSqlEnseigne.value = ''
            remplacementValueSqls.push(remplacementValueSqlEnseigne);

        } else {
            let remplacementValueSqlEnseigne = {} as RemplacementValueClass;
            remplacementValueSqlEnseigne.elementToReplace = 'noFlterEnseigne';
            remplacementValueSqlEnseigne.type = 'string';
            remplacementValueSqlEnseigne.value = ''
            remplacementValueSqls.push(remplacementValueSqlEnseigne);
        }

        if (this.generic  && !this.specific) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'generique';
            remplacementValueSql.type = 'string';
            remplacementValueSql.value = String(this.generic);
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.specific && !this.generic) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'specific';
            remplacementValueSql.type = 'string';
            remplacementValueSql.value = String(this.specific);
            remplacementValueSqls.push(remplacementValueSql);
        }

        if (this.generic && this.specific) {
            let remplacementValueSql = {} as RemplacementValueClass;
            remplacementValueSql.elementToReplace = 'allstatus';
            remplacementValueSql.type = 'string';
            remplacementValueSql.value = '';
            remplacementValueSqls.push(remplacementValueSql);
        }

        return remplacementValueSqls;
    }

    export(tab: string): ExportClass[] {
        return this.exports.filter(e => e.tab == tab)
    }


    close(id: string) {
        this.modalService.close(id);
    }
}
