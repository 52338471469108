import { Component, Injector, Input, OnInit } from '@angular/core';
import { CategoryDtoInterface, DeliveryCountryDtoInterface, ProductYoukadoDtoInterface } from "src/app/shared/interfaces/models/rest";
import { BaseComponents } from "src/app/shared/classes/components/base-components.class";
import { ProductFormvalidationInterface } from "src/app/shared/interfaces/formvalidation/product/product-formvalidation.interface";
import { CategoryService } from "src/app/shared/services/category.service";
import { CategorytreeUtils } from "src/app/shared/utils/categorytree.utils";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {debounce} from "underscore";

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.css']
})
export class ProductCategoriesComponent extends BaseComponents implements OnInit {

    categories: CategoryDtoInterface;
    selectedCategories: number[] = [];
    deliveryCountry: DeliveryCountryDtoInterface;
    formGroup: UntypedFormGroup;
    formGroupSearch: UntypedFormGroup;
    searchCriteria: { keywords?: string, main?: boolean, specific?: boolean, service?: boolean, animation?: boolean, archive?: boolean };
    treeCatgeriesSelected: any[] = [];

    private init = false;

    @Input() productYoukado: ProductYoukadoDtoInterface;
    @Input() validationData: ProductFormvalidationInterface;

    @Input("deliveryCountry") set setDeliveryCountry(deliveryCountry: DeliveryCountryDtoInterface) {
        this.deliveryCountry = deliveryCountry;
        if (this.init) {
            this.loadCategories();
        }
    }

    constructor(protected injector: Injector,
        protected formBuilder: UntypedFormBuilder,
        protected categoryService: CategoryService,) {
        super(injector);
        this.search = debounce(this.search, 300);
    }

    ngOnInit(): void {
        // why does this show an ExpressionChangedAfterItHasBeenCheckedError error without timeout ?
        // troubles with the service itself ?
        setTimeout(() => {
            this.commondataService.showLoader(true);
        });

        this.loadCategories();

        if (!this.formGroup) {
            this.formGroup = this.formBuilder.group({
                category: new UntypedFormControl('')
            });
        }

        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
                searchArchive: new UntypedFormControl(false),
                searchFilter: new UntypedFormControl('searchMain')
            });
            this.search();
        }

        this.init = true;
    }

    search() {
        switch (this.formGroupSearch.value.searchFilter) {
            case "searchMain":
                this.formGroupSearch.value.searchMain = true;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchSpecific":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = true;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchService":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = true;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchAnimation":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = true;
                break;
        }
        this.searchCriteria = {
            keywords: this.formGroupSearch.value.keywords,
            main: this.formGroupSearch.value.searchMain,
            specific: this.formGroupSearch.value.searchSpecific,
            service: this.formGroupSearch.value.searchService,
            animation: this.formGroupSearch.value.searchAnimation,
        };
    }

    categoryChange(): void {
        if (this.productYoukado.categoriesProductYoukado == null || this.productYoukado.categoriesProductYoukado == undefined) {
            this.productYoukado.categoriesProductYoukado = [];
        }
        let productYoukadoCategoriesInTree = this.productYoukado.categoriesProductYoukado
            .filter(item => CategorytreeUtils.findCategoryInTree(item.idCategory, this.categories));
        productYoukadoCategoriesInTree
            .filter(item => !this.formGroup.value.category.includes(item.idCategory))
            .forEach(item => {
            this.productYoukado.categoriesProductYoukado.splice(this.productYoukado.categoriesProductYoukado
                    .findIndex(item2 => item2.idCategory === item.idCategory), 1);
        });
        (this.formGroup.value.category as number[])
            .filter(item => !productYoukadoCategoriesInTree.map(item2 => item2.idCategory).includes(item))
            .forEach(item => {
            this.productYoukado.categoriesProductYoukado.push(CategorytreeUtils.findCategoryInTree(item, this.categories));
        });
        this.loadSelectedCategories();
    }

    private loadSelectedCategories(): void {
        this.treeCatgeriesSelected = [];
        if (this.productYoukado.categoriesProductYoukado != null && this.productYoukado.categoriesProductYoukado != undefined) {
            for (let category of this.productYoukado.categoriesProductYoukado) {
                this.treeCatgeriesSelected.push(CategorytreeUtils.findParent(category.idCategory, this.categories));
            }
        }
    }

    private loadCategories(): void {
        // todo no category by country
        this.categoryService.listTree().subscribe(response => {
            this.categories = CategorytreeUtils.generateTree(response, false);

            if (this.productYoukado?.categoriesProductYoukado?.length) {
                this.selectedCategories = this.productYoukado.categoriesProductYoukado.map(item => item.idCategory);
                this.formGroup.patchValue({
                    category: this.selectedCategories
                })
            }

            this.loadSelectedCategories();
            this.search();
            this.commondataService.showLoader(false);
        }, error => {
            console.log('Error : ' + JSON.stringify(error));
            this.commondataService.showLoader(false);
        });
    }
}
