import {Injectable, Injector} from '@angular/core';

import {Observable} from "rxjs/internal/Observable";
import {of} from "rxjs";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {DoApiClass} from "src/app/shared/classes/api/do-api.class";

@Injectable({
    providedIn: 'root'
})
export class TabService extends HttpServiceClass<DoApiClass> {
    protected endpoint = 'cards/cardsFilterDo';

    constructor(protected injector: Injector) {
        super(injector);
    }

    listMock(id: number): Observable<{ id: number, name: string } []> {
        switch (id) {
            case 64:
                return of([
                    {id: 1, name: "Catalogue"},
                    {id: 2, name: "Vente"},
                ]);
            default:
                return of([
                    {id: 1, name: "Tableau de bord"},
                    {id: 2, name: "Suivi du programme"},
                    {id: 3, name: "Récompenses"},
                    {id: 4, name: "Animations"},
                ]);
        }
    }

    protected getId(item: DoApiClass): number {
        return 0;
    }
}
