export namespace TokenActions {
    export class Add {
        static readonly type = 'Add';
        constructor(public payload: any) {}
    }

    export class Refresh {
        static readonly type = 'Refresh';
        constructor(public payload: any) {}
    }

    export class Delete {
        static readonly type = 'Delete';
        constructor() {}
    }
}
