import {Injectable} from "@angular/core";
import {ToastaService, ToastOptions} from "ngx-toasta";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    protected constructor(private toastaService: ToastaService,
                          private translateService: TranslateService) {
    }

    ok(message?: string) {
        this.toastaService.success(this.getToast(this.translateService.instant("core.errors.ok"), message));
    }

    info(message?: string) {
        this.toastaService.info(this.getToast(this.translateService.instant("core.errors.info"), message));
    }

    warning(message?: string) {
        this.toastaService.warning(this.getToast(this.translateService.instant("core.errors.warning"), message));
    }

    error(message?: string, detail?: string) {
        this.toastaService.error(this.getToast(this.translateService.instant("core.errors.error"), message, detail));
    }

    private getToast(title: string, message?: string, detail?: string): ToastOptions {
        const toast: ToastOptions = {
            title: title,
            showClose: true,
            timeout: 10000,
            theme: 'bootstrap'
        };
        if (message) {
            toast.msg = message;
            if (detail) {
                toast.msg = "<a href='mailto:dev@youkado.com?subject=Bug%20appli%20achat&body="
                    + encodeURI(
                        this.translateService.instant("core.errors.pleasefill").replace(/'/g, '&apos;')
                        + "\n\n"
                        + detail.substring(0, 1500).replace(/'/g, '&apos;')
                    )
                    + "'>"
                    + toast.msg
                    + "</a>";
            }
        }
        return toast;
    }
}
