import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {DragtypeEnum} from "src/app/shared/enums/dragtype.enum";
import {DroptypeEnum} from "src/app/shared/enums/droptype.enum";

@Injectable({
    providedIn: 'root'
})
export class CategorytreeHandler {
    // we assume we'll have only one editable categorytree per page

    onForwardDrag: Subject<{ dragtype: DragtypeEnum, category: number, parent: number }> = new Subject();
    onForwardDrop: Subject<{ dropType: DroptypeEnum, category: number, destination: number }> = new Subject();
    onForwardSelection: Subject<never> = new Subject();
    onForwardEdit: Subject<number> = new Subject();
    onForwardAdd: Subject<number> = new Subject();
    onForwardDelete: Subject<number> = new Subject();
    onForwardSearch: Subject<number> = new Subject();

    forwardDrag(dragtype: DragtypeEnum, category: number, parent: number): void {
        this.onForwardDrag.next({dragtype, category, parent});
    }

    forwardSelection(): void {
        this.onForwardSelection.next();
    }

    forwardDrop(dropType: DroptypeEnum, category: number, destination: number): void {
        this.onForwardDrop.next({dropType, category, destination});
    }

    forwardEdit(category: number) {
        this.onForwardEdit.next(category);
    }

    forwardAdd(category: number) {
        this.onForwardAdd.next(category);
    }

    forwardDelete(category: number) {
        this.onForwardDelete.next(category);
    }

    forwardSearch(category: number) {
        this.onForwardSearch.next(category);
    }
}
