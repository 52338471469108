import {Injectable, Injector} from '@angular/core';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {map} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class TranslateService extends HttpServiceClass<any> {
    protected endpoint = "translate";

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected getId(item: any): number {
        return 0;
    }

    translate(item: any, params?: any) {
        return this.httpClient.post<any>(
            this.getPath(), item, {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
