import { Component, OnInit, ViewChild, ElementRef, Input, Injector } from '@angular/core';
import { MediaToProductYoukadoDtoInterface, ProductSupplierDtoInterface, ProductYoukadoDtoInterface } from 'src/app/shared/interfaces/models/rest';
import { CommonDataService } from 'src/app/common-data.service';
import { ProductyoukadoService } from 'src/app/shared/services/productyoukado.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProductFormvalidationInterface } from "src/app/shared/interfaces/formvalidation/product/product-formvalidation.interface";
import { UploadService } from "src/app/shared/services/upload-service";
import { BaseComponents } from "src/app/shared/classes/components/base-components.class";
import { HttpParams } from "@angular/common/http";

@Component({
    selector: 'app-product-images',
    templateUrl: './product-images.component.html',
    styleUrls: ['./product-images.component.css']
})
export class ProductImagesComponent extends BaseComponents implements OnInit {

    @ViewChild("fileInput") fileInput: ElementRef;
    @Input() productYoukado: ProductYoukadoDtoInterface;
    @Input() validationData: ProductFormvalidationInterface;
    fileToUpload: FileList | null;
    alertErrorImage: boolean = false;
    imageLink: string = null;
    errorImageLink: boolean = false;

    constructor(private _commondata: CommonDataService,
        private productService: ProductyoukadoService,
        protected uploadService: UploadService,
        protected injector: Injector) {
        super(injector);
    }


    ngOnInit(): void {
        this.sortImages();
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.productYoukado.mediaToProductYoukados, event.previousIndex, event.currentIndex);
    }

    sortImages() {
        console.log(this.productYoukado.mediaToProductYoukados)
        if (typeof this.productYoukado.mediaToProductYoukados != 'undefined' && this.productYoukado.mediaToProductYoukados != null && this.productYoukado.mediaToProductYoukados.length != 0) {
            this.productYoukado.mediaToProductYoukados.sort((a, b) => a.priority - b.priority);
        }
    }

    deleteImage(indexMedia: number) {
        const filteredMedias: MediaToProductYoukadoDtoInterface[] = [];
        this.productYoukado.mediaToProductYoukados.forEach(function (media, index) {
            if (index != indexMedia) {
                filteredMedias.push(media);
            }

        });
        this.productYoukado.mediaToProductYoukados = filteredMedias;
    }

    handleFileInput(event: any) {
        this.fileToUpload = event.target.files;
        const formData = new FormData();
        formData.append("file", this.fileToUpload[0]);
        let productSupplier: ProductSupplierDtoInterface = null;
        let index: number = 0;
        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers.length != 0) {
            productSupplier = this.productYoukado.productSuppliers.find(s => s.forced);
            if (productSupplier == null || productSupplier == undefined) {
                productSupplier = this.productYoukado.productSuppliers[0];
            }
        }

        if (typeof this.productYoukado.mediaToProductYoukados != 'undefined' && this.productYoukado.mediaToProductYoukados != null && this.productYoukado.mediaToProductYoukados.length != 0) {
            index = this.productYoukado.mediaToProductYoukados.length;
        }
        this._commondata.showLoader(true);

        let httpParams = new HttpParams();
        httpParams = httpParams.append("supplierCode", productSupplier != null ? productSupplier.supplier.code : 'images');
        httpParams = httpParams.append("productCode", productSupplier != null ? productSupplier.code : this.productYoukado.code);
        httpParams = httpParams.append("fileListLength", index);

        this.dealWithStandardObservableSubscription(this.uploadService.handleFileUpload(formData, httpParams), {
            success: response => {
                const media: MediaToProductYoukadoDtoInterface = response;
                if (this.productYoukado.mediaToProductYoukados == null || this.productYoukado.mediaToProductYoukados == undefined || this.productYoukado.mediaToProductYoukados.length == 0) {
                    this.productYoukado.mediaToProductYoukados = [];
                }
                this.productYoukado.mediaToProductYoukados.push(media);
                this.fileInput.nativeElement.value = "";
                this.validationData.images = false;
            },
            error: response => {
                this.alertErrorImage = true;
                console.log('Erreur ! : ' + JSON.stringify(response));
            },
        });
    }

    handleFileUploadWithImageLink() {
        this.errorImageLink = false;
        if (this.imageLink == null || this.imageLink == "") {
            this.errorImageLink = true;
            return false;
        }

        let productSupplier: ProductSupplierDtoInterface = null;
        let index: number = 0;
        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers.length != 0) {
            productSupplier = this.productYoukado.productSuppliers.find(s => s.forced);
            if (productSupplier == null || productSupplier == undefined) {
                productSupplier = this.productYoukado.productSuppliers[0];
            }
        }
        if (typeof this.productYoukado.mediaToProductYoukados != 'undefined' && this.productYoukado.mediaToProductYoukados != null && this.productYoukado.mediaToProductYoukados.length != 0) {
            index = this.productYoukado.mediaToProductYoukados.length + 1;
        }

        this._commondata.showLoader(true);

        let httpParams = new HttpParams();
        httpParams = httpParams.append("imageLink", this.imageLink);
        httpParams = httpParams.append("supplierCode", productSupplier != null ? productSupplier.supplier.code : 'images');
        httpParams = httpParams.append("productCode", productSupplier != null ? productSupplier.code : this.productYoukado.code);
        httpParams = httpParams.append("fileListLength", index);

        this.dealWithStandardObservableSubscription(this.uploadService.handleFileUploadWithImageLink(httpParams), {
            success: response => {
                this.imageLink = null;
                const media: MediaToProductYoukadoDtoInterface = response;
                if (this.productYoukado.mediaToProductYoukados == null || this.productYoukado.mediaToProductYoukados == undefined || this.productYoukado.mediaToProductYoukados.length == 0) {
                    this.productYoukado.mediaToProductYoukados = [];
                }
                this.productYoukado.mediaToProductYoukados.push(media);
                this.fileInput.nativeElement.value = "";
                this.validationData.images = false;
                this._commondata.showLoader(false);
            },
            error: response => {
                this.alertErrorImage = true;
                this._commondata.showLoader(false);
                console.log('Erreur ! : ' + JSON.stringify(response));
            },
        });

        return true;
    }

}
