<div class="row">
    <div class="col-sm-6 mb-4">
        <h4> Catégories incluses dans catalogue par enseigne</h4>
    </div>
    <div class="col-sm-6">
        <ol class="breadcrumb pt-0 float-left float-sm-right ">
            <li class="breadcrumb-item active">
                <a href="javascript:history.back()" class="default-color">
                    <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i>
                    {{'core.navigation.back' | translate}}
                </a>
            </li>
        </ol>
    </div>
</div>

<div class="card card-statistics mb-30">
    <div class="card-body">
        <div class="row">

            <!-- Choose enseigne -->
            <div class="col-md-3">
                <label class="control-label"
                       style="display: flex;justify-content: center;">Enseigne</label>
                <div class="mb-2">
                    <ng-multiselect-dropdown id="enseigne"
                                             name="enseigne"
                                             [(ngModel)]="selectedEnseigne"
                                             [placeholder]="'Sélectionner une enseigne'"
                                             [settings]="dropdownSettingsEnseignesSearch"
                                             [data]="allEnseignesList"
                                             (onSelect)="getCategorieSpec()"
                                            (onDeSelect)="getCategorieSpec()">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <div  class="col-md-6" style="align-self: center;text-align: center;">
   
                <button *ngIf="selectedEnseigne != null && selectedEnseigne.length > 0"  type="button" class="btn btn-info" data-toggle="modal" data-target="#exampleModal" (click)="getCategorieSpec()">
                    Réinitialiser l'arborescence
                  </button>
            </div>

            <div  class="col-md-2" style="align-self: center;text-align: center;">
                <button (click)="save()" class="button black medium">
                    <i class="fa fa-floppy-o" aria-hidden="true"></i>
                    {{'components.category.form.save' | translate}}
                </button>

            </div>
            
        </div>
</div>

<div class="card card-statistics mb-30">
    <div class="card-body">
        <div class="form-row">

            <div class="col-md-12">
                <div class="card card-statistics mb-30" style="width: 100%">
                    <div class="card-body">
                        <form [formGroup]="formGroupSearch">
                            <div class="form-row align-items-center">
                                <div class="col-md-3">
                                    <div class="form-group mb-0" style="position: relative;padding-right: 5px;">
                                        <input placeholder="{{'components.category.search.keywords' | translate}}"
                                               formControlName="keywords" class="form-control search-input"
                                               (input)="search()">
                                        <button class="search-button" type="submit" style="">
                                            <i class="fa fa-search not-click"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchMain" formControlName="searchFilter" value="searchMain"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchMain">
                                                {{'components.category.form.main' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchSpecific" formControlName="searchFilter" value="searchSpecific"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchSpecific">
                                                {{'components.category.form.specific' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchService" formControlName="searchFilter" value="searchService"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchService">
                                                {{'components.category.form.service' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchAnimation" formControlName="searchFilter" value="searchAnimation"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchAnimation">
                                                {{'components.category.form.animation' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div style="padding-top: 18px;" class="col-lg-3 col-md-4 col-sm-6">
                                    <ng-multiselect-dropdown formControlName="searchTypeCategory" [(ngModel)]="currentTypeCategory"
                                                             [settings]="dropDownCategorySettings"
                                                             [data]="dropdownListTypeCategory"
                                                             [placeholder]="'Type offre'"
                                                             (onSelect)="search()"
                                                             (onDeSelect)="search()"
                                                             class="card">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
            <div class="row">

                <div class="col-xl-6 mb-30">
                    <div class="card card-statistics mb-30" style="width: 100%">
                        <div  *ngIf="selectedEnseigne != null && this.selectedEnseigne.length > 0" class="card-body">

                                <categorytree-component [data]="categories"
                                                                            [(ngModel)]="selectedCategoriesInTree"
                                                                            [searchCriteria]="searchCriteria"
                                                                            (onChange)="categoryChange()"
                                                                            (onSearch)="searchCategory($event)"
                                                                            [collapse]="false"
                                                                            [hasCheckboxesOnlyForLastChild]="false"
                                                                            [showStatus]="true"
                                                                            [isRules]="true"
                                                                            [isRulesCatalogue]="true"
                                                                            style="cursor: pointer;">
                                                        {{'components.catalog.sidebar.categories' | translate}}
                                </categorytree-component>
                                <div style="text-align: center;" *ngIf="categories != null && categories.childCategories == null">
                                    <img style="width: 140px;text-align: center;"
                                         src="assets/images/pre-loader/loader-01.svg">
                                </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 mb-30">
                    <div class="card card-statistics mb-30" style="width: 100%">
                        <div class="card-body">
                            <h6 style="margin-bottom: 4px !important;" class="mb-5">Catégorie(s) principales incluses dans le catalogue<i (click)="showMain=!showMain" class="fa {{!showMain ? 'fa-plus-square' : 'fa-minus-square'}}" style="font-weight: bold;cursor:pointer;padding-left: 10px;"></i></h6>
                            <div *ngIf="showMain">
                                <div style="display: flex;"
                                *ngFor="let treeCategory of treeCatgeriesSelectedMain; let index = index;">
                                <span style="display: flex;" *ngFor="let category of treeCategory; let index = index;">
                                    <i style="padding-left: 5px;padding-right: 5px;padding-top: 3px;font-size: 21px;"
                                        *ngIf="index!=0" class="fa fa-chevron-right" aria-hidden="true"></i>
                                    <span>{{category}}</span>
                                </span>
                            </div>
                            </div>
                            

                            <h6 style="margin-bottom: 4px !important;" class="mb-5">Catégorie(s) spécifiques incluses dans le catalogue<i (click)="showSpecific=!showSpecific" class="fa {{!showSpecific ? 'fa-plus-square' : 'fa-minus-square'}}" style="font-weight: bold;cursor:pointer;padding-left: 10px;"></i></h6>
                            <div *ngIf="showSpecific">
                                <div style="display: flex;"
                                *ngFor="let treeCategory of treeCatgeriesSelectedSpecific; let index = index;">
                                <span style="display: flex;" *ngFor="let category of treeCategory; let index = index;">
                                    <i style="padding-left: 5px;padding-right: 5px;padding-top: 3px;font-size: 21px;"
                                        *ngIf="index!=0" class="fa fa-chevron-right" aria-hidden="true"></i>
                                    <span>{{category}}</span>
                                </span>
                               </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>

            </div>

           
        </div>
    </div>
</div>