import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../../common-data.service';


@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  constructor(private _commondata: CommonDataService,
  ) { }

  ngOnInit(): void {

    this._commondata.showLoader(false);

  }

}
