import {Component, Input} from '@angular/core';
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {ChartComponent} from "src/app/components/charts/chart.component";


@Component({
    selector: 'image-charts-component',
    templateUrl: './image-charts.component.html',
    styleUrls: ['./image-charts.component.css']
})
export class ImageChartsComponent extends ChartComponent {

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        this.card = card;
    }
}
