import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {RuleDtoInterface} from "src/app/shared/interfaces/models/rest";
import {Injectable, Injector} from "@angular/core";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";

@Injectable({
    providedIn: 'root'
})
export class RuleService extends HttpServiceClass<RuleDtoInterface[]> {
    protected endpoint = "rules";

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected getId(item: RuleDtoInterface[]): number {
        return item[0].idRule;
    }

    /*getRulesList(params?: { name: string; active: boolean; idEnseigne: number; type: string }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getRulesList" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }*/

    getProperties(params?: { idEnseigne: number; idRule: number }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getProperties" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getRulesName(params?: { ruleName: string; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getRulesName" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
