import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';

import { combineLatest } from "rxjs";
import { IDropdownSettings, ListItem } from "ng-multiselect-dropdown/multiselect.model";

import { BaseComponents } from "src/app/shared/classes/components/base-components.class";
import { DeliverycountryService } from "src/app/shared/services/deliverycountry.service";
import { CategoryDtoInterface, DeliveryCountryDtoInterface } from "src/app/shared/interfaces/models/rest";
import { CategoryService } from "src/app/shared/services/category.service";

import { DropdownElementClass } from "src/app/shared/classes/dropdown/dropdownElement.class";
import { TranslateService } from "@ngx-translate/core";


// ================================= TODO garbage class : has to be refactored to become generic =================================
// code de marde

@Component({
    selector: 'filterbar-component',
    templateUrl: './filterbar.component.html',
    styleUrls: ['./filterbar.component.scss'],
})
export class FilterbarComponent extends BaseComponents implements OnInit {

    idEnseigne: number;


    deliveryCountries: DeliveryCountryDtoInterface[] = [];
    currentDeliveryCountrie: DeliveryCountryDtoInterface[] = [];
    typeCategory: string[] = [];
    currentDeliveryCountrie2: DeliveryCountryDtoInterface;

    categories: CategoryDtoInterface[] = [];
    currentCategory: any[] = [];
    dropdownListCategory: DropdownElementClass[] = [];
    dropdownListTypeCategory: any[] = [
        { item_id: 1, item_text: 'Principales' },
        { item_id: 2, item_text: 'Spécifiques' },
        { item_id: 3, item_text: 'Services' },
        { item_id: 4, item_text: 'Animations' },
    ];



    dropDownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'idDeliveryCountry',
        textField: 'label',
        allowSearchFilter: true,
        enableCheckAll: false
    };

    dropDownCategorySettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'text',
        allowSearchFilter: true,
        enableCheckAll: false
    };

    dropdownTypeCategorySettings = {
        singleSelection: true,
        enableCheckAll: true,
        allowSearchFilter: true,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: "Sélectionner tout",
        unSelectAllText: "Désélectionner tout",
        searchPlaceholderText: "Rechercher",
    };

    @Output() onFiltersChanged: EventEmitter<any> = new EventEmitter();

    constructor(private injector: Injector,
        private deliverycountryService: DeliverycountryService,
        private categoryService: CategoryService) {
        super(injector);
        // @ts-ignore

        this.subscriptions.push(combineLatest([
            this.deliverycountryService.list(),
            this.categoryService.listTree()
        ]).subscribe(response => {
            this.deliveryCountries = response[0];
            // on force le filtre des pays de livraison au pays FRANCE
            /*this.currentDeliveryCountrie = [
                this.deliveryCountries.find(country => country.idDeliveryCountry === 5)
            ]*/

            this.currentDeliveryCountrie2 = this.deliveryCountries.find(country => country.idDeliveryCountry === 5);

            this.categories = response[1];
            this.setDropDownCategoryList(this.categories);
            this.currentCategory = [
                this.dropdownListCategory.find(a => a.id == 0)
            ];

            this.typeCategory = [
                this.dropdownListTypeCategory.find(a => a.item_id == 1)
            ];
            this.onFiltersChanged.emit({
                deliveryCountries: this.deliveryCountries,
                deliveryCountrie: this.currentDeliveryCountrie2,
                // decomment si ng multiselect
                // deliveryCountrie: this.currentDeliveryCountrie,
                categories: this.categories,
                typeCategorie: this.typeCategory,
                principale: true,
            });
            this.emitValues()
        }, error => {
            this.dealWithStandardError(error);
            this.commondataService.showLoader(false);
        }));

    }
    setDropDownCategoryList(categories: CategoryDtoInterface[]): void {
        let list = [];
        for (let cat of categories) {
            let libele = cat.translationCategoriesCategory.find(translationCat => translationCat.languageTranslationCategory.code == "FR")?.labelYoukado;
            if (!libele) {
                libele = cat.code
            }
            list.push({ id: cat.idCategory, text: libele })
        }
        this.dropdownListCategory = list;
    }

    ngOnInit() {
    }

    emitValues(): void {
        this.onFiltersChanged.emit({

            deliveryCountrie: this.currentDeliveryCountrie2,
            // decomment si ng multiselect
            //deliveryCountrie: this.currentDeliveryCountrie,
            categorie: this.currentCategory,
            typeCategorie: this.typeCategory,
            principale: true
        });

    }


    deliveryCountrieChanged(listItem: ListItem): void {
        this.emitValues();
    }

    categoryChanged(listItem: ListItem): void {
        this.emitValues();
    }

    reset(): void {
        this.emitValues();
    }
}
