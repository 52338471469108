import {TranslateService} from "@ngx-translate/core";
import {Component} from "@angular/core";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'ng8';

    constructor(public translateService: TranslateService) {
        // this.translateService.addLangs(["en", "fr", "nl", "it", "ar", "es", "pt"]);
        this.translateService.addLangs(["en", "fr", "nl"]);
        this.translateService.setDefaultLang("fr");
        const browserLang = this.translateService.getBrowserLang();
        this.translateService.use(browserLang.match(/en|fr|nl/) ? browserLang : "en");
    }

    ngOnInit() {
    }

    async hideToasta() {
        document.getElementById("toasta").style.setProperty('display', 'none');
        await new Promise(f => setTimeout(f, 10000));
        document.getElementById("toasta").style.setProperty('display', 'block');
    }
}
