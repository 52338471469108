import {Component, Injector, OnDestroy} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {CommonDataService} from "src/app/common-data.service";

import {ToastService} from "src/app/shared/services/toast.service";
import {BehaviorSubject, Observable, Subscription} from "rxjs";


@Component({
    template: ''
})
export abstract class BaseComponents implements OnDestroy {
    translateService: TranslateService;
    commondataService: CommonDataService;
    toastService: ToastService;
    subscriptions: Subscription[] = [];


    protected constructor(injector: Injector) {
        this.translateService = injector.get(TranslateService);
        this.commondataService = injector.get(CommonDataService);
        this.toastService = injector.get(ToastService);
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }

    dealWithStandardObservableSubscription(
        observable: Observable<any>,
        callbacks?: {
            success?: (response: any) => any,
            error?: (error: any) => any,
            complete?: () => any,
            finally?: () => any
        }
    ): void {
        observable.subscribe((response: any) => {
            if (callbacks?.success) {
                callbacks.success(response);
            }
            if (callbacks?.finally) {
                callbacks.finally();
            }
            this.commondataService.showLoader(false);
            this.toastService.ok();
        }, (error: any) => {
            if (callbacks?.error) {
                callbacks.error(error);
            }
            if (callbacks?.finally) {
                callbacks.finally();
            }
            this.dealWithStandardError(error);
        }, () => {
            if (callbacks?.complete) {
                callbacks.complete();
            }
        });
    }

    dealWithStandardError(error: any): void {
        this.commondataService.showLoader(false);
        this.toastService.error(this.translateService.instant("core.errors.contact"), JSON.stringify(error));
    }
}
