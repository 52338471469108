import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { CommonDataService } from './common-data.service';
import { ListProductsComponent } from './components/catalog/list-products.component';
import { ListProductsItemComponent } from './components/catalog/list-products-item/list-products-item.component';
import { ProductComponent } from "./components/product/product.component";
import { SidebarCatalogComponent } from "./components/sidebar/sidebar-catalog.component";
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SupplierComponent } from "./components/supplier/supplier.component";
import { getHttpLoaderFactory } from "src/app/shared/functions/loaders/translation-loader.function";
import { ParamsTranslatePipe } from "src/app/shared/pipes/paramtranslate.pipe";
import { NgxsModule } from "@ngxs/store";
import { environment } from "src/environments/environment";
import { AppState } from "src/app/shared/store/states/app.state";
import { AutosizeModule } from "ngx-autosize";
import { AutosizetextareaDirective } from "src/app/shared/directives/autosizetextarea.directive";
import { AutofocussearchmultiselectDirective } from "src/app/shared/directives/autofocussearchmultiselect.directive";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { TagInputModule } from 'ngx-chips';
import { CKEditorModule } from 'ngx-ckeditor';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ProductSupplierComponent } from './components/product/product-supplier/product-supplier.component';
import { ProductDetailsComponent } from './components/product/product-details/product-details.component';
import { ProductsSupplierItemComponent } from './components/product/product-supplier/products-supplier-item/products-supplier-item.component';
import { ProductImagesComponent } from './components/product/product-images/product-images.component';
import { ProductSalePriceComponent } from './components/product/product-sale-price/product-sale-price.component';
import { ProductDescriptionsComponent } from './components/product/product-descriptions/product-descriptions.component';
import { ProductCategoriesComponent } from './components/product/product-categories/product-categories.component';
import { Modal1Component } from './shared/directives/index';
import { ModalService } from './shared/services/index';
import { CategorytreeComponent } from './components/categorytree/categorytree.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { CategoryComponent } from './components/category/category.component';
import { ToastaModule } from "ngx-toasta";
import { PaginationComponent } from "src/app/components/pagination/pagination.component";
import { IndeterminateDirective } from "src/app/shared/directives/indeterminate.directive";
import { NullablecheckboxComponent } from "src/app/components/nullablecheckbox/nullablecheckbox.component";
import { PricehistoryComponent } from "src/app/components/pricehistory/pricehistory.component";
import { CountryComponent } from "src/app/components/country/country.component";

import { CustomlocationStrategy } from "src/app/shared/strategy/customlocation.strategy";
import { initializer } from "src/app/shared/auth/app-init";
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { APP_INITIALIZER } from '@angular/core';

import { } from '@angular/core';
import { AccessDeniedComponent } from './components/auth/access-denied/access-denied.component';
import { RequestInterceptor } from "src/app/shared/auth/request.interceptor";
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { RuleComponent } from './components/rule/rule.component';
import { SalePriceComponent } from './components/sale-price/sale-price.component';
import {FilterbarComponent} from "src/app/components/filterbar/filterbar.component";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {APP_BASE_HREF, DecimalPipe, LocationStrategy} from "@angular/common";
import {GridsterModule} from "angular-gridster2";
import {CardComponent} from "src/app/components/charts/card.component";
import {GooglemapsComponent} from "src/app/components/googlemaps/googlemaps.component";
import {ValueChartsComponent} from "src/app/components/charts/value/value-charts.component";
import {TableChartsComponent} from "src/app/components/charts/table/table-charts.component";
import {TilesChartsComponent} from "src/app/components/charts/tiles/tiles-charts.component";
import {ImageChartsComponent} from "src/app/components/charts/image/image-charts.component";
import {PieChartsComponent} from "src/app/components/charts/pie/pie-charts.component";
import {StoryChartsComponent} from "src/app/components/charts/story/story-charts.component";
import {LinealtChartsComponent} from "src/app/components/charts/line-alt/linealt-charts.component";
import {BannerChartsComponent} from "src/app/components/charts/banner/banner-charts.component";
import {EmptyChartsComponent} from "src/app/components/charts/empty/empty-charts.component";
import {ButtonChartsComponent} from "src/app/components/charts/button/button-charts.component";
import {
    CustomhorizontallineChartsComponent
} from "src/app/components/charts/customhorizontalline/customhorizontalline-charts.component";
import {MapChartsComponent} from "src/app/components/charts/map/map-charts.component";
import {LineChartsComponent} from "src/app/components/charts/line/line-charts.component";
import {BignumberPipe} from "src/app/shared/pipes/bignumber.pipe";
import {GooglemapsService} from "src/app/shared/services/googlemaps.service";
import { ExportsComponent } from './components/exports/exports.component';
import { DatePipe } from '@angular/common';
import { CategoryCatalogueComponent } from './components/category-catalogue/category-catalogue.component';
import { RuleCatalogueComponent } from './components/rule-catalogue/rule-catalogue.component';
import { FilterVentesComponent } from './components/exports/filter-ventes/filter-ventes.component';
import { FilterOffresComponent } from './components/exports/filter-offres/filter-offres.component';

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        SidebarCatalogComponent,
        ListProductsComponent,
        ListProductsItemComponent,
        SupplierComponent,
        ParamsTranslatePipe,
        BignumberPipe,
        AutosizetextareaDirective,
        IndeterminateDirective,
        AutofocussearchmultiselectDirective,
        ProductComponent,
        ProductSupplierComponent,
        Modal1Component,
        ProductDetailsComponent,
        ProductsSupplierItemComponent,
        ProductImagesComponent,
        ProductSalePriceComponent,
        ProductDescriptionsComponent,
        ProductCategoriesComponent,
        CategorytreeComponent,
        CategoryComponent,
        PaginationComponent,
        NullablecheckboxComponent,
        PricehistoryComponent,
        CountryComponent,
        AccessDeniedComponent,
        DashboardComponent,
        RuleComponent,
        SalePriceComponent,
        FilterbarComponent,
        CardComponent,
        GooglemapsComponent,
        ValueChartsComponent,
        TableChartsComponent,
        TilesChartsComponent,
        ImageChartsComponent,
        PieChartsComponent,
        StoryChartsComponent,
        LinealtChartsComponent,
        BannerChartsComponent,
        LineChartsComponent,
        EmptyChartsComponent,
        ButtonChartsComponent,
        CustomhorizontallineChartsComponent,
        MapChartsComponent,
        ExportsComponent,
        CategoryCatalogueComponent,
        RuleCatalogueComponent,
        FilterVentesComponent,
        FilterOffresComponent
    ],
    imports: [
        NgxsModule.forRoot([AppState], {
            developmentMode: !environment.production
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: getHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        ToastaModule.forRoot(),
        AppRoutingModule,
        NgScrollbarModule,
        HttpClientModule,
        NgbModule,
        NgbModalModule,
        NgMultiSelectDropDownModule,
        FormsModule,
        ReactiveFormsModule,
        AutosizeModule,
        CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
        TagInputModule,
        CKEditorModule,
        NgxDatatableModule,
        BrowserAnimationsModule,
        DragDropModule,
        AutocompleteLibModule,
        NgSelectModule,
        KeycloakAngularModule,
        ChartsModule,
        NgxChartsModule,
        GridsterModule
    ],
    exports: [ToastaModule],
    providers: [
        CommonDataService,
        ModalService,
        GooglemapsService,
        DecimalPipe,
        DatePipe,
        { provide: APP_BASE_HREF, useValue: '/marketplace/' },
        { provide: LocationStrategy, useClass: CustomlocationStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [KeycloakService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
