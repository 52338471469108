import {EventEmitter, Injectable, Injector, Output, ViewContainerRef} from "@angular/core";

import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class ApiGooglemapsService extends HttpServiceClass<any>{
    protected endpoint = 'cards';

    constructor(protected injector: Injector) {
        super(injector);
    }

    geocoding(address:string): Observable<any> {
        const options = {
            params:new HttpParams()
        };
        options.params = options.params.set("key", "AIzaSyCnhBtO9TubyQz9pWQ_zcXXOgPRtUcoaiI");
        options.params = options.params.set("address", address);

        return this.httpClient.get<any>(
            "https://maps.googleapis.com/maps/api/geocode/json", options)
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    protected getId(item: any): number {
        return 0;
    }
}
