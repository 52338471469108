import {Injectable, Injector} from '@angular/core';
import {LanguageDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {LanguageEnum} from "src/app/shared/enums/language.enum";

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends HttpServiceClass<LanguageDtoInterface> {
    protected endpoint = "languages";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: LanguageDtoInterface): number {
        return item.idLanguage;
    }

    languageToLanguageId(value: string): number {
        switch(value) {
            case LanguageEnum.FR:
                return 1;
                break;
            case LanguageEnum.NL:
                return 2;
                break;
            case LanguageEnum.EN:
                return 8;
                break;
            default:
                throw new Error('Implement language translation')
        }
    }
}
