import {Component, Input} from '@angular/core';
import {ChartComponent} from "src/app/components/charts/chart.component";
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";


@Component({
    selector: 'table-charts-component',
    templateUrl: './table-charts.component.html',
    styleUrls: ['./table-charts.component.css']
})
export class TableChartsComponent extends ChartComponent {
    columnIcons: string[] = [];
    rowIcons: string[] = [];

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        this.card = card;
        if (this.card.icone) {
            this.columnIcons = this.card.icone.split(";");
        }
        if (this.card.urlBackground) {
            this.rowIcons = this.card.urlBackground.split(";");
        }
    }
}
