import {Action, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {AppStoreInterface} from "src/app/shared/interfaces/store/app-store.interface";
import {TokenActions} from "src/app/shared/store/actions/token.actions";

@State<AppStoreInterface>({
    name: 'app',
    defaults: {
        token: null
    }
})
@Injectable()
export class AppState {
    @Action(TokenActions.Add)
    addToken(stateContext: StateContext<AppStoreInterface>, action: TokenActions.Add) {
        const state = stateContext.getState();
        stateContext.patchState({
            ...state,
            token: action.payload
        });
    }
}
