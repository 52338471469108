import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CommonDataService {

    public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public menuIsOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    showLoader(value: boolean) {
        this.loader.next(value);
    }

    setExpandDiv(id: any) {

    }

    changeStateMenu(isOpen : boolean) {
        this.menuIsOpened.next(isOpen);
    }
}
