import {Injectable, Injector} from '@angular/core';
import {HttpServiceClass} from "../classes/services/http-services.class";
import {HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {CategoryDtoInterface} from "src/app/shared/interfaces/models/rest";

@Injectable({
    providedIn: 'root'
})
export class CategoryService extends HttpServiceClass<CategoryDtoInterface> {
    protected endpoint = "categories";

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected getId(item: CategoryDtoInterface): number {
        return item.idCategory;
    }


    listTree(params?: HttpParams) {
        return this.httpClient.get<CategoryDtoInterface[]>(
            this.getPath({path: "tree"}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }



    getNextValSequence(params?: HttpParams) {
        return this.httpClient.get<number>(
            this.getPath({ path: "nextVal" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getEnseignesInclusion(params?: { idCategory: number }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getEnseignesInclusion" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getProperties(params?: { idEnseigne: number; idCategory: number }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getProperties" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getMainCategories(params?: HttpParams) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getMainCategories" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getCategoryByCode(params?: { categoryCode: string; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getCategoryByCode" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getCategoryProducts(params?: { idCategory: number; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getCategoryProducts" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    updateCategoryProducts(params?: { idCategory: number; idProduct: number }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "updateCategoryProducts" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getAllProductsByCategory(params?: { idCategory: number; }){
        return this.httpClient.get<number[]>(
            this.getPath({ path: "getCategoryProducts" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
