import {Injectable, Injector} from '@angular/core';
import {HistoryProductSupplierPriceDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";

@Injectable({
    providedIn: 'root'
})
export class PricehistoryService extends HttpServiceClass<HistoryProductSupplierPriceDtoInterface> {
    protected endpoint = "historyproductsupplierprices";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: HistoryProductSupplierPriceDtoInterface): number {
        return item.idHistoryProductSupplierPrice;
    }
}
