<div class="card-body" style="height: 100%">
    <ng-container *ngIf="card">
        <h3 *ngIf="card.libelle">{{card.libelle}}</h3>
        <!--            <h5 *ngIf="card.comment">{{card.comment}}</h5>-->
        <div >
            <table style="width: 100%">
                <tr>
                    <th></th>
                    <th *ngIf="rowIcons.length"></th>
                    <th *ngFor="let entete of card.entete; let index = index;"
                        [ngStyle]="{'width':60/card.entete.length}" class="cols">
                        <p *ngIf="columnIcons.length > index">
                            <img src="./assets/images/app/_core/components/charts/{{columnIcons[index]}}.png" alt=""
                                 style="width: 70px; max-height: 70px;"/>
                        </p>
                        <p>{{entete.title}}</p>
                    </th>
                </tr>
                <tr *ngFor="let line of card.data; let index = index;">
                    <td *ngIf="rowIcons.length">
                        <!--                            <img src=""/>-->
                        <!---->
                        <div class="icon"
                             [style.backgroundImage]="'url(\'assets/images/app/_core/components/charts/' + rowIcons[index] + '.png\')'"></div>
                    </td>
                    <td>{{line.name}}</td>
                    <td *ngFor="let value of line.values; let index = index;"
                        class="cols"
                        [ngStyle]="{'text-align' : isNumericValue(value) ? 'right' : 'center'}">
                        {{value | number : '1.0-2' }}
                        <span *ngIf="card.entete[index]?.unit">{{line.units[index]}}</span>
                        <!--                            <ng-container *ngIf="line.parentValues[index] !== undefined">-->
                        <!--                                <span *ngIf="line.parentValues[index]">-->
                        <!--                                    <span [ngClass]="{'green': value > line.parentValues[index], 'red': value < line.parentValues[index]}">-->
                        <!--                                        {{value}} %-->
                        <!--                                    </span>-->
                        <!--                                    <small>{{value - line.parentValues[index] > 0 ? "+" : ""}}{{value - line.parentValues[index]}} %</small>-->
                        <!--                                </span>-->
                        <!--                                <span *ngIf="!line.parentValues[index]">-->
                        <!--                                    {{value}} %-->
                        <!--                                </span>-->
                        <!--                            </ng-container>-->
                        <!--                            <span *ngIf="line.parentValues[index] === undefined">-->
                        <!--                                {{value}}-->
                        <!--                            </span>-->

                        <small *ngIf="line.parentValues[index]"
                               [ngClass]="{'green': value > line.parentValues[index], 'red': value < line.parentValues[index]}">
                            {{value - line.parentValues[index] > 0 ? "+" : ""}}{{value - line.parentValues[index] | number : '1.0-2' }}
                            <ng-container *ngIf="card.entete[index].unit === '%'">pts</ng-container>
                        </small>
                    </td>
                </tr>
            </table>
            <p class="comment" *ngIf="card.comment">{{card.comment}}</p>
        </div>
    </ng-container>
</div>
