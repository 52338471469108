import {Component, Input} from '@angular/core';
import {ChartDataSets, ChartLegendOptions, ChartOptions} from "chart.js";

import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {ChartComponent} from "src/app/components/charts/chart.component";
import {Label} from "ng2-charts";

@Component({
    selector: 'pie-charts-component',
    templateUrl: './pie-charts.component.html',
    styleUrls: ['./pie-charts.component.css']
})
export class PieChartsComponent extends ChartComponent {
    pieChartDatasets: ChartDataSets[] = [];
    pieChartLabels: Label[] = [];
    valuesAverage: string;

    options: ChartOptions = {
        animation: {
            duration: 1000, // general animation time
            easing: 'easeOutBack'
        },

        hover: {
            animationDuration: 1000, // duration of animations when hovering an item
        },
       legend: {
            position: 'top',
            display: true
        },
        responsive: true,
        responsiveAnimationDuration: 0,
        maintainAspectRatio: false
    };

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        this.card = card;

        this.loadDataForChart(card);
    }
    private loadDataForChart(card: CardApiClass): void {
        this.pieChartDatasets = [];
        this.pieChartLabels = [];

        let data: any[] = [];

        // set values, labels
        card.charsets.forEach((charset: any) => {
            charset.values.forEach((line: any) => {
                this.pieChartLabels.push(line.label);
                data.push(line.value);
            });
            this.pieChartDatasets.push({data: data})
            }
        );
        //TODO: spécifique dashboard marketplace, voir une autre facon pour récupérer cette valeur ?
        this.valuesAverage = card.charsets[0].valuesAverage + "%";
    }
    public chartClicked(e: any): void {
        console.log(e);
    }

    public chartHovered(e: any): void {
        // console.log(e);
    }


}
