import {Injectable, Injector} from '@angular/core';
import {HistoryCatalogueInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";

@Injectable({
    providedIn: 'root'
})
export class CataloguehistoryService extends HttpServiceClass<HistoryCatalogueInterface> {
    protected endpoint = "historyCatalogues";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: HistoryCatalogueInterface): number {
        return item.idHistoryCatalogue;
    }
}
