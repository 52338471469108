import {Injectable, Injector} from '@angular/core';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {UserDtoInterface} from "src/app/shared/interfaces/models/rest";

@Injectable({
    providedIn: 'root'
})
export class UserService extends HttpServiceClass<UserDtoInterface> {
    protected endpoint = "users";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: UserDtoInterface): number {
        return item.idUser;
    }

}
