import {Injectable, Injector} from '@angular/core';
import {VatDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {map} from "rxjs/operators";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class VatsService extends HttpServiceClass<VatDtoInterface> {
    protected endpoint = "vats";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: VatDtoInterface): number {
        return item.idVat;
    }

    getVats(expeditionCountryCode: number, params?: HttpParams) {
        return this.httpClient.get<VatDtoInterface[]>(
            this.getPath({path: "" + expeditionCountryCode}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
