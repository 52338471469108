import { CompactType, DisplayGrid, GridsterComponent, GridsterConfig, GridType } from "angular-gridster2";
import { combineLatest, timer } from "rxjs";

declare var google: any;
import { Component, ChangeDetectionStrategy, OnInit, ViewChild, TemplateRef, Injector } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonDataService } from './../../common-data.service';
import * as shape from 'd3-shape';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { DeliveryCountryDtoInterface, TableDtoInterface } from "src/app/shared/interfaces/models/rest";
import { DeliverycountryService } from "src/app/shared/services/deliverycountry.service";
import { DashboardService } from "src/app/shared/services/dashboard.service";
//import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DashbordOptionsModelsInterface } from "src/app/shared/interfaces/models/dashboard/dashboard-options-models.interface";
import { GooglemapsService } from "src/app/shared/services/googlemaps.service";
import { ApiGooglemapsService } from "src/app/shared/services/googlemaps/api-googlemaps.service";
import { CardService } from "src/app/shared/services/card.service";
import { RegionService } from "src/app/shared/services/region.service";
import { AgencyService } from "src/app/shared/services/agency.service";
import { SynchronizationService } from "src/app/shared/services/synchronization.service";
import { TabService } from "src/app/shared/services/tab.service";
import { AtcService } from "src/app/shared/services/atc.service";
import { TokenService } from "src/app/shared/services/token.service";
import { DoService } from "src/app/shared/services/do.service";
import { Gridsteritemextended } from "src/app/shared/classes/gridsteritemextended.class";
import { AgencyApiClass } from "src/app/shared/classes/api/agency-api.class";
import { CardApiClass } from "src/app/shared/classes/api/card-api.class";
import { RegionApiClass } from "src/app/shared/classes/api/region-api.class";

import { MarkertypeGooglemapsEnum } from "src/app/shared/enums/googlemaps/markertype-googlemaps.enum";
import { CalculationUtils } from "src/app/shared/utils/calculation.utils";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponents } from "src/app/shared/classes/components/base-components.class";
import { FiltersUtils } from "src/app/shared/utils/filters.utils";
import { Subject } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { LanguageService } from "src/app/shared/services/language.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponents implements OnInit {


  deliveryCountryActif: string = "FR";
  deliveryCountries: DeliveryCountryDtoInterface[] = [];
  totalProduct: string = null;
  publishedProduct: string = null;
  unpublishedProduct: string = null;
  standbyProduct: string = null;
  productState: any[] = null;
  productNumberTable: TableDtoInterface = null;

  options: GridsterConfig = {
    // general
    gridType: GridType.VerticalFixed,
    fixedRowHeight: 160,
    compactType: CompactType.None,
    displayGrid: DisplayGrid.None,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    setGridSize: true,

    // sizing - grid
    margin: 30,
    minCols: 12,
    maxCols: 12,
    minRows: 1,
    maxRows: 100,

    pushItems: true,


    // sizing-item
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
  };

  filters: any = null;
  filtersChanged = new Subject<number>();

  cards: CardApiClass[] = null;
  dashboard: Gridsteritemextended[] = [];
  cardToMap = new Map<number, number>();
  cardsLoadMap = new Map<number, number>();

  tabs: { id: number, name: string }[];
  // tabs = ["1", "2", "3", "4"];
  currentTab = 1;

  regions: RegionApiClass[] = [];
  agencies: AgencyApiClass[] = [];

  // currentRegion: RegionApiClass[];
  // currentDo: DoApiClass[];
  // currentAgency: AgencyApiClass[];
  // currentAtc: AtcApiClass[];

  iconRatio = 0.61255;
  locked: boolean = true;
  libTooltipLocker = "Déverouiller l'écran permet de personnaliser le dashboard";

  constructor(private _commondata: CommonDataService,
    private modal: NgbModal,
    protected deliverycountryService: DeliverycountryService,
    protected dashboardService: DashboardService,
    private injector: Injector,
    private googlemapsService: GooglemapsService,
    private apiGooglemapsService: ApiGooglemapsService,
    private cardService: CardService,
    private regionService: RegionService,
    private doService: DoService,
    private agencyService: AgencyService,
    private atcService: AtcService,
    private tokenService: TokenService,
    private tabService: TabService,
    translateService: TranslateService,
    private languageService: LanguageService,
    private synchronizationService: SynchronizationService) {
    super(injector);

  }


  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @ViewChild("gridster") gridster: GridsterComponent;

  /*public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };*/
  public barChartType: ChartType = 'bar';
  /*public barChartPlugins = [
    DataLabelsPlugin
  ];*/

  /*public barChartData: ChartData<'bar'> = {
    labels: ['ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI', 'ACCESSOIRES AUDI'],
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80],
        label: 'Produit publiés',
        backgroundColor: '#93c6a6',
        hoverBackgroundColor: '#2f4454',
      },

    ]
  };
  */


  /*public doughnutChartLabels: string[] = ['<0%', '0 à 10%', '10 à 20%', '20 à 25%', '25 à 30%', '30 à 40%', '>40%'];
  public doughnutChartData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      { data: [5, 400, 7000, 8000, 10000, 900, 300] }
    ]
  };*/

  public doughnutChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };

  public doughnutChartType: ChartType = 'doughnut';

  /*
  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
  }*/

  /*public randomize(): void {
    // Only Change 3 values
    this.barChartData.datasets[0].data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.round(Math.random() * 100),
      56,
      Math.round(Math.random() * 100),
      40];

    this.chart?.update();
  }*/


  ngOnInit() {
    //   subscribe to event
    this._commondata.menuIsOpened.subscribe(() => {
      if (this.gridster) {
        const subcribe = timer(400).subscribe(event => {
          // reload gridster to trigger reflow
          this.options.api.resize();
        });

      }
    });

    const dashboardOptions: DashbordOptionsModelsInterface = {
      label: "products_number",
      dashboardType: "table",
    }
    const httpParams = this.dashboardService.optionsToParams(dashboardOptions);
    this.dashboardService.generate(httpParams).subscribe(response => {
      // @ts-ignore
      this.productNumberTable = response.tableDto;
      this.totalProduct = this.productNumberTable.rows.find(row => row.dataRow.includes("FR")).dataRow[1];
      this.publishedProduct = this.productNumberTable.rows.find(row => row.dataRow.includes("FR")).dataRow[2];
      this.unpublishedProduct = this.productNumberTable.rows.find(row => row.dataRow.includes("FR")).dataRow[3];
      this.standbyProduct = this.productNumberTable.rows.find(row => row.dataRow.includes("FR")).dataRow[4];

    }, error => {
      console.log('Error : ' + error);
    });

    this.deliverycountryService.list().subscribe((response: DeliveryCountryDtoInterface[]) => {
      this.productState = [];
      this.deliveryCountries = response;
      const deliveryCountry = this.deliveryCountries.find(deliveryCountry => deliveryCountry.code == "FR");
      let index = this.deliveryCountries.findIndex(deliveryCountry => deliveryCountry.code === "FR");
      this.deliveryCountries.splice(index, 1);
      this.deliveryCountries.unshift(deliveryCountry);
      setTimeout(() => this._commondata.showLoader(false), 200);
    });


    this.commondataService.showLoader(true);

    combineLatest([
      this.tabService.listMock(64),
      this.cardService.list(),
      this.filtersChanged
    ]).subscribe(response => {
      // tabs
      if (!this.tabs) {
        this.tabs = response[0];
        if (this.tabs?.length) {
          this.currentTab = this.tabs[0].id;
        }
      }

      // cards
      if (!this.cards) {
        // init
        response[1].forEach((item: { enseigne: number; }) => {
          item.enseigne = this.tokenService.idEnseigne;
        });
        this.cards = response[1];
        this.cards.forEach(item => {
          this.cardsLoadMap.set(item.divId, 0);
        });
      }

      this.filters = response[2];

      this.getCards();
    }, error => {
      this.dealWithStandardError(error);
      this.commondataService.showLoader(false);
    })


  }


  changeDeliveryCountry(deliveryCountry: string) {
    this.deliveryCountryActif = deliveryCountry;
    this.totalProduct = this.productNumberTable.rows.find(row => row.dataRow.includes(deliveryCountry)).dataRow[1];
    this.publishedProduct = this.productNumberTable.rows.find(row => row.dataRow.includes(deliveryCountry)).dataRow[2];
    this.unpublishedProduct = this.productNumberTable.rows.find(row => row.dataRow.includes(deliveryCountry)).dataRow[3];
    this.standbyProduct = this.productNumberTable.rows.find(row => row.dataRow.includes(deliveryCountry)).dataRow[4];
  }

  tabClick(tabId: any): void {
    this.currentTab = tabId;
  }

  lockedChange() {
    this.locked = !this.locked;
    this.options.draggable.enabled = !this.locked;
    this.options.resizable.enabled = !this.locked;
    this.options.api.optionsChanged();
    this.libTooltipLocker = this.locked ? 'Déverouiller l\'écran permet de personnaliser le dashboard' : 'Vérrouiller l\'écran bloquera la disposition du dashboard';

  }

  getCards(): void {
    let gridsteritemextended: Gridsteritemextended;

    var httpParams: HttpParams = FiltersUtils.getHttpParamsFromParams(this.filters);
    httpParams = httpParams.set("idLanguage", this.languageService.languageToLanguageId(this.translateService.currentLang));

    this.cards.forEach((cardListItem: CardApiClass) => {
      gridsteritemextended = this.dashboard.find(item => item?.divId === cardListItem?.divId);
      if (!gridsteritemextended) {
        gridsteritemextended = {} as Gridsteritemextended;
        DashboardComponent.fillGridsteritemInfo(gridsteritemextended, cardListItem);
        this.dashboard.push(gridsteritemextended);
      }

      if (cardListItem.panel === "MULTILINESCHART" && (this.filters?.dateFrom2 || this.filters?.dateTo2)) {

        // todo code degueu ; généraliser les appels de plusieurs "séries" de valeur ?
        // @ts-ignore

        this.subscriptions.push(combineLatest([
          this.cardService.get(cardListItem.divId, FiltersUtils.getHttpParamsFromParams({
            dateFrom1: this.filters?.dateFrom1,
            dateTo1: this.filters?.dateTo1
          })),
          this.cardService.get(cardListItem.divId, FiltersUtils.getHttpParamsFromParams({
            dateFrom1: this.filters?.dateFrom2,
            dateTo1: this.filters?.dateTo2
          }))
        ]).subscribe(response => {
          let card: CardApiClass = response[0];
          let card2: CardApiClass = response[1];
          if (!card || !card2) {
            return;
          }

          card.charts.forEach((chart, index) => {
            chart.value = chart.value.concat(card2.charts[index].value);
            chart.value.forEach((value, index) => {
              value.name = "Periode " + (index + 1);
            })
          });

          this.dashboard.find(item => item?.divId === cardListItem?.divId).card = card;
          this.dashboard.find(item => item?.divId === cardListItem?.divId).card.enseigne = cardListItem.enseigne;
          this.fillCardsInfo(gridsteritemextended);
          this.cardsLoadMap.set(cardListItem.divId, this.cardsLoadMap.get(cardListItem.divId) - 1);
        }, error => {
          this.dealWithStandardError(error);
          this.commondataService.showLoader(false);
        }));

        this.cardsLoadMap.set(cardListItem.divId, this.cardsLoadMap.get(cardListItem.divId) + 1);
        // /todo fin code degueu
      }
      else if (cardListItem.panel !== "COUNTRYCHART" || !this.dashboard.find(item => item?.divId === cardListItem?.divId).card) {
        this.cardService.get(cardListItem.divId, httpParams).subscribe((card: CardApiClass) => {
          if (!card) {
            return;
          }
          this.dashboard.find(item => item?.divId === cardListItem?.divId).card = card;
          this.dashboard.find(item => item?.divId === cardListItem?.divId).card.enseigne = cardListItem.enseigne;
          this.fillCardsInfo(gridsteritemextended);
          this.cardsLoadMap.set(cardListItem.divId, this.cardsLoadMap.get(cardListItem.divId) - 1);
        });
        this.cardsLoadMap.set(cardListItem.divId, this.cardsLoadMap.get(cardListItem.divId) + 1);
      }

      // this.cards.forEach(item => {
      // });
    });
  }

  googlemapsComponentReady(data: { mapId: number, divId: number }): void {
    console.log("googlemapsComponentReady");
    this.cardToMap.set(data.divId, data.mapId);
    this.updateMap(data.divId);
  }

  private updateMap(divId: number) {

    const mapId = this.cardToMap.get(divId);
    if (mapId === null || mapId === undefined) {
      return;
    }

    // this.googlemapsService.addControls(mapId, [{
    //     id: 0,
    //     title: "Régions",
    //     position: google.maps.ControlPosition.TOP_LEFT,
    //     index: 0,
    //     callback: () => {
    //         if (0 === this.currentMode) {
    //             return;
    //         }
    //         this.currentMode = 0;
    //
    //         this.drawRegions(mapId);
    //     },
    //     activation: true
    // }, {
    //     id: 1,
    //     title: "DO",
    //     position: google.maps.ControlPosition.TOP_LEFT,
    //     index: 1,
    //     callback: () => {
    //         if (1 === this.currentMode) {
    //             return;
    //         }
    //         this.currentMode = 1;
    //
    //         this.regions.forEach(region => {
    //             this.googlemapsService.clearPolygon(mapId, region.nom);
    //             this.googlemapsService.clearMarker(mapId, region.nom);
    //         });
    //     }
    // }]);

    this.drawAgencies(divId, mapId);

    this.drawRegions(divId, mapId);

    // this.googlemapsService.clearMarkers(mapId);
    // const card = this.dashboard.find(item => item.card.cardId === cardId);
    // (card.card.data.cards[0].valeur as any[]).forEach(item => {
    //     this.googlemapsService.drawMarker(mapId, item, item.description);
    // });
  }

  private drawRegions(divId: number, mapId: number): void {

    const data = this.dashboard.find(item => item.card?.divId === divId)?.card;
    if (!data?.value) {
      return;
    }

    let maxPoints = 0;
    this.regions.forEach(region => {
      const regionData = (data.value as any[]).find(item => item.region === region.nom);
      if (!regionData) {
        return;
      }

      // regionData.value1 = Math.floor(Math.random() * (100)) + 1;
      // regionData.value2 = Math.floor(Math.random() * (100)) + 1;
      // regionData.value3 = Math.floor(Math.random() * (regionData.value2)) + 1;
      maxPoints = regionData.value1 > maxPoints ? regionData.value1 : maxPoints;
    });

    this.regions.forEach(region => {
      if (!region.center || !region.path) {
        return;
      }

      const regionData = (data.value as any[]).find(item => item.region === region.nom);
      if (!regionData) {
        return;
      }

      // poly
      const options = {
        strokeWeight: 0,
        fillColor: "#e20d23",
        fillOpacity: 0.2 + (regionData.value3 / 100) * 0.6,
        // editable:true
      };
      this.googlemapsService.drawPolygon(mapId, region.nom, region.path, options);

      // marker
      const size = 32 + (regionData.value1 / maxPoints) * 96;
      const pourcentage = CalculationUtils.getClosestInTenPercent((regionData.value2 / regionData.value1) * 100);
      this.googlemapsService.drawMarker(mapId, {
        latitude: region.center.lat,
        longitude: region.center.lng,
        name: region.nom,
        type: MarkertypeGooglemapsEnum.Region
      }, region.nom, {
        url: "assets/images/app/_core/components/charts/map/lightbulb-" + pourcentage + ".png",
        size: new google.maps.Size(size * this.iconRatio, size),
        scaledSize: new google.maps.Size(size * this.iconRatio, size),
        anchor: new google.maps.Point((size * this.iconRatio) / 2, size / 2)
      });
    });
  }

  // noinspection JSUnusedLocalSymbols
  private drawAgencies(divId: number, mapId: number): void {
    this.agencies.forEach(agency => {
      if (agency.center) {
        this.googlemapsService.drawMarker(mapId, {
          latitude: agency.center.lat,
          longitude: agency.center.lng,
          name: agency.nom,
          type: MarkertypeGooglemapsEnum.Agency
        }, agency.nom, {
          // todo change image
          // url: "assets/images/app/_core/components/charts/map/lightbulb-" + CalculationUtils.getClosestInTenPercent(Math.random() * 100) + ".png",
          url: "assets/images/app/_core/components/charts/map/marker.svg",
          size: new google.maps.Size(24 * this.iconRatio, 24),
          scaledSize: new google.maps.Size(24 * this.iconRatio, 24),
          anchor: new google.maps.Point(12 * this.iconRatio, 24)
        });
      }
    });
  }

  private static fillGridsteritemInfo(gridsteritemextended: Gridsteritemextended, card: CardApiClass): void {
    gridsteritemextended.divId = card.divId;
    gridsteritemextended.onglet = card.onglet;
    gridsteritemextended.panel = card.panel;

    gridsteritemextended.cols = card.gridCols;
    gridsteritemextended.rows = card.gridRows;
    gridsteritemextended.x = card.gridX;
    gridsteritemextended.y = card.gridY;
  }

  private fillCardsInfo(gridsteritemextended: Gridsteritemextended): void {
    if (!gridsteritemextended?.card) {
      return;
    }

    switch (gridsteritemextended.card.panel) {
      case 'COUNTRYCHART': {
        this.updateMap(gridsteritemextended.card.divId);
        break;
      }
    }
  }

}
