import {Component, Input} from '@angular/core';
import {ChartComponent} from "src/app/components/charts/chart.component";
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";


@Component({
    selector: 'banner-charts-component',
    templateUrl: './banner-charts.component.html',
    styleUrls: ['./banner-charts.component.css']
})
export class BannerChartsComponent extends ChartComponent {

    banners: string[] = [];
    bannersUrl: string[] = [];

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        this.card = card
        if (this.card.urlBackground) {
            this.banners = this.card.urlBackground.split(";");
        }
        if (this.card.url) {
            this.bannersUrl = this.card.url.split(";");
        }
    }

    open(url: string) {
        if (url) {
            window.open(url, '_blank').focus();
        }
    }
}
