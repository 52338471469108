import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";

import {Injectable, Injector} from "@angular/core";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {MediaToProductYoukadoInterface} from "src/app/shared/interfaces/models/rest";

@Injectable({
    providedIn: 'root'
})
export class UploadService extends HttpServiceClass<MediaToProductYoukadoInterface>  {

    protected endpoint = "files";


    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: MediaToProductYoukadoInterface): number {
        return item.idMediaProductYoukado;
    }

    handleFileUpload(formData: FormData, params?: HttpParams) {
        return this.httpClient.post<MediaToProductYoukadoInterface>(
            this.getPath({ path: "handleFileUpload" }),formData, { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    handleFileUploadWithImageLink(params?: HttpParams) {
        return this.httpClient.post<MediaToProductYoukadoInterface>(
            this.getPath({ path: "handleFileUploadWithImageLink" }),{}, { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

}
