<nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">


        <li *ngIf="pagination.hasPreviousPages" class="page-item"><a class="page-link" (click)="paginate(pagination.currentPage-1)">{{'core.pagination.previous' | translate}}</a></li>
        <li *ngIf="!pagination.hasPreviousPages" class="page-item"><a class="page-link disabled">{{'core.pagination.previous' | translate}}</a></li>

        <li *ngIf="pagination.hasUncountedPreviousPages" class="page-item disabled"><a class="page-link">...</a></li>
        <li *ngFor="let page of pagination.previousPages" class="page-item"><a class="page-link" (click)="paginate(page)">{{page}}</a></li>

        <li class="page-item active"><a class="page-link">{{pagination.currentPage}}</a></li>

        <li *ngFor="let page of pagination.nextPages" class="page-item"><a class="page-link" (click)="paginate(page)">{{page}}</a></li>
        <li *ngIf="pagination.hasUncountedNextPages" class="page-item disabled"><a class="page-link">...</a></li>

        <li *ngIf="pagination.hasNextPages" class="page-item"><a class="page-link" (click)="paginate(pagination.currentPage+1)">{{'core.pagination.next' | translate}}</a></li>
        <li *ngIf="!pagination.hasNextPages" class="page-item"><a class="page-link disabled">{{'core.pagination.next' | translate}}</a></li>


        <li class="page-item"><a class="page-link disabled">{{ 'core.pagination.totalpages' | translate:{totalpages: pagination.totalPages} }}</a></li>
    </ul>
</nav>
