import {Component, EventEmitter, Input, Output} from '@angular/core';

import {environment} from "src/environments/environment";
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";

@Component({
    selector: 'charts-component',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent {
    @Input() card: CardApiClass;
    @Input() type: string;
    @Input() divId: number;
    @Input() loading: number = 0;
    @Output() onGooglemapsComponentReady: EventEmitter<{ mapId: number, divId: number }> = new EventEmitter();
    debuggingMode = environment.debugging;

    googlemapsComponentReady(mapId: number, divId: number) {
        this.onGooglemapsComponentReady.emit({mapId, divId});
    }

    showCard(): void {
        alert(JSON.stringify(this.card));
    }

    // loading: boolean = true;
    // @Input("loading") set setLoading(loading: number) {
    //     console.log("loading charts ", loading, this.divId);
    //     this.loading = loading;
    // }
}
