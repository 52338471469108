<div #mapWrapper id="googleMap" [ngStyle]="{'width': width, 'height' : height}"></div>
<!--<div #streetViewWrapper id="googleMap" [ngStyle]="{'width': width, 'height' : height}" style="display:none;"></div>-->
<!--
<div class="gmnoprint gm-bundled-control gm-bundled-control-on-bottom" draggable="false" controlwidth="28" controlheight="93" style="margin: 10px; user-select: none; position: absolute; bottom: 107px; right: 28px;">
    <div class="gm-svpc" controlwidth="28" controlheight="28" style="background-color: rgb(255, 255, 255); box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; border-radius: 2px; width: 28px; height: 28px; cursor: url(&quot;http://maps.gstatic.com/mapfiles/openhand_8_8.cur&quot;) 8 8, default; position: absolute; left: 0px; top: 0px;">
        <div style="position: absolute; left: 1px; top: 1px;">
            <div aria-label="Street View Pegman Control" style="width: 26px; height: 26px; overflow: hidden; position: absolute; left: 0px; top: 0px;cursor: pointer;">
                <img id="streetViewToggle"
                     src="http://maps.gstatic.com/mapfiles/api-3/images/cb_scout5.png"
                     draggable="false"
                     style="position: absolute;  width: 215px; height: 835px; user-select: none; border: 0px; padding: 0px; margin: 0px; max-width: none;"
                     (click)="askForStreetView($event)"
                     [ngStyle]="{ 'filter' : ( mayGoToStreetView ? 'none;' : 'grayscale(100%);' )}">
            </div>
        </div>
    </div>
</div>-->
