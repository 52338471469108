<label *ngIf="validationData.images" style="color:red" class="control-label">*Merci d'ajouter des images</label>
<ngb-alert style="text-align: center;" (close)="validationData.alertImage=false" *ngIf="validationData.alertImage"
           type="danger">
    L'image n'est as au bon format : la taille minimale est 300x300
</ngb-alert>
<div cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)" class="row">
    <div cdkDrag *ngFor="let media of productYoukado.mediaToProductYoukados; let index = index;" class="col-xl-2 col-lg-4 col-md-6 col-sm-12 mb-30">
        <a (click)="deleteImage(index)" href="javascript:void(0);" class="text-dark" data-toggle="tooltip" data-placement="left" title="" ngbTooltip="Supprimer image"><i
                style="position: absolute;font-size: 22px" class="fa fa-times-circle"></i></a>
        <img class="img-fluid mb-20" src="{{media.link}}" alt="">
    </div>
</div>

<div class="card card-statistics">
    <div class="card-body">
        <h5 class="card-title">{{'components.product.tabs.images.add-medias' | translate}}</h5>
        <div class="form-row">
            <div class="col-md-9 mb-3">
                <div class="mb-2">
                    <input #fileInput (change)="handleFileInput($event)" type="file" class="custom-file-input" id="customFileLang" lang="fr">
                    <label class="custom-file-label" for="customFileLang">{{'components.product.tabs.images.chose-file' | translate}}</label>
                </div>
            </div>
        </div>
        <div style="padding-top: 10px;" class="form-row">
            <div class="col-md-8 mb-3">
                <div class="mb-2">
                    <input placeholder="Lien image" (keyup)="errorImageLink=false" type="text" class="form-control" id="imageLink" name="imageLink" [(ngModel)]="imageLink"/>
                    <em class="error help-block" *ngIf="errorImageLink">Merci de saisir un lien d'image</em>
                </div>
            </div>
            <div style="padding-top: 5px;" class="col-md-4 mb-3">
                <div class="mb-2">
                    <button (click)="handleFileUploadWithImageLink()" class="btn btn-secondary button_space">Upload</button>
                </div>
            </div>
        </div>
    </div>
</div>
