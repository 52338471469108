import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions} from "chart.js";

import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {BaseChartDirective} from "ng2-charts";

import {Color} from "ng2-charts/lib/color";
import {Label} from "ng2-charts";
import {ObjectUtils} from "src/app/shared/utils/object.utils";
import {ChartComponent} from "src/app/components/charts/chart.component";
import {ColorsUtils} from "src/app/shared/utils/color.utils";


@Component({
    selector: 'linealt-charts-component',
    templateUrl: './linealt-charts.component.html',
    styleUrls: ['./linealt-charts.component.css']
})
export class LinealtChartsComponent extends ChartComponent {

    // https://valor-software.com/ng2-charts/#LineChart

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    @ViewChild("container") container: ElementRef;

    currentChartIndex = 0;
    lineChartDatasets: ChartDataSets[] = [];
    lineChartLabels: Label[] = [];
    lineChartColors: Color[] = [];

    lineChartOptions: ChartOptions = {
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0
            },
            point:
                {
                    borderWidth: 0
                }
        },
        animation: {
            duration: 0, // general animation time
            easing: 'easeOutBack'
        },
        hover: {
            animationDuration: 350,
        },
        legend: {
            display: true,
            position: 'top'
        },
        tooltips: {
            axis: "x",
            mode: "x-axis",
            intersect: true,
            titleFontSize: 0,
            titleMarginBottom: 0
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                    color: '#f3f3f3',
                    drawTicks: false,
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Time'
                }
            }],
            yAxes: [{
                display: true,
                gridLines: {
                    color: '#f3f3f3',
                    drawTicks: false,
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Value',
                }
            }]
        },
    };

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        //TODO: mise d'un timer pour que le chart puisse se charger  avant de loadData du chart (problème d'origine: le setcard s'execute avant l'init quand on change et que l'on revient sur l'onglert)
        setTimeout(()=> {
            if (!card?.charts) {
                return;
            }

            if (!this.card) {

                // colors
                this.lineChartColors = [];
                ColorsUtils.getEnseigneColor(64).forEach((item: any)=> {
                    this.lineChartColors.push({
                        backgroundColor: 'transparent',
                        borderColor: item,
                        pointBackgroundColor: item,
                        pointBorderColor: item
                    });
                });

                this.card = card;
            } else {
                // this.updateCardValues(this.card, card);
            }

            this.loadDataForChart(card);

            this.card = card;
        }, 50);
    }

    lineButtonClick(index: number) {
        if (index !== this.currentChartIndex) {
            this.currentChartIndex = index;
            this.loadDataForChart(this.card)
        }
    }

    private loadDataForChart(card: CardApiClass): void {
        this.lineChartDatasets = [];
        this.lineChartLabels = []

        // sort
        card.charts.forEach(item1 => {
            if (item1.legend.sortXAxisData) {
                item1.value.forEach(item2 => {
                    item2.series = item2.series.sort((item3, item4) => item3.xaxis.localeCompare(item4.xaxis));
                });
            }
        });

        // labels
        card.charts[0].value[0].series.forEach(item => {
            this.lineChartLabels.push(item.xaxis);
        });

        // check if more points in compare graphs, then add unmarked points on x axis
        let length = 0;
        card.charts[0].value.forEach(value => {
            if (value.series.length > length) {
                length = value.series.length;
            }
        });
        if (length > this.lineChartLabels.length) {
            for (let i = this.lineChartLabels.length; i < length; i++) {
                this.lineChartLabels.push("");
            }
        }

        // values
        card.charts[this.currentChartIndex].value.forEach(line => {
            const set = {
                label: line.name,
                data: [] as any[]
            };
            line.series.forEach(item => {
                set.data.push(parseInt(item.yaxis));
            });
            this.lineChartDatasets.push(set);

            this.lineChartOptions.legend.display = this.lineChartDatasets.length > 1;
            this.lineChartOptions = ObjectUtils.copy(this.lineChartOptions);
        });
    }

    public chartClicked(e: any): void {
        console.log(e);
    }

    public chartHovered(e: any): void {
        // console.log(e);
    }
}
