import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalService} from 'src/app/shared/services';
import {
    CatalogueDtoInterface,
    DeliveryCountryDtoInterface,
    EnseigneDtoInterface,
    LanguageDtoInterface,
    ProductSupplierDtoInterface,
    ProductSupplierPriceDtoInterface,
    ProductYoukadoDtoInterface,
    RangeDtoInterface,
    SupplierDtoInterface,
    SupplierToDeliveryCountryDtoInterface,
    TranslationProductSupplierDtoInterface,
    VatDtoInterface
} from 'src/app/shared/interfaces/models/rest';
import {SupplierService} from 'src/app/shared/services/supplier.service';
import {VatsService} from 'src/app/shared/services/vats-service';
import {CommonDataService} from 'src/app/common-data.service';
import {ControlContainer, NgForm} from '@angular/forms';
import {
    ProductFormvalidationInterface
} from "src/app/shared/interfaces/formvalidation/product/product-formvalidation.interface";
import {NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {ProductUtils} from "src/app/shared/utils/product.utils";
import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";
import {HttpParams} from "@angular/common/http";
import {PaginationInterface} from "src/app/shared/interfaces/pagination.interface";
import {PaginationUtils} from "src/app/shared/utils/pagination.utils";
import {EnseignesService} from "src/app/shared/services/enseignes-service";
import {BaseComponents} from "src/app/shared/classes/components/base-components.class"
import {LanguageService} from "src/app/shared/services/language.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-product-supplier',
    templateUrl: './product-supplier.component.html',
    styleUrls: ['./product-supplier.component.css'],

    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class ProductSupplierComponent extends BaseComponents implements OnInit {

    @ViewChild('tabs')
    private tabs: NgbTabset;
    @ViewChild('sizesDatatable')
    private table: any;

    @Input() productYoukado: ProductYoukadoDtoInterface;
    @Input() deliveryCountry: DeliveryCountryDtoInterface;
    @Input() validationData: ProductFormvalidationInterface;
    @Input() productSuppliersCopy: ProductSupplierDtoInterface[];
    @Input() catalogue: CatalogueDtoInterface;
    @Output() updatedBestProductSupplier = new EventEmitter<ProductSupplierDtoInterface>();

    productsSupplierToAdd: ProductSupplierDtoInterface;
    suppliers: SupplierDtoInterface[];
    vats: VatDtoInterface[];
    ranges: RangeDtoInterface[];
    vatShippingCosts: VatDtoInterface;
    availableLanguages: LanguageDtoInterface[] = [];
    priceEnseignesPageable: PaginationbarInterface;
    priceEnseignes: EnseigneDtoInterface[] = [];
    enseigne: number;
    catalogueActif: CatalogueDtoInterface;
    dutyFreeTotalBuyingPriceActif: number;
    enseignes: EnseigneDtoInterface[];

    dropdownSettings: any = {};
    selectedSupplier: any = null;
    cell: any = 'cell';
    expanded: any;
    editing: any = {};
    temp: any = [];
    sizes: any = [];
    filterRows: any = [];
    selectRows: any = [];
    sortRows: any = [];
    selected: any[] = [];

    // DataTable Content Titles
    columns: any = [
        {prop: 'Code'},
        {name: 'Taille'},
        {name: 'Prix HT'}
    ];

    disableVat: boolean = false;
    showFormSizes: boolean = false;
    errorSupplier: boolean = true;
    dataMissing: boolean = false;
    alertError: boolean = false;

    dropdownSettingsDeliveryCountries: any = {};
    allDeliveryCountries: any[] = [];
    selectedDeliveryCountries: any[] = [];
    dropdownSettingsEnseignes: any = {};
    enseignesSelectedList: EnseigneDtoInterface[] = [];
    enseignesSelected: number[] = [];

    constructor(
        protected router: Router,
        protected injector: Injector,
        private _commondata: CommonDataService,
        protected enseignesService: EnseignesService,
        private supplierService: SupplierService,
        private vatsService: VatsService,
        protected languageService: LanguageService,
        private modalService: ModalService) {
        super(injector);
        this.productsSupplierToAdd = {} as ProductSupplierDtoInterface;
        this.productsSupplierToAdd.productSupplierPrices = [];
        this.productsSupplierToAdd.childProductSuppliers = [];
        this.productsSupplierToAdd.deliveryCountryProductSupplier = this.deliveryCountry;
    }

    ngOnInit(): void {

        this.getSupplierDeliveryCountryForALL();
        this.calculMargin();
        //this.getRanges();
        this.dropdownSettings = {
            singleSelection: true,
            allowSearchFilter: true,
            textField: "label",
            idField: "idSupplier",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.dropdownSettingsDeliveryCountries = {
            enableCheckAll: true,
            allowSearchFilter: false,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.dropdownSettingsEnseignes = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "nomEnseigne",
            idField: "id_ext_enseigne",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.availableLanguages = [];
        this.languageService.list().subscribe(
            (response) => {
               this.availableLanguages = response;
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        )
    }

    updateBestSupplier(event: any) {
        this.updatedBestProductSupplier.emit(event);
    }

    getSupplierDeliveryCountryForALL() {
        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers.length !== 0) {
            for (let productSupp of this.productYoukado.productSuppliers) {
                this.supplierService.getSupplierDeliveryCountry(productSupp.supplier.idSupplier).subscribe(
                    (response) => {
                        productSupp.supplier.supplierToDeliveryCountries = response;
                    },
                    (error) => {
                        console.log('Error : ' + JSON.stringify(error));
                    }
                )
            }
        }
    }

    addDeliveryCountry(deliveryCountry: DeliveryCountryDtoInterface) {
        let catalogueCountry = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == deliveryCountry.code);
        if (catalogueCountry == null) {
            const catalogue = {} as CatalogueDtoInterface;
            catalogue.state = "new";
            catalogue.deliveryCountriesCatalogue = deliveryCountry;
            this.productYoukado.catalogues.push(catalogue);
        }
    }

    getTypeCatalogue(productSupplier: ProductSupplierDtoInterface) {
        return productSupplier.supplier.supplierToDeliveryCountries.find(sd => sd.deliveryCountriesSupplier.code == this.deliveryCountry.code).typeCatalogue;
    }

    addSupplier(valid: any) {
        if (valid) {
            if (!ProductUtils.isNullOrEmpty(this.productsSupplierToAdd.childProductSuppliers)) {
                for (let childProductSupplier of this.productsSupplierToAdd.childProductSuppliers) {
                    if (ProductUtils.isNullOrEmpty(childProductSupplier.code) || ProductUtils.isNullOrEmpty(childProductSupplier.size) || ProductUtils.isNullOrEmpty(childProductSupplier.stock) || ProductUtils.isNullOrEmpty(childProductSupplier.dutyFreeBuyingPrice)) {
                        this.tabs.select("sizes");
                        this.dataMissing = true;
                        return false;
                    }
                }
            }
            if (typeof this.productYoukado.productSuppliers == 'undefined' || this.productYoukado.productSuppliers == null || this.productYoukado.productSuppliers.length == 0) {
                this.productYoukado.productSuppliers = [];
            }

            for (let deliveryCountry of this.selectedDeliveryCountries) {
                let productSupplierToDuplicate: ProductSupplierDtoInterface = JSON.parse(JSON.stringify(this.productsSupplierToAdd));
                productSupplierToDuplicate.idProductSupplier = null;
                let deliveryCountryVar = this.allDeliveryCountries.find(d => d.code == deliveryCountry.code);
                productSupplierToDuplicate.deliveryCountryCode = deliveryCountryVar.code;
                productSupplierToDuplicate.deliveryCountryProductSupplier = deliveryCountryVar;
                this.productYoukado.productSuppliers.push(productSupplierToDuplicate);
                this.addDeliveryCountry(deliveryCountryVar);
            }

            console.log(JSON.stringify(this.deliveryCountry));
            this.productYoukado.productSuppliers.push(this.productsSupplierToAdd);

            ProductUtils.sortProductSuppliers(this.productYoukado, this.deliveryCountry);
            this.modalService.close("productsSupplierToAdd");
            this.validationData.productsSupplier = false;
            //this.calculMarginRange();
            return true;
        }

        return false;
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    supplierDetails() {
        this.getSuppliers();
        this.selectedDeliveryCountries = [];
        this.selectedSupplier = null;
        this.productsSupplierToAdd = {} as ProductSupplierDtoInterface;
        this.vats = [];
        this.productsSupplierToAdd.childProductSuppliers = [];
        this.productsSupplierToAdd.productSupplierPrices = [];
        this.productsSupplierToAdd.productSupplierPrices.push({} as any);
        this.productsSupplierToAdd.deliveryCountryProductSupplier = this.deliveryCountry;
        this.getLanguages();
        this.openModal("productsSupplierToAdd");
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    updateValue(event: any, cell: any, rowIndex: any) {
        this.editing[rowIndex + '-' + cell] = false;
        this.sizes[rowIndex][cell] = event.target.value;
        this.sizes = [...this.sizes];
    }

    updateRow(row: any, event: any) {
        if (event.target.name == "size_code") {
            row.code = event.target.value;
            //console.log(event.target.name);
        } else if (event.target.name == "size_size") {
            row.size = event.target.value;
        } else if (event.target.name == "size_stock") {
            row.stock = event.target.value;
        } else if (event.target.name == "size_dutyFreeBuyingPrice") {
            let productSupplierPrice = null;
            if (typeof this.productsSupplierToAdd.productSupplierPrices != 'undefined' && this.productsSupplierToAdd.productSupplierPrices != null && this.productsSupplierToAdd.productSupplierPrices.length != 0) {
                productSupplierPrice = this.productsSupplierToAdd.productSupplierPrices[0];
            }
            //console.log(this.productsSupplierToAdd.deliveryCountryProductSupplier);
            row.dutyFreeBuyingPrice = parseFloat(event.target.value);
            ProductUtils.calculBuyingPrices(row, productSupplierPrice != null ? productSupplierPrice.vat : null);
            ProductUtils.calculAllTotalBuyingPrice(row);
            const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == this.productsSupplierToAdd.deliveryCountryProductSupplier.code);
            row.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, row.dutyFreeTotalBuyingPrice);
        }
    }

    updateFilter(event: any) {
        const val = event.target.value.toLowerCase();

        // filter our data
        const temp = this.temp.filter(function (d: any) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        // update the rows
        this.filterRows = temp;
        // Whenever the filter changes, always go back to the first page
        //this.table.offset = 0;
    }

    onChangeSupplier(supplier: any) {
        this.productsSupplierToAdd.supplier = this.suppliers.find(s => s.idSupplier == supplier.idSupplier);

        this.supplierService.getSupplierDeliveryCountry(supplier.idSupplier).subscribe(
            (response) => {
                this.commondataService.showLoader(false);
                this.productsSupplierToAdd.supplier.supplierToDeliveryCountries = response;
                this.allDeliveryCountries = this.productsSupplierToAdd.supplier.supplierToDeliveryCountries.filter(st => st.orderType != 'INACTIF').map((supplierToDeliveryCountries: SupplierToDeliveryCountryDtoInterface) => supplierToDeliveryCountries.deliveryCountriesSupplier);
                this.allDeliveryCountries = this.allDeliveryCountries.filter(d => d.code != this.deliveryCountry.code);
                if (this.productYoukado.productSuppliers != null) {
                    let deliveryCountry = this.productYoukado.productSuppliers.filter(s => s.deliveryCountryProductSupplier.code != this.deliveryCountry.code && s.supplier.idSupplier == supplier.idSupplier).map((productSupplier: ProductSupplierDtoInterface) => productSupplier.deliveryCountryProductSupplier.code);
                    this.allDeliveryCountries = this.allDeliveryCountries.filter(d => !deliveryCountry.includes(d.code));
                }
                this.getVats();
                this.errorSupplier = false;

            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
                this.commondataService.showLoader(false);
            }
        );
    }

    addSize() {
        if (this.productsSupplierToAdd.supplier == null || this.productsSupplierToAdd.dutyFreeShippingPrice == null) {
            this.alertError = true;
            return false;
        }
        for (let productSupplierPrice of this.productsSupplierToAdd.productSupplierPrices) {
            if (productSupplierPrice.vat == null) {
                this.alertError = true;
                return false;
            }
        }
        if (typeof this.productsSupplierToAdd.childProductSuppliers == 'undefined' || this.productsSupplierToAdd.childProductSuppliers == null || this.productsSupplierToAdd.childProductSuppliers.length == 0) {
            this.productsSupplierToAdd.childProductSuppliers = [];
        }
        this.productsSupplierToAdd.childProductSuppliers.push({
            "state": "published",
            "dutyFreeShippingPrice": this.productsSupplierToAdd.dutyFreeShippingPrice,
            "allTaxesIncludedShippingPrice": this.productsSupplierToAdd.allTaxesIncludedShippingPrice,
            "productSupplierPrices": this.productsSupplierToAdd.productSupplierPrices,
            "ecoTaxes": this.productsSupplierToAdd.ecoTaxes,
            "d3e": this.productsSupplierToAdd.d3e,
            "sorecop": this.productsSupplierToAdd.sorecop,
            "allTaxesIncludedEcoTaxes": this.productsSupplierToAdd.all_taxes_included_eco_taxes,
            "allTaxesIncludedD3e": this.productsSupplierToAdd.all_taxes_included_d3e,
            "allTaxesIncludedSoreco": this.productsSupplierToAdd.all_taxes_included_sorecop,
        } as any);
        this.productsSupplierToAdd.childProductSuppliers = [...this.productsSupplierToAdd.childProductSuppliers];
        this.tabs.select("sizes");
        return true;
    }

    onItemDeSelect(supplier: any) {
        //console.log("onChangeSupplier : " + JSON.stringify(supplier));
        this.productsSupplierToAdd.supplier = null;
        this.errorSupplier = true;
        this.vats = [];
        for (let productSupplierPrice of this.productsSupplierToAdd.productSupplierPrices) {
            productSupplierPrice.vat = null;
        }
        this.allDeliveryCountries = [];
    }

    forceSupplier(indexProductSupplier: number) {
        let index = 0;
        let productSupplierActif = this.productYoukado.productSuppliers[indexProductSupplier];
        if (!productSupplierActif.forced && productSupplierActif.stock <= 0) {
            this.modalService.open('alertSupplierOutOfStock');
            return false;
        }
        productSupplierActif.forced = !productSupplierActif.forced;
        for (let productSupplier of this.productYoukado.productSuppliers) {
            if (index != indexProductSupplier && productSupplier.deliveryCountryProductSupplier.code == this.deliveryCountry.code) {
                productSupplier.forced = false;
            }
            index = index + 1;
        }
        this.changeSalePrice(this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code));
        //this.calculMarginRange();
        return true;
    }

    addBuyingPrice() {
        this.productsSupplierToAdd.productSupplierPrices.push({} as any);
    }

    deleteBuyingPrice(index: number) {
        this.productsSupplierToAdd.productSupplierPrices.splice(index, 1);
        this.calculcalculBuyingPricesProductSupplier();
        this.calculAllTotalBuyingPrice();
    }

    // calculMarginRange() {
    //     if (this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers != undefined) {
    //         let productSupplier = {} as any;
    //         let productSupplierForced: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code && s.forced);
    //         let productSupplierBestChoice: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code && s.isBestChoice);
    //         let productSupplierWithout: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code);

    //         if (productSupplierForced != null && productSupplierForced != undefined) {
    //             productSupplier = productSupplierForced;
    //         } else if (productSupplierBestChoice != null && productSupplierBestChoice != undefined) {
    //             productSupplier = productSupplierBestChoice;
    //         } else {
    //             productSupplier = productSupplierWithout;
    //         }
    //         this.ranges.forEach(function (range, index) {
    //             range.margin = range.dutyFreeSalePrice != null && range.dutyFreeSalePrice != 0 ? parseFloat((((range.dutyFreeSalePrice - productSupplier.dutyFreeTotalBuyingPrice) / productSupplier.dutyFreeTotalBuyingPrice) * 100).toFixed(2)) : 0;
    //             //console.log("RANGEINDEX : " + JSON.stringify(productSupplierForced));
    //         });
    //     }

    // }

    onChangeVat() {
        //console.log(this.productsSupplierToAdd.vatAmount);
    }

    /* Change product supplier range */
    onChangeRange() {
        this.validationData.ranges = this.productYoukado.range == null;
    }

    compareFn(option1: any, option2: any): boolean {
        if (option1 && option2) {
            return option1.code === option2.code;
        }
        return false;
    }

    getSuppliers() {
        this.allDeliveryCountries = [];
        this.commondataService.showLoader(true);
        this.supplierService.getSuppliers(this.deliveryCountry.code).subscribe(
            (response) => {
                this.suppliers = response;
                this.commondataService.showLoader(false);
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
                this.commondataService.showLoader(false);
            }
        );
    }

    getSupplierDeliveryCountry(supplierId: number) {
        this.commondataService.showLoader(true);
        this.supplierService.getSupplierDeliveryCountry(supplierId).subscribe(
            (response) => {
                this.commondataService.showLoader(false);
                this.productsSupplierToAdd.supplier.supplierToDeliveryCountries = response;
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
                this.commondataService.showLoader(false);
            }
        );
    }


    getVats() {
        this.disableVat = false;
        //console.log("expeditionCountrySupplier : " + this.productsSupplierToAdd.supplier.expeditionCountrySupplier.code);
        this.vatsService.getVats(this.productsSupplierToAdd.supplier.expeditionCountrySupplier.idExpeditionCountry).subscribe(
            (response: any) => {
                this.vats = response;
                this.vats.sort((a, b) => a.rate - b.rate);
                this.vatShippingCosts = JSON.parse(JSON.stringify(this.vats.find(v => v.isShippingCosts == true)));
                if (this.productsSupplierToAdd.supplier != null && this.productsSupplierToAdd.supplier.supplierToDeliveryCountries != null && this.productsSupplierToAdd.supplier.supplierToDeliveryCountries.length != 0) {
                    const supplierToDeliveryCountry = this.productsSupplierToAdd.supplier.supplierToDeliveryCountries.find(sd => sd.deliveryCountriesSupplier);
                    supplierToDeliveryCountry.desactivatVatProduct;
                    //console.log("deactivateVatProduct : " + supplierToDeliveryCountry.desactivatVatProduct);
                    if (supplierToDeliveryCountry.desactivatVatProduct) {
                        const vat = this.vats.find(v => v.rate == 0);
                        for (let productSupplierPrice of this.productsSupplierToAdd.productSupplierPrices) {
                            productSupplierPrice.vat = vat;
                            this.disableVat = true;
                        }
                    }
                    if (supplierToDeliveryCountry.desactivateVatShipping) {
                        this.vatShippingCosts.rate = 0;
                    }
                }
                this.calculAllTaxesIncludedShippingPrice()
                this.commondataService.showLoader(false);
                //console.log("SUPPLIERS : " + JSON.stringify(response))
            },
            (error: any) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }

    // getRanges() {
    //     this.rangesService.getRanges(this.deliveryCountry.code).subscribe(
    //         (response: any) => {
    //             this.ranges = response;
    //             this.ranges.sort((a, b) => a.dutyFreeSalePrice - b.dutyFreeSalePrice);
    //             this.calculMarginRange();
    //             setTimeout(() => this._commondata.showLoader(false), 200);
    //             //console.log("RANGES : " + JSON.stringify(response))
    //         },
    //         (error: any) => {
    //             setTimeout(() => this._commondata.showLoader(false), 200);
    //             console.log('Error : ' + JSON.stringify(error));
    //         }
    //     );
    // }


    getLanguages() {
        if (this.productsSupplierToAdd.translationProductSupplier == null || this.productsSupplierToAdd.translationProductSupplier.length == 0) {
            this.productsSupplierToAdd.translationProductSupplier = [];
        }

        const languagesProduct: string[] = this.productsSupplierToAdd.translationProductSupplier.map((language: TranslationProductSupplierDtoInterface) => language.language.code);

        const languagesDeliveryCountry: LanguageDtoInterface[] = this.availableLanguages.map((language: LanguageDtoInterface) => language);

        for (let language of languagesDeliveryCountry) {

            if (!languagesProduct.includes(language.code)) {
                const translationProductSupplierDtoInterface = {} as TranslationProductSupplierDtoInterface;
                translationProductSupplierDtoInterface.language = language;
                this.productsSupplierToAdd.translationProductSupplier.push(translationProductSupplierDtoInterface);
            }
        }

        this.productsSupplierToAdd.translationProductSupplier.sort((a, b) => a.language.order < b.language.order ? -1 : 1);

    }

    changeSalePrice(catalogue: CatalogueDtoInterface) {
        if (this.productYoukado.productType != null && this.productYoukado.productType == 'Service') {
            catalogue.allTaxesIncludedSalePrice = catalogue.dutyFreeSalePrice;
        } else {
            catalogue.allTaxesIncludedSalePrice = parseFloat((catalogue.dutyFreeSalePrice * 1.2).toFixed(2));
        }
        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers.length !== 0) {
            for (let productSupplier of this.productYoukado.productSuppliers) {
                if (productSupplier.deliveryCountryProductSupplier.code == this.deliveryCountry.code) {
                    productSupplier.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, productSupplier.dutyFreeTotalBuyingPrice);
                }
            }

            let productSupplierForced: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code && s.forced);
            let productSupplierBestChoice: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code && s.isBestChoice);
            let productSupplierWithout: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code);

            if (productSupplierForced != null) {
                catalogue.margin = productSupplierForced.margin;
            } else if (productSupplierBestChoice != null) {
                catalogue.margin = productSupplierBestChoice.margin;
            } else {
                catalogue.margin = productSupplierWithout.margin;
            }
        }
    }

    calculMargin() {
        console.log("productYoukado : "+JSON.stringify(this.productYoukado))
        console.log("productSuppliers : "+JSON.stringify(this.productYoukado.productSuppliers))
        console.log("catalogues : "+JSON.stringify(this.productYoukado.catalogues))
        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers.length !== 0) {
            for (let productSupplier of this.productYoukado.productSuppliers) {
                const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == productSupplier.deliveryCountryProductSupplier.code);
                console.log("catalogue : "+JSON.stringify(catalogue))
                productSupplier.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, productSupplier.dutyFreeTotalBuyingPrice);
            }
        }
    }

    calculBuyingPrices(productSupplierPrice: ProductSupplierPriceDtoInterface) {
        ProductUtils.calculBuyingPrices(productSupplierPrice, productSupplierPrice.vat);
        this.calculcalculBuyingPricesProductSupplier();
        this.calculAllTotalBuyingPrice();
    }

    calculcalculBuyingPricesProductSupplier() {
        this.productsSupplierToAdd.dutyFreeBuyingPrice = null;
        this.productsSupplierToAdd.allTaxesIncludedBuyingPrice = null;
        this.productsSupplierToAdd.vatAmount = null;
        for (let productSupplierPrice of this.productsSupplierToAdd.productSupplierPrices) {
            this.productsSupplierToAdd.dutyFreeBuyingPrice = parseFloat((this.productsSupplierToAdd.dutyFreeBuyingPrice + productSupplierPrice.dutyFreeBuyingPrice).toFixed(2));
            this.productsSupplierToAdd.allTaxesIncludedBuyingPrice = parseFloat((this.productsSupplierToAdd.allTaxesIncludedBuyingPrice + productSupplierPrice.allTaxesIncludedBuyingPrice).toFixed(2));
            this.productsSupplierToAdd.vatAmount = parseFloat((this.productsSupplierToAdd.vatAmount + productSupplierPrice.vatAmount).toFixed(2));
        }
    }

    calculAllTaxesIncludedShippingPrice() {
        this.productsSupplierToAdd.allTaxesIncludedShippingPrice = parseFloat(((this.productsSupplierToAdd.dutyFreeShippingPrice * (this.vatShippingCosts != null ? this.vatShippingCosts.rate / 100 : 0)) + this.productsSupplierToAdd.dutyFreeShippingPrice).toFixed(2));
        this.calculAllTotalBuyingPrice();
    }

    calculAllTotalBuyingPrice() {
        ProductUtils.calculAllTotalBuyingPrice(this.productsSupplierToAdd);
        const catalogue = this.productYoukado.catalogues.find((c: CatalogueDtoInterface) => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code);
        this.productsSupplierToAdd.margin = ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, this.productsSupplierToAdd.dutyFreeTotalBuyingPrice);
    }

    onSubmit(form: NgForm) {
        //console.log("NgForm validation : " + form.valid);
        if (form.valid) {
            //console.log("valid NgForm : ");
        }
    }

    toggleExpandRow(row: any) {
        this.table.rowDetail.toggleExpandRow(row);
        this.productsSupplierToAdd.childProductSuppliers = [...this.productsSupplierToAdd.childProductSuppliers];
    }

    onDetailToggle(event: any) {
        //console.log('Detail Toggled', event);
    }

    search(pagination?: { page: number, size?: number }): void {
        if (!pagination) {
            pagination = {page: 0, size: 10};
        } else {
            pagination.size = 10;
        }

        this.performPriceSearch({search: this.getSearchParams(), pagination});
    }

    private performPriceSearch(opt?: { search: HttpParams, pagination?: PaginationInterface }): void {
        this.commondataService.showLoader(true);
        this.enseignesService.search(opt?.search, {pagination: opt?.pagination}).subscribe(
            (response) => {
                this.priceEnseignesPageable = PaginationUtils.getPagination(response);
                this.priceEnseignes = response.content;
                this.commondataService.showLoader(false);
                this.openModal("pricesEnseignes");
            }, (error) => {
                this.dealWithStandardError(error);
            });
        // this.performGenericSearch<HistoryProductSupplierPriceDtoInterface>(this.pricehistoryService, this.pricehistories, this.pricehistoriesPageable, opt);
    }

    private getSearchParams(): HttpParams {
        let httpParams = new HttpParams();
        httpParams = httpParams.append("idEnseigne", this.enseignesSelected.toString());
        httpParams = httpParams.append("allTaxesIncludedSalePrice", this.catalogueActif.allTaxesIncludedSalePrice);
        httpParams = httpParams.append("dutyFreeSalePrice", this.catalogueActif.dutyFreeSalePrice);
        httpParams = httpParams.append("dutyFreeTotalBuyingPrice", this.dutyFreeTotalBuyingPriceActif);
        return httpParams;
    }

    pricesEnseignes() {


        this.catalogueActif = this.productYoukado.catalogues.find(c => c.deliveryCountriesCatalogue.code == this.deliveryCountry.code);

        if (typeof this.productYoukado.productSuppliers != 'undefined' && this.productYoukado.productSuppliers != null && this.productYoukado.productSuppliers.length !== 0) {
            let productSupplierForced: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code && s.forced);
            let productSupplierBestChoice: ProductSupplierDtoInterface = this.productYoukado.productSuppliers.find(s => s.deliveryCountryProductSupplier.code == this.deliveryCountry.code && s.isBestChoice);

            if (productSupplierForced != null) {
                this.dutyFreeTotalBuyingPriceActif = productSupplierForced.dutyFreeTotalBuyingPrice;
            } else if (productSupplierBestChoice != null) {
                this.dutyFreeTotalBuyingPriceActif = productSupplierBestChoice.dutyFreeTotalBuyingPrice;
            }
        }
        if (this.dutyFreeTotalBuyingPriceActif && this.catalogueActif.allTaxesIncludedSalePrice) {
            this.search();
            this.getEnseignes();
        } else if (!this.dutyFreeTotalBuyingPriceActif) {
            this.openModal("alertNoSupplierAvailable");
        } else if (!this.catalogueActif.allTaxesIncludedSalePrice) {
            this.openModal("alertNoAllTaxesIncludedSalePrice");
        }

    }

    /* Check selectedItem */
    changeEnseigne(selectedItem: any): void {
        this.enseignesSelected = this.enseignesSelectedList.map((enseigne: EnseigneDtoInterface) => enseigne.id_ext_enseigne);
        this.search();
    }

    /* Get all enseignes from ext_enseigne_operation_fidelite */
    getEnseignes() {
        this.enseignesService.getEnseignes().subscribe(
            (response) => {
                this.enseignes = response;
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }

    openExternalDetails(externalId: string) {

        const url = this.router.serializeUrl(
            this.router.createUrlTree(
                ["/product/" + externalId]
            )
        );
        window.open('/marketplace/#' + url, '_blank');

    }

    updateEmptyLibilleSupplierLg(label: string) {
        for (let translation of this.productsSupplierToAdd?.translationProductSupplier) {
            if (!translation.label) {
                translation.label = label;
            }
        }
    }

}
