<!--    <li>-->
<!--        <a>-->
<!--            <div class="form-group" style="position: relative;">-->
<!--                <textarea #self-->
<!--                          (input)="testss(self)"-->
<!--                          placeholder="Search"-->
<!--                          style="resize: none;"-->
<!--                          rows="1"-->
<!--                          class="form-control"></textarea>-->
<!--                <button class="search-button" type="submit" style="">-->
<!--                    <i class="fa fa-search not-click"></i>-->
<!--                </button>-->
<!--            </div>-->
<!--        </a>-->
<!--    </li>-->
<!--    <li>-->
<!--        <a>-->
<form class="form-search-bar" [formGroup]="formGroup" (submit)="search()">
    <div class="form-group" style="position: relative;padding-right: 5px;">
        <!--<textarea -->
        <!--[formControl]="keywords"-->
        <!--autosizetextarea-->
        <!--[maxRows]="3"-->
        <!--placeholder="{{'components.catalog.sidebar.keywords' | translate}}"-->
        <!--rows="1"-->
        <!--class="form-control"></textarea>-->
        <input type="text"
               formControlName="keywords"
               class="form-control large"
               placeholder="{{'components.catalog.sidebar.keywords' | translate}}">
        <button class="search-button" type="submit" style="">
            <i class="fa fa-search not-click"></i>
        </button>
    </div>
    <!--        </a>-->
    <!--    </li>-->
    <ul class="nav navbar-nav side-menu">
        <!--    <li>-->
        <!--        <a>-->
        <!--            <div class="form-group" style="position: relative;">-->
        <!--                <input type="text" class="form-control" placeholder="Search" value="" name="search">-->
        <!--                <button class="search-button" type="submit" style="">-->
        <!--                    <i class="fa fa-search not-click"></i>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </a>-->
        <!--    </li>-->
        <li>
            <a>
                <div class="form-group">
                    <label for="ean">{{'components.catalog.sidebar.ean' | translate}}</label>
                    <!--                    <div class="input-group">-->
                    <!--                        <div class="input-group-prepend">-->
                    <!--                            <div class="input-group-text"><i class="fa fa-barcode" aria-hidden="true"></i></div>-->
                    <!--                        </div>-->
                    <input id="ean"
                           type="text"
                           formControlName="ean"
                           class="form-control"
                           placeholder=" ">
                    <!--                    </div>-->
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group">
                    <label for="code">{{'components.catalog.sidebar.code' | translate}}</label>
                    <!--                    <div class="input-group">-->
                    <!--                        <div class="input-group-prepend">-->
                    <!--                            <div class="input-group-text"><i class="fa fa-terminal" aria-hidden="true"></i></div>-->
                    <!--                        </div>-->
                    <input id="code"
                           formControlName="code"
                           type="text"
                           class="form-control"
                           placeholder=" ">
                    <!--                    </div>-->
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group">
                    <label for="deliveryCountries">{{'components.catalog.sidebar.deliveryCountries' | translate}}</label>
                    <ng-multiselect-dropdown id="deliveryCountries"
                                             formControlName="deliveryCountries"
                                             autofocussearchmultiselect
                                             [settings]="{
                                                    singleSelection: false,
                                                    idField: 'idDeliveryCountry',
                                                    textField: 'code',
                                                    selectAllText: 'Sélectionner tout',
                                                    unSelectAllText: 'Désélectionner tout',
                                                    allowSearchFilter: true,
                                                    enableCheckAll: false,
                                                    searchPlaceholderText: 'Rechercher'
                                                }"
                                             (onSelect)="changeCountry()"
                                             (onDeSelect)="changeCountry()"
                                             [placeholder]="' '"
                                             [(ngModel)]="deliveryCountriesSelectedItems"
                                             [data]="availableDeliveryCountries"
                                             class="form-control">
                        <ng-template #optionsTemplate let-option="option">
                            <div>
                                <img [src]="'assets/images/flags/' + option + '.png'"
                                     style="width: 18px; margin-right: 2px;"/>
                                {{'core.countries.' + option.toLowerCase() | translate}}
                            </div>
                        </ng-template>
                    </ng-multiselect-dropdown>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group">
                    <label for="suppliers">{{'components.catalog.sidebar.suppliers' | translate}}</label>
                    <ng-multiselect-dropdown id="suppliers"
                                             formControlName="suppliers"
                                             autofocussearchmultiselect
                                             [settings]="{
                                                    singleSelection: false,
                                                    idField: 'idSupplier',
                                                    textField: 'label',
                                                    selectAllText: 'Select All',
                                                    unSelectAllText: 'UnSelect All',
                                                    allowSearchFilter: true,
                                                    enableCheckAll: false,
                                                    searchPlaceholderText: 'Rechercher'
                                                }"
                                             [placeholder]="' '"
                                             [(ngModel)]="suppliersSelectedItems"
                                             [data]="availableSuppliers"
                                             class="form-control">
                    </ng-multiselect-dropdown>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group">
                    <label for="status">{{'components.catalog.sidebar.status' | translate}}</label>
                    <ng-multiselect-dropdown id="status"
                                             formControlName="status"
                                             [settings]="{
                                                    singleSelection: false,
                                                    idField: 'id',
                                                    textField: 'code',
                                                    selectAllText: 'Sélectionner tout',
                                                    unSelectAllText: 'Désélectionner tout',
                                                    allowSearchFilter: false,
                                                    enableCheckAll: true,
                                                    searchPlaceholderText: 'Rechercher'
                                                }"
                                             [placeholder]="' '"
                                             [data]="availableStatus"
                                             [(ngModel)]="statusSelectedItems"
                                             class="form-control">
                        <ng-template #optionsTemplate let-option="option">
                            <div>
                                {{'core.status.' + option | translate}}
                            </div>
                        </ng-template>
                        <ng-template #optionSelectedTemplate let-option="option">
                            {{'core.status.' + option | translate}}
                        </ng-template>
                    </ng-multiselect-dropdown>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group">
                    <label for="brands">{{'components.catalog.sidebar.brands' | translate}}</label>
                    <div style="width: 100%;" class="ng-autocomplete">
                        <ng-autocomplete id="brands" [data]="brandsYoukadoAutoComplete" [searchKeyword]="keyword"
                                         (inputChanged)='onChangeSearch($event)' [itemTemplate]="itemTemplate"
                                         (selected)="onSelectItem($event)"
                                         [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <div *ngIf="brandsSelectedItems != null && brandsSelectedItems.length > 0" style="background-color: white;padding:10px">
                           <span *ngFor="let selectedOption of brandsSelectedItems" class="selected-option">
                             {{ selectedOption.label }}
                               <span (click)="removeItem(selectedOption)" style="margin-left: 6px;cursor: pointer;" class="remove-btn">x</span>
                           </span>
                        </div>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.label" style="color: black"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div>Aucun résultat</div>
                        </ng-template>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group">
                    <label for="stock">{{'components.catalog.sidebar.stock' | translate}}</label>
                    <!--                    <ng-multiselect-dropdown id="stock"-->
                    <!--                                             formControlName="stock"-->
                    <!--                                             [settings]="{-->
                    <!--                                                    singleSelection: true,-->
                    <!--                                                    idField: 'id',-->
                    <!--                                                    textField: 'code',-->
                    <!--                                                    allowSearchFilter: false,-->
                    <!--                                                    enableCheckAll: false-->
                    <!--                                                }"-->
                    <!--                                             [placeholder]="' '"-->
                    <!--                                             [data]="availableStocks"-->
                    <!--                                             class="form-control">-->
                    <!--                    </ng-multiselect-dropdown>-->
                    <input id="stock"
                           formControlName="stock"
                           type="number"
                           class="form-control"
                           placeholder=" ">
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="costPriceMin">{{'components.catalog.sidebar.costPriceMin' | translate}}</label>
                        <input id="costPriceMin"
                               formControlName="costPriceMin"
                               type="number"
                               class="form-control"
                               placeholder=" ">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="costPriceMax">{{'components.catalog.sidebar.costPriceMax' | translate}}</label>
                        <input id="costPriceMax"
                               formControlName="costPriceMax"
                               type="number"
                               class="form-control"
                               placeholder=" ">
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="retailPriceMin">{{'components.catalog.sidebar.retailPriceMin' | translate}}</label>
                        <input id="retailPriceMin"
                               formControlName="retailPriceMin"
                               type="number"
                               class="form-control"
                               placeholder=" ">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="retailPriceMax">{{'components.catalog.sidebar.retailPriceMax' | translate}}</label>
                        <input id="retailPriceMax"
                               formControlName="retailPriceMax"
                               type="number"
                               class="form-control"
                               placeholder=" ">
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="publicPriceMin">{{'components.catalog.sidebar.publicPriceMin' | translate}}</label>
                        <input id="publicPriceMin"
                               formControlName="publicPriceMin"
                               type="number"
                               class="form-control"
                               placeholder=" ">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="publicPriceMax">{{'components.catalog.sidebar.publicPriceMax' | translate}}</label>
                        <input id="publicPriceMax"
                               formControlName="publicPriceMax"
                               type="number"
                               class="form-control"
                               placeholder=" ">
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group mb-2">
                    <label for="dp1">{{'components.catalog.sidebar.integrationDate' | translate}}</label>
                    <div class="input-group">
                        <input #d1="ngbDatepicker"
                               id="dp1"
                               formControlName="integrationDateFrom"
                               class="form-control"
                               placeholder="yyyy-mm-dd"
                               name="dp1"
                               ngbDatepicker>
                        <div class="input-group-append">
                            <button class="btn btn-secondary"
                                    (click)="d1.toggle()"
                                    type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <input #d2="ngbDatepicker"
                               id="dp2"
                               formControlName="integrationDateTo"
                               class="form-control"
                               placeholder="yyyy-mm-dd"
                               name="dp2"
                               ngbDatepicker>
                        <div class="input-group-append">
                            <button class="btn btn-secondary"
                                    (click)="d2.toggle()"
                                    type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </a>
        </li>

        <li>
            <a>
                <div class="form-group mb-2">
                    <label for="dp3">{{'components.catalog.sidebar.selectionDate' | translate}}</label>
                    <div class="input-group">
                        <input #d3="ngbDatepicker"
                               id="dp3"
                               formControlName="selectionDateFrom"
                               class="form-control"
                               placeholder="yyyy-mm-dd"
                               name="dp3"
                               ngbDatepicker>
                        <div class="input-group-append">
                            <button class="btn btn-secondary"
                                    (click)="d3.toggle()"
                                    type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <input #d4="ngbDatepicker"
                               id="dp4"
                               formControlName="selectionDateTo"
                               class="form-control"
                               placeholder="yyyy-mm-dd"
                               name="dp4"
                               ngbDatepicker>
                        <div class="input-group-append">
                            <button class="btn btn-secondary"
                                    (click)="d4.toggle()"
                                    type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-group mb-2">
                    <label for="dp3">Recherche catégories</label>
                    <form [formGroup]="formGroupSearch">
                        <div class="form-row align-items-center" style="flex-direction: column">
                            <div class="form-group mb-2" style="position: relative;padding-right: 5px;min-width: 90%">
                                <input placeholder="{{'components.category.search.keywords' | translate}}"
                                       formControlName="keywords" (input)="searchCriteriaCategoryUpdate()"
                                       class="form-control search-input">
                                <button class="search-button" type="submit" style="top: 5px;">
                                    <i class="fa fa-search not-click"></i>
                                </button>
                            </div>

                            <div class="form-group mb-2" style="min-width: 93.5%;color: #c2c9d6">
                                <div class="form-check">
                                    <input type="radio" id="searchMainSB" formControlName="searchFilter" value="searchMain"
                                           (click)="searchCriteriaCategoryUpdate()"/>
                                    <label class="form-check-label ml-2" for="searchMainSB">
                                        {{'components.category.form.main' | translate}}
                                    </label>
                                </div>
                            </div>

                            <div class="form-group mb-2" style="min-width: 93.5%;color: #c2c9d6">
                                <div class="form-check">
                                    <input type="radio" id="searchSpecificSB" formControlName="searchFilter" value="searchSpecific"
                                           (click)="searchCriteriaCategoryUpdate()"/>
                                    <label class="form-check-label ml-2" for="searchSpecificSB">
                                        {{'components.category.form.specific' | translate}}
                                    </label>
                                </div>
                            </div>

                            <div class="form-group mb-2" style="min-width: 93.5%;color: #c2c9d6">
                                <div class="form-check">
                                    <input type="radio" id="searchServiceSB" formControlName="searchFilter" value="searchService"
                                           (click)="searchCriteriaCategoryUpdate()"/>
                                    <label class="form-check-label ml-2" for="searchServiceSB">
                                        {{'components.category.form.service' | translate}}
                                    </label>
                                </div>
                            </div>

                            <div class="form-group mb-2" style="min-width: 93.5%;color: #c2c9d6">
                                <div class="form-check">
                                    <input type="radio" id="searchAnimationSB" formControlName="searchFilter" value="searchAnimation"
                                           (click)="searchCriteriaCategoryUpdate()"/>
                                    <label class="form-check-label ml-2" for="searchAnimationSB">
                                        {{'components.category.form.animation' | translate}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                    <categorytree-component [data]="availableCategories"
                                            [hasCheckboxesOnlyForLastChild]="false"
                                            (onSearch)="searchCategory($event)"
                                            [searchCriteria]="searchCriteria"
                                            formControlName="categories"
                                            class="sidebar-categories">
                        {{'components.catalog.sidebar.categories' | translate}}
                    </categorytree-component>
                </div>
            </a>
        </li>
        <li>
            <a>
                <div class="form-row">
                    <div class="col-md-6">
                        <a class="button button-border btn-block"
                           (click)="search()">{{'components.catalog.sidebar.search' | translate}}</a>
                    </div>
                    <div class=" col-md-6">
                        <a class="button button-border gray btn-block"
                           (click)="doReset()">{{'components.catalog.sidebar.reset' | translate}}</a>
                    </div>
                </div>
            </a>
        </li>
    </ul>
</form>
