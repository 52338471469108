import {Injectable, Injector} from '@angular/core';


import {map} from "rxjs/operators";
import {AgencyApiClass} from "src/app/shared/classes/api/agency-api.class";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class AgencyService extends HttpServiceClass<AgencyApiClass> {
    protected getId(item: AgencyApiClass): number {
        throw new Error('Method not implemented.');
    }
    protected endpoint = 'cards/cardsFilterAgence';

    constructor(protected injector: Injector) {
        super(injector);
    }

    list(params?: HttpParams): Observable<AgencyApiClass[]> {
        return this.httpClient.get<AgencyApiClass[]>(
            this.getPath(), {params})
            .pipe(map(data => JsonUtils.parseDates(data)))
            .pipe(map((data: any) => this.fillData(data)));
    }

    private fillData(agencies: AgencyApiClass[]): AgencyApiClass[] {
        agencies.forEach(agency => {
            const geocodingData = this.geocodingData.find(item => item.name === agency.nom);
            if (geocodingData) {
                agency.center = geocodingData.center;
            }
        });
        return agencies;
    }

    private geocodingData = [
        {
            "name": "BIANCHI AJACCIO",
            "region": "CORSE",
            "do": "Corse",
            "address": "France 20090",
            "center": {"lat": 41.9393447, "lng": 8.7205877}
        }, {
            "name": "BIANCHI CALVI",
            "region": "CORSE",
            "do": "Corse",
            "address": "France 20260",
            "center": {"lat": 42.5368416, "lng": 8.7811645}
        }, {
            "name": "CGED TROYES",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 10600",
            "center": {"lat": 48.3807768, "lng": 3.9593503}
        }, {
            "name": "CGED CARCASSONNE",
            "region": "MED",
            "do": "Languedoc Roussillon",
            "address": "France 11000",
            "center": {"lat": 43.2183848, "lng": 2.3517526}
        }, {
            "name": "CGED RODEZ",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 12000",
            "center": {"lat": 44.3467485, "lng": 2.5742907}
        }, {
            "name": "CGED DECAZEVILLE",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 12300",
            "center": {"lat": 44.5839173, "lng": 2.2827384}
        }, {
            "name": "CGED PHOCEA",
            "region": "MED",
            "do": "Provence Ouest",
            "address": "France 13010",
            "center": {"lat": 43.2731832, "lng": 5.4306141}
        }, {
            "name": "CGED MARSEILLE",
            "region": "MED",
            "do": "Provence Ouest",
            "address": "France 13014",
            "center": {"lat": 43.3426011, "lng": 5.38872}
        }, {
            "name": "CGED ARLES",
            "region": "MED",
            "do": "Provence Ouest",
            "address": "France 13200",
            "center": {"lat": 43.6017739, "lng": 4.624375100000001}
        }, {
            "name": "CGED AIX-EN-PROVENCE",
            "region": "MED",
            "do": "Provence Ouest",
            "address": "France 13290",
            "center": {"lat": 43.5034104, "lng": 5.3416255}
        }, {
            "name": "CGED AUBAGNE",
            "region": "MED",
            "do": "Provence Ouest",
            "address": "France 13400",
            "center": {"lat": 43.3001055, "lng": 5.572229699999999}
        }, {
            "name": "CGED LA CIOTAT",
            "region": "MED",
            "do": "Provence Ouest",
            "address": "France 13600",
            "center": {"lat": 43.2107563, "lng": 5.624766999999999}
        }, {
            "name": "CGED CAEN SUD",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 14123",
            "center": {"lat": 49.1468895, "lng": -0.3518548}
        }, {
            "name": "CGED CAEN",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 14200",
            "center": {"lat": 49.2076755, "lng": -0.3308304}
        }, {
            "name": "CGED DEAUVILLE",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 14800",
            "center": {"lat": 49.3348208, "lng": 0.0978043}
        }, {
            "name": "CGED ANGOULEME",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 16340",
            "center": {"lat": 45.661986, "lng": 0.1961318}
        }, {
            "name": "CGED SAINTES",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 17100",
            "center": {"lat": 45.7599641, "lng": -0.6079521999999999}
        }, {
            "name": "CGED LA ROCHELLE",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 17180",
            "center": {"lat": 46.160869, "lng": -1.0976083}
        }, {
            "name": "CGED ROYAN",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 17600",
            "center": {"lat": 45.6916722, "lng": -0.9083622000000001}
        }, {
            "name": "CGED BOURGES",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 18230",
            "center": {"lat": 47.1021587, "lng": 2.3632006}
        }, {
            "name": "CGED BRIVE",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 19100",
            "center": {"lat": 45.1487308, "lng": 1.5195771}
        }, {
            "name": "BIANCHI PORTO VECCHIO",
            "region": "CORSE",
            "do": "Corse",
            "address": "France 20137",
            "center": {"lat": 41.6060151, "lng": 9.2563859}
        }, {
            "name": "BIANCHI FIUMORBU",
            "region": "CORSE",
            "do": "Corse",
            "address": "France 20243",
            "center": {"lat": 41.9968819, "lng": 9.3007367}
        }, {
            "name": "BIANCHI FURIANI",
            "region": "CORSE",
            "do": "Corse",
            "address": "France 20600",
            "center": {"lat": 42.6605477, "lng": 9.422826299999999}
        }, {
            "name": "CGED DIJON",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 21000",
            "center": {"lat": 47.3237985, "lng": 5.0386146}
        }, {
            "name": "CGED SAINT-BRIEUC",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 22000",
            "center": {"lat": 48.5105447, "lng": -2.7680099}
        }, {
            "name": "CGED DINAN",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 22100",
            "center": {"lat": 48.4406134, "lng": -2.0453123}
        }, {
            "name": "CGED PAIMPOL",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 22500",
            "center": {"lat": 48.7644836, "lng": -3.0424311}
        }, {
            "name": "CGED GUERET",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 23000",
            "center": {"lat": 46.155692, "lng": 1.8676049}
        }, {
            "name": "CGED BERGERAC",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 24100",
            "center": {"lat": 44.8681363, "lng": 0.5068824}
        }, {
            "name": "CGED SARLAT",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 24200",
            "center": {"lat": 44.9000339, "lng": 1.2313618}
        }, {
            "name": "CGED PERIGUEUX",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 24430",
            "center": {"lat": 45.1664666, "lng": 0.6240688}
        }, {
            "name": "CGED BESANCON",
            "region": "EST",
            "do": "Franche Comte",
            "address": "France 25220",
            "center": {"lat": 47.2765741, "lng": 6.1102282}
        }, {
            "name": "CGED VALENCE",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 26000",
            "center": {"lat": 44.92272, "lng": 4.9087118}
        }, {
            "name": "CGED PIERRELATTE",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 26700",
            "center": {"lat": 44.3681785, "lng": 4.7174062}
        }, {
            "name": "CGED EVREUX",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 27000",
            "center": {"lat": 49.0255821, "lng": 1.1419556}
        }, {
            "name": "CGED CHARTRES",
            "region": "SUD OUEST",
            "do": "",
            "address": "France 28000",
            "center": {"lat": 48.4439486, "lng": 1.5035523}
        }, {
            "name": "CGED BREST",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 29850",
            "center": {"lat": 48.4475359, "lng": -4.4588716}
        }, {
            "name": "CGED CONCARNEAU",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 29900",
            "center": {"lat": 47.9046952, "lng": -3.9161235}
        }, {
            "name": "CGED ALES",
            "region": "MED",
            "do": "Languedoc Roussillon",
            "address": "France 30100",
            "center": {"lat": 44.127838, "lng": 4.079767299999999}
        }, {
            "name": "CGED NIMES",
            "region": "MED",
            "do": "Languedoc Roussillon",
            "address": "France 30900",
            "center": {"lat": 43.8333998, "lng": 4.3256918}
        }, {
            "name": "CGED TOULOUSE SUD",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 31100",
            "center": {"lat": 43.5618224, "lng": 1.3951694}
        }, {
            "name": "CGED TOULOUSE NORD",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 31200",
            "center": {"lat": 43.6381666, "lng": 1.4338047}
        }, {
            "name": "CGED TOULOUSE COLOMIERS",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 31770",
            "center": {"lat": 43.6155066, "lng": 1.3228229}
        }, {
            "name": "CGED LANGON",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 33210",
            "center": {"lat": 44.5310129, "lng": -0.2807868}
        }, {
            "name": "CGED BORDEAUX CENTRE",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 33300",
            "center": {"lat": 44.8772685, "lng": -0.554149}
        }, {
            "name": "CGED BORDEAUX",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 33370",
            "center": {"lat": 44.84034490000001, "lng": -0.4408037}
        }, {
            "name": "CGED ANDERNOS",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 33510",
            "center": {"lat": 44.7612915, "lng": -1.0681918}
        }, {
            "name": "CGED PESSAC",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 33600",
            "center": {"lat": 44.8019097, "lng": -0.6488505}
        }, {
            "name": "CGED MONTPELLIER",
            "region": "MED",
            "do": "Languedoc Roussillon",
            "address": "France 34070",
            "center": {"lat": 43.59685959999999, "lng": 3.8502617}
        }, {
            "name": "CGED SETE",
            "region": "MED",
            "do": "Languedoc Roussillon",
            "address": "France 34200",
            "center": {"lat": 43.3979814, "lng": 3.6116494}
        }, {
            "name": "CGED BEZIERS",
            "region": "MED",
            "do": "Languedoc Roussillon",
            "address": "France 34500",
            "center": {"lat": 43.3416692, "lng": 3.238251}
        }, {
            "name": "CGED RENNES OUEST",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 35000",
            "center": {"lat": 48.11734209999999, "lng": -1.7075198}
        }, {
            "name": "CGED CESSON-SEVIGNE",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 35510",
            "center": {"lat": 48.1237782, "lng": -1.6052733}
        }, {
            "name": "CGED RENNES NORD",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 35520",
            "center": {"lat": 48.2166699, "lng": -1.7134887}
        }, {
            "name": "CGED REDON",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 35600",
            "center": {"lat": 47.6858473, "lng": -2.077688}
        }, {
            "name": "CGED CHATEAUROUX",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 36000",
            "center": {"lat": 46.8031198, "lng": 1.6926546}
        }, {
            "name": "CGED TOURS",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 37520",
            "center": {"lat": 47.3835142, "lng": 0.6486092}
        }, {
            "name": "CGED VIENNE-REVENTIN",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 38121",
            "center": {"lat": 45.47034559999999, "lng": 4.8332776}
        }, {
            "name": "CGED GRENOBLE",
            "region": "RHA",
            "do": "Dauphine",
            "address": "France 38400",
            "center": {"lat": 45.17745679999999, "lng": 5.7678717}
        }, {
            "name": "CGED MOIRANS",
            "region": "RHA",
            "do": "Dauphine",
            "address": "France 38430",
            "center": {"lat": 45.3233389, "lng": 5.5764026}
        }, {
            "name": "CGED MONT-DE-MARSAN",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 40090",
            "center": {"lat": 43.9614555, "lng": -0.5446911999999999}
        }, {
            "name": "CGED BLOIS",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 41260",
            "center": {"lat": 47.6129416, "lng": 1.3547302}
        }, {
            "name": "CGED SAINT-ETIENNE",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 42000",
            "center": {"lat": 45.450626, "lng": 4.3859432}
        }, {
            "name": "CGED ROANNE",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 42300",
            "center": {"lat": 46.06975790000001, "lng": 4.0752845}
        }, {
            "name": "CGED LE-PUY-EN-VELAY",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 43000",
            "center": {"lat": 45.0655906, "lng": 3.8627087}
        }, {
            "name": "CGED PORNIC",
            "region": "OUEST",
            "do": "Pays de Loire",
            "address": "France 44210",
            "center": {"lat": 47.1368749, "lng": -2.0802799}
        }, {
            "name": "CGED REZE",
            "region": "OUEST",
            "do": "Pays de Loire",
            "address": "France 44400",
            "center": {"lat": 47.1745467, "lng": -1.5475003}
        }, {
            "name": "CGED SAINT-NAZAIRE",
            "region": "OUEST",
            "do": "Pays de Loire",
            "address": "France 44600",
            "center": {"lat": 47.2896954, "lng": -2.2614914}
        }, {
            "name": "CGED NANTES OUEST",
            "region": "OUEST",
            "do": "Pays de Loire",
            "address": "France 44800",
            "center": {"lat": 47.2319122, "lng": -1.6343515}
        }, {
            "name": "CGED ORLEANS",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 45140",
            "center": {"lat": 47.93737669999999, "lng": 1.8043449}
        }, {
            "name": "CGED CAHORS",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 46000",
            "center": {"lat": 44.446599, "lng": 1.4628042}
        }, {
            "name": "CGED AGEN",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 47550",
            "center": {"lat": 44.1749628, "lng": 0.6441101}
        }, {
            "name": "CGED ANGERS",
            "region": "OUEST",
            "do": "Pays de Loire",
            "address": "France 49100",
            "center": {"lat": 47.491878, "lng": -0.5478767}
        }, {
            "name": "CGED GRANVILLE",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 50400",
            "center": {"lat": 48.8396762, "lng": -1.5301611}
        }, {
            "name": "CGED REIMS",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 51100",
            "center": {"lat": 49.2442751, "lng": 4.0346212}
        }, {
            "name": "CGED CHALONS-EN-CHAMPAGNE",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 51520",
            "center": {"lat": 49.0203601, "lng": 4.3432967}
        }, {
            "name": "CGED SAINT-DIZIER",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 52100",
            "center": {"lat": 48.6335128, "lng": 4.939526}
        }, {
            "name": "CGED NANCY",
            "region": "EST",
            "do": "Lorraine",
            "address": "France 54180",
            "center": {"lat": 48.6462338, "lng": 6.185390099999999}
        }, {
            "name": "CGED VANNES",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 56000",
            "center": {"lat": 47.6617064, "lng": -2.7574616}
        }, {
            "name": "CGED LORIENT",
            "region": "OUEST",
            "do": "Bretagne",
            "address": "France 56600",
            "center": {"lat": 47.7646339, "lng": -3.3398152}
        }, {
            "name": "CGED METZ",
            "region": "EST",
            "do": "Lorraine",
            "address": "France 57140",
            "center": {"lat": 49.171934, "lng": 6.138687}
        }, {
            "name": "CGED FORBACH",
            "region": "EST",
            "do": "Lorraine",
            "address": "France 57350",
            "center": {"lat": 49.19026419999999, "lng": 6.978017500000001}
        }, {
            "name": "CGED NEVERS",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 58000",
            "center": {"lat": 46.95615040000001, "lng": 3.1711675}
        }, {
            "name": "CGED COSNE",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 58200",
            "center": {"lat": 47.4293488, "lng": 3.0061643}
        }, {
            "name": "CGED ROUBAIX",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59100",
            "center": {"lat": 50.6930818, "lng": 3.1845673}
        }, {
            "name": "CGED VALENCIENNES",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59121",
            "center": {"lat": 50.3154829, "lng": 3.4399096}
        }, {
            "name": "CGED LILLE",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59175",
            "center": {"lat": 50.5718083, "lng": 3.0619722}
        }, {
            "name": "CGED DUNKERQUE",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59180",
            "center": {"lat": 50.9936523, "lng": 2.3656093}
        }, {
            "name": "CGED TOURCOING",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59200",
            "center": {"lat": 50.7248229, "lng": 3.1638275}
        }, {
            "name": "CGED ARMENTIERES",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59280",
            "center": {"lat": 50.6491401, "lng": 2.8769295}
        }, {
            "name": "CGED DOUAI",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59500",
            "center": {"lat": 50.3744075, "lng": 3.0885535}
        }, {
            "name": "CGED CAMBRAI",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59554",
            "center": {"lat": 50.19559109999999, "lng": 3.2123951}
        }, {
            "name": "CGED MAUBEUGE",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 59750",
            "center": {"lat": 50.2926012, "lng": 3.918826299999999}
        }, {
            "name": "CGED BEAUVAIS",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 60000",
            "center": {"lat": 49.4177265, "lng": 2.0828094}
        }, {
            "name": "CGED CREIL",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 60160",
            "center": {"lat": 49.263732, "lng": 2.4297519}
        }, {
            "name": "CGED COMPIEGNE",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 60200",
            "center": {"lat": 49.3912426, "lng": 2.8495589}
        }, {
            "name": "CGED CALAIS",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 62100",
            "center": {"lat": 50.9449796, "lng": 1.8871508}
        }, {
            "name": "CGED MONTREUIL",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 62170",
            "center": {"lat": 50.4793816, "lng": 1.7738917}
        }, {
            "name": "CGED CGED ST OMER",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 62219",
            "center": {"lat": 50.7358653, "lng": 2.2362841}
        }, {
            "name": "CGED ARRAS",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 62223",
            "center": {"lat": 50.3095177, "lng": 2.7946948}
        }, {
            "name": "CGED LENS",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 62300",
            "center": {"lat": 50.432941, "lng": 2.8189536}
        }, {
            "name": "CGED CLERMONT-FERRAND",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 63000",
            "center": {"lat": 45.771264, "lng": 3.1198023}
        }, {
            "name": "CGED PAU",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 64140",
            "center": {"lat": 43.3142872, "lng": -0.3983829}
        }, {
            "name": "CGED BAYONNE",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 64600",
            "center": {"lat": 43.5026588, "lng": -1.5111806}
        }, {
            "name": "CGED OLORON",
            "region": "SUD OUEST",
            "do": "Atlantique",
            "address": "France 64870",
            "center": {"lat": 43.1879746, "lng": -0.5399631}
        }, {
            "name": "CGED TARBES",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 65600",
            "center": {"lat": 43.2279059, "lng": 0.1167463}
        }, {
            "name": "CGED PERPIGNAN",
            "region": "MED",
            "do": "Languedoc Roussillon",
            "address": "France 66000",
            "center": {"lat": 42.7201813, "lng": 2.8876436}
        }, {
            "name": "CGED STRASBOURG",
            "region": "EST",
            "do": "Alsace",
            "address": "France 67300",
            "center": {"lat": 48.6071434, "lng": 7.7445122}
        }, {
            "name": "CGED STRASBOURG SUD",
            "region": "EST",
            "do": "Alsace",
            "address": "France 67450",
            "center": {"lat": 48.647346, "lng": 7.7088641}
        }, {
            "name": "CGED SELESTAT",
            "region": "EST",
            "do": "Alsace",
            "address": "France 67600",
            "center": {"lat": 48.2544005, "lng": 7.4982807}
        }, {
            "name": "CGED COLMAR",
            "region": "EST",
            "do": "Alsace",
            "address": "France 68000",
            "center": {"lat": 48.0927988, "lng": 7.370460299999999}
        }, {
            "name": "CGED MULHOUSE",
            "region": "EST",
            "do": "Alsace",
            "address": "France 68110",
            "center": {"lat": 47.7706068, "lng": 7.353268}
        }, {
            "name": "CGED LYON",
            "region": "RHA",
            "do": "Grand Lyon",
            "address": "France 69007",
            "center": {"lat": 45.7304251, "lng": 4.8399378}
        }, {
            "name": "CGED LYON EST",
            "region": "RHA",
            "do": "Grand Lyon",
            "address": "France 69120",
            "center": {"lat": 45.7883108, "lng": 4.9303872}
        }, {
            "name": "CGED VILLEFRANCHE",
            "region": "RHA",
            "do": "Grand Lyon",
            "address": "France 69400",
            "center": {"lat": 45.9882764, "lng": 4.7056806}
        }, {
            "name": "CGED BRIGNAIS",
            "region": "RHA",
            "do": "Grand Lyon",
            "address": "France 69530",
            "center": {"lat": 45.663552, "lng": 4.737809299999999}
        }, {
            "name": "CGED CHALON-SUR-SAONE",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 71100",
            "center": {"lat": 46.7525007, "lng": 4.833351299999999}
        }, {
            "name": "CGED MONTCEAU-LES-MINES",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 71300",
            "center": {"lat": 46.6746901, "lng": 4.358835}
        }, {
            "name": "CGED LE MANS",
            "region": "OUEST",
            "do": "Pays de Loire",
            "address": "France 72000",
            "center": {"lat": 48.0157803, "lng": 0.1609266}
        }, {
            "name": "CGED CHAMBERY",
            "region": "RHA",
            "do": "Deux Savoies",
            "address": "France 73000",
            "center": {"lat": 45.5700276, "lng": 5.929571300000001}
        }, {
            "name": "CGED ANNEMASSE",
            "region": "RHA",
            "do": "Deux Savoies",
            "address": "France 74100",
            "center": {"lat": 46.1829563, "lng": 6.2372305}
        }, {
            "name": "CGED THONON",
            "region": "RHA",
            "do": "Deux Savoies",
            "address": "France 74200",
            "center": {"lat": 46.3402239, "lng": 6.5201856}
        }, {
            "name": "CGED ANNECY",
            "region": "RHA",
            "do": "Deux Savoies",
            "address": "France 74600",
            "center": {"lat": 45.8473684, "lng": 6.07579}
        }, {
            "name": "CGED SALLANCHES",
            "region": "RHA",
            "do": "Deux Savoies",
            "address": "France 74700",
            "center": {"lat": 45.9323882, "lng": 6.606453}
        }, {
            "name": "CGED PARIS VALMY",
            "region": "IDF",
            "do": "",
            "address": "France 75010",
            "center": {"lat": 48.8785618, "lng": 2.3603689}
        }, {
            "name": "CGED PARIS 18ème",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 75018",
            "center": {"lat": 48.891305, "lng": 2.3529867}
        }, {
            "name": "CGED Pole Tertiaire",
            "region": "IDF",
            "do": "",
            "address": "France 75019",
            "center": {"lat": 48.89061359999999, "lng": 2.3867083}
        }, {
            "name": "CGED PARIS NATION",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 75020",
            "center": {"lat": 48.8599825, "lng": 2.4066412}
        }, {
            "name": "CGED LA VAUPALIERE",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 76150",
            "center": {"lat": 49.4879781, "lng": 1.0098469}
        }, {
            "name": "CGED LE HAVRE",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 76600",
            "center": {"lat": 49.476996, "lng": 0.1639479}
        }, {
            "name": "CGED ROUEN",
            "region": "OUEST",
            "do": "Normandie",
            "address": "France 76800",
            "center": {"lat": 49.3855314, "lng": 1.0952388}
        }, {
            "name": "CGED MELUN",
            "region": "IDF",
            "do": "IDF Est",
            "address": "France 77000",
            "center": {"lat": 48.5175192, "lng": 2.684649}
        }, {
            "name": "CGED MEAUX",
            "region": "IDF",
            "do": "IDF Est",
            "address": "France 77100",
            "center": {"lat": 48.9542785, "lng": 2.9085362}
        }, {
            "name": "CGED BOIS D'ARCY",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 78390",
            "center": {"lat": 48.807166, "lng": 2.0105545}
        }, {
            "name": "CGED NIORT",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 79000",
            "center": {"lat": 46.3326748, "lng": -0.4815849}
        }, {
            "name": "CGED THOUARS",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 79100",
            "center": {"lat": 46.9609397, "lng": -0.1801559}
        }, {
            "name": "CGED ABBEVILLE",
            "region": "NORD",
            "do": "",
            "address": "France 80100",
            "center": {"lat": 50.1173825, "lng": 1.8323901}
        }, {
            "name": "CGED AMIENS",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 80450",
            "center": {"lat": 49.9021919, "lng": 2.3744574}
        }, {
            "name": "CGED ALBI",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 81000",
            "center": {"lat": 43.9329634, "lng": 2.1548473}
        }, {
            "name": "CGED MONTAUBAN",
            "region": "SUD OUEST",
            "do": "Midi Pyrenees",
            "address": "France 82000",
            "center": {"lat": 44.0310432, "lng": 1.3469247}
        }, {
            "name": "CGED TOULON",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 83130",
            "center": {"lat": 43.127866, "lng": 6.0202827}
        }, {
            "name": "CGED SIX-FOURS",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 83140",
            "center": {"lat": 43.0944835, "lng": 5.824835200000001}
        }, {
            "name": "CGED FREJUS",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 83600",
            "center": {"lat": 43.4948227, "lng": 6.770813800000001}
        }, {
            "name": "CGED POITIERS",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 86000",
            "center": {"lat": 46.5831743, "lng": 0.3464227}
        }, {
            "name": "CGED LIMOGES",
            "region": "SUD OUEST",
            "do": "Centre",
            "address": "France 87100",
            "center": {"lat": 45.8452801, "lng": 1.2140596}
        }, {
            "name": "CGED EPINAL",
            "region": "EST",
            "do": "Lorraine",
            "address": "France 88150",
            "center": {"lat": 48.2498295, "lng": 6.4190311}
        }, {
            "name": "CGED AUXERRE",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 89000",
            "center": {"lat": 47.7973595, "lng": 3.5659504}
        }, {
            "name": "CGED SENS",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 89100",
            "center": {"lat": 48.212898, "lng": 3.26923}
        }, {
            "name": "CGED BELFORT",
            "region": "EST",
            "do": "",
            "address": "France 90000",
            "center": {"lat": 47.6437604, "lng": 6.8437053}
        }, {
            "name": "CGED EVRY",
            "region": "IDF",
            "do": "IDF Est",
            "address": "France 91090",
            "center": {"lat": 48.5983017, "lng": 2.4291949}
        }, {
            "name": "CGED NANTERRE",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 92000",
            "center": {"lat": 48.8941822, "lng": 2.2071125}
        }, {
            "name": "CGED VANVES",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 92170",
            "center": {"lat": 48.8246321, "lng": 2.2885466}
        }, {
            "name": "CGED VILLENEUVE-LA-GARENNE",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 92390",
            "center": {"lat": 48.9346952, "lng": 2.325598}
        }, {
            "name": "CGED NOISY",
            "region": "IDF",
            "do": "IDF Est",
            "address": "France 93130",
            "center": {"lat": 48.8916544, "lng": 2.4572494}
        }, {
            "name": "CGED PAVILLONS SOUS BOIS",
            "region": "IDF",
            "do": "",
            "address": "France 93320",
            "center": {"lat": 48.90577469999999, "lng": 2.5036412}
        }, {
            "name": "CGED ALFORTVILLE",
            "region": "IDF",
            "do": "IDF Est",
            "address": "France 94140",
            "center": {"lat": 48.80180240000001, "lng": 2.4213752}
        }, {
            "name": "CGED CHENNEVIERES",
            "region": "IDF",
            "do": "IDF Est",
            "address": "France 94430",
            "center": {"lat": 48.7992707, "lng": 2.5393023}
        }, {
            "name": "CGED CERGY",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 95000",
            "center": {"lat": 49.0331671, "lng": 2.0547222}
        }, {
            "name": "CGED CORMEILLES",
            "region": "IDF",
            "do": "IDF Ouest",
            "address": "France 95240",
            "center": {"lat": 48.9749352, "lng": 2.2021525}
        }, {
            "name": "CGED BOURG-EN-BRESSE",
            "region": "RHA",
            "do": "Ain Bourgogne Franche Comte",
            "address": "France 01000",
            "center": {"lat": 46.2135885, "lng": 5.245280999999999}
        }, {
            "name": "CGED LAON",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 02000",
            "center": {"lat": 49.5433545, "lng": 3.6030655}
        }, {
            "name": "CGED SAINT-QUENTIN",
            "region": "NORD",
            "do": "Nord pas de Calais",
            "address": "France 02100",
            "center": {"lat": 49.8686692, "lng": 3.3234331}
        }, {
            "name": "CGED VOLX",
            "region": "MED",
            "do": "Provence Ouest",
            "address": "France 04130",
            "center": {"lat": 43.8666888, "lng": 5.8301092}
        }, {
            "name": "CGED CANNES-LA-BOCCA",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 06150",
            "center": {"lat": 43.5547021, "lng": 6.969233}
        }, {
            "name": "CGED CANNES",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 06250",
            "center": {"lat": 43.60054909999999, "lng": 7.0014657}
        }, {
            "name": "CGED NICE-SAINT-ROCH",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 06300",
            "center": {"lat": 43.706449, "lng": 7.2922646}
        }, {
            "name": "CGED NICE-SAINT-LAURENT-DU-VAR",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 06700",
            "center": {"lat": 43.67513659999999, "lng": 7.184414}
        }, {
            "name": "CGED CAGNES-SUR-MER",
            "region": "MED",
            "do": "Provence Est",
            "address": "France 06800",
            "center": {"lat": 43.6727328, "lng": 7.1467072}
        }, {
            "name": "CGED AUBENAS",
            "region": "RHA",
            "do": "Sud Loire Auvergne",
            "address": "France 07200",
            "center": {"lat": 44.5981671, "lng": 4.3770704}
        }, {
            "name": "CGED CHARLEVILLE MEZIERES",
            "region": "NORD",
            "do": "Picardie/ Champagne / Ardennes",
            "address": "France 08000",
            "center": {"lat": 49.7488875, "lng": 4.7112617}
        }, {
            "name": "CGED ETAMPES",
            "region": "IDF",
            "do": "",
            "address": "France 32170",
            "center": {"lat": 43.4255004, "lng": 0.3183792}
        }, {
            "name": "CGED FOUGERES",
            "region": "OUEST",
            "do": "",
            "address": "France 35300",
            "center": {"lat": 48.3477251, "lng": -1.1957642}
        }, {
            "name": "CGED SAINT-MALO",
            "region": "OUEST",
            "do": "",
            "address": "France 35400",
            "center": {"lat": 48.6319149, "lng": -1.9815769}
        }, {
            "name": "CGED CHERBOURG",
            "region": "OUEST",
            "do": "",
            "address": "France 50100",
            "center": {"lat": 49.633998, "lng": -1.613426}
        }, {
            "name": "CGED LAVAL",
            "region": "OUEST",
            "do": "",
            "address": "France 53000",
            "center": {"lat": 48.0678944, "lng": -0.7646412}
        }, {
            "name": "CGED PROXI SAILLAGOUSE",
            "region": "MED",
            "do": "",
            "address": "France 66800",
            "center": {"lat": 42.4338962, "lng": 2.086133}
        }, {
            "name": "CGED GRIMAUD",
            "region": "MED",
            "do": "",
            "address": "France 83310",
            "center": {"lat": 43.25093409999999, "lng": 6.503643299999999}
        }, {
            "name": "CGED WISSOUS",
            "region": "IDF",
            "do": "Région Ile-de-France",
            "address": "France 94390",
            "center": {"lat": 48.7301463, "lng": 2.3620519}
        }];

    // propriety_315

}
