import {Component, Input} from '@angular/core';
import {ChartComponent} from "src/app/components/charts/chart.component";
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";


@Component({
    selector: 'story-charts-component',
    templateUrl: './story-charts.component.html',
    styleUrls: ['./story-charts.component.css']
})
export class StoryChartsComponent extends ChartComponent {

    max1: number = null;
    max2: number = null;
    max: number = null;
    color1: string = "#423532";
    color2: string = "#862510";
    baliseForm: string = "M51.8,28.2c0,9.9-18,29.3-18,29.3s-18-19.3-18-29.3s8-18,18-18S51.8,18.3,51.8,28.2z";

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        if (!this.card) {
            this.card = card;
        } else {
            card.valeur.forEach((value: any[], index: number) => {
                this.updateCardValues(this.card.valeur[index][0], value[0]);
                if (this.card.valeur[index][1]) {
                    this.updateCardValues(this.card.valeur[index][1], value[1]);
                }
            });
        }

        this.max1 = 0;
        this.max2 = 0;
        this.card.valeur.forEach((value: { title: string, value1: any, value2: string }[]) => {
            this.max1 = Math.max(this.max1, value[0].value1);
            if (value[1]) {
                this.max2 = Math.max(this.max2, value[1].value1);
            }
        });
        this.max = Math.max(this.max1, this.max2)
    }

    private updateCardValues(destination: any, source: any): void {
        destination.title = source.title;
        destination.value1 = source.value1;
        destination.value2 = source.value2;
        destination.valueEvol3 = source.valueEvol3;
        destination.unit1 = source.unit1;
        destination.unit2 = source.unit2;
    }
}
