import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponents } from "src/app/shared/classes/components/base-components.class";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { SyncCatalogService } from "src/app/shared/services/sync-catalog.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CatalogService } from "src/app/shared/services/catalog.service";
import { CategoryService } from "src/app/shared/services/category.service";
import { CategorytreeExtendedModelsInterface } from "src/app/shared/interfaces/models/extended/categorytree-extended-models.interface";
import { ProductSearchModelsInterface } from "src/app/shared/interfaces/models/search/product-search-models.interface";
import { DeliverycountryService } from "src/app/shared/services/deliverycountry.service";
import { combineLatest } from "rxjs/internal/observable/combineLatest";
import {
    BrandDtoInterface, BrandYoukadoDtoInterface,
    CategoryDtoInterface,
    DeliveryCountryDtoInterface,
    SupplierDtoInterface
} from "src/app/shared/interfaces/models/rest";
import { BrandService } from "src/app/shared/services/brand.service";
import { SupplierService } from "src/app/shared/services/supplier.service";
import { CategorytreeUtils } from "src/app/shared/utils/categorytree.utils";
import { HttpParams } from "@angular/common/http";
import { PaginationInterface } from "src/app/shared/interfaces/pagination.interface";
import { debounce } from "underscore";

@Component({
    selector: 'sidebar-catalog',
    templateUrl: './sidebar-catalog.component.html',
    styleUrls: ['./sidebar-catalog.component.scss']
})
export class SidebarCatalogComponent extends BaseComponents implements OnInit {

    showSideBar = false;
    formGroup: UntypedFormGroup;
    initialized = false;

    searchCriteria: { keywords?: string, main?: boolean, specific?: boolean, service?: boolean, animation?: boolean };
    formGroupSearch: UntypedFormGroup;
    availableCategories: CategorytreeExtendedModelsInterface;
    currentCountry: string;
    availableDeliveryCountries: DeliveryCountryDtoInterface[] = [];
    availableSuppliers: SupplierDtoInterface[] = [];
    availableStatus: { id: number, code: string }[] = [];
    availableBrands: BrandDtoInterface[] = [];
    statusSelectedItems: { id: number, code: string }[] = [];
    deliveryCountriesSelectedItems: DeliveryCountryDtoInterface[] = [];
    suppliersSelectedItems: SupplierDtoInterface[] = [];
    brandsSelectedItems: BrandDtoInterface[] = [];
    brandsYoukadoAutoComplete: BrandYoukadoDtoInterface[];
    keyword: string = 'label';

    availableStatusHard = [
        { id: 1, code: "new" },
        { id: 2, code: "published" },
        { id: 3, code: "unpublished" },
        { id: 4, code: "declined" },
        { id: 5, code: "standby" },
        { id: 6, code: "selected" }
    ];

    constructor(protected injector: Injector,
        protected syncCatalogService: SyncCatalogService,
        protected router: Router,
        protected catalogService: CatalogService,
        protected deliverycountryService: DeliverycountryService,
        protected brandService: BrandService,
        protected supplierService: SupplierService,
        protected categoryService: CategoryService,
        protected activatedRoute: ActivatedRoute,
        protected formBuilder: UntypedFormBuilder) {
        super(injector);
        this.searchCriteriaCategoryUpdate = debounce(this.searchCriteriaCategoryUpdate, 300);
    }

    ngOnInit(): void {
        if (!this.formGroup) {
            this.formGroup = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                ean: new UntypedFormControl(''),
                code: new UntypedFormControl(''),
                categories: new UntypedFormControl(''),
                deliveryCountries: new UntypedFormControl(''),
                suppliers: new UntypedFormControl(''),
                status: new UntypedFormControl(''),
                brands: new UntypedFormControl(''),
                stock: new UntypedFormControl(''),
                costPriceMin: new UntypedFormControl(''),
                costPriceMax: new UntypedFormControl(''),
                retailPriceMin: new UntypedFormControl(''),
                retailPriceMax: new UntypedFormControl(''),
                publicPriceMin: new UntypedFormControl(''),
                publicPriceMax: new UntypedFormControl(''),
                integrationDateFrom: new UntypedFormControl(''),
                integrationDateTo: new UntypedFormControl(''),
                selectionDateFrom: new UntypedFormControl(''),
                selectionDateTo: new UntypedFormControl('')
            });
        }

        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
                searchFilter: new UntypedFormControl('searchMain')
            });
        }

        combineLatest([
            this.activatedRoute.queryParams,
            this.deliverycountryService.listLite(),
            this.brandService.list(),
            this.categoryService.listTree(),
            this.supplierService.list()
        ]).subscribe(results => {
            //this.commondataService.showLoader(false);

            const params = results[0];
            if (!this.initialized) {
                //this.availableCategories = CategorytreeUtils.generateTree([], true);
                this.availableDeliveryCountries = results[1].sort((a, b) => a.label < b.label ? -1 : 1);
                this.availableBrands = results[2].sort((a, b) => a.label < b.label ? -1 : 1);
                // dunno why I have to do it here, change detector issues else...  ▔\0/▔
                this.availableStatus = this.availableStatusHard;
                this.availableCategories = CategorytreeUtils.generateTree(results[3], true);
                this.availableSuppliers = results[4];
            }

            if (params.search) {
                // request url values
                const search: ProductSearchModelsInterface = JSON.parse(params["search"]);
                const form = this.catalogService.searchToForm(
                    search,
                    this.availableDeliveryCountries,
                    this.availableSuppliers,
                    this.availableStatus,
                    this.availableBrands
                );

                this.formGroup.patchValue({
                    keywords: form.keywords,
                    ean: form.ean,
                    code: form.code,
                    deliveryCountries: form.deliveryCountries,
                    status: form.status,
                    brands: form.brands,
                    stock: form.stock,
                    costPriceMin: form.costPriceMin,
                    costPriceMax: form.costPriceMax,
                    retailPriceMin: form.retailPriceMin,
                    retailPriceMax: form.retailPriceMax,
                    publicPriceMin: form.publicPriceMin,
                    publicPriceMax: form.publicPriceMax,
                    integrationDateFrom: form.integrationDateFrom,
                    integrationDateTo: form.integrationDateTo,
                    selectionDateFrom: form.selectionDateFrom,
                    selectionDateTo: form.selectionDateTo,
                    categories: search.categories,
                });

                this.searchCriteriaCategoryUpdate();
                this.statusSelectedItems = [];
                this.availableStatusHard.forEach(status => {
                    if (search.status != null && search.status.includes(status.code)) {
                        this.statusSelectedItems.push(status);
                    }
                });

                this.deliveryCountriesSelectedItems = [];
                this.availableDeliveryCountries.forEach(deliverycountry => {
                    if (search.deliveryCountries != null && search.deliveryCountries.includes(deliverycountry.code)) {
                        this.deliveryCountriesSelectedItems.push(deliverycountry);
                    }
                });


                this.suppliersSelectedItems = [];
                this.availableSuppliers.forEach(supplier => {
                    if (search.suppliers != null && search.suppliers.includes(supplier.idSupplier)) {
                        this.suppliersSelectedItems.push(supplier);
                    }
                });
                let suppliers: number[] = this.suppliersSelectedItems.map((supplier: SupplierDtoInterface) => supplier.idSupplier);
                const selectedSuppliers = this.catalogService.searchToForm({ suppliers: suppliers }, null, this.availableSuppliers, null, null).suppliers;
                if (suppliers) {
                    this.formGroup.patchValue({
                        suppliers: selectedSuppliers
                    });
                }
                //this.changeCountry(suppliers);
                this.brandsSelectedItems = [];
                this.availableBrands.forEach(brand => {
                    if (search.brands != null && search.brands.includes(brand.label)) {
                        this.brandsSelectedItems.push(brand);
                    }
                });

                if (this.router.url.split('?')[0] === "/products") {
                    const httpParams = this.catalogService.searchToParams(search);
                    this.performSearch({ search: httpParams, deliveryCountries: search.deliveryCountries, perform: true, pagination: { page: search.page, size: search.size } });
                }
            } else if (!this.initialized) {
                this.doDefault();
                if (this.router.url.split('?')[0] === "/products") {
                    const search: ProductSearchModelsInterface = JSON.parse('{"status":["published"]}');
                    const httpParams = this.catalogService.searchToParams(search);
                    this.performSearch({ search: httpParams, deliveryCountries: search.deliveryCountries, perform: true, pagination: { page: search.page, size: search.size } });
                }

            }

            this.initialized = true;
        }, error => {
            this.dealWithStandardError(error);
        });

        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
                searchFilter: new UntypedFormControl('searchMain')
            });
        }

        this.subscriptions.push(this.syncCatalogService.onPaginate.subscribe(pagination => {
            this.router.navigate(['/products'], { queryParams: { search: JSON.stringify(this.catalogService.formToSearch(this.formGroup.value, pagination)) } }).then();
        }));

        this.subscriptions.push(this.syncCatalogService.onSearchCategory.subscribe(category => {
            this.formGroup.patchValue({
                categories: [category.idCategory]
            });

            let search = {} as ProductSearchModelsInterface;
            if (this.formGroup.value.categories && this.formGroup.value.categories.length)
                search.categories = this.formGroup.value.categories;

            const url = this.router.serializeUrl(
                this.router.createUrlTree(
                    ['/products'],
                    { queryParams: { search: JSON.stringify(search) } }
                )
            );
            console.log('/marketplace/#' + url);
            window.open('/marketplace/#' + url, '_blank');
        }));
    }

    search(): void {
        this.router.navigate(
            ['/products'],
            { queryParams: { search: JSON.stringify(this.catalogService.formToSearch(this.formGroup.value)) } }
        ).then();
    }

    searchCategory(category: CategoryDtoInterface): void {
        this.syncCatalogService.syncSearchCategory(category);
    }

    searchCriteriaCategoryUpdate() {
        switch (this.formGroupSearch.value.searchFilter) {
            case "searchMain":
                this.formGroupSearch.value.searchMain = true;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchSpecific":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = true;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchService":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = true;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchAnimation":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = true;
                break;
        }
        this.searchCriteria = {
            keywords: this.formGroupSearch.value.keywords,
            main: this.formGroupSearch.value.searchMain,
            specific: this.formGroupSearch.value.searchSpecific,
            service: this.formGroupSearch.value.searchService,
            animation: this.formGroupSearch.value.searchAnimation,
        };
    }

    changeCountry(): void {
        const countries = this.formGroup.value.deliveryCountries as DeliveryCountryDtoInterface[];
        this.availableSuppliers = [];
        this.suppliersSelectedItems = [];
        // suppliers
        let httpParams = new HttpParams();
        if (countries) {
            httpParams = httpParams.append("deliveryCountries", countries.map(item => item.code).join(","));
        }
        
        this.supplierService.search(httpParams, { pagination: { page: 0, size: 99999 } }).subscribe(response => {
            this.availableSuppliers = response.content.sort((a, b) => a.label < b.label ? -1 : 1);
        }, error => {
            this.dealWithStandardError(error);
        });
    }

    private doDefault(): void {
        this.formGroup.reset();
        const form = this.catalogService.searchToForm(
            {"status":["published"]},
            this.availableDeliveryCountries,
            this.availableSuppliers,
            this.availableStatus,
            this.availableBrands
        );
        this.formGroup.patchValue({
            deliveryCountries: form.deliveryCountries,
            status: form.status,
            stock: form.stock,
        });
        //this.changeCountry();
    }

    public doReset(): void {
        this.formGroup.reset();
    }



    private performSearch(opt?: { search: HttpParams, deliveryCountries: string[], perform: boolean, pagination?: PaginationInterface }): void {
        if (!opt || opt.perform) {
            opt.pagination.size = 12;
            this.catalogService.search(opt?.search, { pagination: opt?.pagination }).subscribe(
                (response) => {
                    // if (this.router.url.split('?')[0] === "/products") {
                    //     this.commondataService.showLoader(false);
                    // }
                    this.commondataService.showLoader(false);
                    this.syncCatalogService.syncSearch({ results: response, deliveryCountries: opt.deliveryCountries, perform: false });
                },
                (error) => {
                    this.commondataService.showLoader(false);
                    console.log('Error : ' + error);
                }
            );
            this.commondataService.showLoader(true);
        }
    }

    onChangeSearch(val: string) {
        if (val == null || val == "") {
            this.brandsYoukadoAutoComplete = this.availableBrands.splice(0, 100);
        } else {
            this.brandsYoukadoAutoComplete = this.availableBrands.filter(b => b.label.toUpperCase().indexOf(val.toUpperCase()) !== -1).splice(0, 100);
        }

    }

    onSelectItem(val: any) {
        this.brandsSelectedItems.push(val);
        this.formGroup.patchValue({
            brands: this.brandsSelectedItems,
        });
    }

    removeItem(selectedOption: BrandDtoInterface) {
        const index = this.brandsSelectedItems.indexOf(selectedOption);
        if (index > -1) {
            this.brandsSelectedItems.splice(index, 1);
        }
        this.formGroup.patchValue({
            brands: this.brandsSelectedItems,
        });
    }
}
