<div class="card-body"
     [ngClass]="{'fullscreen': card && (!card.libelle && banners.length === 1)}">
    <ng-container *ngIf="card && (card.libelle || banners.length !== 1)">
        <ng-container *ngIf="card.libelle || banners.length !== 1">
            <h3 *ngIf="card.libelle">{{card.libelle}}</h3>
            <div *ngFor="let banner of banners; index as index;"
                 [style]="{
                    'backgroundImage': 'url('+banner+')',
                    'cursor': bannersUrl[index] ? 'pointer' : ''
                 }"
                 (click)="open(bannersUrl[index])"></div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="card && (!card.libelle && banners.length === 1)">
        <div [style]="{
                'backgroundImage': 'url('+banners[0]+')',
                'cursor': bannersUrl[0] ? 'pointer' : ''
             }"
             (click)="open(bannersUrl[0])"></div>
    </ng-container>
</div>
