import {PageableModelsInterface} from "src/app/shared/interfaces/models/pageable-models.interface";
import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";

export class PaginationUtils {
    static getPagination(pageable: PageableModelsInterface<any>): PaginationbarInterface {
        if (!pageable) {
            return null;
        }
        pageable.number = pageable.number + 1;

        const pagination: PaginationbarInterface = {
            currentPage: pageable.number,
            totalPages: pageable.totalPages,
            previousPages: [],
            nextPages: [],
            hasPreviousPages: pageable.number > 1,
            hasNextPages: pageable.number < pageable.totalPages,
            hasUncountedPreviousPages: pageable.number > 4,
            hasUncountedNextPages: pageable.number < pageable.totalPages - 4
        };

        if (pagination.hasPreviousPages) {
            for (let i = Math.max(1, pagination.currentPage - 3); i < pagination.currentPage; i++) {
                pagination.previousPages.push(i);
            }
        }
        if (pagination.hasNextPages) {
            for (let i = pagination.currentPage + 1; i < Math.min(pagination.currentPage + 4, pagination.totalPages + 1); i++) {
                pagination.nextPages.push(i);
            }
        }

        return pagination;
    }
}
