import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {RuleDtoInterface} from "src/app/shared/interfaces/models/rest";
import {Injectable, Injector} from "@angular/core";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {CategoryToEnseigneDtoInterface} from "src/app/shared/interfaces/models/rest";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CategoryToEnseigne extends HttpServiceClass<CategoryToEnseigneDtoInterface[]> {
    protected endpoint = "categoryToEnseigne";

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected getId(item: CategoryToEnseigneDtoInterface[]): number {
        return item[0].idCategoryToEnseigne;
    }


    getCategoryToEnseigne(params?: HttpParams) {
        return this.httpClient.get<number>(
            this.getPath({ path: "search/findByCriteria" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }


}
