import {Injectable, Injector} from '@angular/core';

import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {map} from "rxjs/operators";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {JsonUtils} from "src/app/shared/utils/json.utils";


@Injectable({
    providedIn: 'root'
})
export class AgencyEligibilityService extends HttpServiceClass<any> {
    protected endpoint = 'cards/eligibility/agency';

    constructor(protected injector: Injector) {
        super(injector);
    }

    getStr(id: string, params?: HttpParams): Observable<any> {
        return this.httpClient.get<any>(
            this.getPath({path: "?15=" + id}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    protected getId(item: any): number {
        return 0;
    }
}
