import {Component, OnInit, Input, Injectable, Injector, Output, EventEmitter} from '@angular/core';

import {NgbCalendar, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {
    ProductFormvalidationInterface
} from "src/app/shared/interfaces/formvalidation/product/product-formvalidation.interface";
import {ProductyoukadoService} from 'src/app/shared/services/productyoukado.service';
import {CommonDataService} from 'src/app/common-data.service';
import {EnseignesService} from 'src/app/shared/services/enseignes-service';
import {PictosService} from 'src/app/shared/services/pictos.service';
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {
    BrandYoukadoDtoInterface, CatalogueDtoInterface, CategoryToEnseigneDtoInterface,
    DeliveryCountryDtoInterface, EnseigneDtoInterface, PictosDtoInterface,
    ProductAndEnseigneDtoInterface, ProductYoukadoDtoInterface, PropertyDtoInterface
} from 'src/app/shared/interfaces/models/rest';
import {CategoryService} from "src/app/shared/services/category.service";

const I18N_VALUES: any = {
    'fr': {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
        weekLabel: 'sem'
    }
    // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
    language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    constructor(private _i18n: I18n) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }

    getWeekLabel(): string {
        return I18N_VALUES[this._i18n.language].weekLabel;
    }

    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.css'],
    providers:
        [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ProductDetailsComponent extends BaseComponents implements OnInit {
    @Input() productYoukado: ProductYoukadoDtoInterface;
    @Input() deliveryCountry: DeliveryCountryDtoInterface;
    @Input() validationData: ProductFormvalidationInterface;
    @Input() brandsYoukado: BrandYoukadoDtoInterface[];
    @Input() currentCatalogueIndex: number;
    @Input() catalogue: CatalogueDtoInterface;
    @Output("checkEanExiste") checkEanExiste: EventEmitter<any> = new EventEmitter();
    @Output("openExternal") openExternal: EventEmitter<any> = new EventEmitter();

    @Input()
    eanCopy: string;
    @Input()
    showMessageEanError: boolean = false;
    @Input()
    showMessageEanSuccess: boolean = false;
    @Input()
    showLoadingEan: boolean = false;

    brandsYoukadoAutoComplete: BrandYoukadoDtoInterface[];
    dropdownSettingsEnseignes: any = {};
    dropdownSettingsPictos: any = {};
    dropdownSettingsForWho: any = {};
    selectedEnseigne: any[] = null;
    enseignes: EnseigneDtoInterface[];
    allPictos: PictosDtoInterface[];
    isShowDateBoost: boolean = false;
    keyword: string = 'label';
    categoryEcoResponsable:any

    constructor(
        private productService: ProductyoukadoService,
        private calendar: NgbCalendar,
        private enseignesService: EnseignesService,
        protected categoryService: CategoryService,
        private pictosService: PictosService,
        protected injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {

        let productAndEnseigne = {} as ProductAndEnseigneDtoInterface;
        productAndEnseigne.idProduct = this.productYoukado.idProductYoukado ? this.productYoukado.idProductYoukado : 0;
        this.commondataService.showLoader(true);
        this.subscriptions.push(combineLatest([
            // Get and store all enseignes in "enseignes" array
            this.enseignesService.getEnseignes(),
            // Get and store all pictos in "allPictos" array
            this.pictosService.getPictos(),
            this.productService.getEnseignesInclusion(productAndEnseigne)
        ]).subscribe(results => {
            this.enseignes = results[0];
            this.allPictos = results[1];
            this.commondataService.showLoader(false);
        }, error => {
            this.dealWithStandardError(error);
        }));

        // Set ng multiselect dropdown settings
        this.dropdownSettingsEnseignes = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "nomEnseigne",
            idField: "id_ext_enseigne",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsPictos = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "idPictos",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsForWho = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "label",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        for (let catalogue of this.productYoukado.catalogues) {
            if (catalogue.deliveryCountriesCatalogue.code == this.deliveryCountry.code) {
                if (catalogue.dateBoostedStart != null || catalogue.dateBoostedEnd != null) {
                    this.isShowDateBoost = true;
                }
            }
        }
        //this.deliveryCountry = this.productYoukado.catalogues[this.currentCatalogueIndex].deliveryCountriesCatalogue;
        this.categoryService.getCategoryByCode({categoryCode: 'CAT428'}).subscribe(
            (response) => {
                this.categoryEcoResponsable=response
            },
            (error) => {
                console.log(error);
            }
        )

    }

    onChangeSearch(val: string) {
        this.validationData.brand = false;
        if (val == null || val == "") {
            this.validationData.brand = true;
            this.brandsYoukadoAutoComplete = this.brandsYoukado.splice(0, 10);
        } else {
            this.brandsYoukadoAutoComplete = this.brandsYoukado.filter(b => b.label.indexOf(val) !== -1).splice(0, 10);
        }
        const brand = this.brandsYoukado.find((d: BrandYoukadoDtoInterface) => d.label.toUpperCase() == val.toUpperCase());
        if (brand != null) {
            this.productYoukado.brandYoukado = brand;
        } else {
            this.productYoukado.brandYoukado = {
                idBrandYoukado: null,
                label: val
            }
        }
    }

    openExternalDetails() {
        this.openExternal.emit();
    }

    checkEan() {
        this.checkEanExiste.emit();
    }

    /* Change product type */
    onChangeType() {
        this.productYoukado.lienAvantage = null;
        this.validationData.productType = false;
        if (this.productYoukado.productType == "" || this.productYoukado.productType == null) {
            this.validationData.productType = true;
        }
    }

    /* Onclick checkbox "nouveauté" */
    showDateBoost() {
        this.isShowDateBoost = !this.isShowDateBoost;
        for (let catalogue of this.productYoukado.catalogues) {
            if (catalogue.deliveryCountriesCatalogue.code == this.deliveryCountry.code) {
                if (this.isShowDateBoost) {
                    // @ts-ignore
                    catalogue.dateBoostedStart = this.calendar.getToday();
                    // @ts-ignore
                    catalogue.dateBoostedEnd = this.calendar.getNext(this.calendar.getToday(), 'm', 1);
                } else {
                    catalogue.dateBoostedStart = null;
                    catalogue.dateBoostedEnd = null;
                }
            }
        }
    }

    /* Onclick checkbox "ecoresponsable" */
    setEcoresponsable() {
        if (this.catalogue.pictos == null) {
            this.catalogue.pictos = [];
        }
        if (this.productYoukado.categoriesProductYoukado == null) {
            this.productYoukado.categoriesProductYoukado = [];
        }
        this.productYoukado.ecoresponsable = !this.productYoukado.ecoresponsable;
        if (this.productYoukado.ecoresponsable){
            if (this.catalogue.pictos.findIndex(e=>e.label==='Responsable')==-1)
                this.catalogue.pictos= [ ...this.catalogue.pictos,this.allPictos.filter(p=>p.label==='Responsable')[0]]
            if (this.productYoukado.categoriesProductYoukado.findIndex(e=>e.code==='CAT428')==-1){
                this.productYoukado.categoriesProductYoukado= [ ...this.productYoukado.categoriesProductYoukado,this.categoryEcoResponsable]
            }
        } else {
            this.productYoukado.categoriesProductYoukado = this.productYoukado.categoriesProductYoukado.filter(c => c.code != 'CAT428');
        }
    }

    /* Onclick checkbox "montantlibre" */
    setMontantlibre() {
        this.productYoukado.montantlibre = !this.productYoukado.montantlibre;
    }

    onSelectedEnseigne(enseigne: any) {
        let enseignes: EnseigneDtoInterface[] = this.enseignes.filter(e => e.id_ext_enseigne == enseigne.id_ext_enseigne);
        for (let enseigne of enseignes) {
            enseigne.isDataLoadProp = false;
        }
        this.getEnseigneProperties(enseignes);
    }

    onDeSelectedEnseigne(enseigne: any) {
        this.productYoukado.enseignesProperties.delete(enseigne.nomEnseigne);
        this.buildProductToEnseigne();
    }

    getEnseigneProperties(enseignes: EnseigneDtoInterface[]) {
        if (enseignes != null && enseignes.length > 0) {
            for (let enseigne of enseignes) {
                let productAndEnseigne = {} as ProductAndEnseigneDtoInterface;
                productAndEnseigne.idEnseigne = enseigne.id_ext_enseigne;
                productAndEnseigne.idProduct = this.productYoukado.idProductYoukado ? this.productYoukado.idProductYoukado : 0;
                this.commondataService.showLoader(true);

                this.productService.getProperties(productAndEnseigne).subscribe(
                    (response) => {
                        if (this.productYoukado.enseignesProperties == null || this.productYoukado.enseignesProperties == undefined && this.productYoukado.enseignesProperties.size == 0) {
                            this.productYoukado.enseignesProperties = new Map();
                        }
                        this.productYoukado.enseignesProperties.set(enseigne.nomEnseigne, response);
                        enseigne.isDataLoadProp = true;
                        this.buildProductToEnseigne();
                        this.commondataService.showLoader(false);

                    },
                    (error) => {
                        console.log('Error : ' + JSON.stringify(error));
                    }
                );
            }

        }
    }

    OpenAccordion(sectionName: any, Wrapdiv: any) {

        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "acd-des") {
            document.getElementById(sectionName).setAttribute("class", "acd-des show");
            document.getElementById(Wrapdiv).setAttribute("class", "acd-group acd-active");
        } else {
            document.getElementById(sectionName).setAttribute("class", "acd-des");
            document.getElementById(Wrapdiv).setAttribute("class", "acd-group");
        }
    }

    buildProductToEnseigne() {
        this.productYoukado.productToEnseigne = [];
        this.productYoukado.showEnseigne = new Map();
        this.productYoukado.enseignesProperties.forEach((propertyDtoEnseigne: PropertyDtoInterface[], key: string) => {
            let pushProductToEnseigne: Boolean = true;
            this.productYoukado.showEnseigne.set(key, false);
            for (let propertyDto of propertyDtoEnseigne) {
                if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {
                    this.productYoukado.showEnseigne.set(key, true);
                }
                if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {


                    for (let value of propertyDto.selectedPropertyValues) {
                        if (value != null) {
                            const productToEnseigne = {} as CategoryToEnseigneDtoInterface;
                            productToEnseigne.nomEnseigne = key;
                            productToEnseigne.idPropriete = propertyDto.propertyId;
                            productToEnseigne.valeurPropriete = value.label;
                            let propertyValueDto = propertyDto.propertyValues.find(p => p.label != null && p.label == value.label);
                            if (propertyValueDto != null && propertyValueDto.value != null && propertyValueDto.value.trim() != "") {
                                productToEnseigne.valeurPropriete = propertyValueDto.value;
                            }
                            if (this.productYoukado.productToEnseigne == null) {
                                this.productYoukado.productToEnseigne = [];
                            }
                            this.productYoukado.productToEnseigne.push(productToEnseigne);
                            pushProductToEnseigne = false;
                        }
                    }
                }
            }

            if (pushProductToEnseigne) {
                const productToEnseigne = {} as CategoryToEnseigneDtoInterface;
                productToEnseigne.nomEnseigne = key;
                this.productYoukado.productToEnseigne.push(productToEnseigne);
            }

        });

    }

    changeEan() {
        this.showMessageEanSuccess = false;
        this.showMessageEanError = false;
    }

    onSelectPicto($event:any) {
        if ($event.label==='Responsable'){
            this.productYoukado.ecoresponsable=true
            if (this.productYoukado.categoriesProductYoukado.findIndex(e=>e.code==='CAT428')==-1){
                this.productYoukado.categoriesProductYoukado= [ ...this.productYoukado.categoriesProductYoukado,this.categoryEcoResponsable]
            }
        }

    }
}
