import { Injectable, Injector } from '@angular/core';
import {TemplateBtnDtoInterface} from '../interfaces/models/rest';
import { HttpServiceClass } from "src/app/shared/classes/services/http-services.class";
import { JsonUtils } from "src/app/shared/utils/json.utils";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class TemplateDescriptionService extends HttpServiceClass<TemplateBtnDtoInterface> {
    protected endpoint = "templateBtn";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: TemplateBtnDtoInterface): number {
        return item.idTemplate;
    }

    getTemplateBtn(params?: HttpParams) {
        return this.httpClient.get<TemplateBtnDtoInterface[]>(
            this.getPath({ path: "" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    translate(item: any[], params?: HttpParams){
        return this.httpClient.post<any[]>(
            this.getPath(), item, {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
