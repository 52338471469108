import {Component, Input} from '@angular/core';

import {DecimalPipe} from "@angular/common";
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {ChartComponent} from "src/app/components/charts/chart.component";
import {CardExportService} from "src/app/shared/services/export/card-export.service";



@Component({
    selector: 'value-charts-component',
    templateUrl: './value-charts.component.html',
    styleUrls: ['./value-charts.component.css']
})
export class ValueChartsComponent extends ChartComponent {
    exporting: boolean = false;

    constructor(private cardExportService: CardExportService,
                private decimalPipe: DecimalPipe) {
        super();
    }

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        if (card.comment?.indexOf("{{") > -1 && card.detail?.length > 0) {
            card.detail.forEach((detail: any, index: number) => {
                if (card.comment.indexOf("{{" + index + "}}") > -1) {
                    card.comment = card.comment.replace("{{" + index + "}}", this.decimalPipe.transform(detail.value) + " " + detail.unit);
                    card.detail.splice(index, 1);
                }
            })
        }

        if (card.unit?.indexOf("{{") > -1 && card.detail?.length > 0) {
            card.detail.forEach((detail: any, index: number) => {
                if (card.unit.indexOf("{{" + index + "}}") > -1) {
                    card.unit = card.unit.replace("{{" + index + "}}", this.decimalPipe.transform(detail.value) + " " + detail.unit);
                    card.detail.splice(index, 1);
                }
            })
        }

        if (!this.card) {
            this.card = card;
        } else {
            this.updateCardValues(this.card, card);
        }
    }

    private updateCardValues(destination: any, source: any): void {
        this.increaseProgressive(destination, source, "value")
        destination.unit = source.unit;
        destination.detail = source.detail;
        destination.comment = source.comment;
        destination.url = source.url;
        destination.urlBackground = source.urlBackground;
        destination.downloadable = source.downloadable;
    }

    private increaseProgressive(destination: any, source: any, key: string) {
        const duration = 500;
        const steps = 20;

        const isFloat = !(destination[key] % 1 === 0 || source[key] % 1 === 0);
        const delta: number = (source[key] - destination[key]) / (duration / steps);

        for (let i = 1; i <= (duration / steps); i++) {
            let newValue: number;
            if (i == (duration / steps)) {
                newValue = source[key];
            } else {
                newValue = destination[key] + (isFloat ? delta * i : Math.round(delta * i));
            }
            setTimeout(() => {
                destination[key] = newValue;
            }, (i - 1) * steps);
        }
    }

    export(): void {
        if (this.exporting) {
            return;
        }
        this.exporting = true;
        this.cardExportService.saveFile(this.card.divId).subscribe(() => {
            this.exporting = false;
        });
    }
}
