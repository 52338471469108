<a data-toggle="collapse"
   [draggable]="level && isDraggable"
   *ngIf="data && (!hidden || hidden.get(data.idCategory) !== hideSearchedKeywordsEnum.Hidden)"
   [attr.aria-expanded]="!collapse"
   (dragstart)="dragstart()"
   (dragend)="dragend()"
   (drop)="drop($event, null)"
   (dragover)="dragover($event, null)"
   (dragleave)="dragleave()"
   (click)="collapse=!collapse"
   [ngClass]="{'hovered': this.hoveredMove}"
   style="padding-bottom: 11px;"
   [ngStyle]="{
           'border': isDraggable ?
                (isDragging && isDraggingToMove ?
                    this.hoveredMove ?
                    '5px dashed rgba(67,195,245)' :
                    '5px dashed rgba(67,195,245,0.5)'
                : '5px dashed transparent')
           : ''
   }">
    <div class="pull-left">

        <i *ngIf="data && data.childCategories && !hasCheckboxes && (hasEdition || hasDetails) && hasCarets"
           class="ti-plus-before"></i>

        <span #ngContent class="right-nav-text">
            <ng-content></ng-content>
        </span> 

        <span style="padding-left: 125px;padding-right: 5px;" *ngIf="isRules && data.idCategory == null" #ngContent class="right-nav-text">
             <b>Sélectionner tout</b>
        </span> 

        <span *ngIf="!ngContent || ngContent.childNodes.length == 0 || isRules"
              class="right-nav-text">
            <input *ngIf="(hasCheckboxes && !hasCheckboxesOnlyForLastChild) || (data.childCategories == null && hasCheckboxesOnlyForLastChild) || isRules"
                   type="checkbox"
                   [ngModel]="selected.get(data.idCategory)"
                   (click)="checkboxClick($event)">
            <ng-container *ngFor="let translation of data.translationCategoriesCategory">
                <ng-container *ngIf="translation.languageTranslationCategory.code===language">
                    {{translation.labelYoukado}}
                </ng-container>
            </ng-container>
        </span>

        <span *ngIf="(showStatus || hasDetails) && level && data && data.published" class="tooltip-content" placement="top-left"
              ngbTooltip="{{'components.categorytree.tooltip.published' | translate}}">
            <i class="fa fa-play-circle" aria-hidden="true" style="color: forestgreen; font-size: 16px; padding-right: 3px;"></i>
        </span>
        <span *ngIf="(showStatus || hasDetails) && level && data && !data.published" class="tooltip-content" placement="top-left"
              ngbTooltip="{{'components.categorytree.tooltip.unpublished' | translate}}">
            <i class="fa fa-stop-circle" aria-hidden="true" style="color: lightslategrey; font-size: 16px; padding-right: 3px;"></i>
        </span>
        <span *ngIf="(showStatus || hasDetails) && level && data && data.isMain" class="tooltip-content" placement="top-left" ngbTooltip="{{'components.categorytree.tooltip.main' | translate}}">
            <i class="fa fa-bookmark" aria-hidden="true" style="color: lightslategrey; font-size: 16px; padding-right: 3px;"></i>
        </span>
        <span *ngIf="(showStatus || hasDetails) && level && data && data.isSpecificCategory" class="tooltip-content" placement="top-left"
              ngbTooltip="{{'components.categorytree.tooltip.specific' | translate}}">
            <i class="fa fa-bullseye" aria-hidden="true" style="color: lightslategrey; font-size: 16px; padding-right: 3px;"></i>
        </span>
        <span *ngIf="(showStatus || hasDetails) && level && data && data.isService" class="tooltip-content" placement="top-left"
              ngbTooltip="{{'components.categorytree.tooltip.service' | translate}}">
            <i class="fa fa-handshake-o" aria-hidden="true" style="color: lightslategrey; font-size: 16px; padding-right: 3px;"></i>
        </span>
        <span *ngIf="(showStatus || hasDetails) && level && data && data.archive" class="tooltip-content" placement="top-left"
              ngbTooltip="{{'components.categorytree.tooltip.archive' | translate}}">
            <i class="fa fa-archive" aria-hidden="true" style="color: lightslategrey; font-size: 16px; padding-right: 3px;"></i>
        </span>
        <span *ngIf="(showStatus || hasDetails) && level && data && data.type=='Personnelle'" class="tooltip-content" placement="top-left"
              ngbTooltip="Loisir">
            <i class="fa fa-shopping-basket" aria-hidden="true" style="color: #17a2b8; font-size: 16px; padding-right: 3px;"></i>
        </span>
        <span *ngIf="(showStatus || hasDetails) && level && data && data.type=='Professionnelle'" class="tooltip-content" placement="top-left"
              ngbTooltip="Professionnelle">
            <i class="fa fa-road" aria-hidden="true" style="color: #153b54; font-size: 16px; padding-right: 3px;"></i>
        </span>

        <!--        <i *ngIf="hasDetails && level && data && data.isAnimation" class="fa fa-calendar-o" aria-hidden="true" style="color: lightslategrey; font-size: 16px; padding-right: 3px;"></i>-->
    </div>

    <div class="pull-right" *ngIf="data">
        <i *ngIf="hasCarets && !hasEdition && !hasDetails && data.childCategories"
           class="ti-plus"></i>
        <i *ngIf="level"
           (click)="search($event)"
           style="cursor: alias;"
           class="fa fa-search text-warning action-button searchGlass"
           aria-hidden="true"></i>
        <i *ngIf="hasEdition && level"
           (click)="edit($event)"
           class="fa fa-pencil text-primary action-button"
           aria-hidden="true"></i>
        <i *ngIf="hasEdition"
           [attr.disabled]="level>=3"
           [ngClass]="{'action-button': level < 3}"
           (click)="add($event)"
           class="fa fa-plus text-success"
           aria-hidden="true"></i>
        <!--        <i *ngIf="hasEdition"-->
        <!--           [ngStyle]="{'visibility': isChild ? '' : 'hidden'}"-->
        <!--           (click)="delete($event)"-->
        <!--           class="fa fa-trash text-danger action-button"-->
        <!--           aria-hidden="true"></i>-->
    </div>
    <div class="clearfix"></div>
</a>
<ul *ngIf="!collapse && data"
    class="collapse"
    [ngClass]="{'hide': collapse, 'show': !collapse}"
    [ngStyle]="{'padding-bottom': isDraggable && (!isDragging || !isDraggingToReorder)? '10px': '0'}">
    <li *ngFor="let child of data.childCategories"
        [ngStyle]="{'margin-top': isDraggable && (!isDragging || !isDraggingToReorder) && (!hidden ||  hidden.get(child.idCategory) !== hideSearchedKeywordsEnum.Hidden) ? '10px': '0'}">
        <div *ngIf="isDraggable && isDragging && isDraggingToReorder && (!hidden || hidden.get(child.idCategory) !== hideSearchedKeywordsEnum.Hidden)"
             (drop)="drop($event, child, true)"
             (dragover)="dragover($event, child, true)"
             (dragleave)="dragleave()"
             [ngClass]="{'hovered': this.hoveredReorder === child.idCategory}"
             class="reorder-zone">
        </div>
        <categorytree-component [data]="child"
                                [isChild]="true"
                                [isDraggable]="isDraggable"
                                [language]="language"
                                [level]="level+1"
                                [hidden]="hidden"
                                [selected]="selected"
                                [parent]="data.idCategory"
                                [hasEdition]="hasEdition"
                                [hasCheckboxesOnlyForLastChild]="hasCheckboxesOnlyForLastChild"
                                [hasDetails]="hasDetails"
                                [hasCheckboxes]="hasCheckboxes"
                                [hasCarets]="hasCarets"
                                [showStatus]="showStatus"
                                [isRules]="isRules"
                                [isRulesCatalogue]="isRulesCatalogue"
                                (onChange)="childClick()"></categorytree-component>
    </li>
    <li>
        <div *ngIf="isDraggable && isDragging && isDraggingToReorder"
             (drop)="drop($event, null, true)"
             (dragover)="dragover($event, null, true)"
             (dragleave)="dragleave()"
             [ngClass]="{'hovered': this.hoveredReorder === null}"
             class="reorder-zone">
        </div>
    </li>
</ul>
