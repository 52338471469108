import {Component, OnInit, Input, ViewChild, AfterViewInit, Injector, Renderer2} from '@angular/core';
import {
    LanguageDtoInterface,
    ProductYoukadoDtoInterface,
    TranslationProductYoukadoDtoInterface,
    TemplateBtnDtoInterface,
    TranslationDtoInterface
} from 'src/app/shared/interfaces/models/rest';
import {ControlContainer, NgForm} from '@angular/forms';
import {NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {
    ProductFormvalidationInterface
} from "src/app/shared/interfaces/formvalidation/product/product-formvalidation.interface";
import {TemplateDescriptionService} from 'src/app/shared/services/template.description.service';
import {CommonDataService} from "src/app/common-data.service";
import {TranslateService} from "src/app/shared/services/translate.service";
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";

@Component({
    selector: 'app-product-descriptions',
    templateUrl: './product-descriptions.component.html',
    styleUrls: ['./product-descriptions.component.css'],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class ProductDescriptionsComponent extends BaseComponents implements OnInit, AfterViewInit {
    @Input() productYoukado: ProductYoukadoDtoInterface;
    @Input() deliveryCountry: any;
    @Input() indexDescription: number;
    @Input() validationData: ProductFormvalidationInterface;
    deliveryCountriesLanguages: string[] = ["FR", "IT"];
    @ViewChild('tabsdescriptions')
    private tabsDescriptions: NgbTabset;
    @ViewChild('description')
    private descriptionChild: any;

    public templateBtns: TemplateBtnDtoInterface[];
    public translationDto: TranslationDtoInterface;
    public translationSuccess: boolean;
    public translationError: boolean;
    dropdownSettingsLanguages: any = {};
    languagesList: string[] = [];
    selectedLanguagesLabel: string[] = [];
    selectedLanguagesDescription: string[] = [];
    selectedLanguagesTags: string[] = [];
    selectedLanguagesAll: string[] = [];
    showCkeditor: boolean = false;

    constructor(
        private templateBtnService: TemplateDescriptionService,
        private translationService: TranslateService,
        private renderer: Renderer2,
        protected injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {

        setTimeout(() => {
            const style = this.renderer.createElement('style');
            style.textContent = `
        .cke_notifications_area {
          display: none !important;
        }
      `;
            this.renderer.appendChild(document.head, style);
        }, 0);

        for (let productLanguage of this.productYoukado.translationProductYoukados) {
            this.languagesList.push(productLanguage.language.code);
        }

        this.dropdownSettingsLanguages = {
            enableCheckAll: true,
            allowSearchFilter: true,
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.getTemplateBtn();
    }

    ngAfterViewInit(): void {

        if (this.tabsDescriptions != null) {
            this.tabsDescriptions.select(this.deliveryCountry.code);
            this.showCkeditor = true;
        }
    }

    changeTab() {
        if (this.tabsDescriptions != null) {
            this.tabsDescriptions.select(this.deliveryCountry.code);
            this.showCkeditor = true;
        }
    }

    getTemplateBtn(): void {
        this.templateBtnService.getTemplateBtn().subscribe(
            (response) => {
                this.templateBtns = response;
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }

    displayLanguage(language: string) {
        const languagesDeliveryCountry: string[] = this.deliveryCountry.deliveryCountriesLanguages.map((language: LanguageDtoInterface) => language.code);
        return languagesDeliveryCountry.includes(language);
    }

    onChangeTranslationLabel(index: number) {
        let label = this.productYoukado.translationProductYoukados[index].label;

        // Check if label is null, if true, ask user to write one
        if (this.validationData.validationDataDescriptions[index] != null) {
            this.validationData.validationDataDescriptions[index].productLabel = label == "";
        }
    }

    onChangeTranslationDescription(index: number) {
        // Parse ck-editor innerValue to check if body is null
        let descriptionBodyText = $("iframe").contents().find("body").text();

        if ((descriptionBodyText || '').trim().length === 0) {
            this.productYoukado.translationProductYoukados[index].description = "";
        }

        // If body is null, that means description is null then ask user to write one
        if (this.validationData.validationDataDescriptions[index] != null) {
            this.validationData.validationDataDescriptions[index].description = descriptionBodyText == "";
        }
    }

    onClickTemplateButton(btn: any) {
        $("iframe").contents().find("body").append(btn.html);
        if ($("iframe").contents().find("body").html() != null) {
            $("iframe").contents().find("body").html(
                $("iframe").contents().find("body").html().replace("<p><br></p>", ""));
        }
    }

    onChangeTags(translationProductYoukadoDto: TranslationProductYoukadoDtoInterface) {
        let tags = translationProductYoukadoDto.tags;
        this.productYoukado.translationProductYoukados.forEach((trans) => {
            if (translationProductYoukadoDto.language == trans.language) {
                translationProductYoukadoDto.tags = [];
                tags.forEach((tag) => {
                    let obj = JSON.parse(JSON.stringify(tag));
                    if (obj.value != null) {
                        translationProductYoukadoDto.tags.push(obj.value);
                    } else {
                        translationProductYoukadoDto.tags.push(obj);
                    }
                });
            }
        });
    }

    onClickTranslate(index: number, field: String) {
        let isTrad: Boolean = false;

        this.translationDto = {} as TranslationDtoInterface;
        this.translationDto.sourceLang = this.productYoukado.translationProductYoukados[index].language.code;

        switch (field) {
            case 'label':
                if (this.productYoukado.translationProductYoukados[index].label != null &&
                    this.productYoukado.translationProductYoukados[index].label.trim() != "" &&
                    this.selectedLanguagesLabel.length > 0) {
                    this.translationDto.isLabel = true;
                    this.translationDto.label = this.productYoukado.translationProductYoukados[index].label;
                    this.translationDto.selectedLanguages = this.selectedLanguagesLabel;
                    isTrad = true;
                }
                break;
            case 'description':
                if (this.productYoukado.translationProductYoukados[index].description != null &&
                    this.productYoukado.translationProductYoukados[index].description.trim() != "" &&
                    this.selectedLanguagesDescription.length > 0) {
                    this.translationDto.isDescription = true;
                    this.translationDto.description = this.productYoukado.translationProductYoukados[index].description;
                    this.translationDto.selectedLanguages = this.selectedLanguagesDescription;
                    isTrad = true;
                }
                break;
            case 'tags':
                if (this.productYoukado.translationProductYoukados[index].tags != null &&
                    this.selectedLanguagesTags.length > 0) {
                    this.translationDto.isTags = true;
                    this.translationDto.tags = this.productYoukado.translationProductYoukados[index].tags;
                    this.translationDto.selectedLanguages = this.selectedLanguagesTags;
                    isTrad = true;
                }
                break;
            case 'all':
                if (this.selectedLanguagesAll.length > 0) {
                    if (this.productYoukado.translationProductYoukados[index].label != null && this.productYoukado.translationProductYoukados[index].label.trim() != "") {
                        this.translationDto.isLabel = true;
                        this.translationDto.label = this.productYoukado.translationProductYoukados[index].label;
                        isTrad = true;
                    }
                    if (this.productYoukado.translationProductYoukados[index].description != null && this.productYoukado.translationProductYoukados[index].description.trim() != "") {
                        this.translationDto.isDescription = true;
                        this.translationDto.description = this.productYoukado.translationProductYoukados[index].description;
                        isTrad = true;
                    }
                    if (this.productYoukado.translationProductYoukados[index].tags != null) {
                        this.translationDto.isTags = true;
                        this.translationDto.tags = this.productYoukado.translationProductYoukados[index].tags;
                        isTrad = true;
                    }
                    this.translationDto.selectedLanguages = this.selectedLanguagesAll;
                }
                break;
        }

        if (isTrad) {
            this.commondataService.showLoader(true);
            this.translationDto.productYoukado = this.productYoukado;

            this.dealWithStandardObservableSubscription(this.translationService.translate(this.translationDto), {
                success: response => {
                    //console.log("response : " + JSON.stringify(response));
                    this.productYoukado.translationProductYoukados = response.productYoukado.translationProductYoukados;
                    this.translationSuccess = true;
                    this.translationError = false;
                    this.commondataService.showLoader(false);
                },
                error: response => {
                    console.log("error : " + JSON.stringify(response));
                    this.translationSuccess = false;
                    this.translationError = true;
                    this.commondataService.showLoader(false);
                },
            });

        }

    }
}
