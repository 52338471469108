import {Component, Injector, OnInit} from '@angular/core';
import {CommonDataService} from "src/app/common-data.service";
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";

@Component({
  selector: 'app-rule-catalogue',
  templateUrl: './rule-catalogue.component.html',
  styleUrls: ['./rule-catalogue.component.css']
})
export class RuleCatalogueComponent extends BaseComponents implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {

    this.commondataService.showLoader(false);

  }

}
