<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="deliveryCountriesAdd">
    <form id="deliveryCountriesForm" name="deliveryCountriesForm" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        <div class="section-title mb-10">
                            <h3 class="float-left">Ajouter un pays de livraison</h3>
                        </div>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                            (click)="closeModal('deliveryCountriesAdd')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div style="justify-content: center;">
                        <div class="mb-2">
                            <ng-multiselect-dropdown id="deliveryCountries" name="deliveryCountries"
                                                     [settings]="dropdownSettingsDeliveryCountries"
                                                     [(ngModel)]="selectedDeliveryCountries"
                                                     [placeholder]="'Sélectionner pays de livraison'"
                                                     [data]="deliveryCountries">

                                <ng-template #optionsTemplate let-option="option">
                                    <div>
                                        <img [src]="'assets/images/flags/' + option + '.png'"
                                             style="width: 18px; margin-right: 2px;" alt="Drapeau_{{option}}"/>
                                        {{option}}
                                    </div>
                                </ng-template>
                            </ng-multiselect-dropdown>
                            <!-- <p class="error help-block" *ngIf="f.invalid && deliveryCountryToAddVar.errors?.required">
                                Merci de sélectionner un pays de livraison</p> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                            (click)="closeModal('deliveryCountriesAdd')">Fermer
                    </button>
                    <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                            (click)="f.form.valid && addDeliveryCountry(f)">Ajouter
                    </button>
                </div>

            </div>
        </div>
    </form>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="alertSuppliersOutOfStock">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Alert rupture de stock</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="redirectSuppliersTab()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Impossible de publier le produit pour <b>{{deliveryCountry != null ? deliveryCountry.label :
                        ''}}</b>, Tous les fournisseurs n'ont pas de stock
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="redirectSuppliersTab()">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>
<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="alertMarginNegatif">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Alert marge negative</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="redirectMarginNegatif()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Attention : la marge est négative
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="redirectMarginNegatif()">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>

<div *ngIf="!productYoukado.idProductYoukado && !addProduct" class="page-title">
    <div class="row">
        <div class="col-sm-6 mb-4">
            <h4>Aucun produit trouvé</h4>
        </div>
    </div>
</div>

<div *ngIf="productYoukado.idProductYoukado || addProduct">
    <div class="page-title">

        <div class="row">

            <div class="col-sm-3">
                <!--                <ng-container *ngFor="let translation of productYoukado.translationProductYoukados; let index = index;">-->
                <!--                    <h4 *ngIf="translation.language.code==deliveryCountry.code" class="mb-0"> {{translation.label}}</h4>-->
                <!--                </ng-container>-->
                <h4 class="mb-0">{{title}}</h4>
            </div>
            <div class="col-sm-7 country-block">
                <div (click)="changeTab()">
                    <div (click)="changeDeliveryCountry(catalogue.deliveryCountriesCatalogue)"
                         *ngFor="let catalogue of productYoukado.catalogues; let index = index;"
                         style="display: inline-block;">
                    <span *ngIf="catalogue"
                          [ngClass]="{'state-tabs-image-inactive':catalogue.deliveryCountriesCatalogue.code != deliveryCountry.code,'state-tabs-image-active':catalogue.deliveryCountriesCatalogue.code == deliveryCountry.code,'badge-danger': catalogue.state==='declined', 'badge-warning': catalogue.state==='unpublished','badge-primary': catalogue.state==='standby',  'badge-success': catalogue.state==='published', 'badge-info': catalogue.state==='selected', 'badge-secondary':catalogue.state==='new'}"
                          class="badge badge-pill float-right mt-1"
                          style="position: relative;left: -22px;top: -10px;;cursor:pointer;z-index: 1">{{'core.status.' +
                    catalogue.state | translate}}</span>
                        <span
                                [ngClass]="{'country-tabs-image-active': catalogue.deliveryCountriesCatalogue.code == deliveryCountry.code, 'country-tabs-image-inactive': catalogue.deliveryCountriesCatalogue.code != deliveryCountry.code}">
                        <img style="width: 35px;margin: 5px;height:30px;cursor:pointer"
                             src="assets/images/countries/{{catalogue.deliveryCountriesCatalogue.code}}.jpg"
                             alt="Drapeau_{{catalogue.deliveryCountriesCatalogue.code}}">
                    </span>

                        <h6 [ngClass]="{'country-tabs-label': catalogue.deliveryCountriesCatalogue.code == deliveryCountry.code}"
                            style="text-align: center;width: 38px;;cursor:pointer">
                            {{catalogue.deliveryCountriesCatalogue.code}}</h6>
                    </div>
                    <div style="display: inline-block;position: absolute;" ngbTooltip="Ajouter un pays">
                        <a (click)="addDeliveryCountryModal()" href="javascript:void(0);" data-toggle="tooltip"
                           placement="right" title="">
                            <i style="font-size: 26px;margin-right: 2px;height:37px;cursor:pointer"
                               ngbTooltip="Ajouter un fournisseur manuel"
                               class="fa fa-plus text-success action-button"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-sm-2">
                <ol class="breadcrumb pt-0 float-left float-sm-right ">
                    <!--                    <li class="breadcrumb-item"><a href="/" class="default-color">{{'components.layout.home' | translate}}</a></li>-->
                    <!--                    <li class="breadcrumb-item active">{{'components.product.product' | translate}}</li>-->
                    <li class="breadcrumb-item active">
                        <a href="javascript:history.back()" class="default-color">
                            <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i> {{'core.navigation.back' |
                            translate}}
                        </a>
                    </li>
                </ol>
            </div>
        </div>
    </div>

    <!-- main body -->
    <div class="row">
        <div class="col-xl-12 mb-30">
            <div class="card card-statistics h-100">
                <div *ngIf="productYoukado.catalogues != undefined && productYoukado.catalogues != null && productYoukado.catalogues.length != 0"
                     class="card-body">
                    <span *ngIf="catalogue"
                          [ngClass]="{'badge-danger': catalogue.state==='declined', 'badge-warning': catalogue.state==='unpublished','badge-primary': catalogue.state==='standby',  'badge-success': catalogue.state==='published', 'badge-info': catalogue.state==='selected', 'badge-secondary':catalogue.state==='new'}"
                          class="badge badge_state float-right"
                          style="font-size: 1em; float: right">
                        {{'core.status.' + catalogue.state |translate}}
                    </span>
                    <h5>
                        Prix de revient HT
                        <img *ngIf="catalogue" style="width: 25px;height:20px;"
                             src="assets/images/countries/{{catalogue.deliveryCountriesCatalogue.code}}.jpg"
                             alt="Drapeau_{{catalogue.deliveryCountriesCatalogue.code}}">
                        : {{bestProductSupplier != null ? bestProductSupplier.dutyFreeTotalBuyingPrice : ''}}€
                    </h5>
                    <h5 class="text-primary" style="width: fit-content">
                        <span style="color:grey">
                            {{'components.product.tabs.details.ean' | translate}} :
                        </span>
                        <span>
                            {{productYoukado.ean}}
                        </span>
                    </h5>

                    <div style="padding-top: 10px;" class="row">
                        <div class="col-lg-12 col-md-12">
                            <form>
                                <ngb-tabset (tabChange)="beforeChange($event)" #tabs class="tab nav-center">
                                    <ngb-tab id="productImages"
                                             title="{{'components.product.tabs.images-title' | translate}}">
                                        <ng-template ngbTabContent>
                                            <app-product-images [validationData]="validationData"
                                                                [productYoukado]="productYoukado">
                                            </app-product-images>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab id="productDetails"
                                             title="{{'components.product.tabs.details-title' | translate}}">
                                        <ng-template ngbTabContent>
                                            <app-product-details ngModelGroup="productDetailsForm"
                                                                 [catalogue]="catalogue"
                                                                 (checkEanExiste)="checkEanExiste()"
                                                                 [brandsYoukado]="brandsYoukado"
                                                                 [eanCopy]="eanCopy"
                                                                 (openExternal)="openExternal()"
                                                                 [showMessageEanError]="showMessageEanError"
                                                                 [showMessageEanSuccess]="showMessageEanSuccess"
                                                                 [showLoadingEan]="showLoadingEan"
                                                                 [validationData]="validationData"
                                                                 [deliveryCountry]="deliveryCountry"
                                                                 [productYoukado]="productYoukado"
                                                                 [currentCatalogueIndex]="currentCatalogueIndex">
                                            </app-product-details>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab id="suppliers"
                                             title="{{'components.product.tabs.suppliers-title' | translate}}">
                                        <ng-template ngbTabContent>
                                            <app-product-supplier [productSuppliersCopy]="productSuppliersCopy"
                                                                  [validationData]="validationData"
                                                                  [deliveryCountry]="deliveryCountry"
                                                                  (openExternal)="openExternal()"
                                                                  [catalogue]="catalogue"
                                                                  [productYoukado]="productYoukado"
                                                                  (updatedBestProductSupplier)="bestProductSupplier=$event">
                                            </app-product-supplier>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab id="descriptions"
                                             title="{{'components.product.tabs.descriptions-title' | translate}}">
                                        <ng-template ngbTabContent>
                                            <app-product-descriptions #descriptions [validationData]="validationData"
                                                                      [indexDescription]="indexDescription"
                                                                      [deliveryCountry]="deliveryCountry"
                                                                      [productYoukado]="productYoukado">
                                            </app-product-descriptions>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab id="categories"
                                             title="{{'components.product.tabs.categories-title' | translate}}">
                                        <ng-template ngbTabContent>
                                            <app-product-categories [validationData]="validationData"
                                                                    [deliveryCountry]="deliveryCountry"
                                                                    [productYoukado]="productYoukado">
                                            </app-product-categories>
                                        </ng-template>
                                    </ngb-tab>

                                    <ngb-tab *ngIf="productYoukado.idProductYoukado" id="history"
                                             title="Historique">
                                        <ng-template ngbTabContent>
                                            <app-price-history
                                                    [isProduct]="true"
                                                    [deliveryCountry]="deliveryCountry"
                                                    [productYoukado]="productYoukado">
                                            </app-price-history>
                                        </ng-template>
                                    </ngb-tab>

                                </ngb-tabset>

                                <ngb-alert style="text-align: center;" (close)="alertError=false" *ngIf="alertError"
                                           type="danger">
                                    {{'components.product.errors.contactinfo' | translate}}
                                </ngb-alert>
                                <ngb-alert style="text-align: center;" (close)="alertError=false" *ngIf="alertErrorEAN"
                                           type="danger">
                                    {{'components.product.errors.uniqueean.text' | translate}}
                                    <button class="btn-ean-redirect" (click)="goToExistingProduct()">
                                        {{'components.product.errors.uniqueean.button' | translate}}
                                    </button>
                                </ngb-alert>
                                <ngb-alert style="text-align: center;" (close)="alertSuccess=false" *ngIf="alertSuccess"
                                           type="success">
                                    {{'components.product.success' | translate}}
                                </ngb-alert>


                                <ng-container *ngIf="hasAccess">
                                    <div *ngIf="catalogue" id="savingButtons"
                                         class="text-center follow-scroll"
                                    >
                                        <button (click)="save('save')"
                                                class="btn btn-secondary button_space saving-button">
                                            <i class="fa fa-floppy-o" aria-hidden="true"></i>
                                            {{'components.product.save' | translate}}
                                        </button>
                                        <button (click)="save('published')" *ngIf="catalogue.state!=='published'"
                                                class="btn btn-success button_space saving-button">
                                            <i class="fa fa-play-circle" aria-hidden="true"></i>
                                            {{'components.product.publish' | translate}}
                                        </button>
                                        <button (click)="declined('unpublished')"
                                                *ngIf="catalogue.state==='published' || catalogue.state==='standby'"
                                                class="btn btn-warning button_space saving-button">
                                            <i class="fa fa-stop-circle" aria-hidden="true"></i>
                                            {{'components.product.unpublish' | translate}}
                                        </button>
                                        <button (click)="save('standby')"
                                                *ngIf="catalogue.state==='published' || catalogue.state==='unpublished'"
                                                class="btn btn-primary button_space saving-button">
                                            <i class="fa fa-pause-circle" aria-hidden="true"></i>
                                            {{'components.product.standby' | translate}}
                                        </button>
                                        <button (click)="declined('declined')"
                                                *ngIf="catalogue.state==='new' || catalogue.state==='selected'"
                                                class="btn btn-danger button_space saving-button">
                                            <i class="fa fa-ban" aria-hidden="true"></i>
                                            {{'components.product.refuse' | translate}}
                                        </button>
                                        <button (click)="save('selected')"
                                                *ngIf="catalogue.state==='new' || catalogue.state==='declined'"
                                                class="btn btn-info button_space saving-button">
                                            <i class="fa fa-check-circle-o" aria-hidden="true"></i>
                                            {{'components.product.select' | translate}}
                                        </button>
                                        <img class="flag-deliveryCountry"
                                             src="assets/images/countries/{{deliveryCountry.code}}.jpg"
                                             alt="Drapeau_{{deliveryCountry.code}}">
                                    </div>
                                </ng-container>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="declinedReason">
    <div class="modal-dialog modal-lg" *ngIf="productYoukado.catalogues != undefined">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Saisir un motif de refus/dépublication</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="closeModal('declinedReason')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div *ngIf="catalogue" class="mb-2" style="display: grid;width: -webkit-fill-available;">
                        <select required (ngModelChange)="inputReason = null"
                                [(ngModel)]="catalogue.motif" name="motif" id="motif"
                                class="custom-select custom-select-lg mb-3">
                            <option value="" selected></option>
                            <option *ngFor="let motif of allMotifs" value="{{motif.label}}">{{motif.label}}</option>
                            <option value="autre">Autre</option>
                        </select>

                        <div *ngIf="catalogue.motif == 'autre'" class="mb-2"
                             style="display: grid">
                            <label>Inscrire un motif personnalisé</label>
                            <textarea [(ngModel)]="inputReason" style="height: 115%;"></textarea>
                        </div>

                        <em *ngIf="catalogue.motif == undefined ||
                            catalogue.motif == '' ||
                            (catalogue.motif == 'autre' && (inputReason == null || inputReason == ''))"
                            class="error help-block">
                            Veuillez saisir un motif
                        </em>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="closeModal('declinedReason')">
                    Fermer
                </button>
                <button
                        *ngIf="catalogue && !(catalogue.motif == undefined ||
                            catalogue.motif == '' ||
                            (catalogue.motif == 'autre' && (inputReason == null || inputReason == '')))"
                        (click)="save(stateSave)" type="submit" class="btn btn-secondary" data-dismiss="modal">
                    Envoyer
                </button>
            </div>
        </div>
    </div>
</app-model>