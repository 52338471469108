import { Component, OnInit } from '@angular/core';
import { SalePriceService } from "src/app/shared/services/sale-price.service";
import { CommonDataService } from "src/app/common-data.service";
import { EnseignesService } from "src/app/shared/services/enseignes-service";
import { EnseigneDtoInterface, SalePriceDtoInterface } from "src/app/shared/interfaces/models/rest";
import { KeycloakService } from 'keycloak-angular';

@Component({
    selector: 'sale-prices',
    templateUrl: './sale-price.component.html',
    styleUrls: ['./sale-price.component.css']
})
export class SalePriceComponent implements OnInit {

    dropdownSettings: any = {};
    enseignesList: EnseigneDtoInterface[];
    selectedEnseigne: EnseigneDtoInterface[];
    salePriceList: SalePriceDtoInterface[] = [];
    hasAccess: boolean = false;

    constructor(private _commondata: CommonDataService,
        private commondataService: CommonDataService,
        private enseignesService: EnseignesService,
        protected salePriceService: SalePriceService,
        protected keycloakService: KeycloakService) {
    }

    ngOnInit(): void {
        //this.commondataService.showLoader(false);

        this.hasAccess = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_DIRECTION_GENERALE')
        || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_SUPER_ADMIN')
        || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_COMPTABLITE');

        this.dropdownSettings = {
            singleSelection: true,
            allowSearchFilter: true,
            textField: "nomEnseigne",
            idField: "id_ext_enseigne",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.getEnseignes();
    }

    /* Get all sale prices of an enseigne */
    getSalePrices(enseigne: EnseigneDtoInterface) {
        this.salePriceService.getSalePrice({ idEnseigne: enseigne.id_ext_enseigne }).subscribe(
            (response) => {
                if (response.length > 0) {
                    this.salePriceList = response;
                } else {
                    let newSalePrice = {} as SalePriceDtoInterface;
                    newSalePrice.idEnseigne = enseigne.id_ext_enseigne;
                    this.salePriceList = [newSalePrice];
                }
            },
            (error) => {
                console.log("error : ");
                console.log(error);
            }
        );
    }

    /* Get all enseignes from ext_enseigne_operation_fidelite */
    getEnseignes() {
        this.commondataService.showLoader(true);
        this.enseignesService.getEnseignes().subscribe(
            (response) => {
                this.enseignesList = response;
                this.commondataService.showLoader(false);
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
                this.commondataService.showLoader(false);
            }
        );
    }

    /* Add a line in salePriceList */
    addLine(index: number) {
        let newSalePrice = {} as SalePriceDtoInterface;
        newSalePrice.idEnseigne = this.selectedEnseigne[0].id_ext_enseigne;
        this.salePriceList.splice(index, 0, newSalePrice);
    }

    /* Delete a line in salePriceList */
    deleteLine(index: number) {
        this.salePriceList.splice(index, 1);
        if (this.salePriceList.length <= 0) {
            this.salePriceList.push({} as SalePriceDtoInterface);
        }
    }

    /* Save salePriceList to database */
    save() {
        if (this.selectedEnseigne != undefined) {
            this.commondataService.showLoader(true);
            console.log(this.salePriceList);
            this.salePriceService.post(this.salePriceList).subscribe(
                (response) => {
                    /*console.log(response);*/
                    this.commondataService.showLoader(false);
                },
                (error) => {
                    console.log(error);
                    this.commondataService.showLoader(false);
                }
            )
        }
    }
}
