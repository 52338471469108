export const environment = {
    debugging: false,
    production: true,
    apiUrl: "https://marketplace.kalido.fr/api_marketplace",
    keycloak: {
        // Url of the Identity Provider
        issuer: 'https://keycloak.byyoukado.com/auth/',
        // Realm
        realm: 'marketplace-realm',
        clientId: 'webMarketplace',
      },
};
