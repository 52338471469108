<div class="card-body">
    <ng-container *ngIf="card">
        <h3>{{card.libelle}}</h3>
        <div>
            <div *ngFor="let data of card.data; let index = index;">
                <p class="image" *ngIf="icons.length > index">
                    <img src="./assets/images/app/_core/components/charts/{{icons[index]}}.png" alt=""/>
                </p>
                <p class="value">{{data.value}} {{data.unit}}</p>
            </div>
        </div>
        <div style="clear: both;"></div>
        <p class="comment" *ngIf="card.comment">{{card.comment}}</p>
    </ng-container>
</div>
