import {Injectable, Injector} from '@angular/core';
import {SupplierDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {HttpParams} from "@angular/common/http";
import {SupplierSearchModelsInterface} from "src/app/shared/interfaces/models/search/supplier-search-models.interface";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SupplierService extends HttpServiceClass<SupplierDtoInterface> {
    protected endpoint = "suppliers";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: SupplierDtoInterface): number {
        return item.idSupplier;
    }

    searchToParams(search: SupplierSearchModelsInterface): HttpParams {
        let httpParams = new HttpParams();
        if (!search) {
            return httpParams;
        }

        if (search.keywords)
            httpParams = httpParams.append("keywords", search.keywords);
        if (search.page)
            httpParams = httpParams.append("page", search.page);
        if (search.size)
            httpParams = httpParams.append("size", search.size);


        return httpParams;
    }

    getSuppliers(deliveryCountryCode: string, params?: HttpParams) {

        return this.httpClient.get<SupplierDtoInterface[]>(
            this.getPath({path: "supplierDeliveryCountry/" + deliveryCountryCode}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getSupplierDeliveryCountry(supplierId: number, params?: HttpParams) {

        return this.httpClient.get<SupplierDtoInterface[]>(
            this.getPath({path: "SupplierToDeliveryCountries/" + supplierId}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getNextValSequence(params?: HttpParams) {
        return this.httpClient.get<number>(
            this.getPath({ path: "nextVal" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    getSupplierByCode(params?: { supplierCode: string; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getSupplierByCode" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
