import {Component, Input} from '@angular/core';
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {ChartComponent} from "src/app/components/charts/chart.component";


@Component({
    selector: 'tiles-charts-component',
    templateUrl: './tiles-charts.component.html',
    styleUrls: ['./tiles-charts.component.css']
})
export class TilesChartsComponent extends ChartComponent {
    icons: string[] = [];

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        this.card = card;
        if (this.card.icone) {
            this.icons = this.card.icone.split(";");
        }
    }
}
