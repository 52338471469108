import {Injector} from "@angular/core";

import {map} from "rxjs/operators";
import {PageableModelsInterface} from "../../interfaces/models/pageable-models.interface";
import {environment} from "src/environments/environment";
import {JsonUtils} from "../../utils/json.utils";
import {SearchModelInterface} from "../../interfaces/models/search-model.interface";
import {PaginationInterface} from "src/app/shared/interfaces/pagination.interface";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

export abstract class HttpServiceClass<T> {

    protected endpoint: string = undefined;
    protected httpClient: HttpClient;

    protected constructor(injector: Injector) {
        this.httpClient = injector.get(HttpClient);
    }

    protected abstract getId(item: T): number;

    list(params?: HttpParams): Observable<T[]> {
        return this.httpClient.get<T[]>(
            this.getPath(), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    listLite(params?: HttpParams): Observable<T[]> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set("full", false);
        return this.list(params);
    }

    search(params: HttpParams, options?: { pagination?: PaginationInterface }): Observable<PageableModelsInterface<T>> {
        if (!params) {
            params = new HttpParams();
        }
        if (options && options.pagination) {
            if (options.pagination.page)
                params = params.set("page", options.pagination.page - 1);
            if (options.pagination.size)
                params = params.set("size", options.pagination.size);
        }
        return this.httpClient.get<PageableModelsInterface<T[]>>(
            this.getPath({path: "search/findByCriteria"}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    get(id: number, params?: HttpParams): Observable<T> {
        return this.httpClient.get<T>(
            this.getPath({id}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    post(item: T, params?: HttpParams): Observable<T> {
        return this.httpClient.post<T>(
            this.getPath(), item, {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    put(item: T, params?: HttpParams): Observable<T> {
        return this.httpClient.put<T>(
            this.getPath({item}), item, {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    delete(item: T, params?: HttpParams): Observable<T> {
        return this.httpClient.delete<T>(
            this.getPath({item}), {params})
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

    protected getPath(options?: { item?: T, id?: number, search?: SearchModelInterface, path?: string }): string {
        let url = environment.apiUrl + "/" + this.endpoint;

        if (options) {
            if (options.path) {
                url += "/" + options.path;
            } else if (options.item) {
                url += "/" + this.getId(options.item);
            }

            if (options.id != null) {
                url += "/" + options.id;
            }
            if (options.search) {
                let urlAdd = "";
                if (options.search.page != null) {
                    urlAdd += "?page=" + options.search.page;
                }
                if (options.search.size) {
                    urlAdd += (urlAdd !== "" ? "&" : "?") + "size=" + options.search.size;
                }
                if (options.search.sort) {
                    urlAdd += (urlAdd !== "" ? "&" : "?") + "sort=" + options.search.sort;
                }
                if (options.search.filter) {
                    urlAdd += (urlAdd !== "" ? "&" : "?") + "filter=" + options.search.filter;
                }
            }
        }

        return url;
    }
}
