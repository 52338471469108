<div class="page-title">
    <div class="row">
        <div class="col-sm-6 mb-4">
            <h4>{{"components.exports.title" | translate}}</h4>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb pt-0 float-left float-sm-right ">
                <li class="breadcrumb-item active">
                    <a href="javascript:history.back()" class="default-color">
                        <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i> {{'core.navigation.back' |
                        translate}}
                    </a>
                </li>
            </ol>
        </div>
    </div>

</div>

<div class="tab">
    <ngb-tabset #tabsdescriptions style="text-align: left;" class="tab nav-center">
        <ng-container
                *ngFor="let exportsTab of exportsTabs; let index = index;">
            <ngb-tab id="{{exportsTab}}">
                <ng-template ngbTabTitle>
                    {{exportsTab}}
                </ng-template>
                <ng-template ngbTabContent>


                    <div class="card card-statistics mb-30">
                        <div class="card-body">

                            <div class="form-row">

                                <div *ngIf="exportsTab=='Offres' || exportsTab=='Ventes'" class="col-md-2 mb-3">
                                    <label class="control-label"
                                           for="deliveryCountries">Pays de livraison</label>
                                    <div class="mb-2">
                                        <ng-multiselect-dropdown
                                                [settings]="{
                                                    singleSelection: false,
                                                    idField: 'idDeliveryCountry',
                                                    textField: 'code',
                                                    selectAllText: 'Sélectionner tout',
                                                    unSelectAllText: 'Désélectionner tout',
                                                    enableCheckAll: false,
                                                    searchPlaceholderText: 'Rechercher'
                                                }"
                                                id="deliveryCountries"
                                                [placeholder]="'Sélectionner'"
                                                name="supplier"
                                                [data]="availableDeliveryCountries"
                                                [(ngModel)]="deliveryCountriesSelectedItems"
                                                (onSelect)="changeCountry()"
                                        >
                                            <ng-template #optionsTemplate let-option="option">
                                                <div>
                                                    <img [src]="'assets/images/flags/' + option + '.png'"
                                                         style="width: 18px; margin-right: 2px;"/>
                                                    {{'core.countries.' + option.toLowerCase() | translate}}
                                                </div>
                                            </ng-template>

                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Offres' || exportsTab=='Ventes'" class="col-md-2 mb-3">
                                    <label class="control-label"
                                           for="deliveryCountries">Fournisseurs</label>
                                    <div class="mb-2">
                                        <ng-multiselect-dropdown
                                                [settings]="{
                                                    singleSelection: false,
                                                    idField: 'idSupplier',
                                                    textField: 'label',
                                                    selectAllText: 'Sélectionner tout',
                                                    unSelectAllText: 'Désélectionner tout',
                                                    enableCheckAll: false,
                                                    allowSearchFilter: true,
                                                    searchPlaceholderText: 'Rechercher'
                                                }"
                                                id="suppliers"
                                                [placeholder]="'Sélectionner'"
                                                name="supplier"
                                                [data]="availableSuppliers"
                                                [(ngModel)]="suppliersSelectedItems">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Offres' " class="col-md-2 mb-3">
                                    <label class="control-label"
                                           for="publicationState">Etat de publication</label>
                                    <div class="mb-2">
                                        <ng-multiselect-dropdown
                                                [settings]="{
                                                    singleSelection: false,
                                                    idField: 'code',
                                                    textField: 'label',
                                                    selectAllText: 'Sélectionner tout',
                                                    unSelectAllText: 'Désélectionner tout',
                                                    enableCheckAll: true,
                                                    searchPlaceholderText: 'Rechercher'
                                                }"
                                                id="publicationState"
                                                [placeholder]="'Sélectionner'"
                                                name="supplier"
                                                [data]="availablePublicationState"
                                                [(ngModel)]="publicationStateSelectedItems">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Offres' " class="col-md-2 mb-3">
                                    <label class="control-label">
                                        <b>Intervalle date enrichissement</b>
                                    </label>
                                </div>

                                <div *ngIf="exportsTab=='Offres' " class="col-md-2 mb-3">
                                    <label class="control-label" for="publicationStartDate">
                                        Du
                                    </label>
                                    <div class="input-group">
                                        <input id="publicationStartDate" class="form-control" placeholder="AAAA-MM-JJ"
                                               name="d1"
                                               #c1="ngModel" [(ngModel)]="publicationStartDate" ngbDatepicker
                                               #d1="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" (click)="d1.toggle()" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Offres'" class="col-md-2 mb-3">
                                    <label class="control-label" for="publicationEndDate">
                                        Au
                                    </label>
                                    <div class="input-group">
                                        <input id="publicationEndDate" class="form-control" placeholder="AAAA-MM-JJ"
                                               name="d1"
                                               #c1="ngModel" [(ngModel)]="publicationEndDate" ngbDatepicker
                                               #d1="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" (click)="d1.toggle()" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Offres'" class="col-md-2 remember-checkbox">
                                    <input id="generic"
                                           [(ngModel)]="generic"
                                           type="checkbox"
                                           class="form-control">
                                    <label for="generic">Générique</label>
                                </div>

                                <div *ngIf="exportsTab=='Offres'" class="col-md-2 remember-checkbox">
                                    <input id="specific"
                                           [(ngModel)]="specific"
                                           type="checkbox"
                                           class="form-control">
                                    <label for="specific">Spécifique</label>
                                </div>


                                <div class="col-md-2">
                                    <label class="control-enseigne" for="enseigne">
                                        Enseigne
                                    </label>
                                    <div class="mb-2">
                                        <ng-multiselect-dropdown id="enseigne"
                                                                 [settings]="{
                                                    singleSelection: false,
                                                    idField: 'nomEnseigne',
                                                    textField: 'nomEnseigne',
                                                    selectAllText: 'Sélectionner tout',
                                                    unSelectAllText: 'Désélectionner tout',
                                                    enableCheckAll: false,
                                                    allowSearchFilter: true,
                                                    searchPlaceholderText: 'Rechercher'
                                                }"
                                                                 [(ngModel)]="selectedEnseigne"
                                                                 [placeholder]="'Sélectionner'"
                                                                 name="enseigne" [data]="enseignesList"
                                                                 >
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Ventes'" class="col-md-1 mb-3">
                                    <label class="control-label">
                                        <b>Intervalle date commande</b>
                                    </label>
                                </div>

                                <div *ngIf="exportsTab=='Ventes' " class="col-md-2 mb-3">
                                    <label class="control-label" for="orderStartDate">
                                        Du
                                    </label>
                                    <div class="input-group">
                                        <input id="orderStartDate" class="form-control" placeholder="AAAA-MM-JJ"
                                               name="d1"
                                               #c1="ngModel" [(ngModel)]="orderStartDate" ngbDatepicker
                                               #d1="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" (click)="d1.toggle()" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Ventes' " class="col-md-2 mb-3">
                                    <label class="control-label" for="orderEndDate">
                                        Au
                                    </label>
                                    <div class="input-group">
                                        <input id="orderEndDate" class="form-control" placeholder="AAAA-MM-JJ" name="d1"
                                               #c1="ngModel" [(ngModel)]="orderEndDate" ngbDatepicker
                                               #d1="ngbDatepicker">
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" (click)="d1.toggle()" type="button">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 mb-3">
                                    <label class="control-label"
                                           for="Langues">Langue produit</label>
                                    <div class="mb-2">

                                <ng-multiselect-dropdown
                                    [settings]="{
                                        singleSelection: true,
                                        idField: 'idLanguage',
                                        textField: 'label',
                                        selectAllText: 'Sélectionner tout',
                                        unSelectAllText: 'Désélectionner tout',
                                        enableCheckAll: true,
                                        searchPlaceholderText: 'Rechercher'
                                    }"
                                        style="padding-right: 10px;width:75%"
                                        [(ngModel)]="selectedLanguagesProduit"
                                        [data]="languages"
                                        [placeholder]="'Sélectionner une langues'"
                                        id="languagesAllProduit" name="languagesAllProduit">
                                    </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div class="col-md-3 mb-3">
                                    <label class="control-label"
                                           for="Langues">Langue catégorie</label>
                                    <div class="mb-2">

                                        <ng-multiselect-dropdown
                                                [settings]="{
                                        singleSelection: true,
                                        idField: 'idLanguage',
                                        textField: 'label',
                                        selectAllText: 'Sélectionner tout',
                                        unSelectAllText: 'Désélectionner tout',
                                        enableCheckAll: true,
                                        searchPlaceholderText: 'Rechercher'
                                    }"
                                                style="padding-right: 10px;width:75%"
                                                [(ngModel)]="selectedLanguagesCategorie"
                                                [data]="languages"
                                                [placeholder]="'Sélectionner une langues'"
                                                id="languagesAllCategorie" name="languagesAllCategorie">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div *ngIf="exportsTab=='Ventes'"  class="col-md-3 mb-3">
                                    <label class="control-label"
                                           for="idCategory">Catégorie</label>
                                    <div class="mb-2">
                                        <ng-multiselect-dropdown
                                                [settings]="dropDownCategorySettings"
                                                style="padding-right: 10px;width:75%"
                                                [(ngModel)]="currentCategory"
                                                [data]="dropdownListCategory"
                                                [disabled]="!categories || categories.length <= 1"
                                                [placeholder]="'Sélectionner une catégorie'"
                                                placeholder="Categorie"
                                                id="idCategory" name="idCategory"
                                                class="card">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-statistics mb-30">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">

                                        <div *ngFor="let export of export(exportsTab); index as columnIndex;"
                                             class=" col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-30">

                                            <button (click)="getExport(export.id, export.fileName, exportsTab)"
                                                    class="button black medium">
                                                <i class="fa  fa-2x mr10"
                                                   [ngClass]="{ 'fa-file-excel-o': export.type == 'xslx', 'fa-file-pdf-o': export.type == 'pdf' }"></i>
                                                {{export.libelle}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </ng-template>
            </ngb-tab>
        </ng-container>
    </ngb-tabset>
</div>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="showPopupDeliveryCountry">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="close('showPopupDeliveryCountry')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Merci de sélectionner un pays de livraison
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="close('showPopupDeliveryCountry')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="showLanguages">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="close('showLanguages')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Merci de sélectionner une langue
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="close('showLanguages')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="showPopupStatePublication">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="close('showPopupStatePublication')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Merci de sélectionner un état de publication
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="close('showPopupStatePublication')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="orderInterval">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="close('orderInterval')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Merci de sélectionner un intervalle de date de commande
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="close('orderInterval')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>

