import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PageableModelsInterface} from "src/app/shared/interfaces/models/pageable-models.interface";
import {CatalogueDtoInterface} from "src/app/shared/interfaces/models/rest";
import {CategorytreeExtendedModelsInterface} from "src/app/shared/interfaces/models/extended/categorytree-extended-models.interface";

@Injectable({
    providedIn: 'root'
})
export class SyncCatalogService {
    onSearch: Subject<{ results: PageableModelsInterface<CatalogueDtoInterface>, deliveryCountries: string[], perform: boolean }> = new Subject();
    onPaginate: Subject<{ page: number, size?: number }> = new Subject();
    onSearchCategory: Subject<CategorytreeExtendedModelsInterface> = new Subject();

    syncSearch(opt: { results: PageableModelsInterface<CatalogueDtoInterface>, deliveryCountries: string[], perform: boolean }): void {
        this.onSearch.next(opt);
    }

    syncPaginate(pagination: { page: number, size?: number }): void {
        this.onPaginate.next(pagination);
    }

    syncSearchCategory(category: CategorytreeExtendedModelsInterface): void {
        this.onSearchCategory.next(category);
    }
}
