<div class="card-body">
    <div *ngIf="card?.icone"
         class="icon"
         [ngStyle]="{'background-image': 'url(\'./assets/images/app/_core/components/charts/' + card.icone+'.png\')'}"></div>
    <div *ngIf="!card?.icone && card?.url"
         class="icon square"
         [ngStyle]="{'background-image': 'url(\'' + card.url+'\')'}"></div>
    <div *ngIf="!card?.icone && card?.urlBackground"
         class="icon round"
         [ngStyle]="{'background-image': 'url(\'' + card.urlBackground+'\')'}"></div>
    <div class="content">
        <div *ngIf="card"
             [ngClass]="{'middle' : !card.libelle}">
            <h3 *ngIf="card.libelle" class="specific-color-font underlined">{{card.libelle}}</h3>
            <p class="main"
               [ngClass]="{'small' : card.unit?.length > 6 && card.comment}">
                {{card.value | number}}
                <span *ngIf="card.unit?.length <= 6"> {{card.unit}}</span>
                <!--                    <ng-container *ngIf="(!card.unit?.length || card.unit?.length > 6) && card.detail?.length">-->
                <ng-container *ngFor="let detail of card.detail">
                            <span *ngIf="detail.unit === '%'"
                                  [ngClass]="{'green': detail.value > 0, 'red': detail.value < 0}">
                                {{detail.value > 0 ? "+" : ""}}{{detail.value | number : '1.0-2' }} %
                            </span>
                    <span *ngIf="detail.unit === '% comparatif'"
                          [ngClass]="{'green': detail.value > 100, 'red': detail.value < 100}">
                                {{detail.value | number : '1.0-2' }} %
                            </span>
                </ng-container>
                <!--                    </ng-container>-->
            </p>
            <p class="unit" *ngIf="card.unit?.length > 6">{{card.unit}}</p>
            <p class="comment" *ngIf="card.comment">{{card.comment}}</p>
            <p class="shortDetail"
               *ngIf="(!card.unit || card.unit.length <= 6) && !card.comment && card.detail?.length > 0">
                {{card.detail[0].value | number}} {{card.detail[0].unit}}
            </p>
        </div>
    </div>
    <div *ngIf="card?.downloadable"
         class="download"
         (click)="export()"
         [ngClass]="{'exporting':exporting}">
    </div>
</div>
