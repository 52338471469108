import {isNumeric} from 'rxjs/util/isNumeric';
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {ChartInterface} from "src/app/shared/interfaces/chart.interface";

export class ChartComponent implements ChartInterface {
    card: CardApiClass;
    loading: number = 0;

    isNumericValue(value: any): boolean {
        return isNumeric(value);
    }
}
