import {Injectable, Injector} from '@angular/core';


import {map} from "rxjs/operators";
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class CardService extends HttpServiceClass<CardApiClass> {
    protected endpoint = 'cards';

    constructor(protected injector: Injector) {
        super(injector);
    }

    // propriety_315
    // http://localhost:8080/FidWeb/api/admin/cards/30035?year=2021&15=CGED LILLE'

    // todo remove override - onglet should be numeric
    list(params?: HttpParams): Observable<CardApiClass[]> {
        return this.httpClient.get<CardApiClass[]>(
            this.getPath(), {params})
            .pipe(map(data => JsonUtils.parseDates(data)))
            .pipe(map((data: any) => {
                data.forEach((item: { onglet: number; }) => {
                    // @ts-ignore
                    item.onglet = parseInt(item.onglet);
                })
                return data;
            }));
    }

    protected getId(item: CardApiClass): number {
        return 0;
    }

}
