import {Injectable, Injector} from '@angular/core';
import {DeliveryCountryDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";

@Injectable({
    providedIn: 'root'
})
export class DeliverycountryService extends HttpServiceClass<DeliveryCountryDtoInterface> {
    protected endpoint = "deliverycountries";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: DeliveryCountryDtoInterface): number {
        return item.idDeliveryCountry;
    }

    getDeliveryCountries(params?: HttpParams) {
        return this.httpClient.get<DeliveryCountryDtoInterface[]>(
                this.getPath({path: ""}), {params}
            ).pipe(map(data => JsonUtils.parseDates(data)));
    }
}
