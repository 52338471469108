import {Injectable, Injector} from '@angular/core';
import {
    ExpeditionCountryDtoInterface
} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";

@Injectable({
    providedIn: 'root'
})
export class ExpeditioncountryService extends HttpServiceClass<ExpeditionCountryDtoInterface> {
    protected endpoint = "expeditioncountries";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: ExpeditionCountryDtoInterface): number {
        return item.idExpeditionCountry;
    }

    getExpeditionCountries(params?: HttpParams) {
        return this.httpClient.get<ExpeditionCountryDtoInterface[]>(
            this.getPath({path: ""}), {params}
        ).pipe(map(data => JsonUtils.parseDates(data)));
    }

}
