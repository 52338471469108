export class JsonUtils {
    static parseDates<T>(data: any): any {
        if (!data) {
            return data;
        }
        if (data instanceof Object) {
            for (const k in data) {
                if (data.hasOwnProperty(k)) {
                    data[k] = JsonUtils.parseDates(data[k]);
                }
            }
        } else {
            if (typeof data === "string" && data.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
                return new Date(data);
            }
        }
        return data;
    }
}
