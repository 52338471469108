<div class="container-fluid tabs-container row">
    <div class="col-lg-3 col-md-4 col-sm-6">
        <select #deliveryCountry="ngModel" [(ngModel)]="currentDeliveryCountrie2"
                (ngModelChange)="emitValues()" name="deliveryCountry" id="deliveryCountry"
                class="custom-select custom-select-lg mb-3 card">
            <option *ngFor="let deliveryCountry of deliveryCountries" [ngValue]= deliveryCountry>
                {{ deliveryCountry.label
                | translate}}
            </option>
        </select>
    </div>
<!--    <div class="col-lg-3 col-md-4 col-sm-6">-->
<!--        <ng-multiselect-dropdown (onSelect)="deliveryCountrieChanged($event)"-->
<!--                                 (onDeSelect)="deliveryCountrieChanged(null)"-->
<!--                                 [(ngModel)]="currentDeliveryCountrie"-->
<!--                                 [settings]="dropDownSettings"-->
<!--                                 [data]="deliveryCountries"-->
<!--                                 [disabled]="!deliveryCountries || deliveryCountries.length <= 1"-->
<!--                                 placeholder="Pays"-->
<!--                                 class="card">-->
<!--        </ng-multiselect-dropdown>-->
<!--    </div>-->
    <div class="col-lg-3 col-md-4 col-sm-6">
        <ng-multiselect-dropdown (onSelect)="emitValues()"
                                 (onDeSelect)="emitValues()"
                                 [(ngModel)]="currentCategory"
                                 [settings]="dropDownCategorySettings"
                                 [data]="dropdownListCategory"
                                 [disabled]="!categories || categories.length <= 1"
                                 [placeholder]="'Sélectionner une catégorie'"
                                 placeholder="Categorie"
                                 class="card">
        </ng-multiselect-dropdown>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-6">
        <ng-multiselect-dropdown
                                 (onSelect)="emitValues()"
                                 (onSelectAll)="emitValues()"
                                 (onDeSelect)="emitValues()"
                                 (onDeSelectAll)="emitValues()"
                                 [(ngModel)]="typeCategory"
                                 [settings]="dropdownTypeCategorySettings"
                                 [data]="dropdownListTypeCategory"
                                 [placeholder]="'Sélectionner une catégorie'"
                                 id="languagesLabel"
                                 name="languagesLabel"
                                 class="card">
        </ng-multiselect-dropdown>
    </div>

       <!-- <div class="col-lg-3 col-md-4 col-sm-6">
            <ng-multiselect-dropdown (onSelect)="regionChanged($event)"
                                     (onDeSelect)="regionChanged(null)"
                                     [(ngModel)]="currentRegion"
                                     [settings]="dropDownSettings"
                                     [data]="regions"
                                     [disabled]="!regions || regions.length <= 1"
                                     placeholder="Region"
                                     class="card">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <ng-multiselect-dropdown (onSelect)="doChanged($event)"
                                     (onDeSelect)="doChanged(null)"
                                     [(ngModel)]="currentDo"
                                     [settings]="dropDownSettings"
                                     [data]="dosFiltered"
                                     [disabled]="!dosFiltered || dosFiltered.length <= 1"
                                     placeholder="DO"
                                     class="card">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <ng-multiselect-dropdown (onSelect)="agencyChanged($event)"
                                     (onDeSelect)="agencyChanged(null)"
                                     [(ngModel)]="currentAgency"
                                     [settings]="dropDownSettings"
                                     [data]="agenciesFiltered"
                                     [disabled]="!agenciesFiltered || agenciesFiltered.length <= 1"
                                     placeholder="Agence"
                                     class="card">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
            <ng-multiselect-dropdown (onSelect)="atcChanged($event)"
                                     (onDeSelect)="atcChanged(null)"
                                     [(ngModel)]="currentAtc"
                                     [settings]="dropDownSettings"
                                     [data]="atcsFiltered"
                                     [disabled]="true"
                                     placeholder="ATC"
                                     class="card">
            </ng-multiselect-dropdown>
        </div>-->
</div>

<!--<div style="display: flex;" *ngIf="idEnseigne === 295">-->
<!--    <ng-multiselect-dropdown (onSelect)="regionChanged($event)"-->
<!--                             (onDeSelect)="regionChanged(null)"-->
<!--                             [(ngModel)]="currentRegion"-->
<!--                             [settings]="dropDownSettings"-->
<!--                             [data]="regions"-->
<!--                             [disabled]="!regions || regions.length <= 1"-->
<!--                             placeholder="Region"-->
<!--                             class="card"-->
<!--                             style="margin:30px 0 0 30px; width: 25%;">-->
<!--    </ng-multiselect-dropdown>-->
<!--    <ng-multiselect-dropdown (onSelect)="doChanged($event)"-->
<!--                             (onDeSelect)="doChanged(null)"-->
<!--                             [(ngModel)]="currentDo"-->
<!--                             [settings]="dropDownSettings"-->
<!--                             [data]="dosFiltered"-->
<!--                             [disabled]="!dosFiltered || dosFiltered.length <= 1"-->
<!--                             placeholder="DO"-->
<!--                             class="card"-->
<!--                             style="margin:30px 0 0 30px; width: 25%;">-->
<!--    </ng-multiselect-dropdown>-->
<!--    <ng-multiselect-dropdown (onSelect)="agencyChanged($event)"-->
<!--                             (onDeSelect)="agencyChanged(null)"-->
<!--                             [(ngModel)]="currentAgency"-->
<!--                             [settings]="dropDownSettings"-->
<!--                             [data]="agenciesFiltered"-->
<!--                             [disabled]="!agenciesFiltered || agenciesFiltered.length <= 1"-->
<!--                             placeholder="Agence"-->
<!--                             class="card"-->
<!--                             style="margin:30px 0 0 30px; width: 25%;">-->
<!--    </ng-multiselect-dropdown>-->
<!--    <ng-multiselect-dropdown (onSelect)="atcChanged($event)"-->
<!--                             (onDeSelect)="atcChanged(null)"-->
<!--                             [(ngModel)]="currentAtc"-->
<!--                             [settings]="dropDownSettings"-->
<!--                             [data]="atcsFiltered"-->
<!--                             [disabled]="true"-->
<!--                             placeholder="ATC"-->
<!--                             class="card"-->
<!--                             style="margin:30px 30px 0 30px; width: 25%;">-->
<!--    </ng-multiselect-dropdown>-->
<!--    &lt;!&ndash;    [disabled]="!atcsFiltered || atcsFiltered.length <= 1"&ndash;&gt;-->
<!--</div>-->

<!--    <div style="display: flex;" *ngIf="idEnseigne === 388">-->
<!--        <ng-multiselect-dropdown (onSelect)="atcChanged($event)"-->
<!--                                 (onDeSelect)="atcChanged(null)"-->
<!--                                 [(ngModel)]="currentAtc"-->
<!--                                 [settings]="dropDownSettings"-->
<!--                                 [data]="atcsFiltered"-->
<!--                                 [disabled]="true"-->
<!--                                 placeholder="ATC"-->
<!--                                 class="card"-->
<!--                                 style="margin:30px 0 0 30px; width: 20%;">-->
<!--        </ng-multiselect-dropdown>-->

<!--        <div class="card form-group" style="margin:30px 0 0 30px; width: 20%;">-->
<!--            <div class="input-group">-->
<!--                <input #d1="ngbDatepicker"-->
<!--                       id="dp1"-->
<!--                       class="form-control date-picker"-->
<!--                       placeholder="yyyy-mm-dd"-->
<!--                       name="dp1"-->
<!--                       ngbDatepicker>-->
<!--                <div class="input-group-append">-->
<!--                    <button class="btn btn-secondary"-->
<!--                            (click)="d1.toggle()"-->
<!--                            style="font-size: 13px;"-->
<!--                            type="button">-->
<!--                        <i class="fa fa-calendar"></i>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="card form-group" style="margin:30px 0 0 30px; width: 20%;">-->
<!--            <div class="input-group">-->
<!--                <input #d2="ngbDatepicker"-->
<!--                       id="dp2"-->
<!--                       class="form-control date-picker"-->
<!--                       placeholder="yyyy-mm-dd"-->
<!--                       name="dp1"-->
<!--                       ngbDatepicker>-->
<!--                <div class="input-group-append">-->
<!--                    <button class="btn btn-secondary"-->
<!--                            (click)="d2.toggle()"-->
<!--                            style="font-size: 13px;"-->
<!--                            type="button">-->
<!--                        <i class="fa fa-calendar"></i>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="card form-group" style="margin:30px 0 0 30px; width: 20%;">-->
<!--            <div class="input-group">-->
<!--                <input #d3="ngbDatepicker"-->
<!--                       id="dp3"-->
<!--                       class="form-control date-picker"-->
<!--                       placeholder="yyyy-mm-dd"-->
<!--                       name="dp1"-->
<!--                       ngbDatepicker>-->
<!--                <div class="input-group-append">-->
<!--                    <button class="btn btn-secondary"-->
<!--                            (click)="d3.toggle()"-->
<!--                            style="font-size: 13px;"-->
<!--                            type="button">-->
<!--                        <i class="fa fa-calendar"></i>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="card form-group" style="margin:30px 30px 0 30px; width: 20%;">-->
<!--            <div class="input-group">-->
<!--                <input #d4="ngbDatepicker"-->
<!--                       id="dp4"-->
<!--                       class="form-control date-picker"-->
<!--                       placeholder="yyyy-mm-dd"-->
<!--                       name="dp1"-->
<!--                       ngbDatepicker>-->
<!--                <div class="input-group-append">-->
<!--                    <button class="btn btn-secondary"-->
<!--                            (click)="d4.toggle()"-->
<!--                            style="font-size: 13px;"-->
<!--                            type="button">-->
<!--                        <i class="fa fa-calendar"></i>-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
