<div class="card-body">
    <ng-container *ngIf="card">
        <h3>{{card.libelle}}</h3>
        <googlemaps-component (onReady)="googlemapsComponentReady($event)"
                              (onMarkerClicked)="markerClick($event)"
                              (onPolygonClicked)="polygonClick($event)"
                              [ngClass]="{'blurry':showPopup}">
        </googlemaps-component>
        <p class="comment" *ngIf="card.comment">{{card.comment}}</p>
        <div class="map-charts-overlay" [ngClass]="{'shown':showPopup}" (click)="hidePopup()"></div>
        <div class="map-charts-popup-container" *ngIf="showPopup && popupData">
            <div class="map-charts-popup">
                <div class="map-charts-popup-content">
                    <h3>{{popupData.name}}</h3>
                    <div class="map-charts-popup-content-gauge left">
                        <div>
                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                            <h4>Clients éligibles</h4>
                            <p>{{popupData.total | number}}</p>
                        </div>
                    </div>
                    <!--                toosmall < 40% -->
                    <!--                <div class="map-charts-popup-content-gauge right">-->
                    <div class="map-charts-popup-content-gauge right"
                         [style.height.px]="popupData.fillPercent * 2.34"
                         [ngClass]="{'toosmall':popupData.fillPercent < 40}">
                        <div>
                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                            <h4>Taux d'inscription</h4>
                            <p>{{popupData.percent | number:'1.0-2'}} %</p>
                        </div>
                    </div>
                    <div class="map-charts-popup-content-item">
                        <img src="assets/images/app/_core/components/charts/map/lightbulb-{{popupData.fillPercent}}.png"
                             alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
