import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {CategoryService} from "src/app/shared/services/category.service";
import {any, debounce, forEach} from "underscore";
import {ObjectUtils} from "src/app/shared/utils/object.utils";

import {CategorytreeUtils} from "src/app/shared/utils/categorytree.utils";
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {combineLatest} from "rxjs/internal/observable/combineLatest";
import {
    CategoryDtoInterface, DeliveryCountryDtoInterface,
    LanguageDtoInterface,
    TranslationCategoryDtoInterface,
    EnseigneDtoInterface,
    TranslationDtoInterface,
    PropertyDtoInterface,
    CategoryToEnseigneDtoInterface, CategoryAndEnseigneDtoInterface, ProductYoukadoDtoInterface
} from "src/app/shared/interfaces/models/rest";
import {SyncCatalogService} from "src/app/shared/services/sync-catalog.service";
import {ModalService} from "src/app/shared/services";
import {LanguageService} from "src/app/shared/services/language.service";
import {DeliverycountryService} from "src/app/shared/services/deliverycountry.service";
import {EnseignesService} from 'src/app/shared/services/enseignes-service';
import {CommonDataService} from 'src/app/common-data.service';
import {TranslateService} from "src/app/shared/services/translate.service";
import {HttpParams} from "@angular/common/http";
import {SalePriceService} from "src/app/shared/services/sale-price.service";
import {ProductyoukadoService} from "src/app/shared/services/productyoukado.service";
import {CatalogService} from "src/app/shared/services/catalog.service";
import {ProductSearchModelsInterface} from "src/app/shared/interfaces/models/search/product-search-models.interface";
import {PaginationInterface} from "src/app/shared/interfaces/pagination.interface";
import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";
import {PaginationUtils} from "src/app/shared/utils/pagination.utils";
import {KeycloakService} from 'keycloak-angular';
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";

@Component({
    selector: 'category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends BaseComponents implements OnInit {

    formGroup: UntypedFormGroup;
    formGroupSearch: UntypedFormGroup;
    category: CategoryDtoInterface;
    editCategoryParent: CategoryDtoInterface;
    productsYoukadoPageable: PaginationbarInterface;
    languages: LanguageDtoInterface[] = [];
    deliveryCountries: DeliveryCountryDtoInterface[] = [];
    languagesFilled: Map<number, boolean> = new Map();
    language: LanguageDtoInterface = null;
    searchCriteria: { keywords?: string, main?: boolean, specific?: boolean, service?: boolean, animation?: boolean, type?: string };
    categories: CategoryDtoInterface;
    enseignes: EnseigneDtoInterface[];
    enseignesSelected: EnseigneDtoInterface[] = [];
    dropdownSettingsEnseignes: any = {};
    dropdownSettingsDeliveryCountries: any = {};
    dropdownSettingsForWho: any = {};
    enseignesInclusion: any = {};
    enseignesExlusion: any = {};
    deliveryCountriesInclusionValue: any = {};
    deliveryCountriesExlusionValue: any = {};
    translationDto: TranslationDtoInterface;
    emptyLabel: boolean = false;
    isChecked: boolean = false;
    dropdownSettingsLanguages: any = {};
    languagesList: string[] = [];
    selectedLanguages: string[] = [];
    showEnseigne: Map<string, boolean> = new Map();
    isDisabled: boolean = false;
    currentPage: number = 1;
    dropdownListTypeCategory: any[] = [
        { item_id: 'Personnelle', item_text: 'Loisir' },
        { item_id: 'Professionnelle', item_text: 'Professionnelle' },
    ];
    dropDownCategorySettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        allowSearchFilter: false,
        enableCheckAll: false
    };
    currentTypeCategory: any[] = [];
    availableTypes = [
        "Personnelle",
        "Professionnelle",
        "Évènement",
        "Avantage"
    ];

    /* Products to category */
    @ViewChild('categoryTabset')
    categoryTabset: any;
    alreadyLoaded: boolean = false;
    productToCheck: string;
    products: ProductYoukadoDtoInterface[];
    productsToCategory: any[] = [];
    productsLinkedToCategory: any[] = [];
    productsLinkedToCategoryToDelete: any[] = [];
    wrongCodeErrorMessage: boolean = false;
    codeAlreadyInListErrorMessage: boolean = false;
    duplicatedCodes: string[] = [];
    undefinedCodes: string[] = [];
    hasAccess: boolean = false;

    constructor(protected categoryService: CategoryService,
                protected salePriceService: SalePriceService,
                protected formBuilder: UntypedFormBuilder,
                protected modalService: ModalService,
                protected languageService: LanguageService,
                protected deliverycountryService: DeliverycountryService,
                protected syncCatalogService: SyncCatalogService,
                private enseignesService: EnseignesService,
                protected productYoukadoService: ProductyoukadoService,
                private _commondata: CommonDataService,
                private translationService: TranslateService,
                protected catalogService: CatalogService,
                protected injector: Injector,
                private keycloakService: KeycloakService) {
        super(injector);
        this.search = debounce(this.search, 300);

        this.subscriptions.push(this.translateService.onLangChange.subscribe(() => {
            this.language = this.languages.find(item => item.code.toUpperCase() === this.translateService.currentLang.toUpperCase());
        }));
    }

    ngOnInit(): void {

        this.hasAccess = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_DIRECTION_GENERALE')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_SUPER_ADMIN')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_ACHATS');

        this.commondataService.showLoader(true);
        this.subscriptions.push(combineLatest([
            this.categoryService.listTree(),
            this.languageService.listLite(),
            this.deliverycountryService.listLite(),
            this.enseignesService.getEnseignes()
        ]).subscribe(results => {
            this.categories = CategorytreeUtils.generateTree(results[0], true);
            this.languages = results[1].sort((a, b) => a.label < b.label ? -1 : 1);
            this.languages.sort((a, b) => a.order < b.order ? -1 : 1);
            for (let language of this.languages) {
                this.languagesList.push(language.code);
            }
            this.deliveryCountries = results[2].sort((a, b) => a.label < b.label ? -1 : 1);
            this.enseignes = results[3];
            this.language = this.languages.find(item => item.code === "FR");
            this.search();
            this.commondataService.showLoader(false);
        }, error => {
            this.dealWithStandardError(error);
            this.commondataService.showLoader(false);
        }));

        // Set ng multiselect dropdown settings
        this.dropdownSettingsEnseignes = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "nomEnseigne",
            idField: "id_ext_enseigne",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsDeliveryCountries = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsForWho = {
            enableCheckAll: false,
            allowSearchFilter: true,
            textField: "label",
            idField: "label",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };
        this.dropdownSettingsLanguages = {
            enableCheckAll: true,
            allowSearchFilter: true,
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        if (!this.formGroup) {
            this.formGroup = this.formBuilder.group({
                code: new UntypedFormControl('', [Validators.required]),
                orderForPage: new UntypedFormControl('', [Validators.required]),
                orderForBanner: new UntypedFormControl('', [Validators.required]),
                translations: this.formBuilder.array([
                    this.formBuilder.group({
                        languageCode: new UntypedFormControl('', [Validators.required]),
                        label: new UntypedFormControl(''),
                        labelYoukado: new UntypedFormControl(''),
                        banner: new UntypedFormControl(''),
                        subBanner: new UntypedFormControl('')
                    })
                ]),
                type: new UntypedFormControl('', [Validators.required]),
                deliveryCountries: new UntypedFormControl(''),
                deliveryCountriesInclusionType: new UntypedFormControl(''),
                enseignes: new UntypedFormControl(''),
                enseignesInclusion: new UntypedFormControl(''),
                enseignesExlusion: new UntypedFormControl(''),
                deliveryCountriesInclusion: new UntypedFormControl(''),
                deliveryCountriesExlusion: new UntypedFormControl(''),
                main: new UntypedFormControl(''),
                specific: new UntypedFormControl(''),
                service: new UntypedFormControl(''),
                disableSalePriceEnseigne: new UntypedFormControl(''),
                animation: new UntypedFormControl(''),
                archive: new UntypedFormControl('')
            });
        }

        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchTypeCategory: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
                searchFilter: new UntypedFormControl('searchMain'),
            });
        }
    }

    get getTranslationsControls(): AbstractControl[] {
        return (this.formGroup.controls.translations as UntypedFormArray).controls;
    }

    getAbstractControllerAsFromGroup(item: AbstractControl): UntypedFormGroup {
        return item as UntypedFormGroup;
    }

    getAbstractControllerAsFromGroupForLanguage(languageCode: string): boolean {
        const language = (this.formGroup.controls.translations as UntypedFormArray).controls.find(item => (item as UntypedFormGroup).value.languageCode === languageCode);
        return language?.value.label !== null && language?.value.banner !== null && language?.value.subBanner !== null;
    }

    search() {
        switch (this.formGroupSearch.value.searchFilter) {
            case "searchMain":
                this.formGroupSearch.value.searchMain = true;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchSpecific":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = true;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchService":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = true;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchAnimation":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = true;
                break;
        }
        this.searchCriteria = {
            keywords: this.formGroupSearch.value.keywords,
            main: this.formGroupSearch.value.searchMain,
            specific: this.formGroupSearch.value.searchSpecific,
            service: this.formGroupSearch.value.searchService,
            animation: this.formGroupSearch.value.searchAnimation,
            type: this.formGroupSearch.value.searchTypeCategory != null && this.formGroupSearch.value.searchTypeCategory.length > 0 ? this.formGroupSearch.value.searchTypeCategory[0].item_id: null
        };
    }

    structureUpdate(): void {
        for (let i = 0; i < this.categories.childCategories.length; i++) {
            this.categories.childCategories[i].orderForPage = i + 1;
            if (this.categories.childCategories[i].childCategories != undefined) {
                for (let j = 0; j < this.categories.childCategories[i].childCategories.length; j++) {
                    this.categories.childCategories[i].childCategories[j].orderForPage = j + 1;
                    if (this.categories.childCategories[i].childCategories[j].childCategories != undefined) {
                        for (let k = 0; k < this.categories.childCategories[i].childCategories[j].childCategories.length; k++) {
                            this.categories.childCategories[i].childCategories[j].childCategories[k].orderForPage = k + 1;
                        }
                    }
                }
            }
        }
    }

    edit(category: CategoryDtoInterface): void {
        this.selectedLanguages = [];
        this.category = null;
        this.editCategoryParent = null;
        this.formGroup.reset();

        this.category = category;
        this.category.productsToDelete = [];

        this.modalService.open("editModal");

        if (category.isSpecificCategory) {
            this.getEnseigneInclusion(category.idCategory);
        }

        (this.formGroup.controls.translations as UntypedFormArray).clear();

        this.formGroup.patchValue({
            code: category.code,
            orderForPage: category.orderForPage,
            orderForBanner: category.orderForBanner,
            type: category.type,
            main: category.isMain,
            specific: category.isSpecificCategory,
            enseignesInclusion: category.enseignesInclusion,
            enseignesExclusion: category.enseignesExclusion,
            deliveryCountriesInclusion: category.deliveryCountriesInclusion,
            deliveryCountriesExclusion: category.deliveryCountriesExclusion,
            service: category.isService,
            disableSalePriceEnseigne: category.disableSalePriceEnseigne,
            animation: category.isAnimation,
            archive: category.archive,
        });

        this.languages.forEach(language => {
            const categoryTranslation = category.translationCategoriesCategory.find(item =>
                item.languageTranslationCategory && item.languageTranslationCategory.code === language.code
            );
            if (categoryTranslation) {
                this.languagesFilled.set(language.idLanguage, categoryTranslation.label != null && categoryTranslation.banner != null && categoryTranslation.subBanner != null);
                (this.formGroup.controls.translations as UntypedFormArray).push(new UntypedFormGroup({
                    languageCode: new UntypedFormControl(language.code, [Validators.required]),
                    label: new UntypedFormControl(categoryTranslation.label),
                    labelYoukado: new UntypedFormControl(categoryTranslation.labelYoukado),
                    banner: new UntypedFormControl(categoryTranslation.banner),
                    subBanner: new UntypedFormControl(categoryTranslation.subBanner)
                }));
            } else {
                this.languagesFilled.set(language.idLanguage, false);
                this.category.translationCategoriesCategory.push({
                    idTranslationCategory: null,
                    label: null,
                    banner: null,
                    subBanner: null,
                    languageTranslationCategory: language,
                    idCategory: null,
                    codeCategory: null,
                } as TranslationCategoryDtoInterface);
                (this.formGroup.controls.translations as UntypedFormArray).push(new UntypedFormGroup({
                    languageCode: new UntypedFormControl(language.code, [Validators.required]),
                    label: new UntypedFormControl(null),
                    labelYoukado: new UntypedFormControl(null),
                    banner: new UntypedFormControl(null),
                    subBanner: new UntypedFormControl(null)
                }));
            }
        });
    }

    add(category: CategoryDtoInterface): void {
        this.category = null;
        this.editCategoryParent = null;
        this.formGroup.reset();

        this.editCategoryParent = category;
        this.category = CategorytreeUtils.getNewCategoryInParent(this.languages);

        this.categoryService.getNextValSequence().subscribe(
            (response) => {
                this.category.code = "CAT" + response;

                this.modalService.open("editModal");

                this.formGroup.patchValue({
                    code: this.category.code
                });

                (this.formGroup.controls.translations as UntypedFormArray).clear();
                this.languages.forEach(value => {
                    (this.formGroup.controls.translations as UntypedFormArray).push(new UntypedFormGroup({
                        languageCode: new UntypedFormControl(value.code, [Validators.required]),
                        label: new UntypedFormControl(null),
                        labelYoukado: new UntypedFormControl(null),
                        banner: new UntypedFormControl(null),
                        subBanner: new UntypedFormControl(null)
                    }));
                });
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }

    delete(category: CategoryDtoInterface): void {
        this.dealWithStandardObservableSubscription(this.categoryService.delete(CategorytreeUtils.getCloneWithoutRedundancy(category)));
    }

    searchCategory(category: CategoryDtoInterface): void {
        this.syncCatalogService.syncSearchCategory(category);
    }

    changeType(e: Event) {
        // wtf it's not better integrated ?  I must do something wrong but it works
        this.formGroup.patchValue({
            type: (e.target as HTMLSelectElement).value
        });
    }

    submit(published: boolean): void {
        this.buildCategoryToEnseigne();

        if (!this.formGroup.valid) {
            this.formGroup.controls.code.markAsTouched();
            this.formGroup.controls.type.markAsTouched();
            (this.formGroup.controls.translations as UntypedFormArray).controls.forEach(item => {
                (item as UntypedFormGroup).controls.label.markAsTouched();
                (item as UntypedFormGroup).controls.labelYoukado.markAsTouched();
                (item as UntypedFormGroup).controls.banner.markAsTouched();
                (item as UntypedFormGroup).controls.subBanner.markAsTouched();
            });
            return;
        }


        if (!this.category.idCategory) {
            // create
            if (!this.editCategoryParent.childCategories) {
                this.editCategoryParent.childCategories = [];
            }
            this.updateCategoryWithForm(this.category);

            if (this.category.isSpecificCategory && (this.category.categoryToEnseigne == null || this.category.categoryToEnseigne.length == 0)) {
                this.modalService.open("alertCategorieSpecifique");
                return;
            }

            this.category.parentCategories = this.editCategoryParent;
            if (published != null) {
                this.category.published = published;
            }
            this.editCategoryParent.childCategories.splice(this.editCategoryParent.childCategories.length, 0, this.category);

            this._commondata.showLoader(true);

            this.dealWithStandardObservableSubscription(
                this.categoryService.post(CategorytreeUtils.getCloneWithoutRedundancy(this.category))
                , {
                    success: response => {
                        this.category.idCategory = response.idCategory;
                        this.ngOnInit();
                        setTimeout(() => this._commondata.showLoader(false), 200);
                    },
                    error: response => {
                        this.category.published = !published;
                        this.category.parentCategories = null;
                        this.editCategoryParent.childCategories = this.editCategoryParent.childCategories.filter(c => c.code != this.category.code);
                        this._commondata.showLoader(false);
                    },
                }
            );
        } else {
            // update
            this.updateCategoryWithForm(this.category);

            if (this.category.isSpecificCategory && (this.category.categoryToEnseigne == null || this.category.categoryToEnseigne.length == 0)) {
                this.modalService.open("alertCategorieSpecifique");
                return;
            }
            if (published != null) {
                this.category.published = published;
            }

            console.log(CategorytreeUtils.getCloneWithoutRedundancy(this.category));

            this._commondata.showLoader(true);


            this.dealWithStandardObservableSubscription(
                this.categoryService.put(CategorytreeUtils.getCloneWithoutRedundancy(this.category))
                , {
                    success: (response) => {
                        this.ngOnInit();
                            if (this.products != null) {
                                this.products = this.products.filter(p => this.category.productsToDelete.indexOf(p.idProductYoukado) == -1);
                            }
                            setTimeout(() => this._commondata.showLoader(false), 200);

                    },
                    error: () => {
                        setTimeout(() => this._commondata.showLoader(false), 200);
                    }
                }
            );
        }

    }

    changeCategory() {
        this.category.isSpecificCategory = this.formGroup.value.specific;
    }

    changeLanguage(language: LanguageDtoInterface) {
        this.language = language;
    }

    closeModal(): void {
        this.category.enseignesInclusion = this.enseignesInclusion;
        this.category = {} as CategoryDtoInterface;
        this.editCategoryParent = null;
        this.formGroup.reset();
        (this.formGroup.controls.translations as UntypedFormArray).clear();
        this.categoryTabset.activeId = 'Détails';
        this.alreadyLoaded = false;
        this.modalService.close("editModal");
    }

    private updateCategoryWithForm(category: CategoryDtoInterface): void {
        category.code = this.formGroup.value.code;
        category.orderForPage = this.formGroup.value.orderForPage;
        category.orderForBanner = this.formGroup.value.orderForBanner;
        category.type = this.formGroup.value.type;
        category.isSpecificCategory = this.formGroup.value.specific;
        category.isMain = this.formGroup.value.main;
        category.isService = this.formGroup.value.service;
        category.disableSalePriceEnseigne = this.formGroup.value.disableSalePriceEnseigne;
        category.isAnimation = this.formGroup.value.animation;
        category.archive = this.formGroup.value.archive;
        category.enseignesInclusion = this.formGroup.controls.enseignesInclusion.value;
        // category.enseignesExclusion = this.formGroup.controls.enseignesExclusion.value;
        // category.deliveryCountriesInclusion = this.formGroup.controls.deliveryCountriesInclusion.value;
        // category.deliveryCountriesExclusion = this.formGroup.controls.deliveryCountriesExclusion.value;
        category.translationCategoriesCategory = [];
        (this.formGroup.controls.translations as UntypedFormArray).controls.forEach(item => {
            category.translationCategoriesCategory.push({
                idTranslationCategory: null,
                label: (item as UntypedFormGroup).controls.label.value,
                labelYoukado: (item as UntypedFormGroup).controls.labelYoukado.value,
                banner: (item as UntypedFormGroup).controls.banner.value,
                subBanner: (item as UntypedFormGroup).controls.subBanner.value,
                description: null,
                languageTranslationCategory: this.languages.find(language => language.code === (item as UntypedFormGroup).controls.languageCode.value),
                idCategory: category.idCategory,
                codeCategory: category.code,
            });
        });
    }

    onClickTranslate(languageCode: string, label: string, labelYoukado: string, sourceLang: string) {
        if (this.selectedLanguages.length > 0) {
            setTimeout(() => this._commondata.showLoader(true), 200);
            this.translationDto = {} as TranslationDtoInterface;

            this.translationDto.selectedLanguages = this.selectedLanguages;
            this.translationDto.label = label;
            this.translationDto.labelYoukado = labelYoukado;
            if (
                this.translationDto.label.trim() != null && this.translationDto.labelYoukado.trim() != null &&
                !ObjectUtils.isEmpty(this.translationDto.label) && !ObjectUtils.isEmpty(this.translationDto.labelYoukado)
            ) {
                this.emptyLabel = false;
                this.translationDto.translationCategory = this.category.translationCategoriesCategory;
                this.translationDto.isLabel = true;
                this.translationDto.sourceLang = sourceLang;

                this.translationService.translate(this.translationDto).subscribe(
                    (response) => {
                        let translationCategoriesCategory: TranslationCategoryDtoInterface[] = response.translationCategory;
                        (this.formGroup.controls.translations as UntypedFormArray).clear();
                        this.languages.forEach(language => {
                            const categoryTranslation = translationCategoriesCategory.find(item =>
                                item.languageTranslationCategory && item.languageTranslationCategory.code === language.code
                            );
                            if (categoryTranslation) {
                                this.languagesFilled.set(
                                    language.idLanguage,
                                    categoryTranslation.label != null &&
                                    categoryTranslation.labelYoukado != null &&
                                    categoryTranslation.banner != null &&
                                    categoryTranslation.subBanner != null
                                );

                                (this.formGroup.controls.translations as UntypedFormArray).push(new UntypedFormGroup({
                                    languageCode: new UntypedFormControl(language.code, [Validators.required]),
                                    label: new UntypedFormControl(categoryTranslation.label),
                                    labelYoukado: new UntypedFormControl(categoryTranslation.labelYoukado),
                                    banner: new UntypedFormControl(categoryTranslation.banner),
                                    subBanner: new UntypedFormControl(categoryTranslation.subBanner)
                                }));
                            }
                        });
                        setTimeout(() => this._commondata.showLoader(false), 200);
                    },
                    (error) => {
                        console.log("error : " + JSON.stringify(error));
                        setTimeout(() => this._commondata.showLoader(false), 200);
                    }
                );
            } else {
                this.emptyLabel = true;
                setTimeout(() => this._commondata.showLoader(false), 200);
            }
        }
    }

    onSelectedEnseigne(enseigne: any) {
        let enseignes: EnseigneDtoInterface[] = this.enseignes.filter(e => e.id_ext_enseigne == enseigne.id_ext_enseigne);
        this.getEnseigneProperties(enseignes);
    }

    onDeSelectedEnseigne(enseigne: any) {
        this.enseignesProperties.delete(enseigne.nomEnseigne);
        this.buildCategoryToEnseigne();
    }

    enseignesProperties: Map<string, PropertyDtoInterface[]> = new Map();

    getEnseigneInclusion(idCategory: number) {
        this.enseignesProperties = new Map();
        let categoryAndEnseigne = {} as CategoryAndEnseigneDtoInterface;
        categoryAndEnseigne.idCategory = idCategory;

        this.categoryService.getEnseignesInclusion(categoryAndEnseigne).subscribe(
            (response) => {
                this.enseignesSelected = response;
                this.getEnseigneProperties(this.enseignesSelected);
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }

    getEnseigneProperties(enseignes: EnseigneDtoInterface[]) {
        if (enseignes != null && enseignes.length > 0) {
            for (let enseigne of enseignes) {
                let categoryAndEnseigne = {} as CategoryAndEnseigneDtoInterface;
                categoryAndEnseigne.idEnseigne = enseigne.id_ext_enseigne;
                categoryAndEnseigne.idCategory = this.category.idCategory ? this.category.idCategory : 0;

                this.categoryService.getProperties(categoryAndEnseigne).subscribe(
                    (response) => {
                        this.enseignesProperties.set(enseigne.nomEnseigne, response);
                        this.buildCategoryToEnseigne();
                    },
                    (error) => {
                        console.log('Error : ' + JSON.stringify(error));
                    }
                );
            }
        }
    }

    OpenAccordion(sectionName: any, Wrapdiv: any) {

        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "acd-des") {
            document.getElementById(sectionName).setAttribute("class", "acd-des show");
            document.getElementById(Wrapdiv).setAttribute("class", "acd-group acd-active");
        } else {
            document.getElementById(sectionName).setAttribute("class", "acd-des");
            document.getElementById(Wrapdiv).setAttribute("class", "acd-group");
        }
    }

    buildCategoryToEnseigne() {
        this.category.categoryToEnseigne = [];
        this.enseignesProperties.forEach((propertyDtoEnseigne: PropertyDtoInterface[], key: string) => {
            let pushCategoryToEnseigne: Boolean = true;
            this.showEnseigne.set(key, false);
            for (let propertyDto of propertyDtoEnseigne) {
                if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {
                    this.showEnseigne.set(key, true);
                }
                if (propertyDto.selectedPropertyValues != null && propertyDto.selectedPropertyValues.length > 0) {
                    for (let value of propertyDto.selectedPropertyValues) {
                        if (value != null) {
                            const categoryToEnseigne = {} as CategoryToEnseigneDtoInterface;
                            categoryToEnseigne.nomEnseigne = key;
                            categoryToEnseigne.idPropriete = propertyDto.propertyId;
                            categoryToEnseigne.valeurPropriete = value.label;
                            let propertyValueDto = propertyDto.propertyValues.find(p => p.label != null && p.label == value.label);
                            if (propertyValueDto != null && propertyValueDto.value != null && propertyValueDto.value.trim() != "") {
                                categoryToEnseigne.valeurPropriete = propertyValueDto.value;
                            }
                            if (this.category.categoryToEnseigne == null) {
                                this.category.categoryToEnseigne = [];
                            }
                            this.category.categoryToEnseigne.push(categoryToEnseigne);
                            pushCategoryToEnseigne = false;
                        }
                    }
                }
            }

            if (pushCategoryToEnseigne) {
                const categoryToEnseigne = {} as CategoryToEnseigneDtoInterface;
                categoryToEnseigne.nomEnseigne = key;
                this.category.categoryToEnseigne.push(categoryToEnseigne);
            }
        });
    }

    private performSearch(opt?: { search: HttpParams, pagination?: PaginationInterface }): void {
        this._commondata.showLoader(true);
        this.catalogService.search(opt.search, {pagination: opt?.pagination}).subscribe(
            (response) => {
                this.productsYoukadoPageable = PaginationUtils.getPagination(response);
                console.log(this.productsYoukadoPageable);
                this.products = response.content as any as ProductYoukadoDtoInterface[];
                this.alreadyLoaded = true;
                this._commondata.showLoader(false);
            },
            (error) => {
                this.commondataService.showLoader(false);
                console.log('Error : ' + error);
            }
        );
    }

    paginate(pagination: { page: number, size?: number }): void {
        pagination.size = 20;
        const httpParams = this.catalogService.searchToParams(JSON.parse('{"categories":[' + this.category.idCategory + ']}'));
        this.performSearch({search: httpParams, pagination: pagination});
    }

    getProductsToCategory(event: any) {
        if (event.nextId == 'Produits') {
            const pagination: PaginationInterface = {page: 1, size: 20};
            const httpParams = this.catalogService.searchToParams(JSON.parse('{"categories":[' + this.category.idCategory + ']}'));
            this.performSearch({search: httpParams, pagination: pagination});
        }
    }

    checkProductToExclude() {
        this.codeAlreadyInListErrorMessage = false;
        this.wrongCodeErrorMessage = false;
        this.duplicatedCodes = [];
        this.undefinedCodes = [];

        this._commondata.showLoader(true);
        let regexProducts = new RegExp("[A-Za-z0-9]+", "g");
        let foundProducts = this.productToCheck.trim().match(regexProducts);
        for (let p of foundProducts) {
            if (this.productsLinkedToCategory.find(ps => ps.code == p)) {
                this.codeAlreadyInListErrorMessage = true;
                this.duplicatedCodes.push(p);
                this._commondata.showLoader(false);
            } else {
                this.productYoukadoService.getProductByCode({codeProduct: p}).subscribe(
                    (response) => {
                        if (this.productsLinkedToCategory.find(ps => ps.code == response.code)) {
                            this.codeAlreadyInListErrorMessage = true;
                            this.duplicatedCodes.push(p);
                            this._commondata.showLoader(false);
                        } else {
                            if (!response) {
                                this.wrongCodeErrorMessage = true;
                                this.undefinedCodes.push(p);
                            } else {
                                this.productsLinkedToCategory.push(response);
                            }
                            this._commondata.showLoader(false);
                        }
                    },
                    (error) => {
                        console.log("error");
                        console.log(error);
                        this._commondata.showLoader(false);
                    }
                )
            }
        }
    }

    public swapProductsList(index: number, toDelete: boolean) {
        if (toDelete) {
            this.productsLinkedToCategoryToDelete.push(this.productsLinkedToCategory[index]);
            this.productsLinkedToCategory.splice(index, 1);
        } else {
            this.productsLinkedToCategory.push(this.productsLinkedToCategoryToDelete[index]);
            this.productsLinkedToCategoryToDelete.splice(index, 1);
        }
    }

    public selectProductToDelete(productId: number, event: any) {
        if (event.target.checked) {
            this.category.productsToDelete.push(productId);
        } else {
            this.category.productsToDelete = this.category.productsToDelete.filter(product => product != productId);
        }

        console.log(this.category.productsToDelete);

    }
    public getAllProductsByCategory(categoryId: number , event: any) {
        const checked = event.target.checked;
        const idList: number[] = [];
        if (checked) {
            this.categoryService.getAllProductsByCategory({ idCategory: categoryId }).subscribe(
                (response) => {
                    if (Array.isArray(response)) {
                        response.forEach((res) => {
                            idList.push(res.productYoukado);
                        });
                        this.category.productsToDelete = idList;
                    } else {
                        console.log('Invalid response format: Expected an array');
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }  else{
           this.category.productsToDelete=[];
            event.target.checked=false;
        }
    }
}

