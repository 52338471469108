import {Injectable, Injector} from '@angular/core';

import {saveAs} from 'file-saver';
import {HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {map} from "rxjs/operators";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {CardApiClass} from "src/app/shared/classes/api/card-api.class";

// https://shekhargulati.com/2017/07/16/implementing-file-save-functionality-with-angular-4/

@Injectable({
    providedIn: 'root'
})
export class CardExportService extends HttpServiceClass<CardApiClass> {
    protected endpoint = '/cards/export';

    constructor(protected injector: Injector) {
        super(injector);
    }

    saveFile(id: number, params?: HttpParams):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'text/plain');
        return this.httpClient.get(this.getPath({id}), {headers, params, responseType: 'blob'})
            // .toPromise()
            // .then(response => this.saveToFileSystem(response, id));
            .pipe(map(response=>this.saveToFileSystem(response, id)));
    }

    private saveToFileSystem(response: any, id: number) {
        saveAs(response, id + ".xlsx");
    }

    protected getId(item: CardApiClass): number {
        return 0;
    }
}
