import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { Input } from '@angular/core'
import { BaseComponents } from "src/app/shared/classes/components/base-components.class";
import { ProductYoukadoDtoInterface } from "src/app/shared/interfaces/models/rest";
import { ProductUtils } from "src/app/shared/utils/product.utils";
import { ActivatedRoute, Router } from "@angular/router";
import { CatalogService } from "src/app/shared/services/catalog.service";
import {RuleComponent} from "src/app/components/rule/rule.component";

@Component({
    selector: 'app-list-products-item',
    templateUrl: './list-products-item.component.html',
    styleUrls: ['./list-products-item.component.scss']
})
export class ListProductsItemComponent extends BaseComponents {

    product: ProductYoukadoDtoInterface = null;
    deliveryCountries: string[] = null;
    title: string;
    image: string;
    bestPrice: number;
    bestStock: number;
    totalSuppliers: number;
    totalStock: number;
    catalogues: Map<string, { state: string, margin: number, stock: number, hasEligibleSupplier: boolean, code: string, dutyFreeTotalBuyingPrice: number, totalSupplier: number, supplier: string, dutyFreeSalePrice: number }>;
    cataloguesLength: number;
    classIcon: string;

    @Input("productYoukado") set setProductYoukado(input: { product: ProductYoukadoDtoInterface, deliveryCountries: string[] }) {
        this.product = input.product;
        this.deliveryCountries = input.deliveryCountries;
        this.itemCompletion();
    }

    @Input() showDetails = false;

    @Input() inRulesComponent: boolean = false;

    @Output() onShowDetails: EventEmitter<boolean> = new EventEmitter();

    constructor(protected injector: Injector,
        protected catalogService: CatalogService,
        protected activatedRoute: ActivatedRoute,
        protected router: Router) {
        super(injector);
        this.subscriptions.push(this.translateService.onLangChange.subscribe(() => {
            this.title = ProductUtils.getBestTitleForProduct(this.product, this.translateService.currentLang);
        }));
    }

    openExternal() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(
                ["/product/" + this.product.externalId],
                { queryParams: this.activatedRoute.snapshot.queryParams }
            )
        );
        window.open('/marketplace/#' + url, '_blank');
    }

    history(code: string) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree(
                ["/pricehistory/" + code],
                { queryParams: this.activatedRoute.snapshot.queryParams }
            )
        );
        window.open('/marketplace/#' + url, '_blank');
    }

    updateStatus(statusUpdate: { status: string, country: string }): void {
        const catalog = this.product.catalogues.find(item => item.deliveryCountriesCatalogue.code === statusUpdate.country);
        if (catalog) {
            catalog.state = statusUpdate.status;
        }
        this.itemCompletion();

        this.dealWithStandardObservableSubscription(this.catalogService.put(catalog));
    }

    private itemCompletion(): void {
        if (!this.product) {
            return;
        }
        this.title = ProductUtils.getBestTitleForProduct(this.product, this.translateService.currentLang);
        this.image = ProductUtils.getBestImageForProduct(this.product);
        const bestSaleInfo = ProductUtils.getBestSaleInfo(this.product, this.deliveryCountries);
        if (bestSaleInfo) {
            this.bestPrice = bestSaleInfo.bestPrice;
            this.bestStock = bestSaleInfo.bestStock;
            //this.totalSuppliers = bestSaleInfo.totalSuppliers;
            this.totalStock = bestSaleInfo.totalStock;
        }
        let suppliers: string[] = [];
        for (let productSupplier of this.product.productSuppliers) {
            if (!suppliers.includes(productSupplier.supplier.code)) {
                suppliers.push(productSupplier.supplier.code);
            }
        }
        this.totalSuppliers = suppliers.length;
        this.catalogues = ProductUtils.getCatalogueInfoByCountry(this.product);
        this.cataloguesLength = this.catalogues.size;
    }

    details(show: boolean): void {
        this.onShowDetails.emit(show);
    }
}
