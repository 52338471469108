import {Injectable, Injector} from '@angular/core';
import {SuppliersToExportDtoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ExportSupplierService extends HttpServiceClass<SuppliersToExportDtoInterface> {
    protected endpoint = "suppliers";

    protected getId(item: SuppliersToExportDtoInterface): number {
        return 0;
    }
    constructor(protected injector: Injector) {
        super(injector);
    }
// params?: HttpParams
    getSuppliersToExport() {

        return this.httpClient.get<SuppliersToExportDtoInterface[]>(
            this.getPath({path: "SuppliersToExport"}))
            .pipe(map(data => JsonUtils.parseDates(data)));
    }
}
