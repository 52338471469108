export enum SecurityProfilEnum {
    ROLE_ACHATS= "ROLE_ACHATS",
    ROLE_DIRECTION_GENERALE= "ROLE_DIRECTION_GENERALE",
    ROLE_SUPER_ADMIN="ROLE_SUPER_ADMIN",
    ROLE_COMPTABLITE= "ROLE_COMPTABLITE",
    ROLE_MARKETING= "ROLE_MARKETING",
    ROLE_SERVICE_CLIENT="ROLE_SERVICE_CLIENT"
}




