import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";
import {Component, EventEmitter, Input, Output} from "@angular/core";


@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent {

    // pagination: PaginationbarInterface;
    //
    // @Input("pagination") set setPagination(pageable: PageableModelsInterface<any>) {
    //     this.pagination = PaginationUtils.getPagination(pageable);
    // }

    @Input() pagination: PaginationbarInterface;

    @Output() onPaginate = new EventEmitter<{ page: number, size: number }>();

    paginate(page: number, size?: number): void {
        const element = document.querySelector('body');
        element.scrollIntoView();
        this.onPaginate.emit({page, size});
    }
}
