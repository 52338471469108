import {Component, Injector, Input, OnInit} from '@angular/core';
import {
    CategoryDtoInterface,
    DeliveryCountryDtoInterface,
    EnseigneDtoInterface,
    CategoryToEnseigneDtoInterface
} from "src/app/shared/interfaces/models/rest";
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {
    ProductFormvalidationInterface
} from "src/app/shared/interfaces/formvalidation/product/product-formvalidation.interface";
import {CategoryService} from "src/app/shared/services/category.service";
import {CategorytreeUtils} from "src/app/shared/utils/categorytree.utils";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {debounce} from "underscore";
import {SyncCatalogService} from "src/app/shared/services/sync-catalog.service";
import {EnseignesService} from "src/app/shared/services/enseignes-service";
import {CategoryToEnseigne} from "src/app/shared/services/categoryToEnseigne.service";
import {HttpParams} from "@angular/common/http";
import {ModalService} from "src/app/shared/services";
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";

@Component({
    selector: 'app-category-catalogue',
    templateUrl: './category-catalogue.component.html',
    styleUrls: ['./category-catalogue.component.css']
})
export class CategoryCatalogueComponent extends BaseComponents implements OnInit {


    categories: CategoryDtoInterface;
    mainCategories: CategoryDtoInterface[] = [];
    selectedCategories: number[] = [];
    deliveryCountry: DeliveryCountryDtoInterface;
    formGroup: UntypedFormGroup;
    formGroupSearch: UntypedFormGroup;
    searchCriteria: { keywords?: string, main?: boolean, specific?: boolean, service?: boolean, animation?: boolean, archive?: boolean, type?: string };
    treeCatgeriesSelectedSpecific: any[] = [];
    treeCatgeriesSelectedMain: any[] = [];
    selectedCategoriesInTree: any[] = [];
    allEnseignesList: EnseigneDtoInterface[];
    selectedEnseigne: EnseigneDtoInterface[] = [];
    dropdownSettingsEnseignesSearch: any = {};
    showMain: boolean = true;
    showSpecific: boolean = true;
    dropdownListTypeCategory: any[] = [
        {item_id: 'Personnelle', item_text: 'Loisir'},
        {item_id: 'Professionnelle', item_text: 'Professionnelle'},
    ];
    dropDownCategorySettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'item_id',
        textField: 'item_text',
        allowSearchFilter: false,
        enableCheckAll: false
    };
    currentTypeCategory: any[] = [];

    private init = false;
    private categoryToEnseignes: CategoryToEnseigneDtoInterface[] = [];
    private categoryToEnseignesToSave: CategoryToEnseigneDtoInterface[] = [];

    @Input() validationData: ProductFormvalidationInterface;

    constructor(protected injector: Injector,
                protected syncCatalogService: SyncCatalogService,
                protected formBuilder: UntypedFormBuilder,
                protected categoryService: CategoryService,
                private enseignesService: EnseignesService,
                protected modalService: ModalService,
                private categoryToEnseigne: CategoryToEnseigne) {
        super(injector);
        this.search = debounce(this.search, 300);
    }


    ngOnInit(): void {


        this.dropdownSettingsEnseignesSearch = {
            enableCheckAll: false,
            singleSelection: true,
            allowSearchFilter: true,
            textField: "nomEnseigne",
            idField: "id_ext_enseigne",
            searchPlaceholderText: "Rechercher"
        };

        /* Get all enseignes list */
        this.commondataService.showLoader(true);
        this.enseignesService.getEnseignes().subscribe(
            (response) => {
                this.allEnseignesList = response;
                this.commondataService.showLoader(false);
            },
            (error) => {
                console.log(error);
                this.commondataService.showLoader(false);
            }
        )


        if (!this.formGroup) {
            this.formGroup = this.formBuilder.group({
                category: new UntypedFormControl('')
            });
        }

        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                searchTypeCategory: new UntypedFormControl(''),
                searchMain: new UntypedFormControl(true),
                searchSpecific: new UntypedFormControl(false),
                searchService: new UntypedFormControl(false),
                searchAnimation: new UntypedFormControl(false),
                searchArchive: new UntypedFormControl(false),
                searchFilter: new UntypedFormControl('searchMain')
            });
            this.search();
        }

        this.init = true;

    }

    getCategorieSpec(): void {
        this.loadCategories();
    }

    searchCategory(category: CategoryDtoInterface): void {
        this.syncCatalogService.syncSearchCategory(category);
    }

    search() {
        switch (this.formGroupSearch.value.searchFilter) {
            case "searchMain":
                this.formGroupSearch.value.searchMain = true;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchSpecific":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = true;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchService":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = true;
                this.formGroupSearch.value.searchAnimation = false;
                break;
            case "searchAnimation":
                this.formGroupSearch.value.searchMain = false;
                this.formGroupSearch.value.searchSpecific = false;
                this.formGroupSearch.value.searchService = false;
                this.formGroupSearch.value.searchAnimation = true;
                break;
        }
        this.searchCriteria = {
            keywords: this.formGroupSearch.value.keywords,
            main: this.formGroupSearch.value.searchMain,
            specific: this.formGroupSearch.value.searchSpecific,
            service: this.formGroupSearch.value.searchService,
            animation: this.formGroupSearch.value.searchAnimation,
            type: this.formGroupSearch.value.searchTypeCategory != null && this.formGroupSearch.value.searchTypeCategory.length > 0 ? this.formGroupSearch.value.searchTypeCategory[0].item_id : null,
        };
    }


    private loadCategories(): void {

        this.categories = {} as CategoryDtoInterface;
        this.selectedCategoriesInTree = [];
        this.treeCatgeriesSelectedSpecific = [];
        this.treeCatgeriesSelectedMain = [];
        this.categoryToEnseignesToSave = [];
        this.showMain = true;
        this.showSpecific = true;

        if (this.selectedEnseigne != null && this.selectedEnseigne.length > 0) {
            let params = new HttpParams();
            params = params.set("enseigne", this.selectedEnseigne[0].id_ext_enseigne);

            this.categoryToEnseigne.getCategoryToEnseigne(params).subscribe(
                (response) => {
                    this.categoryToEnseignes = response;
                    this.selectedCategoriesInTree = this.categoryToEnseignes.map((categoryToEnseignes: CategoryToEnseigneDtoInterface) => categoryToEnseignes.idCategory);
                    this.commondataService.showLoader(false);
                    this.categoryService.listTree().subscribe(response => {
                        this.categories = CategorytreeUtils.generateTree(response, false);
                        this.loadSelectedCategories();
                        this.search();
                    }, error => {
                        console.log('Error : ' + JSON.stringify(error));
                        this.commondataService.showLoader(false);
                    });
                },
                (error) => {
                    console.log(error);
                    this.commondataService.showLoader(false);
                }
            )


        }

    }

    categoryChange(): void {

        this.loadSelectedCategories();

    }

    private loadSelectedCategories(): void {

        this.treeCatgeriesSelectedSpecific = [];
        this.treeCatgeriesSelectedMain = [];
        this.categoryToEnseignesToSave = [];
        if (this.selectedCategoriesInTree != null && this.selectedCategoriesInTree != undefined) {
            for (let category of this.selectedCategoriesInTree) {
                this.treeCatgeriesSelectedSpecific.push(CategorytreeUtils.findParent(category, this.categories, true, true));
                this.treeCatgeriesSelectedMain.push(CategorytreeUtils.findParent(category, this.categories, false, true));
            }
        }

        this.buildCategoryToEnseignesToSave();

    }

    private buildCategoryToEnseignesToSave() {

        for (let category of this.selectedCategoriesInTree) {
            let categoryToEnseigne = {} as CategoryToEnseigneDtoInterface;
            categoryToEnseigne.idCategory = category;
            categoryToEnseigne.idEnseigne = this.selectedEnseigne[0].id_ext_enseigne;
            this.categoryToEnseignesToSave.push(categoryToEnseigne);
        }

    }

    save() {

        if (this.selectedEnseigne != null && this.selectedEnseigne.length > 0) {

            this.commondataService.showLoader(true);

            let params = new HttpParams();
            params = params.set("idEnseigne", this.selectedEnseigne[0].id_ext_enseigne);
            this.dealWithStandardObservableSubscription(this.categoryToEnseigne.post(this.categoryToEnseignesToSave, params), {
                success: response => {
                    this.loadCategories();
                    this.commondataService.showLoader(false);
                },
                error: response => {
                    this.commondataService.showLoader(false);
                },

            })
        }

    }

    includeGenericCategories() {


        this.categories = {} as CategoryDtoInterface;
        this.showMain = true;
        this.showSpecific = true;
        this.treeCatgeriesSelectedSpecific = [];
        this.treeCatgeriesSelectedMain = [];
        this.categoryToEnseignesToSave = [];


        let params = new HttpParams();
        params = params.set("isMain", false);

        this.categoryService.getMainCategories(params).subscribe(
            (response) => {
                this.mainCategories = response;
                this.selectedCategoriesInTree = this.selectedCategoriesInTree.filter(c => !this.mainCategories.map((cat: CategoryDtoInterface) => cat.idCategory).includes(c));
                console.log(JSON.stringify(this.mainCategories.map((cat: CategoryDtoInterface) => cat.idCategory)));
                this.selectedCategoriesInTree = this.selectedCategoriesInTree.concat(this.mainCategories.map((cat: CategoryDtoInterface) => cat.idCategory));
                this.categoryService.listTree().subscribe(response => {
                    this.categories = CategorytreeUtils.generateTree(response, false);
                    this.loadSelectedCategories();
                    this.search();
                }, error => {
                    console.log('Error : ' + JSON.stringify(error));
                    this.commondataService.showLoader(false);
                });

            },
            (error) => {
                console.log(error);
                this.commondataService.showLoader(false);
            }
        )

    }

    excludeGenericCategories() {

        this.categories = {} as CategoryDtoInterface;
        this.showMain = true;
        this.showSpecific = true;
        this.treeCatgeriesSelectedSpecific = [];
        this.treeCatgeriesSelectedMain = [];
        this.categoryToEnseignesToSave = [];


        let params = new HttpParams();
        params = params.set("isMain", false);

        this.categoryService.getMainCategories(params).subscribe(
            (response) => {
                this.mainCategories = response;

                this.selectedCategoriesInTree = this.selectedCategoriesInTree.filter(c => !this.mainCategories.map((cat: CategoryDtoInterface) => cat.idCategory).includes(c));
                this.categoryService.listTree().subscribe(response => {
                    this.categories = CategorytreeUtils.generateTree(response, false);
                    this.loadSelectedCategories();
                    this.search();
                }, error => {
                    console.log('Error : ' + JSON.stringify(error));
                    this.commondataService.showLoader(false);
                });

            },
            (error) => {
                console.log(error);
                this.commondataService.showLoader(false);
            }
        )

    }

}
