import {Injectable, Injector} from '@angular/core';

import {Subject} from "rxjs";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";


@Injectable({
    providedIn: 'root'
})
export class SynchronizationService extends HttpServiceClass<any> {
    protected endpoint = 'regions';

    onLockedChange: Subject<boolean> = new Subject();

    // onRegionChange: Subject<RegionApiClass> = new Subject();
    // onDoChange: Subject<DoApiClass> = new Subject();
    // onAgencyChange: Subject<AgencyApiClass> = new Subject();
    // onAtcChange: Subject<AtcApiClass> = new Subject();
    //
    // onRegionsSet: Subject<RegionApiClass[]> = new Subject();
    // onDosSet: Subject<DoApiClass[]> = new Subject();
    // onAgenciesSet: Subject<AgencyApiClass[]> = new Subject();
    // onAtcsSet: Subject<AtcApiClass[]> = new Subject();

    constructor(protected injector: Injector) {
        super(injector);
    }

    lockedChanged(locked: boolean): void {
        this.onLockedChange.next(locked);
    }

    protected getId(item: any): number {
        return 0;
    }

    // regionChanged(region: RegionApiClass): void {
    //     this.onRegionChange.next(region);
    // }
    //
    // doChanged(do_: DoApiClass): void {
    //     this.onDoChange.next(do_);
    // }
    //
    // agencyChanged(agency: AgencyApiClass): void {
    //     this.onAgencyChange.next(agency);
    // }
    //
    // atcChanged(atc: AtcApiClass): void {
    //     this.onAtcChange.next(atc);
    // }
    //
    // regionsSet(regions: RegionApiClass[]): void {
    //     this.onRegionsSet.next(regions);
    // }
    //
    // dosSet(dos: DoApiClass[]): void {
    //     this.onDosSet.next(dos);
    // }
    //
    // agenciesSet(agencies: AgencyApiClass[]): void {
    //     this.onAgenciesSet.next(agencies);
    // }
    //
    // atcsSet(atcs: AtcApiClass[]): void {
    //     this.onAtcsSet.next(atcs);
    // }
}
