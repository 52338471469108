<!--Rules page-->
<div class="page-title">
    <div class="row">
        <div class="col-sm-6 mb-4">
            <h4>Prix de vente</h4>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb pt-0 float-left float-sm-right ">
                <li class="breadcrumb-item active">
                    <a href="javascript:history.back()" class="default-color">
                        <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i> {{'core.navigation.back' |
                        translate}}
                    </a>
                </li>
            </ol>
        </div>
    </div>
    <!-- main body -->
    <div class="row">

        <!--Display search select-->
        <div class="col-md-12">
            <div class="card card-statistics mb-30">
                <div class="card-body">
                    <div class="form-row">
                        <div class="col-md-4">
                            <label class="control-enseigne" for="enseigne">
                                Sélectionnez une enseigne
                            </label>
                            <div class="mb-2">
                                <ng-multiselect-dropdown id="enseigne"
                                                         [(ngModel)]="selectedEnseigne"
                                                         [placeholder]="'Sélectionner une enseigne'"
                                                         name="enseigne" [data]="enseignesList"
                                                         [settings]="dropdownSettings"
                                                         (onSelect)="getSalePrices(selectedEnseigne[0])"
                                                         (onDeSelect)="selectedEnseigne = undefined; salePriceList = []">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div *ngIf="hasAccess" class="col-md-8" style="align-self: center;text-align: center;">
                            <button class="button black medium" (click)="save()">
                                <i class="fa fa-floppy-o" aria-hidden="true"></i>
                                {{'components.category.form.save' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Display enseignes sale prices -->
        <div class="col-md-12">
            <div class="row">
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-30"
                     *ngIf="salePriceList.length > 0">
                    <div class="card card-statistics h-100">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                    <label class="control-label" for="salePriceMin">Prix de vente ht minimum</label>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label class="control-label" for="salePriceMax">Prix de vente ht maximum</label>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label class="control-label" for="salePriceRatio">Ratio</label>
                                </div>
                            </div>
                            <div class="form-row"
                                 *ngFor="let salePrice of salePriceList; let i = index">
                                <div class="col-md-4 mb-3">
                                    <div class="mb-2">
                                        <input [disabled]="!hasAccess" type="number" id="salePriceMin"
                                               name="salePriceMin"
                                               class="form-control"
                                               [(ngModel)]="salePrice.minPrice"
                                               required/>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="mb-2">
                                        <input type="number" id="salePriceMax"
                                               name="salePriceMax"
                                               class="form-control"
                                               [(ngModel)]="salePrice.maxPrice"
                                               required/>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="mb-2" style="width: 95%;">
                                        <input type="number" id="salePriceRatio"
                                               name="salePriceRatio"
                                               class="form-control"
                                               [(ngModel)]="salePrice.ratio"
                                               required/>
                                    </div>
                                    <a class="fa fa-trash text-danger action-button"
                                       (click)="deleteLine(i)"
                                       style="position: absolute;right: 0;top: 15px;cursor: pointer"></a>
                                    <a class="fa fa-plus text-success action-button"
                                       (click)="addLine(i)"
                                       style="position: absolute;right: 0;top: -20px;cursor: pointer"></a>
                                </div>
                            </div>
                            <a class="fa fa-plus text-success action-button"
                               (click)="addLine(salePriceList.length)"
                               style="position: absolute;right: 12px;cursor: pointer"></a>
                        </div>
                    </div>
                </div>
                <a *ngIf="salePriceList.length <= 0 && selectedEnseigne != undefined"
                   class="fa fa-plus text-success action-button"
                   (click)="addLine(salePriceList.length)"
                   style="position: absolute;right: 12px;cursor: pointer"></a>
            </div>
        </div>
    </div>
</div>
