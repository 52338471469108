import {Injectable, Injector} from '@angular/core';
import {AtcApiClass} from "src/app/shared/classes/api/atc-api.class";
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";


@Injectable({
    providedIn: 'root'
})
export class AtcService extends HttpServiceClass<AtcApiClass> {
    protected endpoint = 'cards/cardsFilterATC';

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected getId(item: AtcApiClass): number {
        return 0;
    }
}
