<div class="card-body">
    <ng-container *ngIf="card">
        <h3>{{card.libelle}}</h3>
        <div *ngFor="let line of card.valeur; let index = index;">
            <div *ngIf="index > 0" class="filler"></div>
            <div class="line" [ngClass]="{'even': index % 2 === 0}">
                <div class="title">
                    {{line.title}}
                </div>
                <div class="value" [style.width.%]=" 15 + (line.value1 / maxWidth) * 55">
                    <div class="icon"
                         [style.backgroundImage]="'url(\'./assets/images/app/_core/components/charts/customhorizontalline/' + line.icon + '.png\')'"></div>
                    <div class="info">
                        {{line.value1}} {{line.unit1}}
                        <span><strong>{{line.value2 | number : '1.0-0' }}</strong>%</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
