<div class="page-title">
    <div class="row" *ngIf="!isProduct">
        <div class="col-sm-6 mb-4">
            <h4>{{"components.history.title" | translate}}</h4>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb pt-0 float-left float-sm-right ">
                <li class="breadcrumb-item active">
                    <a href="javascript:history.back()" class="default-color">
                        <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i> {{'core.navigation.back' |
                        translate}}
                    </a>
                </li>
            </ol>
        </div>
    </div>
    <!-- main body -->
    <div class="row">
        <div class="col-md-12">
            <div class="card card-statistics mb-30">
                <div class="card-body">
                    <form [formGroup]="formGroupSearch">
                        <div class="form-row align-items-center">
                            <div class="col-sm-3" *ngIf="!isProduct">
                                <div class="form-group mb-0" style="position: relative;">
                                    <input placeholder="{{'components.history.search.keywords' | translate}}"
                                    (input)="search()" autofocus formControlName="code"
                                        class="form-control search-input">
                                    <button class="search-button" type="submit" style=""  (click)="search()">
                                        <i class="fa fa-search not-click"></i>
                                    </button>
                                </div>
                            </div>

                        </div>



                    </form>

                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="card card-statistics mb-30">
                <div class="card-body">

                    <ngb-tabset class="tab nav-center" (tabChange)="tabChange($event)">
                        <ngb-tab id="historyPrice" title="Prix d'achat">
                            <ng-template ngbTabContent>
                                <div class="table-responsive">
                                    <ngx-datatable style="overflow: auto;" class="bootstrap mb-30" [rows]="pricehistories" [rowHeight]="'auto'"
                                        [messages]="{emptyMessage:''}" [reorderable]="true">
                                        <ngx-datatable-footer></ngx-datatable-footer>
                                        <ngx-datatable-column name="createdDate" [width]="140">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.common.table.columns.modifiedDate" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.createdDate |
                                                date: 'dd-MM-yyyy'}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="origin" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                Origine
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template><b>{{row.origin}}</b>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="ean"  *ngIf="!isProduct" [width]="120">
                                            <ng-template ngx-datatable-header-template>
                                                Ean
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.ean}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="code"  *ngIf="!isProduct" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.common.table.columns.code" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.code}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="labelSupplier" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                Fournisseur
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.labelSupplier}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="deliveryCountryCode">
                                            <ng-template ngx-datatable-header-template>Pays livraison</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <img style="width: 30px; margin-left: 18px; height: 25px" src="assets/images/countries/{{row.deliveryCountryCode}}.jpg">
                                                <br>
                                                <b style="margin-left: 27px;">{{row.deliveryCountryCode}}</b>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="dutyFreeBuyingPrice" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.price.table.columns.dutyFreeBuyingPrice" |
                                                translate}}</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.dutyFreeBuyingPrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="allTaxesIncludedBuyingPrice" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.price.table.columns.allTaxesIncludedBuyingPrice" |
                                                translate}}</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.allTaxesIncludedBuyingPrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="dutyFreeShippingPrice" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.price.table.columns.dutyFreeShippingPrice" |
                                                translate}}</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.dutyFreeShippingPrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="allTaxesIncludedShippingPrice" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.price.table.columns.allTaxesIncludedShippingPrice"
                                                | translate}}</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.allTaxesIncludedShippingPrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="dutyFreeTotalBuyingPrice" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.price.table.columns.dutyFreeTotalBuyingPrice" |
                                                translate}}</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.dutyFreeTotalBuyingPrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="allTaxesIncludedTotalBuyingPrice" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.price.table.columns.allTaxesIncludedTotalBuyingPrice"
                                                | translate}}</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.allTaxesIncludedTotalBuyingPrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="vatAmount" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.price.table.columns.vatAmount" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.vatAmount}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </div>
                                <div class="sm-mt-20" *ngIf="pricehistories && pricehistories.length">
                                    <pagination [pagination]="pricehistoriesPageable" (onPaginate)="search($event)">
                                    </pagination>
                                </div>
                            </ng-template>
                        </ngb-tab>

                        <ngb-tab id="historyCatalogue" title="Prix de vente / Etat de publication">
                            <ng-template ngbTabContent>
                                <div class="table-responsive">
                                    <ngx-datatable style="overflow: auto;" class="bootstrap mb-30" [rows]="cataloguehistories"
                                        [rowHeight]="'auto'" [messages]="{emptyMessage:''}" [reorderable]="true">
                                        <ngx-datatable-footer></ngx-datatable-footer>

                                        <ngx-datatable-column name="createdDate">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.common.table.columns.modifiedDate" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.createdDate |
                                                date: 'dd-MM-yyyy'}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="origin" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                Origine
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template><b>{{row.origin}}</b>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="!isProduct" name="ean">
                                            <ng-template ngx-datatable-header-template>
                                                Ean
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.ean}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="!isProduct" name="code">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.common.table.columns.code" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.code}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="deliveryCountryCode">
                                            <ng-template ngx-datatable-header-template>Pays livraison</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <img style="width: 30px; margin-left: 18px; height: 25px" src="assets/images/countries/{{row.deliveryCountryCode}}.jpg">
                                                <br>
                                                <b style="margin-left: 27px;">{{row.deliveryCountryCode}}</b>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="dutyFreeSalePrice">
                                            <ng-template ngx-datatable-header-template>Prix vente HT</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.dutyFreeSalePrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="allTaxesIncludedSalePrice">
                                            <ng-template ngx-datatable-header-template>Prix vente TTC</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.allTaxesIncludedSalePrice}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="margin">
                                            <ng-template ngx-datatable-header-template>Marge</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.margin}}<span *ngIf="row.margin">%</span>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="state">
                                            <ng-template ngx-datatable-header-template>Etat</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>

                                                <span *ngIf="row.state" [ngClass]="{'badge-danger': row.state==='declined', 'badge-warning': row.state==='unpublished','badge-primary': row.state==='standby',  'badge-success': row.state==='published', 'badge-info': row.state==='selected', 'badge-secondary':row.state==='new'}"
                                                      class="badge badge-pill float-right mt-1"
                                                      style="float: none !important;padding: 10px 10px 10px;">{{'core.status.' +
                                                row.state | translate}}</span>

                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="motif">
                                            <ng-template ngx-datatable-header-template>Motif</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.motif}}</ng-template>
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </div>
                                <div class="sm-mt-20" *ngIf="cataloguehistories && cataloguehistories.length">
                                    <pagination [pagination]="cataloguehistoriesPageable" (onPaginate)="search($event)">
                                    </pagination>
                                </div>
                            </ng-template>
                        </ngb-tab>

                        <ngb-tab id="historyStock" title="{{'components.history.stock.title' | translate}}">
                            <ng-template ngbTabContent>
                                <div class="table-responsive">
                                    <ngx-datatable style="overflow: auto;" class="bootstrap mb-30" [rows]="stockhistories" [rowHeight]="'auto'"
                                        [messages]="{emptyMessage:''}" [reorderable]="true">
                                        <ngx-datatable-footer></ngx-datatable-footer>
                                        <ngx-datatable-column name="createdDate">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.common.table.columns.modifiedDate" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.createdDate |
                                                date: 'dd-MM-yyyy'}}</ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="origin" [width]="110">
                                            <ng-template ngx-datatable-header-template>
                                                Origine
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template><b>{{row.origin}}</b>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="!isProduct" name="ean">
                                            <ng-template ngx-datatable-header-template>
                                                Ean
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.ean}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column *ngIf="!isProduct" name="code">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.common.table.columns.code" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.code}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="labelSupplier">
                                            <ng-template ngx-datatable-header-template>
                                                Fournisseur
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.labelSupplier}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="deliveryCountryCode">
                                            <ng-template ngx-datatable-header-template>Pays livraison</ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <img style="width: 30px; margin-left: 18px; height: 25px" src="assets/images/countries/{{row.deliveryCountryCode}}.jpg">
                                                <br>
                                                <b style="margin-left: 27px;">{{row.deliveryCountryCode}}</b>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="dutyFreeBuyingPrice">
                                            <ng-template ngx-datatable-header-template>
                                                {{"components.history.stock.table.columns.stock" | translate}}
                                            </ng-template>
                                            <ng-template let-row="row" ngx-datatable-cell-template>{{row.stock}}
                                            </ng-template>
                                        </ngx-datatable-column>

                                    </ngx-datatable>
                                </div>
                                <div class="sm-mt-20" *ngIf="stockhistories && stockhistories.length">
                                    <pagination [pagination]="stockhistoriesPageable" (onPaginate)="search($event)">
                                    </pagination>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
            </div>
        </div>
    </div>
</div>