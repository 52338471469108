import { HttpParams } from "@angular/common/http";

export class FiltersUtils {

    static getHttpParamsFromParams(params: any): HttpParams {

        let httpParams: HttpParams = new HttpParams();
        if (params) {

            if (params.categorie && params.categorie != undefined && params.categorie.length === 1 && params.categorie[0] != null && params.categorie[0].id !== 0) {
                httpParams = httpParams.set("categorie", params.categorie[0].id);
            }

            if (params.typeCategorie && params.typeCategorie != undefined && params.typeCategorie.length > 0) {
                let listCat = "";
                for (let i = 0; i < params.typeCategorie.length; i++) {
                    listCat += params.typeCategorie[i].item_id;
                    if ( i < params.typeCategorie.length - 1){
                        listCat += "-";
                    }
                }
                httpParams = httpParams.set("typeCategorie", listCat);
            } 
            // param pour select multiple
            // if (params.deliveryCountrie) {
            //     httpParams = httpParams.set("deliveryCountry", params.deliveryCountrie[0].idDeliveryCountry);
            // }

            if (params.deliveryCountrie) {
                httpParams = httpParams.set("deliveryCountry", params.deliveryCountrie.idDeliveryCountry);
            }


        }

        return httpParams;
    }
}
