<div class="card card-statistics h-100">
    <div class="card-body">
        <div class="file-box">
            <a (click)="openExternal()" style="cursor: pointer;">
                <div class="row" style="position: relative;">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <img class="img-fluid" src="{{image}}" alt="">
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </a>
            <div class="d-block d-md-flex justify-content-between">
                <div class="d-block" style="width: 100%;">
                    <div class="p-font-cataloge">
                        <a (click)="openExternal()" style="cursor: pointer;">
                            <h6><b>{{title}}</b></h6>
                        </a>
                        <p *ngIf="!inRulesComponent">
                            <i class="fa fa-barcode" aria-hidden="true"></i>
                            <span style="font-weight: bold;">{{product.ean}}</span>
                            <small class="pull-right">FRNS <span
                                    style="font-weight: bold;">{{totalSuppliers}}</span></small>
                        </p>
                    </div>
                </div>
            </div>

            <div class="row mt-1" *ngIf="!inRulesComponent">
                <div *ngFor="let catalogue of catalogues | keyvalue" class="col-md-6 catalog-box"
                    style="margin-top: 5px;">
                    <div class="catalog-box-wrapper">
                        <p class="text-center">
                            <span class="pull-left">
                                <i *ngIf="catalogue.value.state === 'new'" ngbTooltip="Nouveau" class="fa fa-circle" aria-hidden="true"
                                    style="color: deepskyblue; cursor: alias;"
                                    (click)="history(catalogue.value.code)"></i>
                                <i *ngIf="catalogue.value.state === 'published'" ngbTooltip="Publié" class="fa fa-play-circle"
                                    aria-hidden="true" style="color: forestgreen; cursor: alias;"
                                    (click)="history(catalogue.value.code)"></i>
                                <i *ngIf="catalogue.value.state === 'unpublished'" ngbTooltip="Depublié" class="fa fa-stop-circle"
                                    aria-hidden="true" style="color: lightslategrey; cursor: alias;"
                                    (click)="history(catalogue.value.code)"></i>
                                <i *ngIf="catalogue.value.state === 'declined'" ngbTooltip="Réfusé" class="fa fa-minus-circle"
                                    aria-hidden="true" style="color: tomato; cursor: alias;"
                                    (click)="history(catalogue.value.code)"></i>
                                <i *ngIf="catalogue.value.state === 'standby'" ngbTooltip="Standby" class="fa fa-pause-circle"
                                    aria-hidden="true" style="color: orange; cursor: alias;"
                                    (click)="history(catalogue.value.code)"></i>
                                <i *ngIf="catalogue.value.state === 'selected'" ngbTooltip="Sélectionné" class="fa fa-check-circle"
                                    aria-hidden="true" style="color: yellowgreen; cursor: alias;"
                                    (click)="history(catalogue.value.code)"></i>
                                <b> {{catalogue.key}} </b>
                            </span>
                            &nbsp;
                            <span class="pull-right">
                                <small *ngIf="catalogue.value.dutyFreeTotalBuyingPrice != null && catalogue.value.supplier" [ngStyle]="{
                                    'color': '#626262'
                                        }"><b>{{catalogue.value.dutyFreeTotalBuyingPrice}}</b> €</small>
                                <i *ngIf="!catalogue.value.stock || catalogue.value.stock === 0"
                                    class="fa fa-battery-empty ml-1" aria-hidden="true"></i>
                                <i *ngIf="catalogue.value.stock > 0 && catalogue.value.stock < 33"
                                    class="fa fa-battery-quarter ml-1" aria-hidden="true"></i>
                                <i *ngIf="catalogue.value.stock >= 33 && catalogue.value.stock < 66"
                                    class="fa fa-battery-half ml-1" aria-hidden="true"></i>
                                <i *ngIf="catalogue.value.stock >= 66 && catalogue.value.stock < 100"
                                    class="fa fa-battery-three-quarters ml-1" aria-hidden="true"></i>
                                <i *ngIf="catalogue.value.stock >= 100" class="fa fa-battery-full ml-1"
                                    aria-hidden="true"></i>
                            </span>
                        </p>
                        <p class="text-center">
                            <span class="pull-left">
                                <small class="pull-right">FRNS <span
                                        style="font-weight: bold;">{{catalogue.value.totalSupplier}}</span></small>
                            </span>
                            &nbsp;
                            <span *ngIf="catalogue.value.margin && catalogue.value.supplier" class="pull-right">
                                <small *ngIf="catalogue.value.margin" [ngStyle]="{
                                     
                                    'color': catalogue.value.margin>30?'mediumseagreen':
                                        catalogue.value.margin>20?'darkgoldenrod':
                                            catalogue.value.margin>10?'chocolate':
                                                'crimson'
                                        }"><b>{{catalogue.value.margin}}</b> %</small>
                            </span>
                        </p>
                        <div *ngIf="catalogue.value.supplier" 
                            style="margin-left: -5px; margin-right: -5px;">
                            <p style="font-weight: 500;color:#5b6a6d">{{catalogue.value.supplier}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>