<div *ngIf="!productsYoukado || !productsYoukado.length" class="page-title">
    <div class="row">
        <div class="col-sm-6 mb-4">
            <h4>Aucun produit trouvé</h4>
        </div>
    </div>
</div>

<div *ngIf="productsYoukado && productsYoukado.length" style="padding-left: 15px;padding-bottom: 5px;" class="row">
    <h5 class="text-primary"><span style="color:grey">
            Total produits :</span> {{totalElements | number:'1.0-0':'fr'}}
    </h5>
</div>
<div class="row">

    <div *ngFor="let product of productsYoukado" class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-30">
        <app-list-products-item [productYoukado]="{product:product, deliveryCountries:deliveryCountries}"
            (onShowDetails)="showDetails=$event" [showDetails]="showDetails">
        </app-list-products-item>
    </div>
</div>
<div class="sm-mt-20" *ngIf="productsYoukado && productsYoukado.length">
    <pagination [pagination]="productsYoukadoPageable" (onPaginate)="paginate($event)"></pagination>
</div>