
    <div class="row">
        <div class="col-sm-6 d-inline-flex">
            <h4 class="mb-0"> Tableau de bord</h4>
            <a class="customing-dashboard-locker" data-toggle="dropdown" role="button" aria-haspopup="true"
               aria-expanded="false">
                <span class="tooltip-content" placement="bottom"
                      [ngbTooltip]="libTooltipLocker">
                    <i [ngClass]="{'fa-lock':locked, 'fa-unlock-alt':!locked, 'text-danger':locked, 'text-success':!locked}"
                       class="fa fa-lg"
                       aria-hidden="true"
                       (click)="lockedChange()"></i>
                </span>
            </a>
        </div>
    </div>





    <div class="container-fluid tabs-container tab">
            <div class="row">
                <div *ngFor="let tab of tabs" class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div
                            (click)="tabClick(tab.id)"
                            class="card tab specific-color-bckg-1"
                            [ngClass]="{'specific-color-bckg-2':currentTab === tab.id}"
                            style="">
                        {{tab.name}}
                    </div>
                </div>
            </div>
    </div>




    <!--<div class="tabs-container">-->
    <!--    <div *ngFor="let tab of tabs"-->
    <!--         (click)="tabClick(tab.id)"-->
    <!--         class="card tab specific-color-bckg-1"-->
    <!--         [ngClass]="{'specific-color-bckg-2':currentTab === tab.id}"-->
    <!--         style="">-->
    <!--        {{tab.name}}-->
    <!--    </div>-->
    <!--</div>-->
    <div class="container-fluid tabs-container">
    <filterbar-component (onFiltersChanged)="filtersChanged.next($event)"></filterbar-component>
    </div>


    <ng-container *ngFor="let tab of tabs">
        <gridster #gridster [options]="options" style="background:none;" *ngIf="currentTab === tab.id">
            <ng-container *ngFor="let tile of dashboard">
                <gridster-item *ngIf="tile.onglet === currentTab" [item]="tile">
                    <charts-component [type]="tile.panel"
                                      [divId]="tile.divId"
                                      [card]="tile.card"
                                      [loading]="this.cardsLoadMap.get(tile.divId)"
                                      (onGooglemapsComponentReady)="googlemapsComponentReady($event)"></charts-component>
                </gridster-item>
            </ng-container>
        </gridster>
    </ng-container>

    <!--<ngb-tab>
        <ng-template ngbTabTitle>Catalogue</ng-template>
        <ng-template ngbTabContent>

            <div class="row">

                <div class="col-12 mb-30 ">
                    <div class="card card-statistics h-100">
                        <div class="card-body">
                            <div class="col-sm-7 country-block">
                                <div class="country-space"
                                    *ngFor="let deliveryCountry of deliveryCountries; let i = index"
                                    style="display: inline-block;">
                                    <span *ngIf="deliveryCountry != null && deliveryCountry != undefined"
                                        (click)="changeDeliveryCountry(deliveryCountry.code)"
                                        [ngClass]="{'country-tabs-image-active': deliveryCountry.code == deliveryCountryActif, 'country-tabs-image-inactive': deliveryCountry.code !== deliveryCountryActif}">
                                        <img style="width: 35px; margin-right: 2px;height:30px;cursor:pointer"
                                            src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                                    </span>
                                    <h6 *ngIf="deliveryCountry != null && deliveryCountry != undefined"
                                        [ngClass]="{'country-tabs-label': deliveryCountry.code == deliveryCountryActif}"
                                        style="text-align: center;width: 38px;;cursor:pointer">
                                        {{deliveryCountry.code}}
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-3 col-lg-6 col-md-6 mb-30">
                                    <div class="card card-statistics h-100">
                                        <div [style]="totalProduct == null ? 'text-align: center' : ''"
                                            class="card-body">

                                            <img *ngIf="totalProduct == null" style="width: 54px"
                                                src="assets/images/pre-loader/loader-01.svg">
                                            <div *ngIf="totalProduct != null" class="clearfix">
                                                <div class="float-left">
                                                    <span class="text-primary">
                                                        <i class="fa fa-gift highlight-icon" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div class="float-right text-right">
                                                    <p class="card-text text-dark">Produits</p>
                                                    <h4>{{totalProduct}}</h4>
                                                </div>
                                            </div>
                                            <p class="text-muted pt-3 mb-0 mt-2 border-top">
                                                <i class="fa fa-gift mr-1" aria-hidden="true"></i> Total produits
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 mb-30">
                                    <div class="card card-statistics h-100">
                                        <div [style]="publishedProduct == null ? 'text-align: center' : ''"
                                            class="card-body">
                                            <img *ngIf="publishedProduct == null" style="width: 54px"
                                                src="assets/images/pre-loader/loader-01.svg">
                                            <div *ngIf="publishedProduct != null" class="clearfix">
                                                <div class="float-left">
                                                    <span class="text-success">
                                                        <i class="fa fa-play-circle highlight-icon"
                                                            aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div class="float-right text-right">
                                                    <p class="card-text text-dark">Produits</p>
                                                    <h4>{{publishedProduct}}</h4>
                                                </div>
                                            </div>
                                            <p class="text-muted pt-3 mb-0 mt-2 border-top">
                                                <i class="fa fa-play-circle mr-1" aria-hidden="true"></i> Publiés
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 mb-30">
                                    <div class="card card-statistics h-100">
                                        <div [style]="unpublishedProduct == null ? 'text-align: center' : ''"
                                            class="card-body">
                                            <img *ngIf="unpublishedProduct == null" style="width: 54px"
                                                src="assets/images/pre-loader/loader-01.svg">
                                            <div *ngIf="unpublishedProduct != null" class="clearfix">
                                                <div class="float-left">
                                                    <span class="text-danger">
                                                        <i class="fa fa-stop-circle highlight-icon"
                                                            aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div class="float-right text-right">
                                                    <p class="card-text text-dark">Produits</p>
                                                    <h4>{{unpublishedProduct}}</h4>
                                                </div>
                                            </div>
                                            <p class="text-muted pt-3 mb-0 mt-2 border-top">
                                                <i class="fa fa-stop-circle mr-1" aria-hidden="true"></i> Dépublié
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 mb-30">
                                    <div class="card card-statistics h-100">
                                        <div [style]="standbyProduct == null ? 'text-align: center' : ''"
                                            class="card-body">
                                            <img *ngIf="standbyProduct == null" style="width: 54px"
                                                src="assets/images/pre-loader/loader-01.svg">
                                            <div *ngIf="standbyProduct != null" class="clearfix">
                                                <div class="float-left">
                                                    <span class="text-warning">
                                                        <i class="fa fa-pause-circle highlight-icon"
                                                            aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div class="float-right text-right">
                                                    <p class="card-text text-dark">Produits</p>
                                                    <h4>{{standbyProduct}}</h4>
                                                </div>
                                            </div>
                                            <p class="text-muted pt-3 mb-0 mt-2 border-top">
                                                <i class="fa fa-pause-circle mr-1" aria-hidden="true"></i> Stand-by
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-xl-7 mb-30">
                    <div class="card card-statistics h-100">
                        <div class="card-body">

                            <div class="tab nav-border">
                                <div class="d-block">
                                    <div class="d-block">
                                        <h5 class="card-title">Nombre de produits</h5>
                                    </div>
                                    <div [style]="productNumberTable == null ? 'text-align: center;padding-top: 20px;' : 'padding-top: 20px;'"
                                        class="table-responsive mb-10">
                                        <img *ngIf="productNumberTable == null" src="assets/images/pre-loader/loader-01.svg">
                                        <table *ngIf="productNumberTable != null" class="table center-aligned-table">
                                            <tbody></tbody>
                                            <thead>
                                                <tr class="text-dark">
                                                    <th  *ngFor="let header of productNumberTable.headers; let i = index">{{header}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let row of productNumberTable.rows; let i = index">
                                                    <td>
                                                        <img style="width: 35px; margin-right: 2px;height:30px;cursor:pointer"
                                                            src="assets/images/countries/{{row.dataRow[0]}}.jpg">
                                                    </td>
                                                    <td>
                                                        {{row.dataRow[1]}}</td>
                                                    <td>
                                                        {{row.dataRow[2]}}</td>
                                                    <td>
                                                        {{row.dataRow[3]}} </td>
                                                    <td>
                                                        {{row.dataRow[4]}}</td>
                                                    <td>
                                                        {{row.dataRow[5]}}</td>
                                                    <td>
                                                        {{row.dataRow[6]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 mb-30">
                    <div class="card card-statistics">
                        <div class="card-body">
                            <h5 class="card-title">Evolution nombre de produits publiés </h5>

                            <ngb-tabset class="tab tab-nav">
                                <ngb-tab>
                                    <ng-template ngbTabTitle>Jour</ng-template>
                                    <ng-template ngbTabContent>
                                        <div [style]="productState == null ? 'text-align: center;' : ''"
                                            class="table-responsive mb-10">
                                            <img *ngIf="productState == null"
                                                src="assets/images/pre-loader/loader-01.svg">
                                            <table *ngIf="productState != null" class="table center-aligned-table">
                                                <tbody></tbody>
                                                <thead>
                                                    <tr class="text-dark">
                                                        <th></th>
                                                        <th>Pays de livraison</th>
                                                        <th>J-1</th>
                                                        <th>J</th>
                                                        <th>Evolution</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let deliveryCountry of deliveryCountries; let i = index">
                                                        <td><i class="fa fa-circle text-success"></i></td>
                                                        <td
                                                            *ngIf="deliveryCountry != null && deliveryCountry != undefined">
                                                            <img style="width: 35px; margin-right: 2px;height:30px;cursor:pointer"
                                                                src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                                                        </td>
                                                        <td>2,009 </td>
                                                        <td>5,230</td>
                                                        <td>8.01% <i class="fa fa-level-up text-success"></i></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                                <ngb-tab>
                                    <ng-template ngbTabTitle>Semaine</ng-template>
                                    <ng-template ngbTabContent>
                                        <div [style]="productState == null ? 'text-align: center;' : ''"
                                            class="table-responsive mb-10">
                                            <img *ngIf="productState == null"
                                                src="assets/images/pre-loader/loader-01.svg">
                                            <table *ngIf="productState != null" class="table center-aligned-table">
                                                <tbody></tbody>
                                                <thead>
                                                    <tr class="text-dark">
                                                        <th></th>
                                                        <th>Pays de livraison</th>
                                                        <th>S-1</th>
                                                        <th>S</th>
                                                        <th>Evolution</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let deliveryCountry of deliveryCountries; let i = index">
                                                        <td><i class="fa fa-circle text-success"></i></td>
                                                        <td
                                                            *ngIf="deliveryCountry != null && deliveryCountry != undefined">
                                                            <img style="width: 35px; margin-right: 2px;height:30px;cursor:pointer"
                                                                src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                                                        </td>
                                                        <td>2,009 </td>
                                                        <td>5,230</td>
                                                        <td>8.01% <i class="fa fa-level-up text-success"></i></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                                <ngb-tab>
                                    <ng-template ngbTabTitle>Mois</ng-template>
                                    <ng-template ngbTabContent>
                                        <div [style]="productState == null ? 'text-align: center;' : ''"
                                            class="table-responsive mb-10">
                                            <img *ngIf="productState == null"
                                                src="assets/images/pre-loader/loader-01.svg">
                                            <table *ngIf="productState != null" class="table center-aligned-table">
                                                <tbody></tbody>
                                                <thead>
                                                    <tr class="text-dark">
                                                        <th></th>
                                                        <th>Pays de livraison</th>
                                                        <th>M-1</th>
                                                        <th>M</th>
                                                        <th>Evolution</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let deliveryCountry of deliveryCountries; let i = index">
                                                        <td><i class="fa fa-circle text-success"></i></td>
                                                        <td
                                                            *ngIf="deliveryCountry != null && deliveryCountry != undefined">
                                                            <img style="width: 35px; margin-right: 2px;height:30px;cursor:pointer"
                                                                src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                                                        </td>
                                                        <td>2,009 </td>
                                                        <td>5,230</td>
                                                        <td>8.01% <i class="fa fa-level-up text-success"></i></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
            </div>

            &lt;!&ndash;<div class="row">
                <div class="col-12 mb-30 ">
                    <div class="card card-statistics h-100">
                        <div class="card-body">
                            <h5 class="card-title">Produits publiés par fournissseurs</h5>

                            <div class="col-sm-7 country-block">
                                <div class="country-space"
                                    *ngFor="let deliveryCountry of deliveryCountries; let i = index"
                                    style="display: inline-block;">
                                    <span *ngIf="deliveryCountry != null && deliveryCountry != undefined"
                                        (click)="deliveryCountryActif=deliveryCountry.code"
                                        [ngClass]="{'country-tabs-image-active': deliveryCountry.code == deliveryCountryActif, 'country-tabs-image-inactive': deliveryCountry.code !== deliveryCountryActif}">
                                        <img style="width: 35px; margin-right: 2px;height:30px;cursor:pointer"
                                            src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                                    </span>
                                    <h6 *ngIf="deliveryCountry != null && deliveryCountry != undefined"
                                        [ngClass]="{'country-tabs-label': deliveryCountry.code == deliveryCountryActif}"
                                        style="text-align: center;width: 38px;;cursor:pointer">
                                        {{deliveryCountry.code}}
                                    </h6>
                                </div>
                            </div>

                            <div [style]="productState == null ? 'text-align: center;' : ''">
                                <img *ngIf="productState == null" src="assets/images/pre-loader/loader-01.svg">
                                <div *ngIf="productState != null">
                                    <div style="display: block">
                                        <canvas baseChart [data]="barChartData" [options]="barChartOptions"
                                            [plugins]="barChartPlugins" [type]="barChartType"
                                            (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                                        </canvas>
                                    </div>
                                    &lt;!&ndash; <button mat-button mat-raised-button color="primary"
                                        (click)="randomize()">Update</button> &ndash;&gt;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>&ndash;&gt;

            &lt;!&ndash;<div class="row">
                <div class="col-xl-6 mb-30">
                    <div class="card h-100">
                        <div class="card card-statistics h-100">
                            <div class="card-body">
                                <h5 class="card-title">Nombre de produits par quartile de marge</h5>

                                <div class="col-sm-12 country-block">
                                    <div *ngFor="let deliveryCountry of deliveryCountries; let i = index"
                                        style="display: inline-block;padding-left: 28px;">
                                        <span *ngIf="deliveryCountry != null && deliveryCountry != undefined"
                                            (click)="deliveryCountryActif=deliveryCountry.code"
                                            [ngClass]="{'country-tabs-image-active': deliveryCountry.code == deliveryCountryActif, 'country-tabs-image-inactive': deliveryCountry.code !== deliveryCountryActif}">
                                            <img style="width: 25px; margin-right: 2px;height:20px;cursor:pointer"
                                                src="assets/images/countries/{{deliveryCountry.code}}.jpg">
                                        </span>
                                        <h6 *ngIf="deliveryCountry != null && deliveryCountry != undefined"
                                            [ngClass]="{'country-tabs-label': deliveryCountry.code == deliveryCountryActif}"
                                            style="text-align: center;width: 38px;;cursor:pointer">
                                            {{deliveryCountry.code}}
                                        </h6>
                                    </div>
                                </div>

                                <div [style]="productState == null ? 'text-align: center;' : ''">
                                    <img *ngIf="productState == null" src="assets/images/pre-loader/loader-01.svg">

                                    <div *ngIf="productState != null">
                                        <div class="row">
                                            <div class="col-6 col-sm-6 xs-mb-20">
                                                <h6>Taux de marge théorique global : </h6>
                                            </div>
                                            <div class="col-6 col-sm-4 xs-mb-20">
                                                <h6 class="text-info">30% </h6>
                                            </div>

                                        </div>
                                        <div>

                                            <div *ngIf="productState != null">
                                                <div class="table-responsive mb-10">
                                                    <table class="table center-aligned-table">
                                                        <tbody></tbody>
                                                        <thead>
                                                            <tr class="text-dark">
                                                                <th>
                                                                    <0%</th>
                                                                <th>0 à 10%</th>
                                                                <th>10 à 20%</th>
                                                                <th>20 à 25% </th>
                                                                <th>25 à 30%</th>
                                                                <th>30 à 40%</th>
                                                                <th>>40%</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>5</td>
                                                                <td>400</td>
                                                                <td>7 000</td>
                                                                <td>8 000</td>
                                                                <td>10 00</td>
                                                                <td>900</td>
                                                                <td>300</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div>
                                                    <div>
                                                        <div style="display: block">
                                                            <canvas [options]="doughnutChartOptions" baseChart
                                                                [data]="doughnutChartData" [type]="doughnutChartType">
                                                            </canvas>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>&ndash;&gt;

                &lt;!&ndash; <div class="col-xl-6 mb-30">
                    <div class="card card-statistics">
                        <div class="card-body">
                            <div class="claerfix">
                                <h5 class="card-title">Nombre De Produits Par Quartile De Marge</h5>
                            </div>
                            <div class="row">
                                <div class="col-lg-7">
                                    <div>
                                        <canvas [options]="doughnutChartOptions" baseChart [data]="doughnutChartData"
                                            [type]="doughnutChartType">
                                        </canvas>

                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <h3>$50,500 </h3>
                                    <div class="row no-gutters">
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-12 mt-30">
                                            <b>Member Commission </b>
                                            <p class="text-success">$4,564</p>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-12 mt-30">
                                            <b>Product sales</b>
                                            <p class="text-info">$1,616</p>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4 col-lg-12 mt-30">
                                            <b>Consulting</b>
                                            <p class="text-danger">$6,546</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> &ndash;&gt;
        </ng-template>
    </ngb-tab>

    <ngb-tab>
        <ng-template ngbTabTitle>Vente</ng-template>
        <ng-template ngbTabContent>
        </ng-template>
    </ngb-tab>-->