<!-- Rules page -->
<div class="page-title">
    <div class="row">
        <div class="col-sm-6 mb-4">
            <h4>{{"components.rules.title" | translate}} d'exclusion</h4>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb pt-0 float-left float-sm-right ">
                <li class="breadcrumb-item active">
                    <a href="javascript:history.back()" class="default-color">
                        <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i>
                        {{'core.navigation.back' | translate}}
                    </a>
                </li>
            </ol>
        </div>
    </div>
    <!-- main body -->
    <div class="row">

        <!-- Search rule -->
        <div class="col-md-12">
            <div class="card card-statistics mb-30">
                <div class="card-body">
                    <div class="form-row">

                        <!-- Choose rule name -->
                        <div class="col-md-2-5">
                            <label class="control-label"
                                   style="display: flex;justify-content: center;">Nom règle/ Valeur règle</label>
                            <div class="mb-2">
                                <input type="text"
                                       name="searchRuleName"
                                       id="searchRuleName"
                                       class="form-control"
                                       [(ngModel)]="searchRuleName"
                                       (ngModelChange)="this.searchRuleNameUpdate.next($event)">
                            </div>
                        </div>

                        <!-- Choose enseigne -->
                        <div class="col-md-2-5">
                            <label class="control-label"
                                   style="display: flex;justify-content: center;">Enseigne</label>
                            <div class="mb-2">
                                <ng-multiselect-dropdown id="enseigne"
                                                         name="enseigne"
                                                         [(ngModel)]="selectedEnseigne"
                                                         [placeholder]="'Sélectionner une enseigne'"
                                                         [settings]="dropdownSettingsEnseignesSearch"
                                                         [data]="allEnseignesList"
                                                         (onSelect)="getRulesList()"
                                                         (onDeSelect)="getRulesList()">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>

                        <!-- Choose rule type -->
                        <div class="col-md-2-5">
                            <label class="control-label"
                                   style="display: flex;justify-content: center;">Type</label>
                            <div class="mb-2">
                                <ng-multiselect-dropdown id="ruleType"
                                                         name="ruleType"
                                                         [(ngModel)]="selectedRuleType"
                                                         [placeholder]="'Sélectionner un type'"
                                                         [settings]="dropdownSettingsRuleType"
                                                         [data]="allRuleTypesList"
                                                         (onSelect)="getRulesList()"
                                                         (onDeSelect)="getRulesList()">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>

                        <!-- Choose rule activeness -->
                        <div class="col-md-2-5 remember-checkbox"
                             style="display: flex;justify-content: center;align-items: center;">
                            <input id="isRuleActive"
                                   name="isRuleActive"
                                   type="checkbox"
                                   class="form-control"
                                   [checked]="this.isRuleActive"
                                   (click)="getRulesList('activeButton')">
                            <label for="isRuleActive">Active</label>
                        </div>

                        <!-- Create new rule -->
                        <div *ngIf="hasAccess" class="col-md-2-5"
                             style="display: flex;justify-content: center;align-items: center;">
                            <a class="button xx-small"
                               (click)="createRule()">
                                <i class="fa fa-plus" aria-hidden="true" style="font-size: 18px;"></i>
                                NOUVEAU
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Display rules cards -->
        <div class="col-md-12">
            <div class="row">


                <div *ngFor="let rule of displayedRules"
                     (click)="editRule(rule)"
                     class="col-lg-4 col-md-4 col-sm-6 mb-30">
                    <div class="card card-statistics h-100 card_rules">
                        <div class="card-body" style="cursor: pointer;">
                            <h6 class="card-title">{{rule.name}}</h6>

                            <ul class="addresss-info invoice-addresss list-unstyled">
                                <li>
                                    <b>Type : </b><span>{{rule.type}}</span>
                                </li>
                                <li style="line-height: 32px;"><b>Enseignes : </b>
                                    <span *ngFor="let ruleEnseigne of rule.ruleDtos; let i = index"
                                          style="color: white;margin: 2px;padding:5px"
                                          [ngStyle]="{'background-color':ruleEnseigne.active ? '#84ba3f' : '#dc3545'}">
                                            {{ruleEnseigne.enseigneDto.nomEnseigne}}
                                    </span>
                                </li>
                                <li>
                                    <b> Pour qui : </b>
                                    <span *ngFor="let property of rule.ruleToEnseigne">
                                        <span *ngIf="property.valeurPropriete"
                                              style="background-color: #eeeeee;border-radius: 10px;margin: 2px;padding:0 5px">
                                            {{property.nomPropriete}} -> {{property.valeurPropriete}}
                                        </span>
                                    </span>

                                    <span *ngFor="let ruleDto of rule.ruleDtos">
                                            <span *ngFor="let property of ruleDto.ruleToEnseigne"
                                                  style="background-color: #eeeeee;border-radius: 10px;margin: 2px;padding:0 5px">
                                            {{property.nomPropriete}} -> {{property.valeurPropriete}}
                                            </span>
                                    </span>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


            </div>

            <div class="sm-mt-20" *ngIf="displayedRules && displayedRules.length">
                <pagination [pagination]="rulePageable" (onPaginate)="paginate($event)"></pagination>
            </div>
        </div>
    </div>
</div>

<!-- Rules edit modal -->
<app-model class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true"
           id="editModal">
    <div *ngIf="selectedRule" class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title" id="exampleModalLongTitle">
                    <div class="section-title mb-10"
                         style="font-size: 150%;font-weight: bold;margin-left: 40px;">
                        <input type="text"
                               style="width: 600px;border: 1px solid #dcd9d9;"
                               name="editRuleName"
                               placeholder="SAISIR NOM REGLE"
                               class="form-control modal-title-input-newRule"
                               [(ngModel)]="selectedRule.name">
                    </div>
                    <ngb-alert style="text-align: center;" (close)="noRuleName=false" *ngIf="noRuleName"
                               type="danger">
                        Merci de saisir un nom de règle
                    </ngb-alert>
                </div>

                <div class="mb-4">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                            (click)="closeModal('editModal')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div *ngIf="!selectedRule.idRule;then newRuleIcon else editRuleIcon"></div>
                    <ng-template #newRuleIcon>
                        <span class="pull-right mr-3">
                            <i class="fa fa-file" aria-hidden="true" style="color: deepskyblue; font-size: 21px;"></i>
                        </span>
                    </ng-template>
                    <ng-template #editRuleIcon>
                        <span class="pull-right mr-3">
                            <i class="fa fa-pencil text-primary" aria-hidden="true" style="font-size: 21px;"></i>
                        </span>
                    </ng-template>
                </div>
            </div>
            <div class="modal-body">
                <div class="card card-statistics mb-30">
                    <div class="card-body">

                        <!-- Enseignes + For who -->
                        <div class="form-row">
                            <ngb-tabset class="tab nav-center" style="width: 100%;">
                                <ngb-tab title="Enseignes">
                                    <ng-template ngbTabContent>
                                        <div style="display: flex;align-items: center;justify-content: space-between;">


                                            <!-- Enseignes input -->
                                            <div class="col-md-4 mb-3">
                                                <label class="control-label"
                                                       style="display: flex;justify-content: center;">Enseignes</label>
                                                <div class="mb-2">
                                                    <ng-multiselect-dropdown id="editRuleEnseigne"
                                                                             name="editRuleEnseigne"
                                                                             [(ngModel)]="selectedRule.enseigne"
                                                                             [placeholder]="'Sélectionner une enseigne'"
                                                                             [settings]="dropdownSettingsEnseignes"
                                                                             [data]="allEnseignesList"
                                                                             (onSelect)="onSelectedEnseigne($event)"
                                                                             (onDeSelect)="onDeSelectedEnseigne($event)">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>

                                            <div class="col-md-4 mb-3">
                                                <label class="control-label"
                                                       style="display: flex;justify-content: center;">Type</label>
                                                <div class="mb-2">
                                                    <ng-multiselect-dropdown id="editRuleType"
                                                                             name="editRuleType"
                                                                             [(ngModel)]="ruleType"
                                                                             [placeholder]="'Sélectionner un type'"
                                                                             [settings]="dropdownSettingsRuleType"
                                                                             [data]="allRuleTypesList"
                                                                             (onSelect)="resetValues(false)"
                                                                             (onDeSelect)="resetValues(true)">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>

                                            <!-- Active input -->
                                            <div class="col-md-4 mb-3"
                                                 style="display: flex;flex-direction: column;align-items: center;"
                                                 *ngIf="selectedRule.ruleDtos && selectedRule.ruleDtos.length > 0">
                                                <label class="control-label">Règle active</label>
                                                <div class="col-md-3 remember-checkbox"
                                                     *ngFor="let rule of selectedRule.ruleDtos; let i = index">
                                                    <input id="editRuleActive_{{i}}"
                                                           name="editRuleActive_{{i}}"
                                                           type="checkbox"
                                                           class="form-control"
                                                           style="cursor:pointer;"
                                                           [(ngModel)]="rule.active"
                                                           [checked]="rule.active">
                                                    <label for="editRuleActive_{{i}}"
                                                           style="white-space: nowrap;">
                                                        {{rule.enseigneDto.nomEnseigne}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                                <ngb-tab title="Pour qui ?"
                                         *ngIf=" selectedRule.enseigne && selectedRule.enseigne.length > 0">
                                    <ng-template ngbTabContent>

                                        <!-- For who input -->
                                        <div class="accordion gray plus-icon round">

                                            <div class="row">
                                                <div *ngFor="let enseigne of enseignesProperties | keyvalue; let j = index"
                                                     class="col-xl-3 mb-30">
                                                    <div class="acd-group {{showEnseigne.get(enseigne.key) ? 'acd-active' :''}}"
                                                         id="wrapDiv{{enseigne.key}}">
                                                        <a href="javascript:void(0);" class="acd-heading"
                                                           (click)='OpenAccordion("submenu".concat(enseigne.key),"wrapDiv".concat(enseigne.key))'>{{enseigne.key}}</a>

                                                        <div id="submenu{{enseigne.key}}"
                                                             class="acd-des {{showEnseigne.get(enseigne.key) ? ' show' :''}}">
                                                            <div class="form-group">
                                                                <ng-container
                                                                        *ngFor="let property of enseigne.value; let j = index">
                                                                    <label class="control-label">
                                                                        {{property.propertyLabel}}
                                                                    </label>
                                                                    <div class="mb-2">
                                                                        <ng-multiselect-dropdown id="forWho_{{j}}"
                                                                                                 name="forWho"
                                                                                                 [ngModelOptions]="{standalone: true}"
                                                                                                 [(ngModel)]="property.selectedPropertyValues"
                                                                                                 [placeholder]="'Sélectionner pour qui'"
                                                                                                 [settings]="dropdownSettingsForWho"
                                                                                                 [data]="property.propertyValues">
                                                                        </ng-multiselect-dropdown>
                                                                    </div>
                                                                </ng-container>

                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                            </ngb-tabset>
                        </div>

                        <!-- Row of rule types specific inputs -->
                        <div *ngIf="ruleType && ruleType.length > 0"
                             class="form-row">

                            <!-- Rule type : Categories -->
                            <div *ngIf="ruleType[0] == 'categorie'" class="col-md-12">

                                <!-- Category tree search form -->
                                <div class="card card-statistics mb-30">
                                    <form style="padding: 10px;" [formGroup]="formGroupSearch">
                                        <div class="form-row align-items-center">
                                            <div class="col-md-3">
                                                <div class="form-group mb-0"
                                                     style="position: relative;padding-right: 5px;">
                                                    <input placeholder="{{'components.category.search.keywords' | translate}}"
                                                           formControlName="keywords" class="form-control search-input"
                                                           (input)="searchCriteriaCategoryUpdate()">
                                                    <button class="search-button" type="submit" style="">
                                                        <i class="fa fa-search not-click"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="col-md-2-5-filter">
                                                <div class="form-group mb-0">
                                                    <div class="form-check">
                                                        <input type="radio" id="searchMain"
                                                               formControlName="searchFilter" value="searchMain"
                                                               (click)="searchCriteriaCategoryUpdate()">
                                                        <label class="form-check-label ml-2" for="searchMain">
                                                            {{'components.category.form.main' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2-5-filter">
                                                <div class="form-group mb-0">
                                                    <div class="form-check">
                                                        <input type="radio" id="searchSpecific"
                                                               formControlName="searchFilter" value="searchSpecific"
                                                               (click)="searchCriteriaCategoryUpdate()">
                                                        <label class="form-check-label ml-2" for="searchSpecific">
                                                            {{'components.category.form.specific' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2-5-filter">
                                                <div class="form-group mb-0">
                                                    <div class="form-check">
                                                        <input type="radio" id="searchService"
                                                               formControlName="searchFilter" value="searchService"
                                                               (click)="searchCriteriaCategoryUpdate()">
                                                        <label class="form-check-label ml-2" for="searchService">
                                                            {{'components.category.form.service' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-2-5-filter">
                                                <div class="form-group mb-0">
                                                    <div class="form-check">
                                                        <input type="radio" id="searchAnimation"
                                                               formControlName="searchFilter" value="searchAnimation"
                                                               (click)="searchCriteriaCategoryUpdate()">
                                                        <label class="form-check-label ml-2" for="searchAnimation">
                                                            {{'components.category.form.animation' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="categories != null" style="padding-top: 18px;" class="col-lg-3 col-md-4 col-sm-6">
                                                <ng-multiselect-dropdown formControlName="searchTypeCategory"
                                                                         [settings]="dropDownCategorySettings"
                                                                         [data]="dropdownListTypeCategory"
                                                                         [placeholder]="'Type offre'"
                                                                         (onSelect)="searchCriteriaCategoryUpdate()"
                                                                         (onDeSelect)="searchCriteriaCategoryUpdate()"
                                                                         class="card">
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="row">
                                    <div class="col-xl-6 mb-30">
                                        <!-- Category tree -->
                                        <div class="card card-statistics mb-30">
                                            <div class="card-body">
                                                <h6>Choisir une catégorie à exclure</h6>
                                                <div style="text-align: center;">
                                                    <img *ngIf="categories == null"
                                                         style="width: 140px;text-align: center;"
                                                         src="assets/images/pre-loader/loader-01.svg">
                                                </div>
                                                <categorytree-component [data]="categories"
                                                                        [(ngModel)]="selectedCategoriesInTree"
                                                                        [searchCriteria]="searchCriteriaCategories"
                                                                        (onChange)="categoryChange()"
                                                                        (onSearch)="searchCategory($event)"
                                                                        [collapse]="false"
                                                                        [hasCheckboxesOnlyForLastChild]="false"
                                                                        [showStatus]="true"
                                                                        [isRules]="true"
                                                                        [isRulesCatalogue]="false"
                                                                        style="cursor: pointer;">
                                                    {{'components.catalog.sidebar.categories' | translate}}
                                                </categorytree-component>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-xl-6 mb-30">
                                        <!-- Categories selected history -->
                                        <div class="card card-statistics mb-30">
                                            <div class="card-body">
                                                <h6>Catégorie(s) exclue(s)</h6>
                                                <div style="display: flex;"
                                                     *ngFor="let treeCategory of treeCatgeriesSelected; let index = index;">
                                                    <span style="display: flex;"
                                                          *ngFor="let category of treeCategory; let index = index;">
                                                            <i style="padding-left: 5px;padding-right: 5px;padding-top: 3px;font-size: 15px;"
                                                               *ngIf="index!=0" class="fa fa-chevron-right"
                                                               aria-hidden="true"></i>
                                                        <span style="font-size: 14px;" class="right-nav-text">{{category}}</span>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <!-- Rule type : Products -->
                            <div *ngIf="ruleType[0] == 'produit'" class="col-md-12">

                                <!-- Product input and check -->
                                <div class="card card-statistics mb-30">
                                    <div class="card-body">
                                        <h6>Entrer le(s) code(s) ou EAN du(es) produit(s) à exclure</h6>
                                        <!--                                        <span *ngIf="wrongCodeErrorMessage"-->
                                        <!--                                              style="position: absolute;left: 50%;padding: 14px;background-color: #DC3545;color: white;border-radius: 10px;">-->
                                        <!--                                            Produit(s) introuvable(s) :-->
                                        <!--                                            <span *ngFor="let code of undefinedCodes">{{code}};</span>-->
                                        <!--                                        </span>-->
                                        <span *ngIf="codeAlreadyInListErrorMessage"
                                              style="position: absolute;left: 45%;padding: 14px;background-color: #DC3545;color: white;border-radius: 10px;">
                                            Produit(s) déjà présent(s) dans la liste :
                                            <span *ngFor="let code of duplicatedCodes">{{code}};</span>
                                        </span>
                                        <div style="display: flex;">
                                            <input type="text"
                                                   class="form-control"
                                                   name="editRuleValueProduct"
                                                   style="width: 33%"
                                                   [(ngModel)]="productToCheck">
                                            <button (click)="checkProductToExclude()"
                                                    class="btn btn-secondary">
                                                Valider
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Products selected history -->
                                <div *ngIf="productsToExclude && productsToExclude.length > 0"
                                     class="card card-statistics mb-30">
                                    <div class="card-body">
                                        <h6>Produit(s) exclu(s)</h6>
                                        <div style="display: flex;flex-wrap: wrap;margin-left: 40px;">
                                            <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-30"
                                                 *ngFor="let product of productsToExclude; let i = index;"
                                                 style="margin: 0 10px 0 10px;"
                                                 [ngStyle]="{'margin-top': i > 5 ? '35px' : '0'}">
                                                <i (click)="deleteFromProductsToExclude(i)"
                                                   class="fa fa-times-circle-o"
                                                   style="color: red;cursor: pointer;font-size: 2em;"></i>
                                                <app-list-products-item
                                                        [productYoukado]="{product:product, deliveryCountries:null}"
                                                        [inRulesComponent]="false">
                                                </app-list-products-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Rule type : Suppliers -->
                            <div *ngIf="ruleType[0] == 'fournisseur'" class="col-md-6">

                                <!-- Supplier input -->
                                <div class="card card-statistics mb-30">
                                    <div class="card-body">
                                        <h6>Sélectionner le(s) fournisseur(s) à exclure</h6>
                                        <ng-multiselect-dropdown id="supplier" name="supplier"
                                                                 #ngSupplierSelect
                                                                 [(ngModel)]="selectedSuppliers"
                                                                 [placeholder]="'Sélectionner un fournisseur'"
                                                                 [settings]="dropdownSettingsSuppliers"
                                                                 [data]="allSuppliers">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>

                            <!-- Rule type : Brands -->
                            <div *ngIf="ruleType[0] == 'marque'" class="col-md-6">

                                <!-- Brand input -->
                                <div class="card card-statistics mb-30">
                                    <div class="card-body">
                                        <h6>Sélectionner le(s) marque(s) à exclure</h6>
                                        <ng-multiselect-dropdown id="brand" name="brand"
                                                                 #ngBrandSelect
                                                                 [(ngModel)]="selectedBrands"
                                                                 [placeholder]="'Sélectionner une marque'"
                                                                 [settings]="dropdownSettingsBrands"
                                                                 [data]="allBrands">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>

                            <!-- Rule type : Range -->
                            <div *ngIf="ruleType[0] == 'range'" class="col-md-12">

                                <!-- Ranges input and check -->
                                <div class="card card-statistics mb-30">
                                    <div class="card-body">
                                        <h6>Entrer l'intervalle de prix de vente TTC à exclure</h6>
                                        <span *ngIf="minMoreThanMax"
                                              style="position: absolute;bottom: -50px;padding: 14px;background-color: #DC3545;color: white;border-radius: 10px;">
                                            Le minimum est plus grand que le maximum
                                        </span>
                                        <div style="display: flex;justify-content: space-between;">
                                            <div class="slidecontainer">
                                                <input type="text"
                                                       [(ngModel)]="selectedValueMin"
                                                       class="form-control"
                                                       id="rangeMin">
                                                <input type="range"
                                                       value="{{selectedValueMin}}"
                                                       [(ngModel)]="selectedValueMin"
                                                       min="0"
                                                       max="30000"
                                                       class="slider"
                                                       id="rangeMinSlider">
                                            </div>
                                            <div class="slidecontainer">
                                                <input type="text"
                                                       [(ngModel)]="selectedValueMax"
                                                       class="form-control"
                                                       id="rangeMax">
                                                <input type="range"
                                                       value="{{selectedValueMax}}"
                                                       [(ngModel)]="selectedValueMax"
                                                       min="0"
                                                       max="30000"
                                                       class="slider"
                                                       id="rangeMaxSlider">
                                            </div>
                                            <button (click)="checkRangeToExclude()"
                                                    class="btn btn-secondary"
                                                    style="width: 7%;height: 42px;margin-right: 50px;margin-top: 15px;">
                                                Valider
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <!-- Ranges selected history -->
                                <div *ngIf="rangesToExclude && rangesToExclude.length > 0"
                                     class="card card-statistics mb-30" style="margin-top: 50px;">
                                    <div class="card-body">
                                        <h6>Intervalle(s) de prix exclu(s)</h6>
                                        <div style="display: flex;justify-content: space-between;margin: 10px 0 10px 0;"
                                             *ngFor="let range of rangesToExclude; let i = index;">
                                            <span class="rangesToExclude">Prix vente ttc minimum : {{range.minimumValue}}</span>
                                            <span class="rangesToExclude">Prix vente ttc maximum : {{range.maximumValue}}</span>
                                            <i (click)="deleteFromRangesToExclude(i)"
                                               class="fa fa-trash-o"
                                               style="color: red;cursor: pointer;font-size: 1.5em;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span *ngIf="valueIsMissing"
                              style="position: absolute;bottom: -50px;padding: 14px;background-color: #DC3545;color: white;border-radius: 10px;">
                            La règle n'a pas de valeur
                        </span>
                        <span *ngIf="existingRuleName"
                              style="position: absolute;bottom: -50px;padding: 14px;background-color: #DC3545;color: white;border-radius: 10px;">
                            Ce nom de règle existe déjà
                        </span>
                    </div>
                </div>
            </div>

            <!-- Display save button if all information are valid -->
            <div *ngIf="hasAccess" class="modal-footer">
                <a *ngIf="ruleType && ruleType.length > 0 && selectedRule.enseigne && selectedRule.enseigne.length > 0 && selectedRule.name && selectedRule.name.length > 0 && (ruleType[0] != 'categorie' || (categoriesToExclude != null && categoriesToExclude.length > 0)) && (ruleType[0] != 'produit' || (productsToExclude != null && productsToExclude.length > 0)) && (ruleType[0] != 'fournisseur' || (selectedSuppliers != null && selectedSuppliers.length > 0)) && (ruleType[0] != 'marque' || (selectedBrands != null && selectedBrands.length > 0)) && (ruleType[0] != 'range' || (rangesToExclude != null && rangesToExclude.length > 0))"
                   class="button medium"
                   (click)="saveRule()">
                    <i class="fa fa-floppy-o" aria-hidden="true" style="font-size: 21px;"></i>
                    {{'components.rules.form.save' | translate}}
                </a>
                <a class="button gray medium"
                   (click)="closeModal('editModal')">
                    <i class="fa fa-times" aria-hidden="true" style="font-size: 21px;"></i>
                    {{'components.rules.form.close' | translate}}
                </a>
            </div>
        </div>
    </div>
</app-model>
