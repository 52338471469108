<div class="card-body">
    <ng-container *ngIf="card">
        <h3>{{card.libelle}}</h3>
        <div>
            <div class="story-shadow"></div>
            <!--    <div class="story-connexion">-->
            <!--        <div class="left"></div>-->
            <!--        <div class="center"></div>-->
            <!--        <div class="right"></div>-->
            <!--    </div>-->
            <div class="story-container" *ngIf="card">
                <div *ngFor="let column of card.valeur; index as columnIndex;"
                     [ngClass]="{
                            'left': columnIndex === 0,
                            'center': columnIndex === 1,
                            'right': columnIndex === 2
                        }">
                    <ng-container *ngFor="let valeur of column; index as index;">
                        <!--                            <div *ngIf="valeur.value2"-->
                        <!--                                 [ngClass]="{-->
                        <!--                                    'evolution-left': index === 0,-->
                        <!--                                    'evolution-right': index === 1-->
                        <!--                                    }">-->
                        <!--                                <p [ngStyle]="{'margin-top': 72 - (40+((valeur.value1/max)*60))*0.85 + '%'}">-->
                        <!--                                    {{valeur.value2 | bigNumber}}-->
                        <!--                                </p>-->
                        <!--                                <dynamicSVG-component-->
                        <!--                                        [baliseForm]="baliseForm"-->
                        <!--                                        [fillColor]="valeur === column[0] ? color1 : color2"-->
                        <!--                                        [ngStyle]="{'margin-top': 72 - (40+((valeur.value1/max)*60))*0.85 + '%'}">-->
                        <!--                                </dynamicSVG-component>-->
                        <!--                            </div>-->

                        <div class="bar"
                             [ngClass]="{
                                    'bar-left': index === 0,
                                    'bar-right': index === 1
                                    }"
                             [ngStyle]="{
                                    'height': valeur.value1 === column[0].value1 ? (40+((valeur.value1/max)*60))*0.85 + '%' : (40+((valeur.value1/max)*60))*0.85 + '%',
                                    'border-top-right-radius' : (column[1] && (column[0].value1 < column[1].value1) && column[1].unit1 == '%') ? '0px' : '100px',
                                    'border-top-left-radius' : (column[1] && (column[0].value1 < column[1].value1) && column[1].unit1 == '%') ? '30px' : '100px'
                                }">
                            <h4>{{valeur.title}}</h4>
                            <p class="main-info">
                                {{valeur.value1 | bigNumber}}
                                <span *ngIf="valeur.unit1"> {{valeur.unit1}}</span>
                            </p>
                            <p *ngIf="valeur.valueEvol3" class="main-info">
                                <strong>
                                    <!--                                        {{valeur.valueEvol3 > 0 ? "+" : ""}}-->
                                    {{valeur.valueEvol3 | bigNumber}}
                                    <span *ngIf="valeur.unitEvol3">{{valeur.unitEvol3}}</span>
                                    <br/>
                                    <small> vs N-1</small>
                                </strong>

                            </p>
                        </div>
                    </ng-container>
                </div>
                <div class="story-walker"></div>
            </div>
            <div class="story-comment" *ngIf="card.comment">{{card.comment}}</div>
        </div>
    </ng-container>
</div>
