import {Injectable, Injector} from '@angular/core';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";

import {HttpHeaders, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";
import {ExportClass} from "src/app/shared/classes/exports/export.class";
import {saveAs} from 'file-saver';
import {DatePipe} from '@angular/common'
import {RemplacementValueClass} from "src/app/shared/classes/exports/ReplacementValue.class";

@Injectable({
    providedIn: 'root'
})
export class ExportsService extends HttpServiceClass<any> {
    protected endpoint = "exports";

    constructor(protected injector: Injector, public datepipe: DatePipe) {

        super(injector);
    }

    protected getId(item: any): number {
        return 0;
    }

    getListExports(params?: HttpParams) {
        return this.httpClient.get<ExportClass[]>(
            this.getPath(), {params}
        ).pipe(map(data => JsonUtils.parseDates(data)));
    }

    getExport(idExport: number, fileName: string, remplacementValueClass:RemplacementValueClass[], params?: HttpParams) {
        return this.httpClient.post(this.getPath({id: idExport}), remplacementValueClass, {params, responseType: 'blob'})
            .pipe(map(response => this.saveToFileSystem(response, fileName)));
    }

    private saveToFileSystem(response: any, fileName: string) {
        fileName = fileName + "_" + this.datepipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')
        saveAs(response, fileName);
    }
}
