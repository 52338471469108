import {Component, EventEmitter, Input, Output} from '@angular/core';


import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {ChartComponent} from "src/app/components/charts/chart.component";
import {RegionEligibilityService} from "src/app/shared/services/eligibility/region-eligibility.service";
import {AgencyEligibilityService} from "src/app/shared/services/eligibility/agency-eligibility.service";
import {MarkertypeGooglemapsEnum} from "src/app/shared/enums/googlemaps/markertype-googlemaps.enum";
import {CalculationUtils} from "src/app/shared/utils/calculation.utils";

@Component({
    selector: 'map-charts-component',
    templateUrl: './map-charts.component.html',
    styleUrls: ['./map-charts.component.css']
})
export class MapChartsComponent extends ChartComponent {

    showPopup = false;

    popupData: {
        name: string;
        total: number;
        percent: number;
        fillPercent: number;
    } = null;

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        this.card = card;
    }

    @Output() onGooglemapsComponentReady: EventEmitter<number> = new EventEmitter();


    constructor(private regionEligibilityService: RegionEligibilityService,
                private agencyEligibilityService: AgencyEligibilityService) {
        super();
    }

    googlemapsComponentReady(data: number) {
        this.onGooglemapsComponentReady.emit(data);
    }

    markerClick(data: { markerName: string, type: MarkertypeGooglemapsEnum }): void {
        this.popupData = null;
        switch (data.type) {
            case MarkertypeGooglemapsEnum.Region:
                this.regionEligibilityService.getStr(data.markerName).subscribe((response: any) => {
                    this.popupData = {
                        name: data.markerName,
                        total: response.value[0]?.value1 ?? 0,
                        percent: response.value[0]?.value3 ?? 0,
                        fillPercent: CalculationUtils.getClosestInTenPercent(response.value[0]?.value3 ?? 0)
                    };
                });
                break;
            case MarkertypeGooglemapsEnum.Agency:
                this.agencyEligibilityService.getStr(data.markerName).subscribe((response: any) => {
                    this.popupData = {
                        name: data.markerName,
                        total: response.value[0]?.value1 ?? 0,
                        percent: response.value[0]?.value3 ?? 0,
                        fillPercent: CalculationUtils.getClosestInTenPercent(response.value[0]?.value3 ?? 0)
                    };
                });
                break;
        }
        this.showPopup = true;
    }

    polygonClick(polygonName: string): void {
        this.popupData = null;
        this.regionEligibilityService.getStr(polygonName).subscribe((response: any) => {
            this.popupData = {
                name: polygonName,
                total: response.value[0]?.value1 ?? 0,
                percent: response.value[0]?.value3 ?? 0,
                fillPercent: CalculationUtils.getClosestInTenPercent(response.value[0]?.value3 ?? 0)
            };
        });
        this.showPopup = true;
    }

    hidePopup(): void {
        this.showPopup = false;
    }
}
