<div class="card-body" style="display: flex; flex-direction: column; height:100%">
    <ng-container *ngIf="card">
        <h3>{{ card.libelle }}</h3>
        <div class="value-moyenne">
            Marge moyenne: {{valuesAverage}}
        </div>
        <div class="canvas-container" #container>
            <canvas baseChart class="round-chart big-chart mb-0"
                    [datasets]="pieChartDatasets"
                    [labels]="pieChartLabels"
                    [options]="options"
                    [chartType]="'doughnut'"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
        </div>
    </ng-container>
</div>
