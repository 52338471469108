import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private keycloakService: KeycloakService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.keycloakService.isLoggedIn) return next.handle(req);
        let request = req.clone({
            setHeaders: {
                Authorization: 'Bearer ' + this.keycloakService.getKeycloakInstance().token
            }
        });
        return next.handle(request);
    }
}