import {Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {CategorytreeHandler} from "src/app/shared/handlers/categorytree.handler";
import {DragtypeEnum} from "src/app/shared/enums/dragtype.enum";
import {CategorytreeUtils} from "src/app/shared/utils/categorytree.utils";
import {DroptypeEnum} from "src/app/shared/enums/droptype.enum";
import {CategoryDtoInterface} from "src/app/shared/interfaces/models/rest";
import {HidesearchedkeywordsCategoriesEnum} from 'src/app/shared/enums/categories/hidesearchedkeywords-categories.enum';
import {CollapsetypeCategoriesEnum} from 'src/app/shared/enums/categories/collapsetype-categories.enum';
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";

@Component({
    selector: 'categorytree-component',
    templateUrl: './categorytree.component.html',
    styleUrls: ['./categorytree.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CategorytreeComponent),
        }
    ]
})
export class CategorytreeComponent extends BaseComponents implements OnInit, ControlValueAccessor {

    touched = false;
    collapse = true;
    data: CategoryDtoInterface;
    language: string = "FR";
    selected: Map<number, boolean> = new Map();
    hidden: Map<number, HidesearchedkeywordsCategoriesEnum> = new Map();
    hideSearchedKeywordsEnum: typeof HidesearchedkeywordsCategoriesEnum = HidesearchedkeywordsCategoriesEnum;
    isDragging = false;
    isDraggingToReorder = false;
    isDraggingToMove = false;
    hoveredReorder = -1;
    hoveredMove = false;
    draggingCategory: number;
    selectedInput: number[];

    @Input() level = 0;
    @Input() hasCheckboxes = true;
    @Input() hasCheckboxesOnlyForLastChild = false;
    @Input() hasCarets = true;
    @Input() hasEdition = false;
    @Input() hasDetails = false;
    @Input() isChild = false;
    @Input() isRules = false;
    @Input() isRulesCatalogue = false;
    @Input() isDraggable = false;
    @Input() parent: number;
    @Input() collapseType = CollapsetypeCategoriesEnum.ExpandedIfChildrenChecked;
    @Input() showStatus: boolean;

    @Input("language") set setLanguage(language: string) {
        if (language) {
            this.language = language;
        }
    };

    @Input("collapse") set setCollapse(collapse: boolean) {
        this.collapse = collapse;
    };

    @Input("data") set setData(data: CategoryDtoInterface) {
        this.data = data;
        if (this.selectedInput) {
            this.writeValue(this.selectedInput);
        }
        this.checkExpand();
    };

    @Input("selected") set setSelected(selected: Map<number, boolean>) {
        this.selected = selected;
    };

    @Input("hidden") set setHidden(hidden: Map<number, HidesearchedkeywordsCategoriesEnum>) {
        this.hidden = hidden;
    };

    @Input("searchCriteria") set setSearchCriteria(searchCriteria: { keywords?: string, main?: boolean, specific?: boolean, service?: boolean, animation?: boolean, archive?: boolean, type?: string }) {
        // noinspection PointlessBooleanExpressionJS
        if (!searchCriteria || (
            (!searchCriteria.keywords || searchCriteria.keywords === "")
            && (searchCriteria.main === undefined)
            && (searchCriteria.specific === undefined)
            && (searchCriteria.service === undefined)
            && (searchCriteria.animation === undefined)
            && (searchCriteria.archive === undefined)
        )) {
            this.hidden = new Map();
        } else if (this.data) {
            this.areThereSubsSearched(this.data.childCategories, searchCriteria);
            this.areThereSubsFromSearched(this.data.childCategories);
            this.selected = this.selected.set(null, false);
        }
        /*console.log(JSON.stringify(searchCriteria));*/
    };

    @Output() onChange = new EventEmitter<never>();

    @Output() onStructureUpdate = new EventEmitter<Map<number, CategoryDtoInterface>>();

    @Output() onEdit = new EventEmitter<CategoryDtoInterface>();

    @Output() onAdd = new EventEmitter<CategoryDtoInterface>();

    @Output() onDelete = new EventEmitter<CategoryDtoInterface>();

    @Output() onSearch = new EventEmitter<CategoryDtoInterface>();

    constructor(protected injector: Injector,
                protected categorytreeHandler: CategorytreeHandler) {
        super(injector);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.categorytreeHandler.onForwardSelection.subscribe(() => {
            this.checkExpand();
        }));

        if (this.isDraggable) {
            this.subscriptions.push(this.categorytreeHandler.onForwardDrag.subscribe(drag => {
                this.draggingCategory = drag.category;

                switch (drag.dragtype) {
                    case DragtypeEnum.Start:
                        this.isDragging = true;
                        this.isDraggingToMove = !(
                            drag.parent === this.data.idCategory
                            || drag.category === this.data.idCategory
                            || this.level >= 3
                            || CategorytreeUtils.isInParent(this.data.parentCategories, drag.category)
                        );
                        this.isDraggingToReorder = this.data.idCategory === drag.parent;
                        break;
                    case DragtypeEnum.End:
                        this.isDragging = false;
                        this.isDraggingToReorder = false;
                        this.isDraggingToMove = false;
                }
            }));

            this.subscriptions.push(this.categorytreeHandler.onForwardDrop.subscribe(drop => {
                if (!this.level) {
                    const category = CategorytreeUtils.findCategoryInTree(drop.category, this.data);
                    const destination = CategorytreeUtils.findCategoryInTree(drop.destination, this.data);
                    switch (drop.dropType) {
                        case DroptypeEnum.ReorderBefore: {
                            let doContinue = true;

                            if (category.idCategory === destination.idCategory) {
                                // same place
                                doContinue = false;
                            }

                            const destinationParent = CategorytreeUtils.findCategoryInTree(
                                destination.parentCategories ? destination.parentCategories.idCategory : null,
                                this.data
                            );
                            const categoryPosition = destinationParent.childCategories.findIndex(item => item.idCategory === category.idCategory);
                            let destinationPosition = destinationParent.childCategories.findIndex(item => item.idCategory === destination.idCategory);
                            if (destinationPosition - categoryPosition === 1) {
                                // moved on immediate next
                                doContinue = false;
                            }

                            if (doContinue) {
                                destinationParent.childCategories.splice(categoryPosition, 1);
                                destinationPosition = destinationParent.childCategories.findIndex(item => item.idCategory === destination.idCategory);
                                destinationParent.childCategories.splice(destinationPosition, 0, category);

                                const updatedCategories = CategorytreeUtils.reorderCategories(destinationParent.childCategories);
                                updatedCategories.set(category.idCategory, category);

                                this.onStructureUpdate.emit(updatedCategories);
                            }
                            break;
                        }

                        case DroptypeEnum.ReorderAtTheEnd: {
                            let doContinue = true;

                            const categoryPosition = destination.childCategories.findIndex(item => item.idCategory === category.idCategory);
                            if (categoryPosition + 1 === destination.childCategories.length) {
                                // already the last
                                doContinue = false;
                            }

                            if (doContinue) {
                                destination.childCategories.splice(categoryPosition, 1);
                                destination.childCategories.splice(destination.childCategories.length, 0, category);

                                const updatedCategories = CategorytreeUtils.reorderCategories(destination.childCategories);
                                updatedCategories.set(category.idCategory, category);

                                this.onStructureUpdate.emit(updatedCategories);
                            }
                            break;
                        }

                        case DroptypeEnum.Move: {
                            const categoryParent = CategorytreeUtils.findCategoryInTree(category.parentCategories ? category.parentCategories.idCategory : null, this.data);
                            const categoryPosition = categoryParent.childCategories.findIndex(item => item.idCategory === category.idCategory);
                            if (!destination.childCategories) {
                                destination.childCategories = [];
                            }
                            destination.childCategories.push(category);
                            categoryParent.childCategories.splice(categoryPosition, 1);
                            category.parentCategories = destination;

                            const updatedCategories = new Map([
                                ...CategorytreeUtils.reorderCategories(destination.childCategories),
                                ...CategorytreeUtils.reorderCategories(categoryParent.childCategories)
                            ]);
                            updatedCategories.set(category.idCategory, category);

                            this.onStructureUpdate.emit(updatedCategories);
                            break;
                        }
                    }
                }
                this.isDragging = false;
                this.isDraggingToReorder = false;
                this.isDraggingToMove = false;
            }));
        }

        this.subscriptions.push(this.categorytreeHandler.onForwardSearch.subscribe(categoryId => {
            const category = CategorytreeUtils.findCategoryInTree(categoryId, this.data);
            this.onSearch.emit(category);
        }));

        if (this.hasEdition) {
            if (!this.level) {

                this.subscriptions.push(this.categorytreeHandler.onForwardEdit.subscribe(categoryId => {
                    const category = CategorytreeUtils.findCategoryInTree(categoryId, this.data);
                    this.onEdit.emit(category);
                }));

                this.subscriptions.push(this.categorytreeHandler.onForwardAdd.subscribe(categoryId => {
                    const category = CategorytreeUtils.findCategoryInTree(categoryId, this.data);
                    this.onAdd.emit(category);
                }));

                this.subscriptions.push(this.categorytreeHandler.onForwardDelete.subscribe(categoryId => {
                    const category = CategorytreeUtils.findCategoryInTree(categoryId, this.data);
                    const categoryParent = CategorytreeUtils.findCategoryInTree(category.parentCategories ? category.parentCategories.idCategory : null, this.data);
                    const categoryPosition = categoryParent.childCategories.findIndex(item => item.idCategory === category.idCategory);
                    categoryParent.childCategories.splice(categoryPosition, 1);

                    const updatedCategories = CategorytreeUtils.reorderCategories(categoryParent.childCategories);

                    this.onDelete.emit(category);
                    this.onStructureUpdate.emit(updatedCategories);
                }));


            }
        }

        this.checkExpand();
    }

    // noinspection JSUnusedLocalSymbols
    onChangeForm = (data: number[]): any => {
    };
    onTouchedForm = () => {
    };

    registerOnChange(fn: any): void {
        this.onChangeForm = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedForm = fn;
    }

    writeValue(list: number[]): void {
        // writeValue is beeing called AFTER setData...  But should be better done
        if ((list as any as string) !== "") {
            this.selectedInput = list;
            this.selected = new Map();
            this.setSelectedListMain(list);
        }
    }

    checkChildren(data: any, checked: boolean) {

        if (this.isRules) {

            if (!data.childCategories) {
                return;
            }

            if (this.data.idCategory && checked) {
                this.collapse = !checked;
            }


            for (let sub of data.childCategories) {

                if (this.hidden.get(sub.idCategory) == HidesearchedkeywordsCategoriesEnum.Searched || this.hidden.get(sub.idCategory) == HidesearchedkeywordsCategoriesEnum.SearchedCauseChild || this.hidden.get(sub.idCategory) == HidesearchedkeywordsCategoriesEnum.SearchedCauseParent) {
                    this.selected.set(sub.idCategory, checked);
                }
                if (this.isRulesCatalogue) {
                    this.checkChildren(sub, checked);
                }


            }

        }


    }

    checkboxClick(event?: MouseEvent) {
        if (event) {
            event.stopPropagation();
            this.selected.set(this.data.idCategory, (event.target as HTMLInputElement).checked);
            this.checkChildren(this.data, (event.target as HTMLInputElement).checked);
        }

        if (!this.level) {
            if (!this.touched) {
                this.touched = true;
                this.onTouchedForm();
            }

            const list: number[] = [];
            this.getSelectedList(this.data.childCategories, list);
            this.onChangeForm(list);
        }

        this.onChange.emit();
    }

    childClick(): void {
        this.checkboxClick();
    }

    dragstart(): void {
        this.categorytreeHandler.forwardDrag(DragtypeEnum.Start, this.data.idCategory, this.parent);
    }

    dragend(): void {
        this.hoveredReorder = -1;
        this.hoveredMove = false;
        this.categorytreeHandler.forwardDrag(DragtypeEnum.End, this.data.idCategory, this.parent);
    }

    dragleave(): void {
        this.hoveredReorder = -1;
        this.hoveredMove = false;
    }

    dragover(event: DragEvent, item: any, reorder = false): void {
        if (reorder) {
            if (this.isDraggingToReorder) {
                if (item) {
                    this.hoveredReorder = item.idCategory;
                } else {
                    this.hoveredReorder = null;
                }
                event.preventDefault();
            }
        } else {
            if (this.isDraggingToMove) {
                this.hoveredMove = true;
                event.preventDefault();
            }
        }
    }

    drop(event: DragEvent, item: CategoryDtoInterface, reorder = false): void {
        if (reorder) {
            if (item) {
                this.categorytreeHandler.forwardDrop(DroptypeEnum.ReorderBefore, this.draggingCategory, item.idCategory);
            } else {
                this.categorytreeHandler.forwardDrop(DroptypeEnum.ReorderAtTheEnd, this.draggingCategory, this.data.idCategory);
            }
        } else {
            this.categorytreeHandler.forwardDrop(DroptypeEnum.Move, this.draggingCategory, this.data.idCategory);
        }
    }

    edit(event: MouseEvent): void {
        event.stopImmediatePropagation();
        if (!this.level) {
            return;
        }
        this.categorytreeHandler.forwardEdit(this.data.idCategory);
    }

    add(event: MouseEvent): void {
        event.stopImmediatePropagation();
        if (this.level >= 3) {
            return;
        }
        this.categorytreeHandler.forwardAdd(this.data.idCategory);
    }

    // delete(event: MouseEvent): void {
    //     event.stopImmediatePropagation();
    //     if (!this.level) {
    //         return;
    //     }
    //     this.categorytreeHandler.forwardDelete(this.data.idCategory);
    // }

    search(event: MouseEvent): void {
        event.stopImmediatePropagation();
        if (!this.level) {
            return;
        }
        this.categorytreeHandler.forwardSearch(this.data.idCategory);
    }

    private checkExpand(): void {
        switch (this.collapseType) {
            case CollapsetypeCategoriesEnum.Collapsed:
                this.collapse = true;
                break;
            case CollapsetypeCategoriesEnum.Expanded:
                this.collapse = !this.data.childCategories;
                break;
            case CollapsetypeCategoriesEnum.ExpandedIfChildrenChecked:
                if (this.data && this.data.childCategories && this.collapse) {
                    this.collapse = !this.areThereSubsSelected(this.data.childCategories);
                }
                break;
        }
    }

    private areThereSubsSelected(subs: CategoryDtoInterface[]): boolean {
        if (subs && subs.length) {
            for (let sub of subs) {
                if (sub) {
                    if (this.selected.get(sub.idCategory)) {
                        return true;
                    }
                    if (sub.childCategories && this.areThereSubsSelected(sub.childCategories)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private areThereSubsSearched(subs: CategoryDtoInterface[], searchCriteria: { keywords?: string, main?: boolean, specific?: boolean, service?: boolean, animation?: boolean, archive?: boolean, type?: string }): HidesearchedkeywordsCategoriesEnum {
        if (!searchCriteria) {
            return HidesearchedkeywordsCategoriesEnum.Searched;
        }

        // search in tree for keywords and mark as searched, plus mark parents as searched too
        let subsSearched = HidesearchedkeywordsCategoriesEnum.Hidden;
        if (subs && subs.length) {
            for (let sub of subs) {
                if (sub) {
                    const translation = sub.translationCategoriesCategory.find(item => item.languageTranslationCategory.code === this.language);
                    if (
                        (searchCriteria.keywords &&
                            (
                                translation &&
                                (translation.codeCategory.toUpperCase() == searchCriteria.keywords.toUpperCase().trim() ||
                                    translation.labelYoukado?.toUpperCase().indexOf(searchCriteria.keywords.toUpperCase().trim()) > -1 ||
                                    translation.label?.toUpperCase().indexOf(searchCriteria.keywords.toUpperCase().trim()) > -1)
                            )
                        ) &&
                        ((searchCriteria.main && ((sub.isMain && !sub.archive))) ||
                            (searchCriteria.specific && (sub.isSpecificCategory && !sub.archive)) ||
                            (searchCriteria.service && (sub.isService && !sub.archive)) ||
                            (searchCriteria.animation && (sub.isAnimation && !sub.archive)) ||
                            (searchCriteria.archive && (sub.archive)))
                        && (searchCriteria.type && sub.type == searchCriteria.type)
                    ) {
                        // directly searched
                        this.areThereSubsSearched(sub.childCategories, searchCriteria);
                        this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.Searched);
                        subsSearched = Math.max(subsSearched, HidesearchedkeywordsCategoriesEnum.Searched);
                    } else if (
                        (searchCriteria.keywords &&
                            (
                                translation &&
                                (translation.codeCategory.toUpperCase() == searchCriteria.keywords.toUpperCase().trim() ||
                                    translation.labelYoukado?.toUpperCase().indexOf(searchCriteria.keywords.toUpperCase().trim()) > -1 ||
                                    translation.label?.toUpperCase().indexOf(searchCriteria.keywords.toUpperCase().trim()) > -1)
                            )
                        ) &&
                        ((searchCriteria.main && ((sub.isMain && !sub.archive))) ||
                            (searchCriteria.specific && (sub.isSpecificCategory && !sub.archive)) ||
                            (searchCriteria.service && (sub.isService && !sub.archive)) ||
                            (searchCriteria.animation && (sub.isAnimation && !sub.archive)) ||
                            (searchCriteria.archive && (sub.archive)))
                        && (!searchCriteria.type)
                    ) {
                        // directly searched
                        this.areThereSubsSearched(sub.childCategories, searchCriteria);
                        this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.Searched);
                        subsSearched = Math.max(subsSearched, HidesearchedkeywordsCategoriesEnum.Searched);
                    } else if (
                        ((!searchCriteria.keywords && !searchCriteria.type)
                        ) &&
                        ((searchCriteria.main && ((sub.isMain && !sub.archive))) ||
                            (searchCriteria.specific && (sub.isSpecificCategory && !sub.archive)) ||
                            (searchCriteria.service && (sub.isService && !sub.archive)) ||
                            (searchCriteria.animation && (sub.isAnimation && !sub.archive)) ||
                            (searchCriteria.archive && (sub.archive)))
                    ) {
                        // directly searched
                        this.areThereSubsSearched(sub.childCategories, searchCriteria);
                        this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.Searched);
                        subsSearched = Math.max(subsSearched, HidesearchedkeywordsCategoriesEnum.Searched);
                    } else if (
                        (!searchCriteria.keywords
                        ) &&
                        ((searchCriteria.main && ((sub.isMain && !sub.archive))) ||
                            (searchCriteria.specific && (sub.isSpecificCategory && !sub.archive)) ||
                            (searchCriteria.service && (sub.isService && !sub.archive)) ||
                            (searchCriteria.animation && (sub.isAnimation && !sub.archive)) ||
                            (searchCriteria.archive && (sub.archive)))
                        && (searchCriteria.type && sub.type == searchCriteria.type)
                    ) {
                        // directly searched
                        this.areThereSubsSearched(sub.childCategories, searchCriteria);
                        this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.Searched);
                        subsSearched = Math.max(subsSearched, HidesearchedkeywordsCategoriesEnum.Searched);
                    } else if (
                        (searchCriteria.keywords &&
                            (
                                translation &&
                                (translation.codeCategory.toUpperCase() == searchCriteria.keywords.toUpperCase().trim() ||
                                    translation.labelYoukado?.toUpperCase().indexOf(searchCriteria.keywords.toUpperCase().trim()) > -1 ||
                                    translation.label?.toUpperCase().indexOf(searchCriteria.keywords.toUpperCase().trim()) > -1)
                            )
                        ) &&
                        ((!searchCriteria.main) &&
                            (!searchCriteria.specific) &&
                            (!searchCriteria.service) &&
                            (!searchCriteria.animation) &&
                            (!searchCriteria.archive))
                        && (searchCriteria.type && sub.type == searchCriteria.type)
                    ) {
                        // directly searched
                        this.areThereSubsSearched(sub.childCategories, searchCriteria);
                        this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.Searched);
                        subsSearched = Math.max(subsSearched, HidesearchedkeywordsCategoriesEnum.Searched);
                    } else if (sub.childCategories && this.areThereSubsSearched(sub.childCategories, searchCriteria) !== HidesearchedkeywordsCategoriesEnum.Hidden) {
                        // child found searched
                        this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.SearchedCauseChild);
                        subsSearched = Math.max(subsSearched, HidesearchedkeywordsCategoriesEnum.SearchedCauseChild);
                    } else {
                        // not found
                        this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.Hidden);
                        subsSearched = Math.max(subsSearched, HidesearchedkeywordsCategoriesEnum.Hidden);
                    }
                }
            }
        }
        return subsSearched;
    }

    private areThereSubsFromSearched(subs: CategoryDtoInterface[], searchedParent = false) {
        // mark as searched all children of a searched category
        if (subs && subs.length) {
            for (let sub of subs) {
                if (searchedParent && this.hidden.get(sub.idCategory) === HidesearchedkeywordsCategoriesEnum.Hidden) {
                    this.hidden.set(sub.idCategory, HidesearchedkeywordsCategoriesEnum.SearchedCauseParent);
                }
                this.areThereSubsFromSearched(sub.childCategories, searchedParent || this.hidden.get(sub.idCategory) === HidesearchedkeywordsCategoriesEnum.Searched);
            }
        }
    }

    private getSelectedList(subs: CategoryDtoInterface[], list: number[]): void {
        if (subs && subs.length) {
            for (let sub of subs) {
                if (sub && this.selected.get(sub.idCategory)) {
                    list.push(sub.idCategory);
                }
                this.getSelectedList(sub.childCategories, list);
            }
        }
    }

    private setSelectedListMain(list: number[]) {
        if (this.data) {
            this.setSelectedList(this.data.childCategories, list);
            this.categorytreeHandler.forwardSelection();
        } else {
            setTimeout(() => {
                this.setSelectedListMain(list);
            }, 25);
        }
    }

    private setSelectedList(subs: CategoryDtoInterface[], list: number[]): void {
        if (subs && subs.length && list && list.length) {
            for (let sub of subs) {
                if (sub && list.includes(sub.idCategory)) {
                    this.selected.set(sub.idCategory, true);
                }
                this.setSelectedList(sub.childCategories, list);
            }
        }
    }
}
