<div class="page-title">
    <div class="row">
        <div class="col-sm-6 mb-4">
            <h4>{{"components.category.title" | translate}}</h4>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb pt-0 float-left float-sm-right ">
                <li class="breadcrumb-item active">
                    <a href="javascript:history.back()" class="default-color">
                        <i class="fa fa-chevron-left mr-2" aria-hidden="true"></i> {{'core.navigation.back' |
                        translate}}
                    </a>
                </li>
            </ol>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-statistics mb-30">
                <div class="card-body">
                    <form [formGroup]="formGroupSearch">
                        <div class="form-row align-items-center">
                            <div class="col-md-3">
                                <div class="form-group mb-0" style="position: relative;padding-right: 5px;">
                                    <input placeholder="{{'components.category.search.keywords' | translate}}"
                                           formControlName="keywords" class="form-control search-input"
                                           (input)="search()">
                                    <button class="search-button" type="submit" style="">
                                        <i class="fa fa-search not-click"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="col-md-2-5">
                                <div class="form-group mb-0">
                                    <div class="form-check">
                                        <input type="radio" id="searchMain" formControlName="searchFilter" value="searchMain"
                                               (click)="search()">
                                        <label class="form-check-label ml-2" for="searchMain">
                                            {{'components.category.form.main' | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2-5">
                                <div class="form-group mb-0">
                                    <div class="form-check">
                                        <input type="radio" id="searchSpecific" formControlName="searchFilter" value="searchSpecific"
                                               (click)="search()">
                                        <label class="form-check-label ml-2" for="searchSpecific">
                                            {{'components.category.form.specific' | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2-5">
                                <div class="form-group mb-0">
                                    <div class="form-check">
                                        <input type="radio" id="searchService" formControlName="searchFilter" value="searchService"
                                               (click)="search()">
                                        <label class="form-check-label ml-2" for="searchService">
                                            {{'components.category.form.service' | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-2-5">
                                <div class="form-group mb-0">
                                    <div class="form-check">
                                        <input type="radio" id="searchAnimation" formControlName="searchFilter" value="searchAnimation"
                                               (click)="search()">
                                        <label class="form-check-label ml-2" for="searchAnimation">
                                            {{'components.category.form.animation' | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div style="padding-top: 18px;" class="col-lg-3 col-md-4 col-sm-6">
                                <ng-multiselect-dropdown formControlName="searchTypeCategory" [(ngModel)]="currentTypeCategory"
                                                         [settings]="dropDownCategorySettings"
                                                         [data]="dropdownListTypeCategory"
                                                         [placeholder]="'Type offre'"
                                                         (onSelect)="search()"
                                                         (onDeSelect)="search()"
                                                         class="card">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-12 category-box">
            <div class="card card-statistics mb-30">
                <div class="card-body">
                    <div class="main-content">
                        <categorytree-component [data]="categories" (onStructureUpdate)="structureUpdate()"
                            (onEdit)="edit($event)" (onAdd)="add($event)" (onDelete)="delete($event)"
                            (onSearch)="searchCategory($event)" [language]="language?.code"
                            [searchCriteria]="searchCriteria" [hasCheckboxesOnlyForLastChild]="false" [collapse]="false"
                            [isDraggable]="true" [hasCarets]="true" [hasEdition]="true" [hasDetails]="true"
                            [hasCheckboxes]="false">
                            {{'components.catalog.sidebar.categories' | translate}}
                        </categorytree-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-model class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true" id="editModal">
    <form [formGroup]="formGroup" (submit)="submit(null)">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="tab nav-bt">
                    <div class="card card-statistics">
                        <div class="card-body form-box">
                            <p class="mb-4">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                                    (click)="closeModal()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <span class="pull-right mr-3">
                                    <i *ngIf="category && !category.idCategory" class="fa fa-file" aria-hidden="true"
                                        style="color: deepskyblue; font-size: 21px;"></i>
                                    <i *ngIf="category && category.idCategory && category.published"
                                        class="fa fa-play-circle" aria-hidden="true"
                                        style="color: forestgreen; font-size: 21px;"></i>
                                    <i *ngIf="category && category.idCategory && !category.published"
                                        class="fa fa-stop-circle" aria-hidden="true"
                                        style="color: lightslategrey; font-size: 21px;"></i>
                                </span>
                            </p>
                            <div style="clear: both;"></div>
                            <fieldset [disabled]="!category">
                                <ngb-tabset #categoryTabset class="tab nav-center" style="width: 100%;"
                                    (tabChange)="getProductsToCategory($event)">
                                    <ngb-tab title="Détails" [id]="'Détails'">
                                        <ng-template ngbTabContent>
                                            <div class="form-row">
                                                <div class="form-group col-md-6 col-lg-4 col-xl-2">
                                                    <div>
                                                        <label for="ccode">{{'components.category.form.code' |
                                                            translate}}</label>
                                                        <input id="ccode" formControlName="code"
                                                            [ngClass]="{'is-invalid':category && (formGroup.controls.code.dirty || formGroup.controls.code.touched) && formGroup.controls.code.invalid}"
                                                            type="text" class="form-control" readonly>
                                                    </div>

                                                    <div style="margin-left: 20px;margin-top: 30px;">
                                                        <input *ngIf="category != null && category.idCategory != null" [attr.disabled]="category != null && category.idCategory != null" (click)="changeCategory()" id="specific" formControlName="specific" type="checkbox"
                                                            class="form-check-input">
                                                        <input *ngIf="category == null || category.idCategory == null" (click)="changeCategory()" id="specific" formControlName="specific" type="checkbox"
                                                            class="form-check-input">
                                                        <label class="form-check-label" for="specific">
                                                            <!--                                    <i class="fa fa-handshake-o" aria-hidden="true"></i>-->
                                                            {{'components.category.form.specific' | translate}}
                                                        </label>
                                                    </div>

                                                    <div style="margin-left: 20px;margin-top: 30px;">
                                                        <input id="service" formControlName="service" type="checkbox"
                                                            class="form-check-input">
                                                        <label class="form-check-label" for="service">
                                                            <!--                                    <i class="fa fa-handshake-o" aria-hidden="true"></i>-->
                                                            {{'components.category.form.service' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6 col-lg-4 col-xl-2">
                                                    <div>
                                                        <label for="type">Ordre sur le catalogue</label>
                                                        <input id="orderForPage" formControlName="orderForPage"
                                                            [ngClass]="{'is-invalid':category && (formGroup.controls.code.dirty || formGroup.controls.code.touched) && formGroup.controls.code.invalid}"
                                                            type="number" class="form-control">
                                                    </div>

                                                    <div style="margin-left: 20px;margin-top: 30px;">
                                                        <input id="animation" formControlName="animation"
                                                            type="checkbox" class="form-check-input">
                                                        <label class="form-check-label" for="animation">
                                                            <!--                                    <i class="fa fa-calendar-o" aria-hidden="true"></i>-->
                                                            {{'components.category.form.animation' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6 col-lg-4 col-xl-2">
                                                    <div>
                                                        <label for="type">Ordre sur les bannières</label>
                                                        <input id="orderForBanner" formControlName="orderForBanner"
                                                            [ngClass]="{'is-invalid':category && (formGroup.controls.code.dirty || formGroup.controls.code.touched) && formGroup.controls.code.invalid}"
                                                            type="number" class="form-control">
                                                    </div>

                                                    <div style="margin-left: 20px;margin-top: 30px;">
                                                        <input id="archive" formControlName="archive" type="checkbox"
                                                            class="form-check-input">
                                                        <label class="form-check-label" for="archive">
                                                            <!--                                    <i class="fa fa-archive" aria-hidden="true"></i>-->
                                                            {{'components.category.form.archive' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6 col-lg-4 col-xl-2">
                                                    <label for="type">{{'components.category.form.type' |
                                                        translate}}</label>
                                                    <select id="type" formControlName="type"
                                                        [ngClass]="{'is-invalid':category && (formGroup.controls.type.dirty || formGroup.controls.type.touched) && formGroup.controls.type.invalid}"
                                                        class="form-control form-control-lg mb-15">
                                                        <option *ngFor="let availableType of availableTypes"
                                                            [ngValue]="availableType">{{availableType}}</option>
                                                    </select>


                                                    <div style="margin-left: 20px;margin-top: 30px;">
                                                        <input id="disableSalePriceEnseigne"
                                                            formControlName="disableSalePriceEnseigne" type="checkbox"
                                                            class="form-check-input">
                                                        <label class="form-check-label" for="disableSalePriceEnseigne">
                                                            Désactiver tarification enseigne
                                                        </label>
                                                    </div>

                                                    <div *ngIf="category" style="margin-left: 20px;margin-top: 30px;">
                                                        <input id="showFilterCatalogue" type="checkbox"
                                                            class="form-check-input"
                                                            [ngModelOptions]="{standalone:true}"
                                                            [(ngModel)]="category.showFilterCatalogue">
                                                        <label for="showFilterCatalogue" class="form-check-label">
                                                            Afficher dans le catalogue
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6 col-lg-4">
                                                    <div class="col-sm-6 country-block mb-3">
                                                        <h5> {{'components.category.translations' | translate}}</h5>
                                                    </div>
                                                    <div class="col-sm-8 country-block mb-3"
                                                        *ngIf="languages && language">
                                                        <div *ngFor="let languageDisplay of languages"
                                                            (click)="changeLanguage(languageDisplay)" class="mr-2 ml-2"
                                                            style="display: inline-block;">
                                                            <img [ngClass]="{'country-tabs-image': languageDisplay.code === languageDisplay.code}"
                                                                style="cursor: pointer;"
                                                                src="assets/images/flags/{{languageDisplay.code}}.png"
                                                                alt="">
                                                            <h6 [ngClass]="{
                                                        'text-primary': language.code === languageDisplay.code,
                                                        'text-secondary':language.code!==languageDisplay.code && !getAbstractControllerAsFromGroupForLanguage(languageDisplay.code)
                                                    }" style="text-align: center; cursor: pointer;color:black">
                                                                {{languageDisplay.code}}</h6>
                                                        </div>
                                                    </div>
                                                    <ng-container formArrayName="translations"
                                                        *ngFor="let translation of getTranslationsControls; let i = index">
                                                        <div [formGroupName]="i"
                                                            *ngIf="language && getAbstractControllerAsFromGroup(translation).value.languageCode === language.code">
                                                            <div class="form-group col-12">
                                                                <label
                                                                    for="label_{{i}}">{{'components.category.form.label'
                                                                    |
                                                                    translate}}</label>
                                                                <input id="label_{{i}}" formControlName="label"
                                                                    [ngClass]="{
                                                                'is-invalid':category
                                                                && (
                                                                    getAbstractControllerAsFromGroup(translation).controls.label.dirty
                                                                    || getAbstractControllerAsFromGroup(translation).controls.label.touched
                                                                )
                                                                && getAbstractControllerAsFromGroup(translation).controls.label.invalid
                                                           }" type="text" class="form-control">
                                                            </div>
                                                            <div class="form-group col-12">
                                                                <label
                                                                    for="labelYoukado_{{i}}">{{'components.category.form.label'
                                                                    |
                                                                    translate}} interne</label>
                                                                <input id="labelYoukado_{{i}}"
                                                                    formControlName="labelYoukado" [ngClass]="{
                                                                'is-invalid':category
                                                                && (
                                                                    getAbstractControllerAsFromGroup(translation).controls.labelYoukado.dirty
                                                                    || getAbstractControllerAsFromGroup(translation).controls.labelYoukado.touched
                                                                )
                                                                && getAbstractControllerAsFromGroup(translation).controls.labelYoukado.invalid
                                                           }" type="text" class="form-control">
                                                                <em class="error help-block" *ngIf="emptyLabel">
                                                                    Merci de saisir les deux libellés
                                                                </em>
                                                            </div>

                                                            <div class="multiselect-languages">
                                                                <ng-multiselect-dropdown
                                                                    style="padding-right: 10px;width:75%"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    [(ngModel)]="selectedLanguages"
                                                                    [settings]="dropdownSettingsLanguages"
                                                                    [data]="languagesList"
                                                                    [placeholder]="'Sélectionner une ou plusieurs langues'"
                                                                    id="languagesAll" name="languagesAll">
                                                                </ng-multiselect-dropdown>
                                                                <span
                                                                    (click)="onClickTranslate(
                                                            language.code,
                                                            getAbstractControllerAsFromGroup(translation).controls.label.value,
                                                            getAbstractControllerAsFromGroup(translation).controls.labelYoukado.value,
                                                            getAbstractControllerAsFromGroup(translation).value.languageCode)"
                                                                    class="btn btn-info btn-translate btn-translate-label">
                                                                    Traduire
                                                                </span>
                                                                <span class="help-translate help-translate-label">
                                                                    Traduction du label dans les langues sélectionnées
                                                                    <i class="fa fa-solid fa-caret-up help-arrow"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab *ngIf="formGroup.value.specific"  title="Enseignes" [id]="'Enseignes'">
                                        <ng-template ngbTabContent>
                                            <div class="form-group col-md-6 col-lg-4 col-xl-3">
                                                <label class="control-label">Spécifique aux enseignes</label>
                                                <div class="mb-2">
                                                    <ng-multiselect-dropdown id="enseignesInclusion"
                                                        name="enseignesInclusion" formControlName="enseignesInclusion"
                                                        [(ngModel)]="enseignesSelected"
                                                        [placeholder]="'Sélectionner une enseigne'"
                                                        [settings]="dropdownSettingsEnseignes" [data]="enseignes"
                                                        class="form-control form-control-lg mb-15"
                                                        style="padding: 0; border-width: 0;"
                                                        (onSelect)="onSelectedEnseigne($event)"
                                                        (onDeSelect)="onDeSelectedEnseigne($event)">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                            <div class="accordion gray plus-icon round">
                                                <div class="row">
                                                    <div *ngFor="let enseigne of enseignesProperties | keyvalue; let j = index"
                                                         class="col-xl-3 mb-30">

                                                        <div class="acd-group {{showEnseigne.get(enseigne.key) ? 'acd-active' :''}}"
                                                             id="wrapDiv{{enseigne.key}}">

                                                            <a href="javascript:void(0);" class="acd-heading"
                                                               (click)='OpenAccordion("submenu".concat(enseigne.key),"wrapDiv".concat(enseigne.key))'>{{enseigne.key}}</a>
                                                            <div id="submenu{{enseigne.key}}"
                                                                 class="acd-des {{showEnseigne.get(enseigne.key) ? ' show' :''}}">

                                                                <div class="form-group">
                                                                    <ng-container *ngFor="let property of enseigne.value; let j = index">
                                                                        <label class="control-label">
                                                                            {{property.propertyLabel}}
                                                                        </label>
                                                                        <div class="mb-2">
                                                                            <ng-multiselect-dropdown id="forWho_{{j}}" name="forWho"
                                                                                                     [ngModelOptions]="{standalone: true}"
                                                                                                     [(ngModel)]="property.selectedPropertyValues"
                                                                                                     [placeholder]="'Sélectionner pour qui'"
                                                                                                     [settings]="dropdownSettingsForWho"
                                                                                                     [data]="property.propertyValues"
                                                                                                     class="form-control form-control-lg mb-15"
                                                                                                     style="padding: 0; border-width: 0;">
                                                                            </ng-multiselect-dropdown>
                                                                        </div>
                                                                    </ng-container>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab title="Produits" [id]="'Produits'" *ngIf="category && (category.childCategories == null || category.childCategories.length <= 0)">
                                        <ng-template ngbTabContent>
                                            <div *ngIf="products && products.length > 0" class="col-md-12">
                                                <!-- Products assigned to category -->
                                                <div class="card card-statistics mb-30">
                                                    <div class="card-body">
                                                        <div class="col-md-12 col-sm-12 col-lg-10" style="display:inline-flex">

                                                        <h6 style="margin: 10px 10px 10px 18px;">Sélectionner les produits à supprimer de cette catégorie</h6>
                                                        <div class="sm-mt-20" *ngIf="products && products.length">
                                                            <pagination [pagination]="productsYoukadoPageable"
                                                                        (onPaginate)="paginate($event)"></pagination>
                                                        </div>
                                                        </div>
                                                        <div class="col-md-12 col-sm-12 col-lg-10 " style="display:inline-flex">
                                                            <input type="checkbox" id="selectAll"  (change)="getAllProductsByCategory(category.idCategory,$event)" style="margin: 10px 10px 10px 0; margin-left: -14px;">
                                                            <h6 for="selectAll" style="margin: 7px;">Sélectionner tous les produits</h6>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-3 col-lg-5 col-md-6 col-sm-12 mb-30"
                                                                *ngFor="let product of products; let i = index;">
                                                                <input type="checkbox" name="ecoresponsable{{i}}"
                                                                    id="ecoresponsable{{i}}"
                                                                    (change)="selectProductToDelete(product.idProductYoukado, $event)"
                                                                    [checked]="category.productsToDelete != null && category.productsToDelete.indexOf(product.idProductYoukado) > -1">
                                                                <app-list-products-item
                                                                    [productYoukado]="{product:product, deliveryCountries:null}"
                                                                    [inRulesComponent]="false">
                                                                </app-list-products-item>
                                                            </div>
                                                        </div>
                                                        <div class="sm-mt-20" *ngIf="products && products.length">
                                                            <pagination [pagination]="productsYoukadoPageable"
                                                                (onPaginate)="paginate($event)"></pagination>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="!products || products.length <= 0" class="col-md-12">
                                                <!-- No products for this category -->
                                                <div class="card card-statistics mb-30">
                                                    <div class="card-body">
                                                        <h6>Pas de produits pour cette catégorie</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                </ngb-tabset>

                                <a *ngIf="category && hasAccess" class="button black medium" style="margin-top: 20px;"
                                    (click)="submit(null)">
                                    <i class="fa fa-floppy-o" aria-hidden="true" style="font-size: 21px;"></i>
                                    {{'components.category.form.save' | translate}}
                                </a>
                                <a *ngIf="category && category.published && hasAccess" class="button unpublished medium"
                                    (click)="submit(false)">
                                    <i class="fa fa-stop-circle" aria-hidden="true" style="font-size: 21px;"></i>
                                    {{'components.category.form.unpublish' | translate}}
                                </a>
                                <a *ngIf="category && !category.published && hasAccess" class="button published medium"
                                    (click)="submit(true)">
                                    <i class="fa fa-play-circle" aria-hidden="true" style="font-size: 21px;"></i>
                                    {{'components.category.form.publish' | translate}}
                                </a>

                                <a style="color:black !important;" class="button gray medium"
                                   (click)="closeModal()">
                                    <i class="fa fa-times" aria-hidden="true" style="font-size: 21px"></i>
                                    {{'components.rules.form.close' | translate}}
                                </a>

                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="alertCategorieSpecifique">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Merci de sélectionner une enseigne</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="modalService.close('alertCategorieSpecifique')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Une catégorie spécifique doit être attachée à une enseigne 
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="modalService.close('alertCategorieSpecifique')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>

<app-model class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
           aria-hidden="true" id="alertCategorieMain">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <div class="section-title mb-10">
                        <h3 class="float-left">Attention</h3>
                    </div>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Fermer"
                        (click)="modalService.close('alertCategorieMain')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="justify-content: center;" class="form-row">
                    <div style="color: red;text-align: center;" class="mb-2">
                        Une catégorie principale ne doit pas être attachée à une enseigne 
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-secondary" data-dismiss="modal"
                        (click)="modalService.close('alertCategorieMain')">Fermer
                </button>
            </div>
        </div>
    </div>
</app-model>