import {Component, Input} from '@angular/core';

import {CardApiClass} from "src/app/shared/classes/api/card-api.class";
import {ChartComponent} from "src/app/components/charts/chart.component";


@Component({
    selector: 'customhorizontalline-charts-component',
    templateUrl: './customhorizontalline-charts.component.html',
    styleUrls: ['./customhorizontalline-charts.component.css']
})
export class CustomhorizontallineChartsComponent extends ChartComponent {

    maxWidth: number;

    @Input("loading") set setLoading(loading: number) {
        this.loading = loading;
    }

    @Input("card") set setCard(card: CardApiClass) {
        if (!card) {
            return;
        }

        this.maxWidth = -1;
        card.valeur.forEach((valeur: any) => {
            this.maxWidth = Math.max(this.maxWidth, valeur.value1);
            switch (valeur.title) {
                case "Cuisine & Electro":
                    valeur.icon = "2";
                    break;
                case "Jeux & Culture":
                    valeur.icon = "10";
                    break;
                case "Vins & Gastronomie":
                    valeur.icon = "8";
                    break;
                case "Beauté & Bijoux":
                    valeur.icon = "9";
                    break;
                case "Maison & Déco":
                    valeur.icon = "1";
                    break;
                case "Image & Son":
                    valeur.icon = "5";
                    break;
                case "Jardin":
                    valeur.icon = "3";
                    break;
                case "Téléphonie & Objets connectés":
                    valeur.icon = "6";
                    break;
                case "Sports & Loisirs":
                    valeur.icon = "7";
                    break;
                case "Bricolage":
                    valeur.icon = "4";
                    break;
            }
        });

        if (!this.card) {
            this.card = card;
        } else {
            card.valeur.forEach((value: any, index: number) => {
                this.updateCardValues(this.card.valeur[index], value);
            });
        }
    }

    private updateCardValues(destination: any, source: any): void {
        destination.icon = source.icon;
        destination.title = source.title;
        destination.value1 = source.value1;
        destination.value2 = source.value2;
        destination.unit1 = source.unit1;
        destination.unit2 = source.unit2;
    }
}
