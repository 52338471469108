// @ts-ignore

import {clone} from "underscore";

export class ObjectUtils {

    static copy<T>(obj: T): T {
        // return Object.assign({}, obj) as T;
        return clone<T>(obj);
    }

    static areIdentical(a: any, b: any): boolean {
        const tA = typeof a;
        const tB = typeof b;

        if (tA !== tB) {
            return null;
        }
        switch (tA) {
            case "undefined":
            case "symbol":
                // ???
                break;
            case "string":
            case "number":
            case "boolean":
                if (a !== b) {
                    return false;
                }
                break;
            case "object": {
                if ((a && !b) || (!a && b)) {
                    return false;
                } else if (a && b) {
                    if (typeof a.getMonth === "function") {
                        // date
                        if (typeof a.getMonth !== "function" || a.getTime() !== b.getTime()) {
                            return false;
                        }
                    } else if (Array.isArray(a)) {
                        // array
                        if (!Array.isArray(b) || a.length !== b.length) {
                            return false;
                        }
                        for (let i = 0; i < a.length; i++) {
                            if (!ObjectUtils.areIdentical(a[i], b[i])) {
                                return false;
                            }
                        }
                    } else if ({}.toString.call(a) === "[object Function]") {
                        // function
                        if ({}.toString.call(a) !== "[object Function]" || a.toString() !== b.toString()) {
                            return null;
                        }
                    } else {
                        // real object ?
                        const ks = Object.keys(a);
                        if (ks.length !== Object.keys(b).length) {
                            return false;
                        }
                        for (let k of ks) {
                            if (!ObjectUtils.areIdentical(a[k], b[k])) {
                                return false;
                            }
                        }
                    }
                }
            }
        }
        return true;
    }

    static copyObjectProperties(source: any, target: any): void {
        Object.keys(source).forEach(key => {
            target[key] = source[key];
        });
    }

    static isEmpty(object: any): boolean {
        return Object.keys(object).length === 0;
    }
}
