import {Component, Injector} from '@angular/core';
import {BaseComponents} from "src/app/shared/classes/components/base-components.class";
import {SyncCatalogService} from "src/app/shared/services/sync-catalog.service";
import {ProductYoukadoDtoInterface} from "src/app/shared/interfaces/models/rest";
import {ProductUtils} from "src/app/shared/utils/product.utils";
import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";
import {PaginationUtils} from "src/app/shared/utils/pagination.utils";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

@Component({
    selector: 'list-products',
    templateUrl: './list-products.component.html',
    styleUrls: ['./list-products.component.scss']
})
export class ListProductsComponent extends BaseComponents {

    productsYoukadoPageable: PaginationbarInterface;
    productsYoukado: ProductYoukadoDtoInterface[] = [];
    productsYoukadoExtension: Map<number, {
        title: string
    }>;
    deliveryCountries: string[];
    totalElements: number;

    showDetails = false;

    constructor(protected injector: Injector,
                protected syncCatalogService: SyncCatalogService) {
        super(injector);
        this.subscriptions.push(this.syncCatalogService.onSearch.subscribe(opt => {
            if (opt) {
                this.productsYoukadoPageable = PaginationUtils.getPagination(opt.results);
                this.deliveryCountries = opt.deliveryCountries;
                this.productsYoukado = opt.results.content as any as ProductYoukadoDtoInterface[];
                this.totalElements = opt.results.totalElements;
                this.productsYoukadoExtension = new Map();
                for (let item of this.productsYoukado) {
                    const extension = {
                        title: ProductUtils.getBestTitleForProduct(item, this.translateService.currentLang)
                    }
                    this.productsYoukadoExtension.set(item.idProductYoukado, extension);
                }
            }
        }));
    }

    paginate(pagination: { page: number, size?: number }): void {
        this.syncCatalogService.syncPaginate(pagination);
    }
}
