import { Injectable, Injector } from '@angular/core';
import { RangeDtoInterface } from '../interfaces/models/rest';
import { HttpServiceClass } from "src/app/shared/classes/services/http-services.class";
import { JsonUtils } from "src/app/shared/utils/json.utils";
import { map } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { DashbordOptionsModelsInterface } from "src/app/shared/interfaces/models/dashboard/dashboard-options-models.interface";
import { DashboardDtoface } from '../interfaces/models/rest';

@Injectable({
    providedIn: 'root'
})
export class DashboardService extends HttpServiceClass<RangeDtoInterface> {

    protected endpoint = "dashboard";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: RangeDtoInterface): number {
        return item.idRange;
    }


    optionsToParams(options: DashbordOptionsModelsInterface): HttpParams {
        let httpParams = new HttpParams();
        if (!options) {
            return httpParams;
        }

        if (options.label)
            httpParams = httpParams.append("label", options.label);
        if (options.dashboardType)
            httpParams = httpParams.append("dashboardType", options.dashboardType);

        return httpParams;
    }

    generate(params?: HttpParams) {
        return this.httpClient.get<DashboardDtoface>(
            this.getPath({ path: "" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

}