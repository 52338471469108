import {Component, Injector, Input, OnInit} from '@angular/core';
import {BaseComponents} from "src/app/shared/classes/components/base-components.class"
import {
    HistoryProductSupplierPriceDtoInterface,
    HistoryProductSupplierStockDtoInterface,
    HistoryCatalogueInterface, DeliveryCountryDtoInterface, ProductYoukadoDtoInterface
} from "src/app/shared/interfaces/models/rest";
import {PricehistoryService} from "src/app/shared/services/pricehistory.service";
import {CataloguehistoryService} from "src/app/shared/services/cataloguehistory.service";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import {PaginationInterface} from "src/app/shared/interfaces/pagination.interface";
import {debounce} from "underscore";
import {ActivatedRoute} from "@angular/router";
import {NgbTabChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {StockhistoryService} from "src/app/shared/services/stockhistory.service";
import {PaginationUtils} from "src/app/shared/utils/pagination.utils";
import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";
import {DeliverycountryService} from "src/app/shared/services/deliverycountry.service";

@Component({
    selector: 'app-price-history',
    templateUrl: './pricehistory.component.html',
    styleUrls: ['./pricehistory.component.scss']
})
export class PricehistoryComponent extends BaseComponents implements OnInit {

    @Input() isProduct: boolean;
    @Input() productYoukado: ProductYoukadoDtoInterface;
    @Input() deliveryCountry: DeliveryCountryDtoInterface;

    pricehistoriesPageable: PaginationbarInterface;
    pricehistories: HistoryProductSupplierPriceDtoInterface[] = [];
    stockhistoriesPageable: PaginationbarInterface;
    stockhistories: HistoryProductSupplierStockDtoInterface[] = [];
    cataloguehistoriesPageable: PaginationbarInterface;
    cataloguehistories: HistoryCatalogueInterface[] = [];
    formGroupSearch: UntypedFormGroup;
    availableDeliveryCountries: DeliveryCountryDtoInterface[] = [];
    dropdownSettingsDeliveryCountries: any = {};
    selectedDeliveryCountries: any[] = [];

    activeTab = "historyPrice";
    activeTabsSearch: Map<string, string> = new Map();

    columns = [
        {prop: 'code'},
        {name: 'codeSupplier'},
        {name: 'modifiedDate'},
        {name: 'dutyFreeBuyingPrice'},
        {name: 'allTaxesIncludedBuyingPrice'},
        {name: 'dutyFreeShippingPrice'},
        {name: 'allTaxesIncludedShippingPrice'},
        {name: 'dutyFreeTotalBuyingPrice'},
        {name: 'allTaxesIncludedTotalBuyingPrice'},
        {name: 'vatAmount'}
    ];

    constructor(protected injector: Injector,
                protected formBuilder: UntypedFormBuilder,
                protected route: ActivatedRoute,
                protected deliverycountryService: DeliverycountryService,
                protected pricehistoryService: PricehistoryService,
                protected stockhistoryService: StockhistoryService,
                protected cataloguehistoryService: CataloguehistoryService) {
        super(injector);
        this.search = debounce(this.search, 600);
    }

    ngOnInit() {

        if (!this.isProduct) {
            this.commondataService.showLoader(false);
        } else {
            this.commondataService.showLoader(true);
        }
        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                code: new UntypedFormControl(''),
                deliveryCountries: new UntypedFormControl(''),
            });
        }

        if (this.route.snapshot.params.code != undefined) {
            this.formGroupSearch.patchValue({
                code: this.route.snapshot.params.code
            });
            this.search();
        }

        if (this.isProduct && this.productYoukado != null && this.productYoukado.idProductYoukado != null) {
            this.formGroupSearch.patchValue({
                code: this.productYoukado.code,
                deliveryCountries: [this.deliveryCountry]
            });
            this.search();
        }

        this.dropdownSettingsDeliveryCountries = {
            enableCheckAll: true,
            allowSearchFilter: true,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.deliverycountryService.list().subscribe((response) => {
            this.availableDeliveryCountries = response.sort((a, b) => a.label < b.label ? -1 : 1);
        });
    }

    codeInputEmpty() {
        console.log(this.formGroupSearch.value.deliveryCountries.length);
        return this.formGroupSearch.value.code.trim() == '' && (this.formGroupSearch.value.deliveryCountries.length == 0);
    }

    tabChange(event: NgbTabChangeEvent): void {
        this.commondataService.showLoader(true);
        this.activeTab = event.nextId;
        this.search();
    }

    search(pagination?: { page: number, size?: number }): void {
        this.commondataService.showLoader(true);
        if (!this.codeInputEmpty()) {
            if (!pagination) {
                pagination = {page: 0, size: 30};
            } else {
                pagination.size = 30;
            }

            switch (this.activeTab) {
                case "historyPrice":
                    this.performPriceSearch({search: this.getSearchParams(), pagination});
                    break;
                case "historyStock":
                    this.performStockSearch({search: this.getSearchParams(), pagination});
                    break;
                case "historyCatalogue":
                    this.performCatalogueSearch({search: this.getSearchParams(), pagination});
                    break;
            }
        } else {
            this.pricehistories = [];
            this.stockhistories = [];
            this.cataloguehistories = [];
            this.commondataService.showLoader(false);

        }
    }

    // paginate(pagination: { page: number, size?: number }): void {
    //     pagination.size = 30;
    //     this.performSearch({search: this.getSearchParams(), pagination: pagination});
    // }

    private performPriceSearch(opt?: { search: HttpParams, pagination?: PaginationInterface }): void {

        this.activeTabsSearch.set(this.activeTab, this.formGroupSearch.value.code);
        this.pricehistoryService.search(opt?.search, {pagination: opt?.pagination}).subscribe(
            (response) => {
                this.pricehistoriesPageable = PaginationUtils.getPagination(response);
                this.pricehistories = response.content;
                this.commondataService.showLoader(false);
            }, (error) => {
                this.dealWithStandardError(error);
            });
        // this.performGenericSearch<HistoryProductSupplierPriceDtoInterface>(this.pricehistoryService, this.pricehistories, this.pricehistoriesPageable, opt);
    }

    private performStockSearch(opt?: { search: HttpParams, pagination?: PaginationInterface }): void {

        this.activeTabsSearch.set(this.activeTab, this.formGroupSearch.value.code);
        this.commondataService.showLoader(true);
        this.stockhistoryService.search(opt?.search, {pagination: opt?.pagination}).subscribe(
            (response) => {
                this.stockhistoriesPageable = PaginationUtils.getPagination(response);
                this.stockhistories = response.content;
                this.commondataService.showLoader(false);
            }, (error) => {
                this.dealWithStandardError(error);
            });
    }

    private performCatalogueSearch(opt?: { search: HttpParams, pagination?: PaginationInterface }): void {
        this.activeTabsSearch.set(this.activeTab, this.formGroupSearch.value.code);
        this.commondataService.showLoader(true);
        this.cataloguehistoryService.search(opt?.search, {pagination: opt?.pagination}).subscribe(
            (response) => {
                this.cataloguehistoriesPageable = PaginationUtils.getPagination(response);
                this.cataloguehistories = response.content;
                console.log("cataloguehistories : " + JSON.stringify(response.content));
                this.commondataService.showLoader(false);
            }, (error) => {
                this.dealWithStandardError(error);
            });
    }

    private getSearchParams(): HttpParams {
        let httpParams = new HttpParams();
        if (this.formGroupSearch.value.code)
            httpParams = httpParams.append("code", this.formGroupSearch.value.code);

        httpParams = httpParams.append("deliveryCountries",this.deliveryCountry.code);
        return httpParams;
    }
}
