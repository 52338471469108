<div class="card card-statistics">
    <div class="card-body">
        <div class="main-content">
            <div class="tab round shadow">
                <label *ngIf="validationData.descriptions" style="color:red" class="control-label">
                    *Merci de compléter les informations obligatoire
                </label>
                <ngb-tabset #tabsdescriptions style="text-align: left;" class="nav nav-tabs error-border">
                    <ng-container
                            *ngFor="let translation of productYoukado.translationProductYoukados; let index = index;">
                        <ngb-tab id="{{translation.language.code}}">
                            <ng-template ngbTabTitle>
                                {{translation.language.code}}
                            </ng-template>
                            <ng-template ngbTabContent>

                                <!-- Label -->
                                <div class="form-row">
                                    <div class="col-md-8 mb-3">
                                        <label class="control-label" for="translationLabel">
                                            Libellé produit <b>({{translation.language.label}})</b>
                                        </label>
                                        <div class="mb-2">
                                            <input #translationLabel="ngModel"
                                                   (keyup)="onChangeTranslationLabel(index)"
                                                   type="text"
                                                   required
                                                   class="form-control"
                                                   id="translationLabel"
                                                   name="translationLabel"
                                                   [(ngModel)]="translation.label"/>
                                            <em class="error help-block"
                                                *ngIf="validationData.validationDataDescriptions[index] != null &&
                                                validationData.validationDataDescriptions[index].productLabel">
                                                Merci de saisir un libellé
                                            </em>
                                        </div>
                                        <div class="mb-2 multiselect-languages">
                                            <ng-multiselect-dropdown style="padding-right: 10px;width: 29%;"
                                                                     [(ngModel)]="selectedLanguagesLabel"
                                                                     [settings]="dropdownSettingsLanguages"
                                                                     [data]="languagesList"
                                                                     [placeholder]="'Sélectionner une ou plusieurs langues'"
                                                                     id="languagesLabel"
                                                                     name="languagesLabel">
                                            </ng-multiselect-dropdown>

                                            <button (click)="onClickTranslate(index, 'label')"
                                                    class="btn btn-secondary btn-translate btn-translate-label">
                                                Traduire
                                            </button>
                                            <span class="help-translate help-translate-label">
                                                Traduction du label dans les langues sélectionnées
                                                <i class="fa fa-solid fa-caret-down help-arrow"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Description -->
                                <div class="form-row">
                                    <div style="margin-bottom: 10px; width: 100%">
                                        <label style="padding-right: 10px;" class="control-label" for="description">
                                            Description <b>({{translation.language.label}})</b>
                                        </label>
                                        <label style="padding-right: 10px;padding-left: 221px;" class="control-label" for="description">
                                            <b>Coller = <span style="color:red">Ctrl+V</span></b>
                                        </label>
                                        <label style="padding-right: 10px;padding-left: 185px;" class="control-label" for="description">
                                            <b>Coller commme text brut = <span style="color:red">Ctrl+Shift+V </span></b>
                                        </label>
                                        <ck-editor *ngIf="showCkeditor" #description id="description"
                                                   (change)="onChangeTranslationDescription(index)"
                                                   [(ngModel)]="translation.description"
                                                   name="description"
                                                   skin="moono-lisa"
                                                   language="FR"
                                                   [fullPage]="true">
                                        </ck-editor>

                                        <em class="error help-block"
                                            *ngIf="validationData.validationDataDescriptions[index] != null &&
                                            validationData.validationDataDescriptions[index].description">
                                            Merci de saisir une description
                                        </em>
                                        <div *ngFor="let btn of templateBtns"
                                             id="template-buttons"
                                             style="display: inline;">
                                            <button (click)="onClickTemplateButton(btn)"
                                                    *ngIf="btn.language.idLanguage == translation.language.idLanguage"
                                                    style="margin-top: 10px;margin-right: 10px;margin-bottom: 10px">
                                                {{ btn.name }}
                                            </button>
                                        </div>
                                        <div class="mb-2 multiselect-languages">
                                            <ng-multiselect-dropdown style="padding-right: 10px;width: 29%;"
                                                                     [(ngModel)]="selectedLanguagesDescription"
                                                                     [settings]="dropdownSettingsLanguages"
                                                                     [data]="languagesList"
                                                                     [placeholder]="'Sélectionner une ou plusieurs langues'"
                                                                     id="languagesDescription"
                                                                     name="languagesDescription">
                                            </ng-multiselect-dropdown>

                                            <button (click)="onClickTranslate(index, 'description')"
                                                    class="btn btn-secondary btn-translate btn-translate-description">
                                                Traduire
                                            </button>
                                            <span class="help-translate help-translate-description">
                                                Traduction de la description dans les langues sélectionnées
                                                <i class="fa fa-solid fa-caret-down help-arrow"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Keywords -->
                                <div class="form-row">
                                    <div class="col-md-8 mb-3">
                                        <label class="control-tags" for="tags">
                                            Mots-clés <b>({{translation.language.label}})</b>
                                        </label>
                                        <div class="mb-2">
                                            <tag-input
                                                    (onAdd)="onChangeTags(translation)"
                                                    id="tags"
                                                    [ripple]="false"
                                                    [theme]="'bootstrap'"
                                                    [(ngModel)]="translation.tags"
                                                    name="tags">
                                            </tag-input>
                                        </div>
                                        <div class="mb-2 multiselect-languages">
                                            <ng-multiselect-dropdown style="padding-right: 10px;width: 29%;"
                                                                     [(ngModel)]="selectedLanguagesTags"
                                                                     [settings]="dropdownSettingsLanguages"
                                                                     [data]="languagesList"
                                                                     [placeholder]="'Sélectionner une ou plusieurs langues'"
                                                                     id="languagesTags"
                                                                     name="languagesTags">
                                            </ng-multiselect-dropdown>

                                            <button (click)="onClickTranslate(index, 'tags')"
                                                    class="btn btn-secondary btn-translate btn-translate-tags">
                                                Traduire
                                            </button>
                                            <span class="help-translate help-translate-tags">
                                                Traduction des tags dans les langues sélectionnées
                                                <i class="fa fa-solid fa-caret-down help-arrow"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row" style="display: block">
                                    <div class="multiselect-languages" style="justify-content: flex-start;">
                                        <ng-multiselect-dropdown style="padding-right: 10px;width: 29%;"
                                                                 [(ngModel)]="selectedLanguagesAll"
                                                                 [settings]="dropdownSettingsLanguages"
                                                                 [data]="languagesList"
                                                                 [placeholder]="'Sélectionner une ou plusieurs langues'"
                                                                 id="languagesAll"
                                                                 name="languagesAll">
                                        </ng-multiselect-dropdown>
                                        <button (click)="onClickTranslate(index, 'all')"
                                                class="btn btn-secondary btn-translate btn-translate-all">
                                            Tout traduire
                                        </button>
                                    </div>
                                </div>
                                <ngb-alert (close)="translationError=false" *ngIf="translationError" type="danger">
                                    {{'components.product.errors.contactinfo' | translate}}
                                </ngb-alert>

                                <ngb-alert (close)="translationSuccess=false" *ngIf="translationSuccess" type="success">
                                    La traduction a été effectué avec succès
                                </ngb-alert>
                            </ng-template>
                        </ngb-tab>
                    </ng-container>
                </ngb-tabset>
            </div>
        </div>
    </div>
</div>
