import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponents} from "src/app/shared/classes/components/base-components.class"
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {SupplierService} from "src/app/shared/services/supplier.service";
import {
    DeliveryCountryDtoInterface,
    ExpeditionCountryDtoInterface,
    LanguageDtoInterface,
    SupplierDtoInterface,
    SupplierToDeliveryCountryDtoInterface, UserDtoInterface
} from "src/app/shared/interfaces/models/rest";
import {HttpParams} from "@angular/common/http";
import {PaginationInterface} from "src/app/shared/interfaces/pagination.interface";
import {debounce} from "underscore";
import {DeliverycountryService} from "src/app/shared/services/deliverycountry.service";
import {ExpeditioncountryService} from "src/app/shared/services/expeditioncountry.service";
import {ModalService} from "src/app/shared/services";
import {LanguageService} from "src/app/shared/services/language.service";
import {UserService} from "src/app/shared/services/user.service";
import {PaginationbarInterface} from "src/app/shared/interfaces/paginationbar.interface";
import {PaginationUtils} from "src/app/shared/utils/pagination.utils";
import {CommonDataService} from './../../common-data.service';
import {KeycloakService} from 'keycloak-angular';
import {ExportSupplierService} from "src/app/shared/services/export-supplier.service";
import * as XLSX from 'xlsx';
@Component({
    selector: 'supplier',
    templateUrl: './supplier.component.html',
    styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent extends BaseComponents implements OnInit {

    supplierPageable: PaginationbarInterface;
    suppliers: SupplierDtoInterface[];
    supplier: SupplierDtoInterface;
    availableDeliveryCountries: DeliveryCountryDtoInterface[] = [];
    availableExpeditionCountries: ExpeditionCountryDtoInterface[] = [];
    availableDeliveryCountriesToAdd: DeliveryCountryDtoInterface[] = [];
    availableLanguages: LanguageDtoInterface[] = [];
    referents: UserDtoInterface[] = [];
    expeditionCountry: any[] = [];
    referent: any[] = [];
    language: any[] = [];
    deliveryCountryActif: string = "FR";
    selectedDeliveryCountries: any[] = [];
    formGroup: UntypedFormGroup;
    formGroupSearch: UntypedFormGroup;
    dropdownSettingsDeliveryCountries: any = {};
    dropdownSettingExpeditionCountries: any = {};
    hasAccess: boolean = false;
    totalElements: number;
    isRecentCountries: boolean = false;

    availableProductTypes = [
        "Cadeau",
        "Service",
        "Évènement"
    ];

    typeSupplierList = [
        {
            label: 'Automatique',
            code: 'AUTO',
        },
        {
            label: 'Manuel',
            code: 'MANU',
        }
    ];

    typeImportCommandSupplierList = [
        {
            label: 'Manuel',
            code: 'MANUEL',
        },
        {
            label: 'Autonome',
            code: 'AUTONOME',
        },
        {
            label: 'Automatique standard',
            code: 'AUTOMATIQUE STANDARD',
        },
        {
            label: 'Automatique spécifique',
            code: 'AUTOMATIQUE SPECIFIQUE',
        }
    ];

    typeSendCommandSupplierList = [
        {
            label: 'Manuel',
            code: 'MANUEL',
        },
        {
            label: 'Autonome',
            code: 'AUTONOME',
        },
        {
            label: 'Automatique standard',
            code: 'AUTOMATIQUE STANDARD',
        },
        {
            label: 'Automatique spécifique',
            code: 'AUTOMATIQUE SPECIFIQUE',
        },
        {
            label: 'Inactif',
            code: 'INACTIF',
        }
    ];

    constructor(protected injector: Injector,
                protected formBuilder: UntypedFormBuilder,
                protected modalService: ModalService,
                protected deliverycountryService: DeliverycountryService,
                protected expeditioncountryService: ExpeditioncountryService,
                protected languageService: LanguageService,
                protected userService: UserService,
                private _commondata: CommonDataService,
                protected supplierService: SupplierService,
                protected exportSupplierService:ExportSupplierService,
                private keycloakService: KeycloakService) {
        super(injector);
        this.search = debounce(this.search, 300);
    }

    ngOnInit() {

        this.hasAccess = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_DIRECTION_GENERALE')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_SUPER_ADMIN')
            || this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles.includes('ROLE_ACHATS');

        this.commondataService.showLoader(true);

        this.countriesRecentlyAdded = [];
        this.actualCodeSequence = null;

        this.supplier = this.createNewSupplier();
        this.dropdownSettingsDeliveryCountries = {
            enableCheckAll: true,
            allowSearchFilter: true,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.dropdownSettingExpeditionCountries = {
            enableCheckAll: true,
            allowSearchFilter: true,
            textField: "label",
            idField: "code",
            selectAllText: "Sélectionner tout",
            unSelectAllText: "Désélectionner tout",
            searchPlaceholderText: "Rechercher"
        };

        this.deliverycountryService.list().subscribe((response) => {
            this.availableDeliveryCountries = response.sort((a, b) => a.label < b.label ? -1 : 1);
        });

        this.expeditioncountryService.list().subscribe((response) => {
            this.availableExpeditionCountries = response.sort((a, b) => a.label < b.label ? -1 : 1);
        });

        this.languageService.list().subscribe((response) => {
            this.availableLanguages = response.sort((a, b) => a.code < b.code ? -1 : 1);
        });

        let httpParams = new HttpParams();
        httpParams = httpParams.append("roles", "ROLE_ACHATS");
        this.userService.list(httpParams).subscribe((response) => {
            this.referents = response.sort((a, b) => a.name < b.name ? -1 : 1);
            console.log(JSON.stringify(this.referents));
        });

        if (!this.formGroupSearch) {
            this.formGroupSearch = this.formBuilder.group({
                keywords: new UntypedFormControl(''),
                deliveryCountries: new UntypedFormControl(''),
                expeditionCountries: new UntypedFormControl(''),
                searchTypeAuto: new UntypedFormControl(false),
                searchTypeManu: new UntypedFormControl(false)
            });
        }

        if (!this.formGroup) {
            this.formGroup = this.formBuilder.group({
                code: new UntypedFormControl('', [Validators.required]),
                label: new UntypedFormControl('', [Validators.required]),
                productType: new UntypedFormControl('', [Validators.required]),
                expeditionCountry: new UntypedFormControl('', [Validators.required]),
                language: new UntypedFormControl(''),
                referent: new UntypedFormControl(''),
                dropShipping: new UntypedFormControl(false),
                externe: new UntypedFormControl(false),
                destinationCountries: this.formBuilder.array([
                    this.formBuilder.group({
                        code: new UntypedFormControl('', [Validators.required]),
                        codeSequence: new UntypedFormControl('', [Validators.required]),
                        email: new UntypedFormControl('', [Validators.required]),
                        notification: new UntypedFormControl('', [Validators.required]),
                        reminderEmail: new UntypedFormControl(''),
                        reminderEmailDelay: new UntypedFormControl(''),
                        shippingCosts: new UntypedFormControl(''),
                        typeCatalogue: new UntypedFormControl('', [Validators.required]),
                        actualisationType: new UntypedFormControl('', [Validators.required]),
                        orderType: new UntypedFormControl('', [Validators.required]),
                        vatProduct: new UntypedFormControl(false),
                        vatShipping: new UntypedFormControl(false),
                        thirdParty: new UntypedFormControl('', [Validators.required])
                    })
                ], Validators.required),
                phone: new UntypedFormControl(false),
                address1: new UntypedFormControl(''),
                address2: new UntypedFormControl(''),
                zip: new UntypedFormControl(''),
                city: new UntypedFormControl(''),
            });
        }
        this.performSearch({search: this.getSearchParams(), pagination: {page: 0, size: 24}});
    }

    get getDestinationCountriesControls(): AbstractControl[] {
        return (this.formGroup.controls.destinationCountries as UntypedFormArray).controls;
    }

    // Sets the active country and checks if it is among the recently added countries.
    setActiveCountry(codeSequence: string) {
        // Set the code sequence as the active country
        this.deliveryCountryActif = codeSequence;

        // Extract the country code from the code sequence
        const countryCode = this.deliveryCountryActif.split("_")[1];

        // Check if the country is among the recently added countries
        this.isRecentCountries = this.countriesRecentlyAdded.findIndex(e => e.code === countryCode) !== -1;
    }

    /**
     * Updates the fields for all countries based on the values of the current active country.
     * It copies the values from the active country to other countries if they are recent countries.
     */
    updateFieldsForAllCountries() {

        // Get the destination countries form array
        const destinationCountries = this.formGroup.controls.destinationCountries as UntypedFormArray;
        const destinationCountriesArray = destinationCountries?.getRawValue() as any[];
        const currentElementControl = this.getDestinationCountriesControls?.find(control =>
            control.value.codeSequence === this.deliveryCountryActif
        );

        // Iterate through each destination country
        destinationCountriesArray?.forEach((destinationCountry: any, index: number) => {
            const destinationCountryControl = destinationCountries?.at(index) as UntypedFormGroup;
            if (this.findRecentCountries(destinationCountryControl)) {
                for (const key in destinationCountryControl.controls) {
                    if (currentElementControl && key !== 'codeSequence' && key !== 'code' && key !== 'vatProduct' && key !== 'vatShipping') {
                        if (destinationCountryControl.controls[key].value === '') {
                            destinationCountryControl.controls[key].setValue(currentElementControl.value[key]);
                        }
                    } else if ((key === 'vatProduct' && destinationCountryControl.controls[key].value === false) || (key === 'vatShipping' && destinationCountryControl.controls[key].value === false)) {
                        destinationCountryControl.controls[key].setValue(currentElementControl.value[key]);
                    }
                }
            }
        });

    }

    search(): void {
        this.commondataService.showLoader(true);
        this.performSearch({search: this.getSearchParams(), pagination: {page: 0, size: 24}});
    }

    paginate(pagination: { page: number, size?: number }): void {
        pagination.size = 24;
        this.performSearch({search: this.getSearchParams(), pagination: pagination});
    }

    actualCodeSequence: any;

    add(): void {
        this.supplier = this.createNewSupplier();
        this.supplierService.getNextValSequence().subscribe(
            (response) => {
                this.actualCodeSequence = response;
                this.supplier.code = "FRS" + response;

                this.formGroup.patchValue({
                    code: this.supplier.code,
                    label: this.supplier.label,
                    productType: this.supplier.defaultProductType,
                    language: this.supplier.language,
                    referent: this.supplier.referent,
                    dropShipping: this.supplier.dropShipping,
                    externe: this.supplier.externe,
                    expeditionCountry: this.supplier.expeditionCountrySupplier ? [this.supplier.expeditionCountrySupplier] : [],
                    phone: this.supplier.phone,
                    address1: this.supplier.adresse_1,
                    address2: this.supplier.adresse_2,
                    zip: this.supplier.zip_code,
                    city: this.supplier.city
                });

                this.formGroup.controls.expeditionCountry.markAsPristine();
                this.formGroup.controls.language.markAsPristine();
                this.formGroup.controls.referent.markAsPristine();
                (this.formGroup.controls.destinationCountries as UntypedFormArray).clear();
                this.getAllDeliveryCountriesToAdd();

                this.modalService.open("editModal");
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }
    export():void{
        this.exportSupplierService.getSuppliersToExport().subscribe(
            (response) => {
                const currentDate = new Date();
                const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
                const fileName = `exported_suppliers_${formattedDate}`;
                this.exportDataToExcel(response, fileName);
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }
    exportDataToExcel(data: any[], fileName: string): void {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }
    edit(supplier: SupplierDtoInterface): void {
        this.supplier = supplier;
        this.supplierService.getSupplierDeliveryCountry(supplier.idSupplier).subscribe(
            (response: any) => {

                this.supplier.supplierToDeliveryCountries = response;
                for (let country of this.supplier.supplierToDeliveryCountries) {
                    if (country.code == null) {
                        country.code = country.deliveryCountryCode + this.supplier.idSupplier;
                    }
                }
                this.referent = this.supplier.referent ? [{"name": this.supplier.referent}] : [];
                this.language = this.supplier.language ? [{"code": this.supplier.language}] : [];
                console.log(JSON.stringify(this.language))
                this.formGroup.patchValue({
                    code: this.supplier.code,
                    label: this.supplier.label,
                    productType: this.supplier.defaultProductType,
                    language: this.supplier.language,
                    dropShipping: this.supplier.dropShipping,
                    externe: this.supplier.externe,
                    expeditionCountry: this.supplier.expeditionCountrySupplier ? [this.supplier.expeditionCountrySupplier] : [],
                    phone: this.supplier.phone,
                    address1: this.supplier.adresse_1,
                    address2: this.supplier.adresse_2,
                    zip: this.supplier.zip_code,
                    city: this.supplier.city
                });
                this.expeditionCountry.push(this.supplier.expeditionCountrySupplier ? this.supplier.expeditionCountrySupplier : [])
                // this.formGroup.controls.expeditionCountry.markAsUntouched();
                this.formGroup.controls.expeditionCountry.markAsPristine();
                this.formGroup.controls.language.markAsPristine();
                this.formGroup.controls.referent.markAsPristine();
                (this.formGroup.controls.destinationCountries as UntypedFormArray).clear();

                this.supplier.supplierToDeliveryCountries.forEach(supplierToDeliveryCountry => {
                    // I don't like to redefine the validation though...
                    (this.formGroup.controls.destinationCountries as UntypedFormArray).push(new UntypedFormGroup({
                        code: new UntypedFormControl([supplierToDeliveryCountry.deliveryCountriesSupplier], [Validators.required]),
                        codeSequence: new UntypedFormControl(supplierToDeliveryCountry.code, [Validators.required]),
                        email: new UntypedFormControl(supplierToDeliveryCountry.mail, [Validators.required]),
                        notification: new UntypedFormControl(supplierToDeliveryCountry.mailNotification, [Validators.required]),
                        reminderEmail: new UntypedFormControl(supplierToDeliveryCountry.reminderEmail),
                        reminderEmailDelay: new UntypedFormControl(supplierToDeliveryCountry.reminderEmailDelay),
                        shippingCosts: new UntypedFormControl(supplierToDeliveryCountry.shipping_costs_formula),
                        typeCatalogue: new UntypedFormControl(supplierToDeliveryCountry.typeCatalogue, [Validators.required]),
                        actualisationType: new UntypedFormControl(supplierToDeliveryCountry.actualisationType, [Validators.required]),
                        orderType: new UntypedFormControl(supplierToDeliveryCountry.orderType, [Validators.required]),
                        vatProduct: new UntypedFormControl(supplierToDeliveryCountry.desactivatVatProduct),
                        vatShipping: new UntypedFormControl(supplierToDeliveryCountry.desactivateVatShipping),
                        thirdParty: new UntypedFormControl(supplierToDeliveryCountry.third_party_account, [Validators.required])
                    }));
                });
                this.countriesRecentlyAdded = [];
                this.actualCodeSequence = null;
                this.getAllDeliveryCountriesToAdd();
                if (this.supplier.supplierToDeliveryCountries != null && this.supplier.supplierToDeliveryCountries.length > 0) {
                    this.deliveryCountryActif = this.supplier.supplierToDeliveryCountries[0].code;
                }
                this.modalService.open("editModal");
            },
            (error) => {
                console.log('Error : ' + JSON.stringify(error));
            }
        );
    }

    submit(): void {
        if (!this.formGroup.valid) {
            this.formGroup.controls.code.markAsTouched();
            this.formGroup.controls.label.markAsTouched();
            this.formGroup.controls.productType.markAsTouched();
            this.formGroup.controls.language.markAsTouched();
            this.formGroup.controls.referent.markAsTouched();
            this.formGroup.controls.expeditionCountry.markAsTouched();
            (this.formGroup.controls.destinationCountries as UntypedFormArray).controls.forEach(item => {
                item.markAsTouched();
            });
            return;
        }

        this.updateSupplierWithForm(this.supplier);
        this.commondataService.showLoader(true);
        if (!this.supplier.idSupplier) {
            // create
            this.dealWithStandardObservableSubscription(
                this.supplierService.post(this.supplier), {
                    success: (response) => {
                        console.log("post")
                        this.countriesRecentlyAdded = [];
                        this.supplier.idSupplier = response.idSupplier;
                        this.suppliers.push(this.supplier);
                        this.closeModal('editModal');
                    }
                }
            );
        } else {
            // update
            this.dealWithStandardObservableSubscription(
                this.supplierService.put(this.supplier), {
                    success: () => {
                        console.log("put")
                        this.countriesRecentlyAdded = [];
                        this.closeModal('editModal');
                    }
                }
            );
        }
    }

    removeDestinationCountry(index: number) {
        (this.formGroup.controls.destinationCountries as UntypedFormArray).removeAt(index);
        this.getAllDeliveryCountriesToAdd();
        if (this.supplier.supplierToDeliveryCountries != null && this.supplier.supplierToDeliveryCountries.length > 0) {
            this.deliveryCountryActif = this.supplier.supplierToDeliveryCountries[0].code;
        }

        for (let formDeliveryCountry of (this.formGroup.controls.destinationCountries as UntypedFormArray).controls) {
            this.deliveryCountryActif = formDeliveryCountry.value.codeSequence;
        }
    }

    closeModal(id: string): void {
        this.modalService.close(id);
        if (id == 'deliveryCountries') {
            this.closeModal('editModal');
            this.modalService.open('editModal');
        }
    }

    closeModalForm(id: string): void {
        this.formReset();
        this.modalService.close(id);
    }

    getAbstractControllerAsFromGroup(item: AbstractControl) {
        return item as UntypedFormGroup;
    }

    private performSearch(opt?: { search: HttpParams, pagination?: PaginationInterface }): void {
        this._commondata.showLoader(true);
        this.supplierService.search(opt?.search, {pagination: opt?.pagination}).subscribe(
            (response) => {
                //response.content.forEach(item => item.supplierToDeliveryCountries.sort((a, b) => a.deliveryCountryCode < b.deliveryCountryCode ? -1 : 1));
                this.supplierPageable = PaginationUtils.getPagination(response);
                this.suppliers = response.content;
                this.commondataService.showLoader(false);
                this._commondata.showLoader(false);
                this.totalElements = response.totalElements;
            }, (error) => {
                this.dealWithStandardError(error);
                this._commondata.showLoader(false);
            });
    }

    private getSearchParams(): HttpParams {
        let httpParams = new HttpParams();
        if (this.formGroupSearch.value.keywords)
            httpParams = httpParams.append("keywords", this.formGroupSearch.value.keywords);
        if (this.formGroupSearch.value.deliveryCountries && this.formGroupSearch.value.deliveryCountries.length)
            httpParams = httpParams.append("deliveryCountries", (this.formGroupSearch.value.deliveryCountries as DeliveryCountryDtoInterface[]).map(item => item.code).join(","));
        if (this.formGroupSearch.value.expeditionCountries && this.formGroupSearch.value.expeditionCountries.length)
            httpParams = httpParams.append("expeditionCountries", (this.formGroupSearch.value.expeditionCountries as ExpeditionCountryDtoInterface[]).map(item => item.code).join(","));
        if (this.formGroupSearch.value.searchTypeAuto !== null && this.formGroupSearch.value.searchTypeAuto) {
            httpParams = httpParams.append("typeCatalogue", "AUTO");
        }
        if (this.formGroupSearch.value.searchTypeManu !== null && this.formGroupSearch.value.searchTypeManu) {
            httpParams = httpParams.append("typeCatalogue", "MANU");
        }
        httpParams = httpParams.append("loadData", true);
        return httpParams;
    }

    private updateSupplierWithForm(supplier: SupplierDtoInterface): void {
        supplier.code = this.formGroup.value.code;
        supplier.label = this.formGroup.value.label;
        supplier.defaultProductType = this.formGroup.value.productType;
        supplier.expeditionCountrySupplier = this.formGroup.value.expeditionCountry ? this.formGroup.value.expeditionCountry[0] : null;
        supplier.phone = this.formGroup.value.phone;
        supplier.adresse_1 = this.formGroup.value.address1;
        supplier.adresse_2 = this.formGroup.value.address2;
        supplier.zip_code = this.formGroup.value.zip;
        supplier.city = this.formGroup.value.city;
        supplier.dropShipping= this.formGroup.value.dropShipping;
        supplier.externe= this.formGroup.value.externe;
        if (this.formGroup.value.language != undefined) {
            supplier.language = this.formGroup.value.language[0].code;
        }

        if (this.formGroup.value.referent != undefined) {
            supplier.referent = this.formGroup.value.referent[0].name;
        }

        for (let i = 0; i < supplier.supplierToDeliveryCountries.length; i++) {
            const deliveryCountry = supplier.supplierToDeliveryCountries[i];
            const formDeliveryCountry = (this.formGroup.controls.destinationCountries as UntypedFormArray).controls.find(item => item.value.code[0].code === deliveryCountry.deliveryCountryCode);
            if (formDeliveryCountry) {
                // update
                this.updateSupplierToDeliveryCountryWithForm(deliveryCountry, formDeliveryCountry);
            } else {
                // delete
                supplier.supplierToDeliveryCountries.splice(i, 1);
                i--;
            }
        }
        for (let formDeliveryCountry of (this.formGroup.controls.destinationCountries as UntypedFormArray).controls) {
            const deliveryCountry = supplier.supplierToDeliveryCountries.find(item => item.deliveryCountryCode === formDeliveryCountry.value.code[0].code);
            if (!deliveryCountry) {
                // create
                const supplierToDeliveryCountry = this.createNewSupplierToCountry();
                this.updateSupplierToDeliveryCountryWithForm(supplierToDeliveryCountry, formDeliveryCountry);
                supplier.supplierToDeliveryCountries.push(supplierToDeliveryCountry);
            }
        }
    }

    private updateSupplierToDeliveryCountryWithForm(supplierToDeliveryCountry: SupplierToDeliveryCountryDtoInterface, formSupplierToDeliveryCountry: any) {
        supplierToDeliveryCountry.deliveryCountryCode = formSupplierToDeliveryCountry.value.code[0].code;
        supplierToDeliveryCountry.deliveryCountriesSupplier = this.availableDeliveryCountries.find(item => item.code === supplierToDeliveryCountry.deliveryCountryCode);
        supplierToDeliveryCountry.mail = formSupplierToDeliveryCountry.value.email;
        supplierToDeliveryCountry.code = formSupplierToDeliveryCountry.value.codeSequence;
        supplierToDeliveryCountry.mailNotification = formSupplierToDeliveryCountry.value.notification;
        supplierToDeliveryCountry.reminderEmail = formSupplierToDeliveryCountry.value.reminderEmail;
        supplierToDeliveryCountry.reminderEmailDelay = formSupplierToDeliveryCountry.value.reminderEmailDelay;
        supplierToDeliveryCountry.shipping_costs_formula = formSupplierToDeliveryCountry.value.shippingCosts;
        supplierToDeliveryCountry.typeCatalogue = formSupplierToDeliveryCountry.value.typeCatalogue;
        supplierToDeliveryCountry.actualisationType = formSupplierToDeliveryCountry.value.actualisationType;
        supplierToDeliveryCountry.orderType = formSupplierToDeliveryCountry.value.orderType;
        supplierToDeliveryCountry.desactivatVatProduct = formSupplierToDeliveryCountry.value.vatProduct;
        supplierToDeliveryCountry.desactivateVatShipping = formSupplierToDeliveryCountry.value.vatShipping;
        supplierToDeliveryCountry.third_party_account = formSupplierToDeliveryCountry.value.thirdParty;
    }

    private formReset(): void {
        this.supplier = this.createNewSupplier();
        this.formGroup.reset();
        (this.formGroup.controls.destinationCountries as UntypedFormArray).clear();
    }

    private createNewSupplier(): SupplierDtoInterface {
        return {
            idSupplier: null,
            code: null,
            label: null,
            type: null,
            expeditionCountrySupplier: null,
            supplierToDeliveryCountries: [],
            defaultProductType: null,
            adresse_1: "",
            adresse_2: "",
            city: "",
            country: "",
            phone: "",
            zip_code: "",
            language: null,
            referent: null,
            dropShipping: false,
            externe: false,
            origin: '',
            createdDate: null
        };
    }

    private createNewSupplierToCountry(): SupplierToDeliveryCountryDtoInterface {
        return {
            idSupplierToDeliveryCountry: null,
            deliveryCountryCode: null,
            deliveryCountriesSupplier: null,
            mail: null,
            mailNotification: null,
            reminderEmail: null,
            reminderEmailDelay: null,
            shipping_costs_formula: null,
            code: null,
            third_party_account: null,
            desactivatVatProduct: false,
            desactivateVatShipping: false,
            discount: null,
            stockMinSecurity: null,
            typeCatalogue: null,
            actualisationType: null,
            orderType: null
        };
    }

    addDeliveryCountryModal() {
        this.modalService.open("deliveryCountries");
    }

    countriesRecentlyAdded: any[] = [];

    addDeliveryCountry() {
        for (let deliveryCountry of this.selectedDeliveryCountries) {
            let deliveryCountryToAdd = this.availableDeliveryCountriesToAdd.find(d => d.code == deliveryCountry.code);
            (this.formGroup.controls.destinationCountries as UntypedFormArray).push(new UntypedFormGroup({
                code: new UntypedFormControl([deliveryCountryToAdd], [Validators.required]),
                codeSequence: new UntypedFormControl(this.supplier.code + "_" + deliveryCountryToAdd.code),
                email: new UntypedFormControl('', [Validators.required]),
                notification: new UntypedFormControl('', [Validators.required]),
                reminderEmail: new UntypedFormControl(''),
                reminderEmailDelay: new UntypedFormControl(''),
                shippingCosts: new UntypedFormControl(''),
                typeCatalogue: new UntypedFormControl('', [Validators.required]),
                actualisationType: new UntypedFormControl('', [Validators.required]),
                orderType: new UntypedFormControl('', [Validators.required]),
                vatProduct: new UntypedFormControl(false),
                vatShipping: new UntypedFormControl(false),
                thirdParty: new UntypedFormControl('', [Validators.required])
            }));

            this.countriesRecentlyAdded.push(deliveryCountryToAdd);
            console.log("selectedDeliveryCountries : " + JSON.stringify(this.selectedDeliveryCountries));
            if (this.selectedDeliveryCountries != null && this.selectedDeliveryCountries.length > 0) {
                this.deliveryCountryActif = this.supplier.code + "_" + deliveryCountryToAdd.code;
            }
        }
        this.setActiveCountry(this.deliveryCountryActif);
        this.getAllDeliveryCountriesToAdd();
        this.closeModal('deliveryCountries');

    }

    getAllDeliveryCountriesToAdd() {
        this.selectedDeliveryCountries = [];
        let deliveryCountries: any = []
        this.availableDeliveryCountriesToAdd = JSON.parse(JSON.stringify(this.availableDeliveryCountries));
        for (let formDeliveryCountry of (this.formGroup.controls.destinationCountries as UntypedFormArray).controls) {
            if (formDeliveryCountry && formDeliveryCountry.value && formDeliveryCountry.value.code) {
                deliveryCountries.push(formDeliveryCountry.value.code[0].code);
            }
        }
        this.availableDeliveryCountriesToAdd = this.availableDeliveryCountriesToAdd.filter(d => !deliveryCountries.includes(d.code));
    }

    findRecentCountries(control: AbstractControl) {
        return this.countriesRecentlyAdded.find(d => d.code == control.value.code[0].code);
    }
}
