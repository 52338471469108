import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { ListProductsComponent } from './components/catalog/list-products.component';
import { SupplierComponent } from "./components/supplier/supplier.component";
import { ProductComponent } from "./components/product/product.component";
import { CategoryComponent } from "src/app/components/category/category.component";
import { PricehistoryComponent } from "src/app/components/pricehistory/pricehistory.component";
import { CategoryCatalogueComponent } from "src/app/components/category-catalogue/category-catalogue.component";
import { AccessDeniedComponent } from './components/auth/access-denied/access-denied.component';
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import {RuleComponent} from "src/app/components/rule/rule.component";
import {SalePriceComponent} from "src/app/components/sale-price/sale-price.component";
import {ExportsComponent} from "src/app/components/exports/exports.component";
import  {SecurityProfilEnum} from "src/app/shared/enums/security_profil.enum";
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import {RuleCatalogueComponent} from "src/app/components/rule-catalogue/rule-catalogue.component";


const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                pathMatch: 'full',
                children: [
                    { path: '', component: DashboardComponent },
                    // {path: '', component: SidebarCatalogComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'products',
                pathMatch: 'full',
                children: [
                    { path: '', component: ListProductsComponent },
                    // {path: '', component: SidebarCatalogComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'product',
                pathMatch: 'full',
                children: [
                    { path: '', component: ProductComponent },
                    // {path: '', component: SidebarCatalogComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'product/:id',
                pathMatch: 'full',
                children: [
                    { path: '', component: ProductComponent },
                    // {path: '', component: SidebarCatalogComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'categories',
                pathMatch: 'full',
                children: [
                    { path: '', component: CategoryComponent },
                    // {path: '', component: SidebarCatalogComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'suppliers',
                pathMatch: 'full',
                children: [
                    { path: '', component: SupplierComponent },
                    // {path: '', component: DefaultSidebarsComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'pricehistory',
                pathMatch: 'full',
                children: [
                    { path: '', component: PricehistoryComponent },
                    // {path: '', component: DefaultSidebarsComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'pricehistory/:code',
                pathMatch: 'full',
                children: [
                    { path: '', component: PricehistoryComponent },
                    // {path: '', component: SidebarCatalogComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'rules',
                pathMatch: 'full',
                children: [
                    { path: '', component: RuleComponent },
                    // {path: '', component: DefaultSidebarsComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'rulescatalogue',
                pathMatch: 'full',
                children: [
                    { path: '', component: RuleCatalogueComponent },
                    // {path: '', component: DefaultSidebarsComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'categorycatalogue',
                pathMatch: 'full',
                children: [
                    { path: '', component: CategoryCatalogueComponent },
                    // {path: '', component: DefaultSidebarsComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'sale-prices',
                pathMatch: 'full',
                children: [
                    { path: '', component: SalePriceComponent },
                    // {path: '', component: DefaultSidebarsComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'exports',
                pathMatch: 'full',
                children: [
                    { path: '', component: ExportsComponent },
                ],
                canActivate: [AuthGuard],
                // The user need to have this roles to access
                data: { roles: [SecurityProfilEnum.ROLE_ACHATS, SecurityProfilEnum.ROLE_DIRECTION_GENERALE, SecurityProfilEnum.ROLE_SUPER_ADMIN, SecurityProfilEnum.ROLE_COMPTABLITE, SecurityProfilEnum.ROLE_MARKETING, SecurityProfilEnum.ROLE_SERVICE_CLIENT] },
            },
            {
                path: 'access-denied',
                pathMatch: 'full',
                children: [
                    { path: '', component: AccessDeniedComponent },
                    // {path: '', component: SidebarCatalogComponent, outlet: 'sidebar'}
                ],
                canActivate: [AuthGuard],
            },
            {
                path: '',
                redirectTo: '/products',
                pathMatch: 'full',
            },
            {
                path: '**',
                redirectTo: '/products',
                pathMatch: 'full'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
