/// <reference types="@types/googlemaps" />

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {GooglemapsService} from "src/app/shared/services/googlemaps.service";
import {MarkertypeGooglemapsEnum} from "src/app/shared/enums/googlemaps/markertype-googlemaps.enum";


@Component({
    selector: "googlemaps-component",
    templateUrl: "googlemaps.component.html",
    styleUrls: ["googlemaps.component.scss"]
    // host: {
    // 	"[style.width]": "'100%'",
    // 	"[style.height]": "'100%'",
    // 	"[style.display]": "'block'"
    // }
})

export class GooglemapsComponent implements OnInit, AfterViewInit {

    // map object, _core (and later used) by the service
    public map: google.maps.Map = null;
    private mapId: number = null;
    // position
    @HostBinding("style.width") wrapWidth = "100%";
    @HostBinding("style.height") wrapHeight = "100%";
    @HostBinding("style.display") wrapDisplay = "block";
    @Input() lat = 46.227638;
    @Input() lng = 2.213749;
    @Input() zoom = 6;
    // size stuff
    @Input() width = "100%";
    @Input() height = "100%";
    // emitters
    @Output() onReady: EventEmitter<number> = new EventEmitter();
    @Output() onMarkerClicked: EventEmitter<{ markerName: string, type: MarkertypeGooglemapsEnum }> = new EventEmitter();
    @Output() onPolygonClicked: EventEmitter<string> = new EventEmitter();
    @ViewChild("mapWrapper", {read: ViewContainerRef}) mapWrapper: ViewContainerRef;
    @ViewChild("streetViewWrapper") streetViewWrapper: ElementRef;
    private inHeight: number;
    private inWidth: number;
    private inRoutingProcessStart: boolean = null;
    private inRoutingProcessEnd: boolean = null;

    // styles
    // C:\Users\Geoffrey\_Repos\front_admin\node_modules\@types\googlemaps\style-reference.d.ts

    constructor(protected googlemapsService: GooglemapsService,
                private injector: Injector,
                private router: Router) {
        this.googlemapsService.onMarkerClick.subscribe(event => {
            if (event.mapId === this.mapId) {
                this.onMarkerClicked.emit({markerName: event.markerName, type: event.type});
            }
        });
        this.googlemapsService.onPolygonClick.subscribe(event => {
            if (event.mapId === this.mapId) {
                this.onPolygonClicked.emit(event.polygonName);
            }
        });
    }

    ngOnInit() {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                this.inRoutingProcessStart = this.mapWrapper.element.nativeElement.offsetParent != null;
            } else if (event instanceof NavigationEnd) {
                this.inRoutingProcessEnd = this.mapWrapper.element.nativeElement.offsetParent != null;
            }
            if (this.inRoutingProcessStart != null && this.inRoutingProcessEnd != null) {
                if (this.inRoutingProcessStart === false && this.inRoutingProcessEnd) {
                    console.log("I want to be the current map ! - GooglemapsComponent");
                }
                this.inRoutingProcessStart = null;
                this.inRoutingProcessEnd = null;
            }
        });
    }

    ngAfterViewInit() {
        if (this.map == null) {
            this.appendGooglemapsScript();
            this.initSize();
        }
    }

    private appendGooglemapsScript(): void {
        // removed ; why didn't that work anymore ?
        if (!this.googlemapsService.hasMaps()) {
            document.body.appendChild(Object.assign(document.createElement("script"), {
                type: "text/javascript",
                src: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js"
            }));
            // noinspection JSUnusedGlobalSymbols
            document.body.appendChild(Object.assign(document.createElement("script"), {
                type: "text/javascript",
                // no version provided.  As Google said :
                // If you do not explicitly specify a version, you will receive the experimental version by default if you are on the standard plan (this includes customers who do not provide a key).
                // If you're on the premium plan and don't specify a version, you will receive the release version by default.
                src: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCnhBtO9TubyQz9pWQ_zcXXOgPRtUcoaiI",
                onload: () => this.initGooglemaps()
            }));
        } else {
            this.initGooglemaps();
        }
    }

    // set up the map when the JS is loaded
    private initGooglemaps() {
        const mapProp = {
            center: new google.maps.LatLng(this.lat, this.lng),
            zoom: this.zoom,
            maxZoom: 18,
            minZoom: 5,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            gestureHandling: 'greedy',
            backgroundColor: 'white'
        };

        const styledMapType = new google.maps.StyledMapType(
            [
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [
                        {visibility: "off"},
                        {color: "#ff00ff"}
                    ],
                },
                {
                    elementType: "labels",
                    stylers: [{visibility: "off"}]
                },
                {
                    featureType: "administrative",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "landscape",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "poi",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "road",
                    stylers: [{visibility: "off"}],
                },
                {
                    featureType: "transit",
                    stylers: [{visibility: "off"}],
                },
            ],
            {name: "Styled Map"}
        );
        // @ts-ignore
        this.map = new google.maps.Map(document.getElementById("googleMap"), mapProp);
        this.map.mapTypes.set("styled_map", styledMapType);
        this.map.setMapTypeId("styled_map");

        this.initService();
    }

    // give component info to the service
    private initService() {
        this.mapId = this.googlemapsService.init(this.map, this.mapWrapper, this.injector);
        this.onReady.emit(this.mapId);
    }

    // init size to container
    private initSize() {
        this.inWidth = this.mapWrapper.element.nativeElement.clientWidth;
        this.inHeight = this.mapWrapper.element.nativeElement.clientHeight;
    }

    // check size if container size change
    // private checkSize() {
    //     console.log("Google Maps check size");
    //     if (this.mapWrapper.element.nativeElement.clientWidth !== this.inWidth
    //         || this.mapWrapper.element.nativeElement.clientHeight !== this.inHeight) {
    //
    //         const center = this.map.getCenter();
    //         google.maps.event.trigger(this.map, "resize");
    //         this.map.setCenter(center);
    //         this.initSize();
    //     }
    // }
}
