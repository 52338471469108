<label *ngIf="validationData.categories" style="color:red" class="control-label">*Merci d'ajouter une catégorie</label>

<div class="card card-statistics mb-30">
    <div class="card-body">
        <div class="form-row">

            <div class="col-md-12">
                <div class="card card-statistics mb-30" style="width: 100%">
                    <div class="card-body">
                        <form [formGroup]="formGroupSearch">
                            <div class="form-row align-items-center">
                                <div class="col-md-3">
                                    <div class="form-group mb-0" style="position: relative;padding-right: 5px;">
                                        <input placeholder="{{'components.category.search.keywords' | translate}}"
                                               formControlName="keywords" class="form-control search-input"
                                               (input)="search()">
                                        <button class="search-button" type="submit" style="">
                                            <i class="fa fa-search not-click"></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchMain" formControlName="searchFilter" value="searchMain"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchMain">
                                                {{'components.category.form.main' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchSpecific" formControlName="searchFilter" value="searchSpecific"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchSpecific">
                                                {{'components.category.form.specific' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchService" formControlName="searchFilter" value="searchService"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchService">
                                                {{'components.category.form.service' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2-5">
                                    <div class="form-group mb-0">
                                        <div class="form-check">
                                            <input type="radio" id="searchAnimation" formControlName="searchFilter" value="searchAnimation"
                                                   (click)="search()">
                                            <label class="form-check-label ml-2" for="searchAnimation">
                                                {{'components.category.form.animation' | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-3">
                <div class="card card-statistics mb-30" style="width: 100%">
                    <div class="card-body">
                        <h5 style="margin-bottom: 4px !important;" class="mb-5">Catégorie(s) sélectionnée(s)</h5>
                        <div style="display: flex;"
                            *ngFor="let treeCategory of treeCatgeriesSelected; let index = index;">
                            <span style="display: flex;" *ngFor="let category of treeCategory; let index = index;">
                                <i style="padding-left: 5px;padding-right: 5px;padding-top: 3px;font-size: 21px;"
                                    *ngIf="index!=0" class="fa fa-chevron-right" aria-hidden="true"></i>
                                <h6>{{category}}</h6>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-3">
                <div class="card card-statistics mb-30" style="width: 100%">
                    <div class="card-body">
                        <form [formGroup]="formGroup">
                            <categorytree-component formControlName="category" [data]="categories" [collapse]="false"
                                                    [searchCriteria]="searchCriteria" [hasCheckboxesOnlyForLastChild]="true"
                                                    (onChange)="categoryChange()" [showStatus]="true">
                                {{'components.catalog.sidebar.categories' | translate}}
                            </categorytree-component>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>