import {
    MediaToProductSupplierDtoInterface,
    MediaToProductYoukadoDtoInterface,
    ProductSupplierDtoInterface,
    ProductYoukadoDtoInterface,
    TranslationProductSupplierDtoInterface,
    TranslationProductYoukadoDtoInterface,
    DeliveryCountryDtoInterface
} from "src/app/shared/interfaces/models/rest";

export class ProductUtils {

    static getBestTitleForProduct(product: ProductYoukadoDtoInterface, currentLanguage: string): string {
        if (!product) {
            return "";
        }
        let translation = this.getBestTitleFromTranslations(product.translationProductYoukados, currentLanguage);
        if (translation) {
            return translation;
        }
        if (product.productSuppliers) {
            let translationsSuppliersAgglo: TranslationProductSupplierDtoInterface[] = [];
            for (let supplier of product.productSuppliers) {
                if (supplier.translationProductSupplier) {
                    translationsSuppliersAgglo.push(...supplier.translationProductSupplier);
                }
            }
            translation = this.getBestTitleFromTranslations(translationsSuppliersAgglo, currentLanguage);
            if (translation) {
                return translation;
            }
        }
        return "";
    }

    static getBestImageForProduct(product: ProductYoukadoDtoInterface): string {
        if (!product) {
            return "";
        }
        let image = this.getBestImageFromMedia(product.mediaToProductYoukados);
        if (image) {
            return image;
        }
        if (product.productSuppliers) {
            let mediasSuppliersAgglo: MediaToProductSupplierDtoInterface[] = [];
            for (let supplier of product.productSuppliers) {
                if (supplier.mediaToProductSuppliers) {
                    mediasSuppliersAgglo.push(...supplier.mediaToProductSuppliers);
                }
            }
            image = this.getBestImageFromMedia(mediasSuppliersAgglo);
            if (image) {
                return image;
            }
        }
        return "";
    }

    static getBestSaleInfo(product: ProductYoukadoDtoInterface, deliveryCountries?: string[]): {
        bestPrice: number,
        bestStock: number,
        totalSuppliers: number,
        totalStock: number
    } {

        if (!product || !product.productSuppliers) {
            return null;
        }

        let totalSuppliers = 0;
        let totalStock = 0;
        // let isForced = false;
        let bestPrice = null;
        let bestStock = null;


        for (let supplier of product.productSuppliers) {
            if (supplier.stock && (!deliveryCountries || !deliveryCountries.length || deliveryCountries.indexOf(supplier.deliveryCountryCode) > -1)) {
                // removed because we only receive the best product supplier for each country right now
                // if (!isForced) {
                //     isForced = supplier.forced;
                //     if (supplier.isBestChoice || !bestPrice || supplier.dutyFreeTotalBuyingPrice < bestPrice) {
                //         bestPrice = supplier.dutyFreeTotalBuyingPrice;
                //         bestStock = supplier.stock;
                //     }
                // }
                if (!bestPrice || supplier.dutyFreeTotalBuyingPrice < bestPrice) {
                    bestPrice = supplier.dutyFreeTotalBuyingPrice;
                    bestStock = supplier.stock;
                }
                totalSuppliers += supplier.countSupplier;
                totalStock += supplier.stockTotalSupplier;
            }
        }

        return { bestPrice, bestStock, totalSuppliers, totalStock };
    }


    static getCatalogueInfoByCountry(product: ProductYoukadoDtoInterface): Map<string, { state: string, margin: number, stock: number, hasEligibleSupplier: boolean, code: string, dutyFreeTotalBuyingPrice: number, totalSupplier: number, supplier: string, dutyFreeSalePrice: number }> {
        const infoByCountry = new Map<string, { state: string, margin: number, stock: number, hasEligibleSupplier: boolean, code: string, dutyFreeTotalBuyingPrice: number, totalSupplier: number, supplier: string, dutyFreeSalePrice: number }>();
        if (!product || !product.catalogues) {
            return null;
        }

        for (let catalogue of product.catalogues) {
            const info: { state: string, margin: number, stock: number, hasEligibleSupplier: boolean, code: string, dutyFreeTotalBuyingPrice: number, totalSupplier: number, supplier: string, dutyFreeSalePrice: number }
                = { state: catalogue.state, margin: catalogue.margin, stock: 0, hasEligibleSupplier: false, code: null, dutyFreeTotalBuyingPrice: 0, totalSupplier: null, supplier: null, dutyFreeSalePrice: null };
            if (product.productSuppliers) {

                const productSuppliersForced: ProductSupplierDtoInterface = product.productSuppliers.find(productSupplier =>
                    productSupplier.deliveryCountryCode === catalogue.deliveryCountriesCatalogue.code &&
                    productSupplier.forced && productSupplier.stock > 0
                    && ProductUtils.calculMargin(catalogue.dutyFreeSalePrice, productSupplier.dutyFreeTotalBuyingPrice) >= 0
                );

                if (productSuppliersForced != null) {
                    info.dutyFreeTotalBuyingPrice = productSuppliersForced.dutyFreeTotalBuyingPrice;
                    info.stock = productSuppliersForced.stock;
                    info.supplier = productSuppliersForced.supplier.label;
                } else {
                    const productSuppliersIsBestChoice: ProductSupplierDtoInterface = product.productSuppliers.find(productSupplier =>
                        productSupplier.deliveryCountryCode === catalogue.deliveryCountriesCatalogue.code &&
                        productSupplier.isBestChoice
                    );

                    if (productSuppliersIsBestChoice != null) {
                        info.dutyFreeTotalBuyingPrice = productSuppliersIsBestChoice.dutyFreeTotalBuyingPrice;
                        info.stock = productSuppliersIsBestChoice.stock;
                        info.supplier = productSuppliersIsBestChoice.supplier.label;
                    }
                }

                const productSuppliers = product.productSuppliers.filter(productSupplier =>
                    productSupplier.deliveryCountryCode === catalogue.deliveryCountriesCatalogue.code
                );

                info.totalSupplier = productSuppliers.length;
            }
            infoByCountry.set(catalogue.deliveryCountriesCatalogue.code, info);
        }

        return infoByCountry;
    }

    private static getBestTitleFromTranslations(
        translations: TranslationProductYoukadoDtoInterface[] | TranslationProductSupplierDtoInterface[],
        currentLanguage: string
    ) {
        if (!translations) {
            return null;
        }
        let defaultTranslation: string = null;
        for (let translation of translations) {
            if (translation.language && translation.language.code.toUpperCase() == currentLanguage.toUpperCase()) {
                return translation.label;
            }
            if (!defaultTranslation || translation.language.code.toUpperCase() === "FR") {
                defaultTranslation = translation.label;
            }
        }
        return defaultTranslation;
    }

    private static getBestImageFromMedia(images: MediaToProductYoukadoDtoInterface[] | MediaToProductSupplierDtoInterface[]) {
        if (!images) {
            return null;
        }
        for (let image of images) {
            if (image && image.link) {
                return image.link;
            }
        }
        return null;
    }


    static calculMargin(dutyFreeSalePrice: number, dutyFreeTotalBuyingPrice: number): number {
        const margin = dutyFreeSalePrice != null && dutyFreeTotalBuyingPrice != null && dutyFreeSalePrice != 0 ? parseFloat((((dutyFreeSalePrice - dutyFreeTotalBuyingPrice) / dutyFreeSalePrice) * 100).toFixed(2)) : 0;
        return margin;
    }

    static isSupplierActif(productSupplier: ProductSupplierDtoInterface): boolean {
        let supplierToDeliveryCountries = productSupplier.supplier.supplierToDeliveryCountries.find(pts => pts.deliveryCountriesSupplier.code == productSupplier.deliveryCountryCode);
        let isSupplierActif: boolean = true;
        if (supplierToDeliveryCountries != null && supplierToDeliveryCountries.orderType == 'INACTIF') {
            isSupplierActif = false;
        }
        return isSupplierActif;
    }

    static calculBuyingPrices(object: any, vat: any) {
        object.allTaxesIncludedBuyingPrice = parseFloat(((object.dutyFreeBuyingPrice * (vat != null ? vat.rate / 100 : 0)) + object.dutyFreeBuyingPrice).toFixed(2));
        object.vatAmount = parseFloat((object.allTaxesIncludedBuyingPrice - object.dutyFreeBuyingPrice).toFixed(2));
    }

    static calculAllTaxesIncludedShippingPrice(object: any, vatShippingCosts: any) {
        object.allTaxesIncludedShippingPrice = parseFloat(((object.dutyFreeShippingPrice * (vatShippingCosts != null ? vatShippingCosts.rate / 100 : 0)) + object.dutyFreeShippingPrice).toFixed(2));
    }

    static calculAllTotalBuyingPrice(object: any) {
        object.dutyFreeTotalBuyingPrice = object.dutyFreeBuyingPrice + object.dutyFreeShippingPrice + (object.ecoTaxes != null ? object.ecoTaxes : 0) + (object.d3e != null ? object.d3e : 0) + (object.sorecop != null ? object.sorecop : 0)
        object.allTaxesIncludedTotalBuyingPrice = object.allTaxesIncludedBuyingPrice + object.allTaxesIncludedShippingPrice + (object.ecoTaxes != null ? object.ecoTaxes : 0) + (object.d3e != null ? object.d3e : 0) + (object.sorecop != null ? object.sorecop : 0);
    }

    static isNullOrEmpty(objet: any) {
        if (typeof objet != 'undefined' && objet != null && objet.length != 0 && objet != "") {
            return false;
        }
        return true;
    }


    static sortProductSuppliers(productYoukado: ProductYoukadoDtoInterface, deliveryCountry: DeliveryCountryDtoInterface) {
        if (typeof productYoukado.productSuppliers != 'undefined' && productYoukado.productSuppliers != null && productYoukado.productSuppliers.length != 0) {
            let productSuppliers: ProductSupplierDtoInterface[] = productYoukado.productSuppliers.filter(prs => prs.deliveryCountryProductSupplier.code == deliveryCountry.code)
            let productSuppliersInactif = productSuppliers.filter(prs => prs.supplier.supplierToDeliveryCountries.filter(sd => sd.deliveryCountriesSupplier.code == deliveryCountry.code) != null && prs.supplier.supplierToDeliveryCountries.find(sd => sd.deliveryCountriesSupplier.code == deliveryCountry.code).orderType == 'INACTIF');
            productSuppliers.sort((a, b) => a.dutyFreeTotalBuyingPrice - b.dutyFreeTotalBuyingPrice);
            let productSuppliersPositifStock = productSuppliers.filter(p => p.stock > 0 && p.supplier.supplierToDeliveryCountries.filter(p => p.deliveryCountriesSupplier.code == deliveryCountry.code) != null && p.supplier.supplierToDeliveryCountries.find(sd => sd.deliveryCountriesSupplier.code == deliveryCountry.code).orderType != 'INACTIF');
            let productSuppliersNegatifStock = productSuppliers.filter(p => p.stock <= 0 && p.supplier.supplierToDeliveryCountries.filter(p => p.deliveryCountriesSupplier.code == deliveryCountry.code) != null && p.supplier.supplierToDeliveryCountries.find(sd => sd.deliveryCountriesSupplier.code == deliveryCountry.code).orderType != 'INACTIF');
            productSuppliers = [];
            for (let productSupplier of productSuppliersPositifStock) {
                productSuppliers.push(productSupplier);
            }
            for (let productSupplier of productSuppliersNegatifStock) {
                productSuppliers.push(productSupplier);
            }
            for (let productSupplier of productSuppliersInactif) {
                productSuppliers.push(productSupplier);
            }
            for (let prodSupp of productSuppliers) {
                if (prodSupp.deliveryCountryProductSupplier.code == deliveryCountry.code) {
                    prodSupp.isBestChoice = false;
                }
            }

            var keepGoing = true;
            for (let prodSupp of productSuppliers) {
                if (keepGoing) {
                    if (prodSupp.deliveryCountryProductSupplier.code == deliveryCountry.code) {
                        if (prodSupp.stock > 0) {
                            prodSupp.isBestChoice = true;
                        } else {
                            prodSupp.isBestChoice = false;
                        }
                        keepGoing = false;
                    }
                }
                productYoukado.productSuppliers = productYoukado.productSuppliers.filter(s => s.code != prodSupp.code || s.supplier.code != prodSupp.supplier.code || s.deliveryCountryProductSupplier.code != deliveryCountry.code);
                productYoukado.productSuppliers.push(prodSupp);

            }
        }
    }
}
