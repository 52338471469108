<div class="card card-statistics h-100">
    <div id="pre-loader" *ngIf="loading">
        <img src="assets/images/pre-loader/loader-01.svg" alt="">
    </div>
    <div *ngIf="card && debuggingMode"
         class="dev-card-id-label"
         (click)="showCard()">
        {{ card.divId }}
    </div>
    <div style="overflow: auto;" class="card-container" [ngClass]="{
        'blurry-1':loading == 1,
        'blurry-2':loading == 2,
        'blurry-3':loading == 3,
        'blurry-4':loading == 4,
        'blurry-5':loading >= 5
    }">

        <value-charts-component *ngIf="type === 'VALUE'"
                                [card]="card"
                                [loading]="loading"></value-charts-component>
        <table-charts-component *ngIf="type === 'TABCHART' || type === 'GENERALTABCHART'"
                                [card]="card"
                                [loading]="loading"></table-charts-component>
        <tiles-charts-component *ngIf="type === 'TILESCHART'"
                                [card]="card"
                                [loading]="loading"></tiles-charts-component>
        <image-charts-component *ngIf="type === 'IMAGE'"
                                [card]="card"
                                [loading]="loading"></image-charts-component>
        <pie-charts-component *ngIf="type === 'CAROUSEL' || type === 'NEWPIECHART'"
                              [card]="card"
                              [loading]="loading"></pie-charts-component>
        <story-charts-component *ngIf="type === 'STORY'"
                                [card]="card"
                                [loading]="loading"></story-charts-component>
        <linealt-charts-component *ngIf="type === 'MULTILINESCHART'"
                                  [card]="card"
                                  [loading]="loading"></linealt-charts-component>
        <banner-charts-component *ngIf="type === 'BANNERCARD'"
                                 [card]="card"
                                 [loading]="loading"></banner-charts-component>
        <empty-charts-component *ngIf="type === 'EMPTYCARD'"
                                [card]="card"
                                [loading]="loading"></empty-charts-component>
        <button-charts-component *ngIf="type === 'BUTTONCARD'"
                                 [card]="card"
                                 [loading]="loading"></button-charts-component>
        <customhorizontalline-charts-component *ngIf="type === 'CUSTOMLINE'"
                                               [card]="card"
                                               [loading]="loading"></customhorizontalline-charts-component>
        <map-charts-component *ngIf="type === 'COUNTRYCHART'"
                              [card]="card"
                              (onGooglemapsComponentReady)="googlemapsComponentReady($event, divId)"></map-charts-component>

    </div>
</div>
