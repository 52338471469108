import { CategoryDtoInterface, LanguageDtoInterface } from "src/app/shared/interfaces/models/rest";
import { CategorytreeExtendedModelsInterface } from "src/app/shared/interfaces/models/extended/categorytree-extended-models.interface";
import {FormArray} from "@angular/forms";

export class CategorytreeUtils {

    static treeCatgeriesBranch: string[] = [];

    // if tree is set
    static generateTree(inputCategories: CategoryDtoInterface[], setParent: Boolean): CategoryDtoInterface {
        if (!inputCategories) {
            return null;
        }

        const parentCategory = {} as CategorytreeExtendedModelsInterface;
        parentCategory.idCategory = null;
        parentCategory.code = null;

        if (setParent) {
            for (let category of inputCategories) {
                CategorytreeUtils.setParent(category as CategoryDtoInterface, parentCategory);
            }
        }

        inputCategories.sort((a, b) => a.orderForPage < b.orderForPage ? -1 : 1);

        parentCategory.childCategories = inputCategories;
        return parentCategory;
    }

    // sets parent in recursive way
    private static setParent(category: CategoryDtoInterface, parent: CategoryDtoInterface): void {
        if (!category) {
            return;
        }

        category.parentCategories = parent;
        if (category.childCategories && category.childCategories.length) {
            for (let child of category.childCategories) {
                this.setParent(child, category);
            }
            category.childCategories.sort((a, b) => a.orderForPage < b.orderForPage ? -1 : 1);
        }
    }

    // if tree is not set
    // not used anymore since tree is set in API
    // noinspection JSUnusedGlobalSymbols
    static generateTreeIfParentSet(inputCategories: CategoryDtoInterface[]): CategorytreeExtendedModelsInterface {
        const returnCategories: CategorytreeExtendedModelsInterface[] = [];

        if (!inputCategories) {
            return null
        }

        //find parents level 1
        for (let category of inputCategories.filter(item => !item.parentCategories)) {
            //find index
            const rootIndex = inputCategories.findIndex((item) => item.idCategory === category.idCategory);
            //set category level 1 into list
            returnCategories.push(category as CategorytreeExtendedModelsInterface);
            //remove category level 1 from list
            inputCategories.splice(rootIndex, 1);
            // this is the recursive method
            CategorytreeUtils.setChild(category as CategorytreeExtendedModelsInterface, inputCategories, returnCategories[returnCategories.length - 1].childCategories);
        }
        returnCategories.sort((a, b) => a.orderForPage < b.orderForPage ? 1 : -1);

        const parentCategory = {} as CategorytreeExtendedModelsInterface;
        parentCategory.idCategory = null;
        parentCategory.code = null;
        parentCategory.childCategories = returnCategories;
        return parentCategory;
    }

    // sets children in recursive way
    private static setChild(category: CategorytreeExtendedModelsInterface, data: CategoryDtoInterface[], currentChild: CategorytreeExtendedModelsInterface[]) {
        if (data.length > 0) {
            const filterArray = data.filter((item) => item.parentCategories && item.parentCategories.idCategory === category.idCategory);
            if (filterArray && filterArray.length && !currentChild) {
                currentChild = [];
            }
            for (let item of filterArray) {

                let returnCategory: CategorytreeExtendedModelsInterface;
                returnCategory = item as CategorytreeExtendedModelsInterface;

                currentChild.push(returnCategory);
                this.setChild(returnCategory, data, currentChild[currentChild.length - 1].childCategories);
            }
            if (currentChild) {
                currentChild.sort((a, b) => a.orderForPage < b.orderForPage ? -1 : 1);
            }
            category.childCategories = currentChild;
        }
    }

    static isInParent(parent: CategorytreeExtendedModelsInterface, category: number): boolean {
        if (parent) {
            if (parent.idCategory === category) {
                return true;
            } else if (parent.parentCategories) {
                return this.isInParent(parent.parentCategories, category);
            }
        }
        return false;
    }

    static findCategoryInTree(category: number, tree: CategorytreeExtendedModelsInterface): CategorytreeExtendedModelsInterface {
        if (tree.idCategory === category) {
            return tree;
        } else if (tree.childCategories && tree.childCategories.length) {
            for (let child of tree.childCategories) {
                const found = this.findCategoryInTree(category, child);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    static findParentInTree(category: number, tree: CategorytreeExtendedModelsInterface, isSpecific? : boolean, isFilter? : boolean): CategorytreeExtendedModelsInterface {
        if (tree.idCategory === category && (tree.isSpecificCategory == isSpecific || !isFilter)) {
            return tree;
        } else if (tree.childCategories && tree.childCategories.length) { 
            for (let child of tree.childCategories) {

                if(child.isSpecificCategory == isSpecific || !isFilter) {
                    const found = this.findParentInTree(category, child);
                    if (found) {
                        let label = child.translationCategoriesCategory.find(t => t.languageTranslationCategory.code == 'FR').label;
                        this.treeCatgeriesBranch.push(label);
                        return found;
                    }
                }
             
            }
        }
        return null;
    }

    static findParent(category: number, tree: CategorytreeExtendedModelsInterface, isSpecific? : boolean, isFilter? : boolean): string[] {
        this.treeCatgeriesBranch = [];
        this.findParentInTree(category, tree, isSpecific, isFilter);
        let branch: string[] = [];
        for (let label of this.treeCatgeriesBranch.reverse()) {
            branch.push(label);
        }
        return branch;
    }


    static reorderCategories(categories: CategorytreeExtendedModelsInterface[]): Map<number, CategorytreeExtendedModelsInterface> {
        const reorderedCategories: Map<number, CategorytreeExtendedModelsInterface> = new Map();

        let i = 0;
        for (let category of categories) {
            if (category.orderForPage !== i) {
                reorderedCategories.set(category.idCategory, category);
            }
            category.orderForPage = i++;
        }

        return reorderedCategories;
    }

    static unsetChildCategories(category: CategorytreeExtendedModelsInterface): void {
        category.childCategories = null;
        if (category.parentCategories) {
            this.unsetChildCategories(category.parentCategories)
        }

    }

    static getCloneWithoutRedundancy(category: CategoryDtoInterface): CategoryDtoInterface {
        let clone: CategoryDtoInterface = {
            treeCode: category.treeCode,
            idCategory: category.idCategory,
            code: category.code,
            type: category.type,
            orders: category.orders,
            published: category.published,
            orderForPage: category.orderForPage,
            orderForBanner: category.orderForBanner,
            isService: category.isService,
            disableSalePriceEnseigne: category.disableSalePriceEnseigne,
            isAnimation: category.isAnimation,
            isSpecificCategory: category.isSpecificCategory,
            isMain: null,
            archive: category.archive,
            parentCategories: {
                treeCode: null,
                idCategory: category.parentCategories ? category.parentCategories.idCategory : null,
                code: null,
                type: null,
                orders: null,
                published: null,
                orderForPage: null,
                orderForBanner: null,
                isService: null,
                disableSalePriceEnseigne: null,
                isAnimation: null,
                isSpecificCategory: null,
                isMain: null,
                archive: null,
                parentCategories: null,
                childCategories: null,
                translationCategoriesCategory: null,
                enseignesInclusion: null,
                enseignesExclusion: null,
                deliveryCountriesInclusion: null,
                deliveryCountriesExclusion: null,
                categoryToEnseigne: null,
                showFilterCatalogue: category.showFilterCatalogue,
                productsToDelete: category.productsToDelete
            },
            childCategories: null,
            translationCategoriesCategory: category.translationCategoriesCategory,
            enseignesInclusion: category.enseignesInclusion,
            enseignesExclusion: category.enseignesExclusion,
            categoryToEnseigne: category.categoryToEnseigne,
            deliveryCountriesInclusion: category.deliveryCountriesInclusion,
            deliveryCountriesExclusion: category.deliveryCountriesExclusion,
            showFilterCatalogue: category.showFilterCatalogue,
            productsToDelete: category.productsToDelete
        };

        this.unsetChildCategories(clone);
        clone.enseignesInclusion = [];
        
        return clone;
    }

    static getNewCategoryInParent(languages: LanguageDtoInterface[]): CategoryDtoInterface {
        if (!languages) {
            return null;
        }

        const category: CategoryDtoInterface = {
            treeCode: null,
            idCategory: null,
            code: null,
            type: null,
            orders: null,
            published: false,
            orderForPage: null,
            orderForBanner: null,
            isService: false,
            disableSalePriceEnseigne: false,
            isAnimation: false,
            isSpecificCategory: false,
            isMain: false,
            archive: false,
            parentCategories: null,
            translationCategoriesCategory: [],
            childCategories: null,
            enseignesInclusion: [],
            enseignesExclusion: [],
            categoryToEnseigne: [],
            deliveryCountriesInclusion: [],
            deliveryCountriesExclusion: [],
            showFilterCatalogue: true,
            productsToDelete: []
        };
        languages.forEach(language => {
            category.translationCategoriesCategory.push({
                idTranslationCategory: null,
                label: null,
                labelYoukado: null,
                banner: null,
                subBanner: null,
                description: null,
                languageTranslationCategory: language,
                idCategory: null,
                codeCategory: null,
            })
        });

        return category;
    }
}
