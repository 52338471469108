import {Injectable, Injector} from '@angular/core';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {DoApiClass} from "src/app/shared/classes/api/do-api.class";


@Injectable({
    providedIn: 'root'
})
export class DoService extends HttpServiceClass<DoApiClass> {
    protected endpoint = 'cards/cardsFilterDo';

    constructor(protected injector: Injector) {
        super(injector);
    }

    protected getId(item: DoApiClass): number {
        return 0;
    }
}
