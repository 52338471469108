import {Injectable, Injector} from '@angular/core';
import {BrandYoukadoInterface} from '../interfaces/models/rest';
import {HttpServiceClass} from "src/app/shared/classes/services/http-services.class";
import {map} from "rxjs/operators";
import {JsonUtils} from "src/app/shared/utils/json.utils";

@Injectable({
    providedIn: 'root'
})
export class BrandService extends HttpServiceClass<BrandYoukadoInterface> {
    protected endpoint = "brands";

    constructor(protected injector: Injector) {
        super(injector);
    }

    getId(item: BrandYoukadoInterface): number {
        return item.idBrandYoukado;
    }

    getBrandByLabel(params?: { brandLabel: string; }) {
        return this.httpClient.get<number>(
            this.getPath({ path: "getBrandByLabel" }), { params })
            .pipe(map(data => JsonUtils.parseDates(data)));
    }

}
