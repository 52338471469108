import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CatalogueDtoInterface} from "src/app/shared/interfaces/models/rest";

export class DateUtils {

    static readonly DELIMITER = '-';

    static parse(value: string | null): NgbDateStruct {
        console.log("parse : " + value);
        if (value) {

            let date = value.split(DateUtils.DELIMITER);
            let datePars: NgbDateStruct = {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };

            console.log("parse : " + JSON.stringify(datePars));
            return datePars;
        }

        return {
            day: 0,
            month: 0,
            year: 0
        };
    }

    static dateToNgbdate(date: Date): NgbDateStruct {
        if (!date) {
            return null;
        }
        return {day: date.getUTCDate(), month: date.getUTCMonth() + 1, year: date.getUTCFullYear()};
    }
    static previousYear(date: Date): Date {
        date.setFullYear(date.getFullYear() - 1);
        return date;
    }

    static nextYear(date: Date): Date {
        date.setFullYear(date.getFullYear() + 1);
        return date;
    }

    static beginOfMonth(date: Date): Date {
        date.setDate(1);
        return date;
    }

    static beginOfYear(date: Date): Date {
        date.setDate(1);
        date.setMonth(0)
        return date;
    }

    static endOfYear(date: Date): Date {
        date = DateUtils.beginOfYear(date);
        date = DateUtils.nextYear(date);
        date = DateUtils.previousDay(date)
        return date;
    }

    static endOfMonth(date: Date): Date {
        date = DateUtils.beginOfMonth(date);
        date = DateUtils.nextMonth(date);
        date = DateUtils.previousDay(date)
        return date;
    }

    static previousMonth(date: Date): Date {
        date.setMonth(date.getMonth() - 1);
        return date;
    }

    static nextMonth(date: Date): Date {
        date.setMonth(date.getMonth() + 1);
        return date;
    }

    static beginOfDay(date: Date): Date {
        date.setHours(0, 0, 0, 0);
        return date;
    }

    static endOfDay(date: Date): Date {
        date.setDate(date.getDate() + 1);
        return DateUtils.beginOfDay(date);
    }

    static previousDay(date: Date): Date {
        date.setDate(date.getDate() - 1);
        return date;
    }
    static format(date: NgbDateStruct | null): string {
        //console.log("format : " + JSON.stringify(date));
        return date ? date.year && date.month && date.month ? date.year + DateUtils.DELIMITER + (date.month < 10 ? "0" : "") + date.month + DateUtils.DELIMITER + (date.day < 10 ? "0" : "") + date.day : date.toString() : null;
    }


    static formatCatalogueInterfaceDate(catalogues: CatalogueDtoInterface[]) {
        for (let catalogue of catalogues) {
            // @ts-ignore
            catalogue.depublicationStartDate = catalogue.depublicationStartDate != null && catalogue.depublicationStartDate != "" ? this.format(catalogue.depublicationStartDate) : null;
            // @ts-ignore
            catalogue.depublicationEndDate = catalogue.depublicationEndDate != null && catalogue.depublicationEndDate != "" ? this.format(catalogue.depublicationEndDate) : null;
            // @ts-ignore
            catalogue.dateBoostedStart = catalogue.dateBoostedStart != null && catalogue.dateBoostedStart != "" ? this.format(catalogue.dateBoostedStart) : null;
            // @ts-ignore
            catalogue.dateBoostedEnd = catalogue.dateBoostedEnd != null && catalogue.dateBoostedEnd != "" ? this.format(catalogue.dateBoostedEnd) : null;
            // @ts-ignore
            catalogue.editionDate = catalogue.editionDate != null && catalogue.editionDate != "" ? this.format(catalogue.editionDate) : null;
        }
    }

    static parseCatalogueInterfaceDate(catalogues: CatalogueDtoInterface[]) {
        for (let catalogue of catalogues) {
            // @ts-ignore
            catalogue.depublicationStartDate = catalogue.depublicationStartDate != null && catalogue.depublicationStartDate != "" ? this.parse(catalogue.depublicationStartDate) : null;
            // @ts-ignore
            catalogue.depublicationEndDate = catalogue.depublicationEndDate != null && catalogue.depublicationEndDate != "" ? this.parse(catalogue.depublicationEndDate) : null
            // @ts-ignore
            catalogue.dateBoostedStart = catalogue.dateBoostedStart != null && catalogue.dateBoostedStart != "" ? this.parse(catalogue.dateBoostedStart) : null;
            // @ts-ignore
            catalogue.dateBoostedEnd = catalogue.dateBoostedEnd != null && catalogue.dateBoostedEnd != "" ? this.parse(catalogue.dateBoostedEnd) : null;
        }
    }

    static ngbDateToDate(ngbDate: NgbDateStruct): Date {
        if (!ngbDate) {
            return null;
        }
        return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    }
}
